#modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    box-sizing: border-box;
    padding: 20px;
    color: #fff;
  } 
.spinner-box {
    position: absolute;
    left: 50%;
    top: 50%; 
    transform: translate(-50%, -50%);
  }
  
  
  .loading-icon {
    display: flex;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    animation-name: spin;
    animation-duration: 2s;
    transition-timing-function: linear;
    animation-iteration-count: infinite;
    animation: spin 2s linear infinite;
  }
  
  .loading-icon div {
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  #hierarchy-loader{
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 530px;
    margin-top:-20px;
    background-color: #000;
    opacity: 0.7;
    box-sizing: border-box;
    color: #fff;
  }