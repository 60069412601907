@charset "UTF-8";
/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Components import. Ordering matters. See _config.scss for more options
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Flash of unstyled content (FOUC)
 *
 *  CSS fix for FOUC issue, which pops up in latest jQuery version. These styles
 *  are optional, but enabled by default. Feel free to turn them off in configuration.
 *
 * ---------------------------------------------------------------------------- */
input[type="checkbox"][data-fouc],
input[type="radio"][data-fouc] {
  visibility: hidden;
  width: 1.25rem;
  height: 1.25rem;
}

.form-check input[type="checkbox"][data-fouc],
.form-check input[type="radio"][data-fouc] {
  position: absolute;
}

.form-check-inline:not(.form-check-switchery) input[type="checkbox"][data-fouc],
.form-check-inline:not(.form-check-switchery) input[type="radio"][data-fouc] {
  position: relative;
  margin-top: 0.00002rem;
  margin-right: 0.625rem;
}

.form-check-inline.form-check-right
  .form-check-inline:not(.form-check-switchery)
  input[type="checkbox"][data-fouc],
.form-check-inline.form-check-right
  .form-check-inline:not(.form-check-switchery)
  input[type="radio"][data-fouc] {
  margin-right: 0;
  margin-left: 0.625rem;
}

.form-check-switchery input[data-fouc] {
  width: 2.375rem;
  height: 1.25rem;
}

.form-check-switchery-double.form-check input[data-fouc] {
  position: static;
}

.form-check-switch input {
  height: 2.25003rem;
  visibility: hidden;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.form-check-switch input[data-size="large"] {
  height: 2.50002rem;
}

.form-check-switch input[data-size="small"] {
  height: 2.00002rem;
}

input[type="text"][data-fouc] {
  height: 2.25003rem;
  opacity: 0;
}

input[type="text"].form-control-lg[data-fouc] {
  height: 2.50002rem;
}

input[type="text"].form-control-sm[data-fouc] {
  height: 2.00002rem;
}

select[data-fouc]:not([aria-hidden="false"]) {
  height: 2.25003rem;
  opacity: 0;
}

select.form-control-lg[data-fouc]:not([aria-hidden="false"]) {
  height: 2.50002rem;
}

select.form-control-sm[data-fouc]:not([aria-hidden="false"]) {
  height: 2.00002rem;
}

input[type="file"][data-fouc] {
  height: 2.25003rem;
  opacity: 0;
}

input[type="file"].form-control-lg[data-fouc] {
  height: 2.50002rem;
}

input[type="file"].form-control-sm[data-fouc] {
  height: 2.00002rem;
}

.wizard-form[data-fouc] {
  opacity: 0;
}

.wizard-form[data-fouc].wizard {
  opacity: 1;
}

.wizard-form[data-fouc]:not(.wizard) {
  padding-top: 6.75003rem;
  padding-bottom: 3.50003rem;
}

.wizard-form[data-fouc]:not(.wizard) fieldset:not(:first-of-type),
.wizard-form[data-fouc]:not(.wizard) h6 {
  display: none;
}

input[type="text"].colorpicker-flat-full[data-fouc] {
  height: 285px;
}

pre[data-fouc]:not(.ace_editor) {
  height: 450px;
}

pre[data-fouc].ace_editor {
  visibility: visible;
}

.noui-pips-height-helper:not(.noUi-target)[data-fouc] {
  height: 2.62503rem;
}

.noui-pips-height-helper.noui-slider-lg:not(.noUi-target)[data-fouc] {
  height: 2.75003rem;
}

.noui-pips-height-helper.noui-slider-sm:not(.noUi-target)[data-fouc] {
  height: 2.50003rem;
}

.noui-vertical-height-helper:not(.noUi-target)[data-fouc] {
  display: inline-block;
  height: 10rem;
}

input[type="text"].ion-height-helper[data-fouc] {
  height: 3.4375rem;
}

input[type="text"].ion-pips-height-helper[data-fouc] {
  height: 5.5625rem;
}

input[type="text"].noui-height-helper:not(.noUi-target)[data-fouc] {
  height: 0.375rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 * ---------------------------------------------------------------------------- */
.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pace .pace-progress {
  background: #4fc3f7;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 0;
  height: 0.125rem;
}

.pace-inactive {
  display: none;
}

/* ------------------------------------------------------------------------------
 *
 *  # Progress (loading) bars
 *
 *  Static demo of Pace themes. For demonstration purposes only. DO NOT use it in production
 *
 * ---------------------------------------------------------------------------- */
.pace-demo {
  display: inline-block;
  background-color: #37474f;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  border-radius: 0.1875rem;
  width: 88px;
  height: 88px;
}

.pace-demo .pace_progress {
  display: none;
  white-space: nowrap;
}

.theme_xbox,
.theme_xbox_sm,
.theme_xbox_lg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -24px;
}

.theme_xbox .pace_activity,
.theme_xbox_sm .pace_activity,
.theme_xbox_lg .pace_activity {
  -webkit-animation: rotation 1.5s ease-in-out infinite;
  animation: rotation 1.5s ease-in-out infinite;
  margin: auto;
  width: 48px;
  height: 48px;
}

.theme_xbox .pace_activity,
.theme_xbox_sm .pace_activity,
.theme_xbox_lg .pace_activity,
.theme_xbox .pace_activity:before,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_lg .pace_activity:before,
.theme_xbox .pace_activity:after,
.theme_xbox_sm .pace_activity:after,
.theme_xbox_lg .pace_activity:after {
  border: 2px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
}

.theme_xbox .pace_activity:before,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_lg .pace_activity:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  -webkit-animation: rotation ease-in-out 2s infinite;
  animation: rotation ease-in-out 2s infinite;
}

.theme_xbox .pace_activity:after,
.theme_xbox_sm .pace_activity:after,
.theme_xbox_lg .pace_activity:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  -webkit-animation: rotation ease-in-out 1s infinite;
  animation: rotation ease-in-out 1s infinite;
}

.theme_xbox_with_text .pace_activity {
  margin-top: -10px;
}

.theme_xbox_with_text span {
  display: block;
  margin-top: 10px;
  color: #fff;
  white-space: nowrap;
}

.theme_xbox_sm .pace_activity,
.theme_xbox_sm .pace_activity:before,
.theme_xbox_sm .pace_activity:after {
  border-width: 1px;
}

.theme_xbox_lg .pace_activity,
.theme_xbox_lg .pace_activity:before,
.theme_xbox_lg .pace_activity:after {
  border-width: 3px;
}

.theme_perspective {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -15px;
}

.theme_perspective .pace_activity {
  display: inline-block;
  background-color: #fff;
  -webkit-animation: perspective ease-in-out 1.2s infinite;
  animation: perspective ease-in-out 1.2s infinite;
  width: 30px;
  height: 30px;
}

@keyframes perspective {
  0% {
    -webkit-transform: perspective(120px);
    transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@-webkit-keyframes perspective {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

.theme_perspective_with_text .pace_activity {
  margin-top: -12px;
}

.theme_perspective_with_text span {
  display: block;
  margin-top: 15px;
  color: #fff;
  white-space: nowrap;
}

.theme_squares {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -15px;
}

.theme_squares .pace_activity {
  width: 30px;
  height: 30px;
  margin: auto;
}

.theme_squares .pace_activity:before,
.theme_squares .pace_activity:after {
  position: absolute;
  content: "";
  border: 2px solid #fff;
}

.theme_squares .pace_activity:before {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  -webkit-animation: rotation_reverse linear 2s infinite;
  animation: rotation_reverse linear 2s infinite;
}

.theme_squares .pace_activity:after {
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: 5px;
  -webkit-animation: rotation linear 2s infinite;
  animation: rotation linear 2s infinite;
}

.theme_squares_with_text .pace_activity {
  margin-top: -12px;
}

.theme_squares_with_text span {
  display: block;
  margin-top: 15px;
  color: #fff;
  white-space: nowrap;
}

.theme_tail {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -15px;
}

.theme_tail .pace_activity {
  margin: auto;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-left-color: #fff;
  border-radius: 50%;
  -webkit-animation: rotation linear 1.1s infinite;
  animation: rotation linear 1.1s infinite;
}

.theme_tail_circle .pace_activity {
  border-color: rgba(0, 0, 0, 0.15);
  border-left-color: #fff;
}

.theme_tail_with_text .pace_activity {
  margin-top: -12px;
}

.theme_tail_with_text span {
  display: block;
  margin-top: 15px;
  color: #fff;
  white-space: nowrap;
}

.theme_corners {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -7px;
}

.theme_corners .pace_activity {
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-animation: corners ease 1.5s infinite;
  animation: corners ease 1.5s infinite;
  width: 14px;
  height: 14px;
}

@keyframes corners {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
  50% {
    border-radius: 0;
    -webkit-transform: scale(2) rotate(-180deg);
    transform: scale(2) rotate(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(-360deg);
    transform: scale(1) rotate(-360deg);
  }
}

@-webkit-keyframes corners {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    border-radius: 0;
    -webkit-transform: scale(2) rotate(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(-360deg);
  }
}

.theme_corners_with_text .pace_activity {
  margin-top: -28px;
}

.theme_corners_with_text span {
  display: block;
  margin-top: 15px;
  color: #fff;
  white-space: nowrap;
}

.theme_radar {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -15px;
}

.theme_radar .pace_activity {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #fff;
  border-bottom-color: #fff;
  -webkit-animation: rotation linear 1.1s infinite;
  animation: rotation linear 1.1s infinite;
}

.theme_radar_with_text .pace_activity {
  margin-top: -12px;
}

.theme_radar_with_text span {
  display: block;
  margin-top: 15px;
  color: #fff;
  white-space: nowrap;
}

.theme_bar,
.theme_bar_lg,
.theme_bar_sm {
  position: relative;
  width: 200px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}

.theme_bar .pace_progress,
.theme_bar_lg .pace_progress,
.theme_bar_sm .pace_progress {
  max-width: 198px;
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 3px;
  background: #3fcf96;
  color: #fff;
  line-height: 36px;
  border-radius: 100px;
}

.theme_bar_lg {
  height: 6px;
}

.theme_bar_lg .pace_progress {
  height: 4px;
}

.theme_bar_sm {
  height: 4px;
}

.theme_bar_sm .pace_progress {
  height: 2px;
}

/* ------------------------------------------------------------------------------
 *
 *  # Uniform.js
 *
 *  Styles for uniform.min.js - form components styling
 *
 * ---------------------------------------------------------------------------- */
.uniform-checker,
.uniform-choice {
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}

.uniform-checker,
.uniform-checker span,
.uniform-checker input,
.uniform-choice,
.uniform-choice span,
.uniform-choice input {
  width: 1.25rem;
  height: 1.25rem;
}

.uniform-checker span,
.uniform-choice span {
  border: 0.125rem solid #455a64;
  display: inline-block;
  text-align: center;
  position: relative;
}

.uniform-checker span.checked:after,
.uniform-choice span.checked:after {
  opacity: 1;
}

.uniform-checker input[type="checkbox"],
.uniform-checker input[type="radio"],
.uniform-choice input[type="checkbox"],
.uniform-choice input[type="radio"] {
  border: 0;
  background: none;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
  visibility: visible;
  opacity: 0;
  z-index: 2;
}

.form-check.form-check-inline .uniform-checker input[type="checkbox"],
.form-check.form-check-inline .uniform-checker input[type="radio"],
.form-check.form-check-inline .uniform-choice input[type="checkbox"],
.form-check.form-check-inline .uniform-choice input[type="radio"] {
  margin-left: 0;
  margin-right: 0;
}

.form-check .uniform-checker,
.form-check .uniform-choice {
  position: absolute;
  top: 0.00002rem;
  left: 20px;
}

.form-check-right .uniform-checker,
.form-check-right .uniform-choice {
  left: auto;
  right: 0;
}

.form-check-inline .uniform-checker,
.form-check-inline .uniform-choice {
  position: static;
  margin-right: 0.625rem;
  margin-top: 0.00002rem;
}

.form-check-inline.form-check-right .uniform-checker,
.form-check-inline.form-check-right .uniform-choice {
  margin-right: 0;
  margin-left: 0.625rem;
}

.uniform-checker.disabled,
.uniform-choice.disabled {
  opacity: 0.5;
}

.uniform-checker.disabled,
.uniform-checker.disabled input,
.uniform-choice.disabled,
.uniform-choice.disabled input {
  cursor: default;
}

.uniform-checker span {
  color: #455a64;
  border-radius: 0.125rem;
  transition: border-color ease-in-out 0.15s, color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .uniform-checker span {
    transition: none;
  }
}

.uniform-checker span:after {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  line-height: 1;
  color: #43425d;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .uniform-checker span:after {
    transition: none;
  }
}

.form-check-light .uniform-checker span {
  border-color: #fff;
  color: #fff;
}

.uniform-checker[class*="border-"] span {
  border-color: inherit;
  color: inherit;
}

.uniform-choice {
  border-radius: 100px;
}

.uniform-choice span {
  border-radius: 100px;
  transition: border-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .uniform-choice span {
    transition: none;
  }
}

.uniform-choice span:after {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  border: 0.3125rem solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100px;
  opacity: 0;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .uniform-choice span:after {
    transition: none;
  }
}

.form-check-light .uniform-choice span {
  border-color: #fff;
}

.uniform-choice[class*="border-"] span {
  border-color: inherit;
}

.uniform-choice[class*="border-"] span:after {
  border-color: inherit;
}

.dropdown-menu:not([class*="bg-"])
  .dropdown-item.active:not(.disabled)
  .uniform-checker
  span {
  border-color: #fff;
  color: #fff;
}

.dropdown-menu:not([class*="bg-"])
  .dropdown-item.active:not(.disabled)
  .uniform-choice
  span {
  border-color: #fff;
}

.dropdown-item.form-check .form-check-label {
  padding-left: 3.25rem;
}

.dropdown-item.form-check .uniform-checker,
.dropdown-item.form-check .uniform-choice {
  margin-top: 0.50002rem;
  left: 1rem;
}

.dropdown-item.form-check.form-check-right .form-check-label {
  padding-right: 3.25rem;
}

.dropdown-item.form-check.form-check-right .uniform-checker,
.dropdown-item.form-check.form-check-right .uniform-choice {
  left: auto;
  right: 1rem;
}

.dropdown-menu[class*="bg-"]:not(.bg-white):not(.bg-transparent):not(.bg-light)
  .uniform-checker
  span {
  border-color: #fff;
  color: #fff;
}

.dropdown-menu[class*="bg-"]:not(.bg-white):not(.bg-transparent):not(.bg-light)
  .uniform-choice
  span {
  border-color: #fff;
}

.uniform-uploader {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.uniform-uploader .filename {
  color: #999;
  padding: 0.4375rem 0.875rem;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #ddd;
  border-right: 0;
  background-color: #fff;
  text-align: left;
  word-break: break-word;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.uniform-uploader .action {
  z-index: 1;
  border-radius: 0;
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.uniform-uploader input[type="file"] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 2.25003rem;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
}

.uniform-uploader.disabled .filename {
  background-color: #fafafa;
}

.uniform-uploader.disabled .filename,
.uniform-uploader.disabled input[type="file"] {
  cursor: default;
}

.form-control-styled-lg input[type="file"] {
  min-height: 2.50002rem;
}

.form-control-styled-lg .filename {
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.form-control-styled-sm input[type="file"] {
  min-height: 2.00002rem;
}

.form-control-styled-sm .filename {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667;
}

.uniform-select {
  position: relative;
  display: block;
  width: 100%;
  height: 2.25003rem;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .uniform-select {
    transition: none;
  }
}

.uniform-select:hover,
.uniform-select:focus {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset;
}

.uniform-select[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
  color: #fff;
}

.uniform-select[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent):hover,
.uniform-select[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent):focus {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.04) inset;
}

.uniform-select span {
  display: block;
  position: relative;
  text-align: left;
  padding-right: 1.875rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uniform-select span:after {
  content: "\e9c5";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  margin-top: -0.12502rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uniform-select select {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 2.25003rem;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
}

.uniform-select.disabled:not([class*="bg-"]) {
  color: #999;
  background-color: #fafafa;
}

.uniform-select.disabled:hover,
.uniform-select.disabled:focus {
  box-shadow: none !important;
}

.uniform-select.disabled select {
  cursor: default;
}

.uniform-select.disabled[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
  opacity: 0.75;
}

.form-control-styled-lg select:not([size]):not([multiple]) {
  height: 2.50002rem;
}

.form-control-styled-lg .uniform-select {
  height: 2.50002rem;
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.form-control-styled-sm select:not([size]):not([multiple]) {
  height: 2.00002rem;
}

.form-control-styled-sm .uniform-select {
  height: 2.00002rem;
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667;
}

/* ------------------------------------------------------------------------------
 *
 *  # Switchery
 *
 *  Styles for switchery.min.js - toggle switches
 *
 * ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  display: block;
  line-height: 1;
  width: 2.25rem;
  height: 1.125rem;
  position: relative;
  box-sizing: content-box;
  border-radius: 100px;
}

.form-check-switchery .switchery {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.00002rem;
}

.form-check-switchery.disabled .switchery {
  cursor: default;
}

.switchery > small {
  background-color: #fff;
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
}

.form-check-switchery:not(.dropdown-item) {
  padding-left: 3rem;
}

.form-check-switchery:not(.dropdown-item).form-check-right {
  padding-left: 0;
  padding-right: 3rem;
}

.form-check-switchery:not(.dropdown-item).form-check-right .switchery {
  left: auto;
  right: 0;
}

.form-check-switchery-double.form-check-switchery {
  padding-left: 0;
  padding-right: 0;
}

.form-check-switchery-double.form-check-switchery .switchery {
  margin-top: 0;
}

.form-check-switchery-double .form-check-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.form-check-switchery-double .switchery {
  position: relative;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.form-check-switchery-double input {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.dropdown-item.form-check-switchery .form-check-label {
  padding-left: 4.375rem;
}

.dropdown-item.form-check-switchery .switchery {
  top: 50%;
  left: 1rem;
  margin-top: -0.625rem;
}

.dropdown-item.form-check-right.form-check-switchery .form-check-label {
  padding-right: 4.375rem;
}

.dropdown-item.form-check-right.form-check-switchery .switchery {
  left: auto;
  right: 1rem;
}

/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*rtl:begin:ignore*/
  direction: ltr;
  text-align: left;
  /*rtl:end:ignore*/
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .bootstrap-switch {
    transition: none;
  }
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border-radius: 0.1875rem;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding: 0.4375rem 0.875rem;
}

.bootstrap-switch input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.form-check-switch {
  padding: 0;
}

.form-group.row .form-check-switch {
  margin-top: 0;
}

.form-check-switch-left .bootstrap-switch {
  margin-right: 0.625rem;
}

.form-check-switch-right .bootstrap-switch {
  margin-left: 0.625rem;
}

.bootstrap-switch-focused {
  outline: 0;
}

.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch-disabled .bootstrap-switch-label {
  cursor: default;
  opacity: 0.5;
}

.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
}

.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .bootstrap-switch-animate .bootstrap-switch-container {
    transition: none;
  }
}

/*rtl:begin:ignore*/
.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px);
}

.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px);
}

.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

/*rtl:end:ignore*/
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  white-space: nowrap;
  z-index: 1;
}

.bootstrap-switch-default {
  color: #333;
  background-color: #eee;
}

.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196f3;
}

.bootstrap-switch-danger {
  color: #fff;
  background-color: #f44336;
}

.bootstrap-switch-success {
  color: #fff;
  background-color: #4caf50;
}

.bootstrap-switch-warning {
  color: #fff;
  background-color: #ff7043;
}

.bootstrap-switch-info {
  color: #fff;
  background-color: #00bcd4;
}

/*rtl:begin:ignore*/
.bootstrap-switch-handle-on {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.bootstrap-switch-handle-off {
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px);
}

/*rtl:end:ignore*/
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  transition: background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .bootstrap-switch-label {
    transition: none;
  }
}

.bootstrap-switch-label:hover,
.bootstrap-switch-label:active {
  background-color: #fafafa;
}

.bootstrap-switch-label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.25rem;
  height: 0.625rem;
  margin-top: -0.3125rem;
  margin-left: -0.125rem;
  display: inline-block;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 0.5625rem 1rem;
}

.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 0.3125rem 0.75rem;
}

.form-check-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 0.625rem;
}

/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  padding: 0.4375rem 0;
  line-height: 1.5385;
  color: #333;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .select2-selection--single {
    transition: none;
  }
}

.select2-selection--single:hover,
.select2-selection--single:focus,
.select2-container--open .select2-selection--single {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset;
}

.select2-selection--single:not([class*="bg-"]) {
  background-color: #fff;
}

.select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
  border-color: #ddd;
}

.select2-selection--single[class*="bg-"]:hover,
.select2-selection--single[class*="bg-"]:focus,
.select2-container--open .select2-selection--single[class*="bg-"] {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.04) inset;
}

.select2-selection--single[class*="bg-"] .select2-selection__placeholder {
  color: #fff;
}

.select2-container--disabled
  .select2-selection--single[class*="bg-"]
  .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 0.875rem;
  padding-right: 2.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-selection--single .select2-selection__rendered > i {
  margin-right: 0.625rem;
}

.select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  font-size: 0;
  line-height: 1;
  margin-top: 0.12502rem;
  margin-left: 0.625rem;
  opacity: 0.75;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .select2-selection--single .select2-selection__clear {
    transition: none;
  }
}

.select2-selection--single .select2-selection__clear:hover {
  opacity: 1;
}

.select2-selection--single .select2-selection__clear:after {
  content: "\ed6b";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-selection--single .select2-selection__arrow:after {
  content: "\e9c5";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  margin-top: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--disabled .select2-selection--single {
  cursor: default;
  box-shadow: none;
}

.select2-container--disabled .select2-selection--single:not([class*="bg-"]) {
  color: #999;
  background-color: #fafafa;
}

.select2-container--disabled .select2-selection--single[class*="bg-"] {
  opacity: 0.75;
  box-shadow: none;
}

.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0.1875rem;
}

.select2-selection--multiple:not([class*="bg-"]) {
  background-color: #fff;
}

.select2-selection--multiple:not([class*="bg-"]):not([class*="border-"]) {
  border-color: #ddd;
}

.select2-selection--multiple .select2-selection__rendered {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0 0.125rem 0.125rem 0.125rem;
  width: 100%;
}

.select2-selection--multiple .select2-selection__placeholder {
  color: #999;
}

.select2-container--disabled .select2-selection--multiple:not([class*="bg-"]) {
  background-color: #fafafa;
}

.select2-container--disabled .select2-selection--multiple[class*="bg-"] {
  opacity: 0.75;
  box-shadow: none;
}

.select2-container--disabled
  .select2-selection--multiple[class*="bg-"]
  .select2-selection__choice {
  opacity: 1;
}

.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--disabled
  .select2-selection--multiple
  .select2-search__field {
  cursor: default;
}

.select2-selection--multiple .select2-selection__choice {
  background-color: #455a64;
  color: #fff;
  cursor: default;
  float: left;
  margin-right: 0.125rem;
  margin-top: 0.125rem;
  padding: 0.3125rem 0.875rem;
  border-radius: 0.1875rem;
  transition: color ease-in-out 0.15s, background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .select2-selection--multiple .select2-selection__choice {
    transition: none;
  }
}

.select2-selection--multiple .select2-selection__choice:hover,
.select2-selection--multiple .select2-selection__choice:focus {
  background-color: #455a64;
  color: #fff;
}

.select2-selection--multiple .select2-selection__choice > i {
  margin-right: 0.625rem;
}

.select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  cursor: pointer;
  float: right;
  font-size: 0.875rem;
  margin-top: 0.18752rem;
  line-height: 1;
  margin-left: 0.625rem;
  opacity: 0.75;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .select2-selection--multiple
    .select2-selection__choice
    .select2-selection__choice__remove {
    transition: none;
  }
}

.select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove:hover {
  opacity: 1;
}

.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice {
  opacity: 0.6;
}

.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice:hover,
.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice:focus {
  background-color: #455a64;
  color: #fff;
}

.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  display: none;
}

.select2-selection--multiple .select2-search--inline {
  float: left;
}

.select2-selection--multiple .select2-search--inline .select2-search__field {
  font-size: 100%;
  margin-top: 0.125rem;
  padding: 0.3125rem 0;
  background-color: transparent;
  border-width: 0;
  outline: 0;
  color: inherit;
  margin-left: 0.25rem;
  -webkit-appearance: textfield;
}

.select2-selection--multiple
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-selection--multiple
  .select2-search--inline:first-child
  .select2-search__field {
  margin-left: 0;
  padding-left: 0.75rem;
}

.select2-dropdown {
  background-color: #fff;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  position: absolute;
  /*rtl:ignore*/
  left: -100000px;
  width: 100%;
  z-index: 1070;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}

.select2-results > .select2-results__options {
  padding-bottom: 0.5rem;
  max-height: 280px;
  overflow-y: auto;
}

.select2-search--hide + .select2-results > .select2-results__options {
  padding-top: 0.5rem;
}

.select2-results:first-child > .select2-results__options {
  padding-top: 0.5rem;
}

.select2-results__option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .select2-results__option {
    transition: none;
  }
}

.select2-results__option + .select2-results__option {
  margin-top: 1px;
}

.select2-results__option i {
  margin-right: 0.625rem;
}

.select2-results__option i.icon-undefined {
  display: none;
}

.select2-results__option[role="group"] {
  padding: 0;
}

.select2-results__option.select2-results__option--highlighted {
  background-color: #f5f5f5;
  color: #333;
}

.select2-results__option[aria-disabled="true"] {
  color: #999;
  cursor: default;
}

.select2-dropdown[class*="bg-"] .select2-results__option[aria-disabled="true"] {
  color: rgba(255, 255, 255, 0.6);
}

.select2-results__option[aria-selected="true"] {
  color: #fff;
  background-color: #2196f3;
}

.select2-results__options--nested > .select2-results__option {
  padding-left: 2rem;
  padding-right: 2rem;
}

.select2-results__group {
  display: block;
  padding: 0.5rem 1rem;
  cursor: default;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.select2-results__option:first-child > .select2-results__group {
  margin-top: 0;
}

.select2-results__message {
  color: #999;
  cursor: default;
}

.select2-dropdown[class*="bg-"] .select2-results__message {
  color: rgba(255, 255, 255, 0.75);
}

.select2-results__option.loading-results {
  padding-top: 0;
}

.select2-results__option.loading-results + .select2-results__option {
  margin-top: 0.5rem;
}

.select2-results__option--load-more {
  text-align: center;
  margin-top: 0.5rem;
  cursor: default;
}

.select2-container--open .select2-dropdown {
  /*rtl:ignore*/
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--above[class*="bg-"] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--open .select2-dropdown--below[class*="bg-"] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 1rem;
}

.select2-search--dropdown:after {
  content: "\e98e";
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  left: 1.875rem;
  color: inherit;
  display: block;
  font-size: 0.8125rem;
  margin-top: -0.40625rem;
  line-height: 1;
  opacity: 0.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-search--dropdown
  + .select2-results
  .select2-results__message:first-child {
  padding-top: 0;
}

.select2-search--dropdown .select2-search__field {
  padding: 0.4375rem 0.875rem;
  padding-left: 2.5625rem;
  border: 1px solid #ddd;
  outline: 0;
  width: 100%;
  border-radius: 0.1875rem;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select-lg.select2-selection--single {
  padding: 0.5625rem 0;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.select-lg.select2-selection--single .select2-selection__rendered {
  padding-left: 1rem;
  padding-right: 2.5rem;
}

.select-lg.select2-selection--single .select2-selection__arrow:after {
  right: 1rem;
}

.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 0.4375rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.select-lg.select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  padding: 0.4375rem 0;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.select-sm.select2-selection--single {
  padding: 0.3125rem 0;
  font-size: 0.75rem;
  line-height: 1.6667;
}

.select-sm.select2-selection--single .select2-selection__rendered {
  padding-left: 0.25rem;
  padding-right: 2.15rem;
}

.select-sm.select2-selection--single .select2-selection__arrow:after {
  right: 0.75rem;
}

.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 0.1875rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667;
}

.select-sm.select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  padding: 0.1875rem 0;
  font-size: 0.75rem;
  line-height: 1.6667;
}

.select2-selection--multiple[class*="bg-"] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.2);
}

.select2-dropdown[class*="bg-"]
  .select2-search--dropdown
  .select2-search__field {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff;
}

.select2-dropdown[class*="bg-"] .select2-results__option[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.select2-dropdown[class*="bg-"] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important;
}

.select2-result-repository {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 0.9375rem;
}

.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}

.select2-result-repository__meta {
  margin-left: 70px;
}

.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 0.75rem;
}

.select2-result-repository__description {
  font-size: 0.75rem;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 0.9375rem;
}

/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiselect
*
*  Styles for multiselect.js - custom multiple select plugin
*
* ---------------------------------------------------------------------------- */
.multiselect-native-select {
  position: relative;
}

.multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
  left: 0;
  top: 0;
}

.multiselect {
  width: 100%;
  text-align: left;
  padding-left: 0.875rem;
  padding-right: 2.375rem;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiselect:after {
  position: absolute;
  top: 50%;
  right: 0.875rem;
  margin-top: -0.34375rem;
  text-align: right;
}

.multiselect:not([class*="font-weight-"]) {
  font-weight: 400;
}

.multiselect.btn-light {
  background-color: #fff;
  border-color: #ddd;
}

.multiselect.btn-light:hover,
.multiselect.btn-light:focus,
.multiselect.btn-light:not([disabled]):not(.disabled):active,
.btn-group.show .multiselect.btn-light {
  background-color: #fff;
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset;
}

.multiselect.btn-light.disabled {
  background-color: #fafafa;
}

.multiselect.btn-lg {
  padding-left: 1rem;
  padding-right: 2.5rem;
}

.multiselect.btn-lg:after {
  right: 1rem;
}

.multiselect.btn-sm {
  padding-left: 0.75rem;
  padding-right: 2.25rem;
}

.multiselect.btn-sm:after {
  right: 0.75rem;
}

.multiselect-container {
  max-height: 280px;
  overflow-y: auto;
  width: 100%;
}

.multiselect-item input[type="checkbox"],
.multiselect-item input[type="radio"] {
  border: 0;
  background: none;
  display: block;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 1rem;
  margin-top: -0.625rem;
  opacity: 0;
  z-index: 2;
  width: 1.25rem;
  height: 1.25rem;
}

.multiselect-item input[type="checkbox"]:disabled,
.multiselect-item input[type="radio"]:disabled {
  cursor: default;
}

.multiselect-item input[type="checkbox"] ~ .form-check-control-indicator,
.multiselect-item input[type="radio"] ~ .form-check-control-indicator {
  position: absolute;
  left: 1rem;
  top: 50%;
  margin-top: -0.625rem;
  border: 0.125rem solid #455a64;
  width: 1.25rem;
  height: 1.25rem;
}

.multiselect-item input[type="checkbox"] ~ .form-check-control-indicator {
  color: #455a64;
  border-radius: 0.125rem;
  transition: border-color ease-in-out 0.15s, color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .multiselect-item input[type="checkbox"] ~ .form-check-control-indicator {
    transition: none;
  }
}

.multiselect-item input[type="checkbox"] ~ .form-check-control-indicator:after {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  line-height: 1;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .multiselect-item
    input[type="checkbox"]
    ~ .form-check-control-indicator:after {
    transition: none;
  }
}

.multiselect-item input[type="radio"] ~ .form-check-control-indicator {
  border-radius: 100px;
  transition: border-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .multiselect-item input[type="radio"] ~ .form-check-control-indicator {
    transition: none;
  }
}

.multiselect-item input[type="radio"] ~ .form-check-control-indicator:after {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  border: 0.3125rem solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100px;
  opacity: 0;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .multiselect-item input[type="radio"] ~ .form-check-control-indicator:after {
    transition: none;
  }
}

.multiselect-item
  input[type="checkbox"]:checked
  ~ .form-check-control-indicator:after,
.multiselect-item
  input[type="radio"]:checked
  ~ .form-check-control-indicator:after {
  opacity: 1;
}

.multiselect-item.active:not(.disabled)
  input[type="checkbox"]
  ~ .form-check-control-indicator {
  border-color: #fff;
  color: #fff;
}

.multiselect-item.active:not(.disabled)
  input[type="radio"]
  ~ .form-check-control-indicator {
  border-color: #fff;
}

.multiselect-item.disabled .form-check-control-indicator {
  opacity: 0.5;
}

.dropdown-menu[class*="bg-"] .multiselect-item .form-check-control-indicator {
  border-color: #fff;
  color: #fff;
}

.multiselect-group {
  padding: 0.5rem 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.multiselect-group:first-child {
  margin-top: 0;
}

.multiselect-group label {
  margin-bottom: 0;
}

.multiselect-group.disabled {
  color: #999;
  cursor: default;
}

.multiselect-all {
  font-weight: 500;
}

.multiselect-group-clickable label {
  cursor: pointer;
}

.multiselect-filter {
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.multiselect-filter .input-group > i {
  font-size: 0.8125rem;
  position: absolute;
  left: 0.9375rem;
  top: 50%;
  margin-top: -0.40625rem;
  opacity: 0.5;
  z-index: 4;
}

.multiselect-filter .form-control {
  padding-left: 2.5625rem;
}

.input-group .multiselect-native-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.input-group .multiselect-native-select:not(:first-child) .multiselect {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .multiselect-native-select:not(:last-child) .multiselect {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* ------------------------------------------------------------------------------
*
*  # Passy
*
*  Styles for passy.min.js - password length checker and password generator
*
* ---------------------------------------------------------------------------- */
.badge-indicator-absolute {
  position: relative;
}

.badge-indicator-absolute .password-indicator-badge-absolute {
  position: absolute;
  top: 50%;
  margin-top: -0.62502rem;
  right: 0.875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .badge-indicator-absolute .password-indicator-badge-absolute {
    transition: none;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Twiter Typeahead
*
*  Styles for typeahead.bundle.min.js - input suggestion engine
*
* ---------------------------------------------------------------------------- */
.twitter-typeahead {
  width: 100%;
}

.tt-hint {
  color: #999;
}

.tt-menu {
  width: 100%;
  margin-top: 0.125rem;
  min-width: 11.25rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-height: 350px;
  overflow-y: auto;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.typeahead-scrollable .tt-menu {
  max-height: 250px;
}

.tt-suggestion {
  color: #333;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .tt-suggestion {
    transition: none;
  }
}

.tt-suggestion:hover,
.tt-suggestion:focus,
.tt-suggestion.tt-cursor {
  color: #333;
  background-color: #f5f5f5;
}

.tt-heading {
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tt-dataset-group:first-child .tt-heading:first-child {
  margin-top: 0;
}

.typeahead-template .empty-message {
  padding: 0.5rem 1rem;
  text-align: center;
}

/* ------------------------------------------------------------------------------
*
*  # Form Validation
*
*  Styles for validate.min.js - jQuery plugin for simple clientside form validation
*
* ---------------------------------------------------------------------------- */
.validation-invalid-label,
.validation-valid-label {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  color: #f44336;
  position: relative;
  padding-left: 1.625rem;
}

.validation-valid-label {
  color: #4caf50;
}

.validation-invalid-label:before,
.validation-valid-label:before {
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 0.12502rem;
  left: 0;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.validation-invalid-label:empty,
.validation-valid-label:empty {
  display: none;
}

.validation-invalid-label:before {
  content: "\ed63";
}

.validation-valid-label:before {
  content: "\ee73";
}

/* ------------------------------------------------------------------------------
*
*  # Floating labels
*
*  Styles for custom Floating Labels extensions
*
* ---------------------------------------------------------------------------- */
.form-group-float-label {
  position: relative;
  top: 0.4375rem;
  opacity: 0;
}

.form-group-float-label.is-visible {
  top: 0;
  opacity: 1;
}

.form-group-float-label.animate {
  transition: all linear 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-group-float-label.animate {
    transition: none;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Alpaca forms
*
*  Styles for alpaca.min.js - the easiest way to generate interactive HTML5 forms for web applications
*
* ---------------------------------------------------------------------------- */
.alpaca-hidden {
  display: none;
}

.alpaca-field .help-block,
.alpaca-field .alpaca-field-text-max-length-indicator {
  color: #999;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.has-error .multiselect-container > .active .form-check {
  color: #fff;
}

.has-error .btn-group.show .multiselect.btn,
.has-error .multiselect.btn-light:active {
  color: #f44336;
  border-color: #f44336;
}

.alpaca-disabled .form-check label,
.alpaca-disabled .form-check .switchery {
  cursor: default;
  color: #999;
}

.alpaca-invalid .alpaca-control-label,
.alpaca-invalid .alpaca-message {
  color: #f44336;
}

.alpaca-invalid .form-control {
  color: #f44336;
}

.alpaca-invalid .form-control,
.alpaca-invalid .form-control:hover,
.alpaca-invalid .form-control:focus {
  border-color: #f44336;
}

.alpaca-clear {
  clear: both;
}

.alpaca-float-right {
  float: right;
}

.alpaca-field .glyphicon {
  font-family: "icomoon";
  font-size: 1rem;
  vertical-align: middle;
  margin-top: -0.09375rem;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  margin-right: 0.625rem;
}

.alpaca-field .glyphicon-info-sign {
  display: none;
}

.alpaca-field .glyphicon-exclamation-sign:before {
  content: "\ed63";
}

.alpaca-icon-required {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  float: right;
  margin-right: 0;
  margin-left: 0.625rem;
}

.alpaca-icon-required:before {
  content: "*";
}

.alpaca-controlfield-editor {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
}

.alpaca-controlfield-editor .control-field-editor-el {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ace_editor {
  border: 1px solid #cccccc;
}

.alpaca-field-ckeditor.alpaca-invalid > .cke {
  border-color: #d84315;
}

.alpaca-field-optiontree .optiontree + .form-control {
  margin-left: 0.625rem;
}

.alpaca-field-optiontree.optiontree-horizontal .optiontree {
  display: inline-block;
}

.alpaca-field-optiontree.optiontree-horizontal
  .optiontree
  .optiontree-selector {
  display: inline-block;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.alpaca-field-optiontree.optiontree-horizontal
  .optiontree
  .optiontree-selector:empty {
  display: none;
}

.alpaca-field-optiontree.optiontree-horizontal
  .optiontree
  .optiontree-selector
  + .optiontree-selector {
  margin-left: 0.625rem;
}

.alpaca-field-optiontree.optiontree-horizontal input {
  display: inline-block;
  width: auto;
}

.alpaca-field-optiontree.optiontree-horizontal label {
  display: block;
}

.has-error .multiselect {
  border-color: #f44336;
  color: #f44336;
}

.alpaca-container-label:not(legend) {
  margin-top: 1.25rem;
}

.alpaca-array-toolbar {
  margin-bottom: 0.625rem;
}

.alpaca-array-actionbar {
  overflow: hidden;
}

.alpaca-array-actionbar.alpaca-array-actionbar-top {
  padding-bottom: 0.625rem;
}

.alpaca-array-actionbar.alpaca-array-actionbar-bottom {
  padding-top: 0.625rem;
}

.alpaca-array-actionbar,
.alpaca-array-actionbar.btn-group {
  width: 100%;
}

.alpaca-field-object .alpaca-top,
.alpaca-field-object .alpaca-top .alpaca-container,
.alpaca-field-array .alpaca-top,
.alpaca-field-array .alpaca-top .alpaca-container {
  border: 0;
}

.alpaca-container {
  border: 0;
}

.alpaca-control-buttons-container {
  margin-top: 0.625rem;
}

.alpaca-container-item > .alpaca-container > .alpaca-field-object {
  border: 0;
}

.alpaca-form-buttons-container {
  margin-top: 1.25rem;
  text-align: right;
}

.alpaca-container > .form-group {
  margin-left: 0;
  margin-right: 0;
}

.alpaca-field-hidden {
  display: none;
}

.alpaca-container-item:not(:first-child) {
  margin-top: 0.625rem;
}

.alpaca-container .alpaca-container-item:last-child {
  margin-bottom: 0;
}

.alpaca-field-select .btn-group {
  width: 100%;
}

.alpaca-field.alpaca-field-upload .fileupload-active-zone {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.alpaca-field.alpaca-field-upload .template-download td.error,
.alpaca-field.alpaca-field-upload .template-upload td.error {
  color: #d84315;
  word-break: break-all;
}

.alpaca-field.alpaca-field-address .alpaca-field-address-mapcanvas {
  height: 250px;
}

.alpaca-field.alpaca-field-image .alpaca-image-display {
  margin-top: 1.25rem;
}

/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
* ---------------------------------------------------------------------------- */
.tokenfield {
  height: auto;
}

.tokenfield::after {
  display: block;
  clear: both;
  content: "";
}

.tokenfield.form-control:not(input) {
  padding: 0 0 0.125rem 0;
}

.tokenfield .token {
  margin: 0.125rem 0 0 0.125rem;
  cursor: default;
  float: left;
  position: relative;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .tokenfield .token {
    transition: none;
  }
}

.tokenfield .token:not([class*="bg-"]) {
  background-color: #eee;
  color: #333;
}

.tokenfield .token:not([class*="bg-"]):hover,
.tokenfield .token:not([class*="bg-"]):focus {
  background-color: #03a9f4;
  color: #fff;
}

.tokenfield .token .token-label {
  display: block;
  max-width: none !important;
  padding: 0.3125rem 0.875rem;
  padding-right: 2.375rem;
}

.tokenfield .token > .close {
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: inherit;
  right: 0.875rem;
  line-height: 1;
  margin-top: -0.34375rem;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .tokenfield .token > .close {
    transition: none;
  }
}

.tokenfield .token > .close:after {
  content: "\ed6a";
  font-family: "icomoon";
  display: block;
  font-size: 0.6875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tokenfield .token > .close:hover {
  opacity: 1;
}

.tokenfield .twitter-typeahead {
  width: auto;
}

.tokenfield .token-input {
  direction: ltr;
  background: none;
  width: 5.625rem !important;
  max-width: 50%;
  min-width: 4rem;
  padding: 0.3125rem 0.875rem;
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  border: 0;
  outline: 0;
}

.tokenfield.readonly .token .token-label {
  padding-right: 0.875rem;
}

.tokenfield.readonly .token .close {
  display: none;
}

.tokenfield.disabled {
  cursor: default;
}

.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  opacity: 0.5;
}

.tokenfield.disabled .token:hover,
.tokenfield.disabled .token:focus {
  background-color: #eee;
  color: #333;
}

.tokenfield.disabled,
.tokenfield.disabled .token-input,
.tokenfield.disabled .token,
.tokenfield.disabled .token .close {
  cursor: default;
}

.input-group-lg .token > .token-label,
.input-group-lg .token > span,
.tokenfield.form-control-lg .token > .token-label,
.tokenfield.form-control-lg .token > span {
  padding: 0.4375rem 1rem;
  padding-right: 2.03125rem;
}

.input-group-lg .token-input,
.tokenfield.form-control-lg .token-input {
  padding: 0.4375rem 1rem;
}

.input-group-lg .token > .close,
.tokenfield.form-control-lg .token > .close {
  right: 1rem;
}

.input-group-lg.readonly .token > .token-label,
.tokenfield.form-control-lg.readonly .token > .token-label {
  padding-right: 1rem;
}

.input-group-sm .token > .token-label,
.input-group-sm .token > span,
.tokenfield.form-control-sm .token > .token-label,
.tokenfield.form-control-sm .token > span {
  padding: 0.1875rem 0.75rem;
  padding-right: 1.78125rem;
}

.input-group-sm .token-input,
.tokenfield.form-control-sm .token-input {
  padding: 0.1875rem 0.75rem;
}

.input-group-sm .token > .close,
.tokenfield.form-control-sm .token > .close {
  right: 0.75rem;
}

.input-group-sm.readonly .token > .token-label,
.tokenfield.form-control-sm.readonly .token > .token-label {
  padding-right: 0.75rem;
}

/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
* ---------------------------------------------------------------------------- */
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 0 0 0.125rem 0;
  vertical-align: middle;
  width: 100%;
  border-radius: 0.1875rem;
}

.bootstrap-tagsinput input {
  direction: ltr;
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0.3125rem 0.875rem;
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  width: auto !important;
  max-width: 50%;
  min-width: 4rem;
  box-shadow: none;
}

.input-group .bootstrap-tagsinput {
  width: 1%;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
}

.bootstrap-tagsinput .tt-dropdown-menu {
  margin-top: 0.25rem;
  min-width: 11.25rem;
}

.bootstrap-tagsinput .tag {
  margin: 0.125rem 0 0 0.125rem;
  padding: 0.3125rem 0.875rem;
  padding-right: 2.375rem;
  float: left;
  position: relative;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .bootstrap-tagsinput .tag {
    transition: none;
  }
}

.bootstrap-tagsinput .tag:not([class*="bg-"]) {
  background-color: #eee;
  color: #333;
}

.bootstrap-tagsinput .tag:not([class*="bg-"]):hover,
.bootstrap-tagsinput .tag:not([class*="bg-"]):focus {
  background-color: #03a9f4;
  color: #fff;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  line-height: 1;
  margin-top: -0.34375rem;
  opacity: 0.7;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .bootstrap-tagsinput .tag [data-role="remove"] {
    transition: none;
  }
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  opacity: 1;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\ed6a";
  font-family: "icomoon";
  display: block;
  font-size: 0.6875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------------------------------
*
*  # Touchspin spinners
*
*  Styles for touchspin.min.js - spinner form control library
*
* ---------------------------------------------------------------------------- */
.bootstrap-touchspin .input-group-btn-vertical {
  white-space: nowrap;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  width: 2.125rem;
  margin-left: -1px;
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  font-weight: normal;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border: 1px solid #ddd;
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border: 1px solid #ddd;
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.bootstrap-touchspin.input-group-lg .input-group-btn-vertical > .btn {
  width: 2.375rem;
}

.bootstrap-touchspin.input-group-lg
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.bootstrap-touchspin.input-group-sm .input-group-btn-vertical > .btn {
  width: 1.875rem;
}

.bootstrap-touchspin.input-group-sm
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.input-group .input-group-prepend.d-none:first-child + .form-control {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.input-group-lg .input-group-prepend.d-none:first-child + .form-control {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-group-sm .input-group-prepend.d-none:first-child + .form-control {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

/* ------------------------------------------------------------------------------
*
*  # Bootstrap Duallistbox
*
*  Styles for listbox.js - A responsive dual listbox widget optimized for Bootstrap
*
* ---------------------------------------------------------------------------- */
.bootstrap-duallistbox-container .box1 {
  margin-bottom: 1.25rem;
}

.bootstrap-duallistbox-container select.form-control {
  padding: 0.4375rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-duallistbox-container option {
  display: block;
  padding: 0.4375rem 0.875rem;
  border-radius: 0.1875rem;
}

.bootstrap-duallistbox-container.moveonselect .move,
.bootstrap-duallistbox-container.moveonselect .remove {
  display: none;
}

.bootstrap-duallistbox-container.moveonselect .btn-group .moveall,
.bootstrap-duallistbox-container.moveonselect .btn-group .removeall {
  width: 100%;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.bootstrap-duallistbox-container.moveonselect .btn-group .btn + .btn {
  border-left-width: 1px;
}

.bootstrap-duallistbox-container .buttons {
  width: 100%;
  margin-bottom: -1px;
}

.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear2 {
  display: none;
  margin-top: 1.25rem;
}

.bootstrap-duallistbox-container .clear1,
.bootstrap-duallistbox-container .clear1:hover,
.bootstrap-duallistbox-container .clear1:focus,
.bootstrap-duallistbox-container .clear2,
.bootstrap-duallistbox-container .clear2:hover,
.bootstrap-duallistbox-container .clear2:focus {
  background-color: transparent;
  border: 0;
  color: #2196f3;
  padding: 0;
  box-shadow: none;
}

.bootstrap-duallistbox-container .box1.filtered .clear1,
.bootstrap-duallistbox-container .box2.filtered .clear2 {
  display: inline-block;
}

.bootstrap-duallistbox-container .move,
.bootstrap-duallistbox-container .remove,
.bootstrap-duallistbox-container .moveall,
.bootstrap-duallistbox-container .removeall {
  width: 50%;
}

.bootstrap-duallistbox-container .btn-group .btn {
  margin: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-duallistbox-container .btn-group .btn + .btn {
  border-left-width: 0;
}

.bootstrap-duallistbox-container .btn-group .btn > i {
  float: none;
  text-align: center;
}

.bootstrap-duallistbox-container .btn-group .btn > i + i {
  margin-left: -1rem;
}

.bootstrap-duallistbox-container .filter {
  margin-bottom: 1.25rem;
  position: relative;
}

.bootstrap-duallistbox-container .filter.placeholder {
  color: #999;
}

.bootstrap-duallistbox-container .info-container .info {
  display: inline-block;
  margin-top: 1.25rem;
}

.bootstrap-duallistbox-container .info-container .info .badge {
  margin-right: 0.625rem;
}

.sidebar .bootstrap-duallistbox-container {
  text-align: center;
}

.sidebar .bootstrap-duallistbox-container .box1,
.sidebar .bootstrap-duallistbox-container .box2 {
  -ms-flex: auto;
  flex: auto;
  width: 100%;
  max-width: none;
}

.sidebar .bootstrap-duallistbox-container .box1 {
  padding-bottom: 0.3125rem;
}

.sidebar .bootstrap-duallistbox-container .box1:after {
  content: "\e9c9";
  font-size: 1rem;
  font-family: "icomoon";
  display: block;
  text-align: center;
  line-height: 1;
  margin: 0.625rem 0;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }

  .sidebar .bootstrap-duallistbox-container .box1 {
    margin-bottom: 0;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  Styles for steps.min.js - An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
* ---------------------------------------------------------------------------- */
.wizard {
  width: 100%;
}

.wizard > .steps .current-info,
.wizard > .content > .title {
  display: none;
}

.wizard > .content {
  position: relative;
  width: auto;
  padding: 0;
}

.wizard > .content > .body {
  padding: 0 1.25rem;
}

.wizard > .content > iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}

.wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}

.wizard > .steps > ul > li a {
  position: relative;
  padding-top: 3rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: block;
  outline: 0;
  color: #999;
}

.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
  content: "";
  display: block;
  position: absolute;
  top: 2.375rem;
  width: 50%;
  height: 2px;
  background-color: #00bcd4;
  z-index: 9;
}

.wizard > .steps > ul > li:before {
  left: 0;
}

.wizard > .steps > ul > li:after {
  right: 0;
}

.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
  content: none;
}

.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
  background-color: #eee;
}

.wizard > .steps > ul > li.current > a {
  color: #333;
  cursor: default;
}

.wizard > .steps > ul > li.current .number {
  font-size: 0;
  border-color: #00bcd4;
  background-color: #fff;
  color: #00bcd4;
}

.wizard > .steps > ul > li.current .number:after {
  content: "\e913";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  line-height: 2.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .wizard > .steps > ul > li.current .number:after {
    transition: none;
  }
}

.wizard > .steps > ul > li.disabled a {
  cursor: default;
}

.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
  color: #999;
}

.wizard > .steps > ul > li.done .number {
  font-size: 0;
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff;
}

.wizard > .steps > ul > li.done .number:after {
  content: "\ed6f";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  line-height: 2.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .wizard > .steps > ul > li.done .number:after {
    transition: none;
  }
}

.wizard > .steps > ul > li.error .number {
  border-color: #f44336;
  color: #f44336;
}

.card > .card-header:not([class*="bg-"]) > .wizard > .steps > ul {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: 991.98px) {
  .wizard > .steps > ul {
    margin-bottom: 1.25rem;
  }

  .wizard > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }

  .wizard > .steps > ul > li > a {
    margin-bottom: 0;
  }

  .wizard > .steps > ul > li:first-child:before,
  .wizard > .steps > ul > li:last-child:after {
    content: "";
  }

  .wizard > .steps > ul > li:last-child:after {
    background-color: #00bcd4;
  }
}

@media (max-width: 767.98px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }

  .wizard > .steps > ul > li.current:after {
    background-color: #00bcd4;
  }
}

.wizard > .steps .number {
  background-color: #fff;
  color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.1875rem;
  border: 2px solid #eee;
  font-size: 0.875rem;
  z-index: 10;
  line-height: 2.125rem;
  text-align: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
}

.wizard > .actions {
  position: relative;
  text-align: right;
  padding: 1.25rem;
  padding-top: 0;
}

.wizard > .actions > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wizard > .actions > ul::after {
  display: block;
  clear: both;
  content: "";
}

.wizard > .actions > ul > li {
  display: inline-block;
}

.wizard > .actions > ul > li + li {
  margin-left: 1.25rem;
}

/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Styles for summernote.min.js - super simple WYSIWYG Editor for Bootstrap framework
*
* ---------------------------------------------------------------------------- */
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font-style: normal;
  font-size: inherit;
  text-decoration: inherit;
  vertical-align: middle;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.note-icon-align-center:before {
  content: "\f101";
}

.note-icon-align-indent:before {
  content: "\f102";
}

.note-icon-align-justify:before {
  content: "\f103";
}

.note-icon-align-left:before {
  content: "\f104";
}

.note-icon-align-outdent:before {
  content: "\f105";
}

.note-icon-align-right:before {
  content: "\f106";
}

.note-icon-align:before {
  content: "\f107";
}

.note-icon-arrow-circle-down:before {
  content: "\f108";
}

.note-icon-arrow-circle-left:before {
  content: "\f109";
}

.note-icon-arrow-circle-right:before {
  content: "\f10a";
}

.note-icon-arrow-circle-up:before {
  content: "\f10b";
}

.note-icon-arrows-alt:before {
  content: "\f10c";
}

.note-icon-arrows-h:before {
  content: "\f10d";
}

.note-icon-arrows-v:before {
  content: "\f10e";
}

.note-icon-bold:before {
  content: "\f10f";
}

.note-icon-caret:before {
  content: "\f110";
}

.note-icon-chain-broken:before {
  content: "\f111";
}

.note-icon-circle:before {
  content: "\f112";
}

.note-icon-close:before {
  content: "\f113";
}

.note-icon-code:before {
  content: "\f114";
}

.note-icon-col-after:before {
  content: "\f115";
}

.note-icon-col-before:before {
  content: "\f116";
}

.note-icon-col-remove:before {
  content: "\f117";
}

.note-icon-eraser:before {
  content: "\f118";
}

.note-icon-font:before {
  content: "\f119";
}

.note-icon-frame:before {
  content: "\f11a";
}

.note-icon-italic:before {
  content: "\f11b";
}

.note-icon-link:before {
  content: "\f11c";
}

.note-icon-magic:before {
  content: "\f11d";
}

.note-icon-menu-check:before {
  content: "\f11e";
}

.note-icon-minus:before {
  content: "\f11f";
}

.note-icon-orderedlist:before {
  content: "\f120";
}

.note-icon-pencil:before {
  content: "\f121";
}

.note-icon-picture:before {
  content: "\f122";
}

.note-icon-question:before {
  content: "\f123";
}

.note-icon-redo:before {
  content: "\f124";
}

.note-icon-row-above:before {
  content: "\f125";
}

.note-icon-row-below:before {
  content: "\f126";
}

.note-icon-row-remove:before {
  content: "\f127";
}

.note-icon-special-character:before {
  content: "\f128";
}

.note-icon-square:before {
  content: "\f129";
}

.note-icon-strikethrough:before {
  content: "\f12a";
}

.note-icon-subscript:before {
  content: "\f12b";
}

.note-icon-summernote:before {
  content: "\f12c";
}

.note-icon-superscript:before {
  content: "\f12d";
}

.note-icon-table:before {
  content: "\f12e";
}

.note-icon-text-height:before {
  content: "\f12f";
}

.note-icon-trash:before {
  content: "\f130";
}

.note-icon-underline:before {
  content: "\f131";
}

.note-icon-undo:before {
  content: "\f132";
}

.note-icon-unorderedlist:before {
  content: "\f133";
}

.note-icon-video:before {
  content: "\f134";
}

.note-editor {
  position: relative;
}

.note-editor .note-dropzone {
  position: absolute;
  z-index: 100;
  display: none;
  color: #2196f3;
  background-color: #fff;
  pointer-events: none;
  border: 2px dashed #2196f3;
  opacity: 0.95;
}

.note-editor .note-dropzone.hover {
  color: #2196f3;
  border: 2px dashed #2196f3;
}

.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 1.0625rem;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}

.card .note-editor {
  border-color: #ddd;
  box-shadow: none;
}

.note-editor.dragover .note-dropzone {
  display: table;
}

.note-editor .note-form-group:last-child {
  margin-bottom: 0;
}

.note-editor .note-group-image-url:last-child {
  margin-bottom: 0;
}

.note-editor .modal-title {
  font-size: 1.0625rem;
}

.note-editor .dropdown-style pre {
  background-color: #fff;
  padding: 0.5rem 1rem;
}

.summernote-borderless ~ .note-editor {
  border: 0;
  margin: 0;
  border-radius: 0;
}

.note-air-editor {
  outline: 0;
}

@-moz-document url-prefix() {
  .note-image-input {
    height: auto;
  }
}

.note-placeholder {
  position: absolute;
  display: none;
  color: #999;
}

.note-toolbar:not([class*="bg-"]):not([class*="alpha-"]) {
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.9375rem 1.25rem;
  padding-top: 0;
  margin: 0;
}

.dropdown-fontname,
.dropdown-style {
  max-height: 20rem;
  overflow: auto;
}

.dropdown-style a > p {
  margin-bottom: 0;
}

.note-editing-area {
  position: relative;
}

.note-editing-area .note-editable {
  outline: 0;
}

.note-editing-area .note-editable sup {
  vertical-align: super;
}

.note-editing-area .note-editable sub {
  vertical-align: sub;
}

.note-editing-area img.note-float-left {
  margin-right: 0.625rem;
}

.note-editing-area img.note-float-right {
  margin-left: 0.625rem;
}

.note-frame.codeview .note-editing-area .note-editable {
  display: none;
}

.note-frame.codeview .note-editing-area .note-codable {
  display: block;
}

.note-frame .note-editing-area {
  overflow: hidden;
}

.note-frame .note-editing-area .note-editable {
  padding: 1.25rem;
  overflow: auto;
  color: #333;
  background-color: #fff;
  min-height: 9.375rem;
}

.note-frame .note-editing-area .note-editable[contenteditable="false"] {
  background-color: #eee;
}

.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 1.25rem;
  margin-bottom: 0;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  color: #fff;
  background-color: #333;
  border: 0;
  resize: none;
  box-shadow: none;
  border-radius: 0;
}

.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1080;
  width: 100% !important;
  border: 0;
  border-radius: 0;
}

.note-frame.fullscreen .note-resizebar {
  display: none;
}

.note-frame .note-placeholder {
  position: absolute;
  display: none;
  color: #999;
}

.note-statusbar {
  background-color: rgba(0, 0, 0, 0.02);
}

.note-statusbar .note-resizebar {
  width: 100%;
  padding: 2px 0;
  cursor: ns-resize;
  border-top: 1px solid #ddd;
}

.note-statusbar .note-resizebar .note-icon-bar {
  width: 1.25rem;
  margin: 1px auto;
  border-top: 1px solid #ddd;
}

.note-popover.popover {
  display: none;
  max-width: none;
}

.note-popover.popover .popover-content a {
  display: inline-block;
  max-width: 276px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.note-popover.popover .arrow {
  left: 1.25rem !important;
}

.note-popover .popover-content {
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.9375rem 0 0 0.9375rem;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.note-popover .popover-content > span {
  margin-right: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.note-popover .popover-content > .btn-group {
  margin-right: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.note-style h1,
.note-style h2,
.note-style h3,
.note-style h4,
.note-style h5,
.note-style h6,
.note-style blockquote {
  margin: 0;
}

.note-hint-popover {
  min-width: 11.25rem;
  padding: 0.5rem 0;
}

.note-hint-popover .popover-content {
  max-height: 10rem;
  overflow: auto;
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 0.5rem 1rem;
  color: #333;
  clear: both;
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:focus {
  color: #333;
  background-color: #f5f5f5;
  text-decoration: none;
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active {
  color: #fff;
  text-decoration: none;
  background-color: #2196f3;
}

.card-header.note-toolbar > .btn-group {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
}

.card-header.note-toolbar > .btn-group:last-child {
  margin-right: 0;
}

.card-header.note-toolbar .dropdown-menu.note-check a i {
  display: none;
}

.card-header.note-toolbar .dropdown-menu.note-check a.checked {
  color: #fff;
  background-color: #2196f3;
}

.card-header.note-toolbar .note-color-palette {
  line-height: 1;
}

.card-header.note-toolbar .note-color-palette div .note-color-btn {
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
  width: 1.25rem;
  height: 1.25rem;
}

.card-header.note-toolbar .note-color-palette div .note-color-btn:hover {
  border-color: #333;
}

.note-popover .btn-group .note-table,
.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 0.5rem;
}

.note-popover .btn-group .note-table .note-dimension-picker,
.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 1.125rem;
}

.note-popover
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher,
.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}

.note-popover
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted,
.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}

.note-popover
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted,
.note-toolbar
  .btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}

.note-popover .btn-group .note-table .note-dimension-display,
.note-toolbar .btn-group .note-table .note-dimension-display {
  text-align: center;
  margin-top: 0.5rem;
}

.note-color .note-color-row {
  font-size: 0;
}

.note-color .dropdown-menu.show {
  display: -ms-flexbox;
  display: flex;
}

.note-color .dropdown-menu .note-palette {
  display: inline-block;
  width: 10rem;
  margin: 0.4375rem 0.875rem;
}

.note-color .dropdown-menu .note-palette .note-palette-title {
  margin-bottom: 0.625rem;
  font-weight: 500;
}

.note-color .dropdown-menu .note-palette .note-color-reset {
  width: 100%;
  padding: 0.3125rem 0.75rem;
  margin-bottom: 0.875rem;
}

.note-para .dropdown-menu {
  padding: 0.5rem;
  white-space: nowrap;
}

.note-para .dropdown-menu > div:first-child {
  margin-right: 0.625rem;
}

.note-handle {
  position: relative;
}

.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #333;
  z-index: 10;
}

.note-handle .note-control-selection > div {
  position: absolute;
}

.note-handle .note-control-selection .note-control-selection-bg {
  background-color: #333;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}

.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  border: 1px solid #333;
  width: 0.5rem;
  height: 0.5rem;
}

.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}

.note-handle .note-control-selection .note-control-nw {
  top: -0.3125rem;
  left: -0.3125rem;
  border-right: 0;
  border-bottom: 0;
}

.note-handle .note-control-selection .note-control-ne {
  top: -0.3125rem;
  right: -0.3125rem;
  border-bottom: 0;
  border-left: 0;
}

.note-handle .note-control-selection .note-control-sw {
  bottom: -0.3125rem;
  left: -0.3125rem;
  border-top: 0;
  border-right: 0;
}

.note-handle .note-control-selection .note-control-se {
  right: -0.3125rem;
  bottom: -0.3125rem;
  cursor: se-resize;
}

.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 0.3125rem;
  margin: 0.3125rem;
  color: #fff;
  background-color: #333;
  opacity: 0.7;
  border-radius: 0.1875rem;
}

/* ------------------------------------------------------------------------------
*
*  # Trumbowyg editor
*
*  Styles for trumbowyg.min.js - a lightweight WYSIWYG editor
*
* ---------------------------------------------------------------------------- */
#trumbowyg-icons {
  overflow: hidden;
  visibility: hidden;
  height: 0;
  width: 0;
}

#trumbowyg-icons svg {
  height: 0;
  width: 0;
}

.trumbowyg-box {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
}

.trumbowyg-box svg {
  fill: #333;
  display: block;
  margin: 0.12502rem 0;
  width: 1rem;
  height: 1rem;
}

.trumbowyg-box.trumbowyg-fullscreen {
  background: #fff;
  border-width: 0;
}

.trumbowyg-editor {
  position: relative;
}

.trumbowyg-editor[contenteditable="true"]:empty:not(:focus)::before {
  content: attr(placeholder);
  color: #999;
  pointer-events: none;
}

.trumbowyg-editor,
.trumbowyg-textarea {
  position: relative;
  padding: 1.25rem;
  min-height: 350px;
  background-color: #fff;
  width: 100%;
  border-style: none;
  resize: none;
  outline: none;
  overflow: auto;
}

.trumbowyg-editor.trumbowyg-autogrow-on-enter,
.trumbowyg-textarea.trumbowyg-autogrow-on-enter {
  transition: height ease-in-out 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-editor.trumbowyg-autogrow-on-enter,
  .trumbowyg-textarea.trumbowyg-autogrow-on-enter {
    transition: none;
  }
}

.trumbowyg-box-blur .trumbowyg-editor img,
.trumbowyg-box-blur .trumbowyg-editor hr {
  opacity: 0.2;
}

.trumbowyg-textarea {
  position: relative;
  display: block;
  overflow: auto;
  border: 0;
  white-space: normal;
}

.trumbowyg-box.trumbowyg-editor-visible .trumbowyg-textarea {
  height: 1px !important;
  width: 25%;
  min-height: 0 !important;
  padding: 0 !important;
  background: none;
  opacity: 0 !important;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-textarea {
  display: block;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-editor {
  display: none;
}

.trumbowyg-box.trumbowyg-disabled .trumbowyg-textarea {
  opacity: 0.8;
  background-color: transparent;
}

.trumbowyg-button-pane {
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
  margin: 0;
  position: relative;
  list-style: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 11;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.trumbowyg-button-pane .trumbowyg-button-group {
  display: inline-block;
  position: relative;
}

.trumbowyg-button-pane
  .trumbowyg-button-group
  .trumbowyg-fullscreen-button
  svg {
  color: transparent;
}

.trumbowyg-button-pane
  .trumbowyg-button-group:not(:empty)
  + .trumbowyg-button-group {
  padding-left: 1px;
}

.trumbowyg-button-pane
  .trumbowyg-button-group:not(:empty)
  + .trumbowyg-button-group:before {
  content: " ";
  display: inline-block;
  width: 1px;
  background-color: #eaeaea;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.trumbowyg-box:not(.trumbowyg-fullscreen)
  .trumbowyg-button-pane
  .trumbowyg-button-group:first-child
  button {
  border-radius: 0.125rem 0 0 0;
}

.trumbowyg-box:not(.trumbowyg-fullscreen)
  .trumbowyg-button-pane
  .trumbowyg-button-group:last-child
  button {
  border-radius: 0 0.125rem 0 0;
}

.trumbowyg-button-pane button {
  display: inline-block;
  position: relative;
  padding: 0.875rem;
  overflow: hidden;
  border: none;
  cursor: pointer;
  background-color: transparent;
  vertical-align: middle;
  transition: background-color ease-in-out 0.15s, opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-button-pane button {
    transition: none;
  }
}

.trumbowyg-button-pane button.trumbowyg-textual-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.trumbowyg-button-pane button:not(.trumbowyg-disable):hover,
.trumbowyg-button-pane button:not(.trumbowyg-disable):focus,
.trumbowyg-button-pane button.trumbowyg-active {
  background-color: #eee;
  outline: 0;
}

.trumbowyg-button-pane.trumbowyg-disable
  button:not(.trumbowyg-not-disable):not(.trumbowyg-active),
.trumbowyg-button-pane.trumbowyg-disable
  button:not(.trumbowyg-not-disable):not(.trumbowyg-viewHTML-button),
.trumbowyg-disabled
  .trumbowyg-button-pane
  button:not(.trumbowyg-not-disable):not(.trumbowyg-active),
.trumbowyg-disabled
  .trumbowyg-button-pane
  button:not(.trumbowyg-not-disable):not(.trumbowyg-viewHTML-button) {
  opacity: 0.2;
  cursor: default;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown {
  padding-right: 1.75rem;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  height: 0;
  width: 0;
  margin-top: -0.15em;
  border: 0.3em solid transparent;
  border-top-color: #333;
}

.trumbowyg-button-pane .trumbowyg-right {
  float: right;
}

.trumbowyg-dropdown {
  min-width: 11.25rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  margin-top: 1px;
  margin-left: -1px;
  z-index: 1000;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.trumbowyg-dropdown button {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  color: #333;
  white-space: nowrap;
  background: none;
  border: 0;
  text-align: inherit;
  cursor: pointer;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-dropdown button {
    transition: none;
  }
}

.trumbowyg-dropdown button:hover,
.trumbowyg-dropdown button:focus {
  color: #333;
  text-decoration: none;
  background-color: #f5f5f5;
}

.trumbowyg-dropdown button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  margin-top: -0.09375rem;
}

.trumbowyg-modal {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 33rem;
  width: 100%;
  z-index: 11;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.trumbowyg-modal-box {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 30rem;
  width: calc(100% - 3rem);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.trumbowyg-modal-box .trumbowyg-modal-title {
  font-size: 0.9375rem;
  font-weight: 500;
  padding: 1.25rem;
  padding-bottom: 0;
  display: block;
}

.trumbowyg-modal-box .trumbowyg-progress {
  width: 100%;
  height: 0.1875rem;
  position: absolute;
  top: 2.875rem;
}

.trumbowyg-modal-box .trumbowyg-progress .trumbowyg-progress-bar {
  background-color: #4caf50;
  width: 0;
  height: 100%;
  transition: width linear 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-modal-box .trumbowyg-progress .trumbowyg-progress-bar {
    transition: none;
  }
}

.trumbowyg-modal-box form {
  padding: 1.25rem;
  text-align: right;
}

.trumbowyg-modal-box form > label {
  display: block;
  position: relative;
  margin-bottom: 1.25rem;
}

.trumbowyg-modal-box form > label .trumbowyg-input-infos {
  display: block;
  text-align: left;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-modal-box form > label .trumbowyg-input-infos {
    transition: none;
  }
}

.trumbowyg-modal-box form > label .trumbowyg-input-infos span {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5385;
}

.trumbowyg-modal-box
  form
  > label
  .trumbowyg-input-infos
  span.trumbowyg-msg-error {
  color: #f44336;
  margin-left: 30%;
  padding-bottom: 0;
}

.trumbowyg-modal-box form > label input {
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  float: right;
  width: 70%;
  border-radius: 0.1875rem;
}

.trumbowyg-modal-box form > label input:focus {
  outline: 0;
}

.trumbowyg-modal-box form > label.trumbowyg-input-error input,
.trumbowyg-modal-box form > label.trumbowyg-input-error textarea {
  border-color: #f44336;
}

.trumbowyg-modal-box .error {
  display: block;
  color: #f44336;
}

.trumbowyg-modal-button {
  color: #fff;
  display: inline-block;
  text-decoration: none;
  background-color: #324148;
  border: none;
  cursor: pointer;
  margin-left: 0.625rem;
  border: 1px solid transparent;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-modal-button {
    transition: none;
  }
}

.trumbowyg-modal-button.trumbowyg-modal-submit {
  background-color: #66bb6a;
}

.trumbowyg-modal-button.trumbowyg-modal-submit:hover,
.trumbowyg-modal-button.trumbowyg-modal-submit:focus {
  outline: 0;
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

.trumbowyg-modal-button.trumbowyg-modal-reset {
  color: #333;
  background-color: #fafafa;
  border-color: #ddd;
}

.trumbowyg-modal-button.trumbowyg-modal-reset:hover,
.trumbowyg-modal-button.trumbowyg-modal-reset:focus {
  outline: 0;
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.trumbowyg-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  display: none;
  top: 0;
  z-index: 10;
}

body.trumbowyg-body-fullscreen {
  overflow: hidden;
}

.trumbowyg-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99999;
}

.trumbowyg-fullscreen.trumbowyg-box,
.trumbowyg-fullscreen .trumbowyg-editor {
  border: 0;
  border-radius: 0;
}

.trumbowyg-fullscreen .trumbowyg-editor,
.trumbowyg-fullscreen .trumbowyg-textarea {
  height: calc(100% - 2.875rem) !important;
  overflow: auto;
}

.trumbowyg-fullscreen .trumbowyg-overlay {
  height: 100% !important;
}

.trumbowyg-fullscreen .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: #333;
  fill: transparent;
}

.trumbowyg-dropdown-foreColor,
.trumbowyg-dropdown-backColor {
  padding: 0.5rem;
  width: 15rem;
}

.trumbowyg-dropdown-foreColor svg,
.trumbowyg-dropdown-backColor svg {
  display: none !important;
}

.trumbowyg-dropdown-foreColor button,
.trumbowyg-dropdown-backColor button {
  display: block;
  position: relative;
  float: left;
  text-indent: -9999px;
  border: 1px solid #fff;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  transition: -webkit-transform ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s;
  transition: transform ease-in-out 0.15s, -webkit-transform ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .trumbowyg-dropdown-foreColor button,
  .trumbowyg-dropdown-backColor button {
    transition: none;
  }
}

.trumbowyg-dropdown-foreColor button:hover,
.trumbowyg-dropdown-foreColor button:focus,
.trumbowyg-dropdown-backColor button:hover,
.trumbowyg-dropdown-backColor button:focus {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  z-index: 10;
}

/* ------------------------------------------------------------------------------
*
*  # Ace code editor
*
*  Styles Ace - an embeddable code editor written in JavaScript
*
* ---------------------------------------------------------------------------- */
.ace_editor {
  height: 450px;
  position: relative;
}

/* ------------------------------------------------------------------------------
*
*  # Daterange picker
*
*  Date range picker component for Bootstrap
*
* ---------------------------------------------------------------------------- */
.daterangepicker {
  position: absolute;
  left: 0;
  margin-top: 0.125rem;
  width: auto;
  padding: 0;
  z-index: 1070;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.daterangepicker.dropup {
  margin-top: -0.5rem;
}

.daterangepicker .ranges,
.daterangepicker .calendar {
  float: left;
}

.daterangepicker.opensleft .calendars {
  /*rtl:ignore*/
  float: left;
}

.daterangepicker.opensleft .calendar {
  /*rtl:ignore*/
  margin-right: 0.9375rem;
}

.daterangepicker.opensright .calendars {
  /*rtl:ignore*/
  float: right;
}

.daterangepicker.opensright .calendar {
  /*rtl:ignore*/
  margin-left: 0.9375rem;
}

.daterangepicker.single .calendar {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

.daterangepicker.single .ranges {
  display: none;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar {
  display: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0.46875rem 0;
  padding: 0.9375rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.daterangepicker table {
  width: 100%;
  margin: 0;
}

.daterangepicker table tbody th,
.daterangepicker table tbody td {
  cursor: pointer;
}

.daterangepicker th,
.daterangepicker td {
  white-space: nowrap;
  text-align: center;
}

.daterangepicker th.week,
.daterangepicker td.week {
  font-size: 80%;
  color: #999;
}

.daterangepicker th {
  color: #999;
  font-weight: 400;
  font-size: 0.75rem;
}

.daterangepicker th > i {
  top: 0;
}

.daterangepicker th.prev,
.daterangepicker th.next {
  cursor: pointer;
}

.daterangepicker th.available:hover,
.daterangepicker th.available:focus {
  color: #333;
}

.daterangepicker td.available {
  border-radius: 0.1875rem;
}

.daterangepicker td.available:hover,
.daterangepicker td.available:focus {
  color: #333;
  background-color: #f5f5f5;
}

.daterangepicker td.off,
.daterangepicker td.disabled {
  color: #ccc;
}

.daterangepicker td.disabled {
  cursor: default;
}

.daterangepicker td.in-range {
  color: #333;
  background-color: #f5f5f5;
  border-radius: 0;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.active:focus {
  color: #fff;
  background-color: #26a69a;
  border-radius: 0.1875rem;
}

.daterangepicker .table-condensed tr > th,
.daterangepicker .table-condensed tr > td {
  padding: 0.4375rem;
  min-width: 2.12503rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .daterangepicker .table-condensed tr > th,
  .daterangepicker .table-condensed tr > td {
    transition: none;
  }
}

.daterangepicker .table-condensed thead tr:last-child th {
  padding-top: 0.9375rem;
}

.daterangepicker .table-condensed .month {
  font-size: 0.9375rem;
  color: #333;
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
  font-weight: 500;
}

.daterangepicker select {
  display: inline-block;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 3.75rem;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  right: 0.75rem;
  top: auto;
  bottom: 0.62502rem;
  color: #999;
}

.daterangepicker .daterangepicker_input input {
  padding-left: 0.75rem;
  padding-right: 2.1875rem;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 0.4375rem 0;
}

.daterangepicker .calendar-time select.disabled {
  color: #999;
  cursor: default;
}

.ranges {
  background-color: #fff;
  position: relative;
  margin-top: 0.46875rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.ranges ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0;
}

.ranges ul + .daterangepicker-inputs {
  border-top: 1px solid #ddd;
}

.ranges ul li {
  color: #333;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1px;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ranges ul li {
    transition: none;
  }
}

.ranges ul li:first-child {
  margin-top: 0;
}

.ranges ul li:hover,
.ranges ul li:focus {
  color: #333;
  background-color: #f5f5f5;
}

.ranges ul li.active {
  color: #fff;
  background-color: #26a69a;
}

.ranges .daterangepicker-inputs {
  padding: 0.9375rem;
  padding-top: 1.1875rem;
}

.ranges
  .daterangepicker-inputs
  .daterangepicker_input
  + .daterangepicker_input {
  margin-top: 1.1875rem;
}

.ranges .daterangepicker-inputs .daterangepicker_input > span {
  display: block;
  margin-bottom: 0.625rem;
  color: #999;
}

.ranges .daterangepicker-inputs + .range_inputs {
  border-top: 1px solid #ddd;
}

.ranges .range_inputs {
  padding: 0.9375rem;
}

.ranges .range_inputs .btn {
  display: block;
  width: 100%;
}

.ranges .range_inputs .btn + .btn {
  margin-top: 0.9375rem;
}

.ranges ul + .daterangepicker-inputs {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .ranges {
    margin-top: 0.46875rem;
    margin-bottom: 0.46875rem;
  }
}

.daterange-custom {
  cursor: pointer;
}

.daterange-custom-display {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 1.5rem;
  height: 2.69238rem;
}

.daterange-custom-display:after {
  content: "\e9c1";
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.5rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.daterange-custom-display > i {
  display: block;
  font-size: 1.875rem;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.015em;
}

.daterange-custom-display b {
  margin-left: 0.5rem;
  font-weight: 400;
  text-align: center;
}

.daterange-custom-display b > i {
  font-size: 0.6875rem;
  display: block;
  line-height: 0.75rem;
  font-style: normal;
  font-weight: 400;
}

.daterange-custom-display em {
  margin: 0 0.5rem;
}

@media (max-width: 767.98px) {
  .opensleft,
  .opensright {
    left: 0 !important;
    right: 0;
  }

  .opensleft .calendars,
  .opensright .calendars {
    float: none;
  }

  .daterangepicker.opensleft .ranges,
  .daterangepicker.opensleft .calendar,
  .daterangepicker.opensleft .calendars,
  .daterangepicker.opensright .ranges,
  .daterangepicker.opensright .calendar,
  .daterangepicker.opensright .calendars {
    float: none;
  }

  .daterangepicker {
    width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .daterangepicker .calendar {
    margin-left: 0;
    margin-right: 0;
  }

  .daterangepicker .ranges {
    width: 100%;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Picker base
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
* ---------------------------------------------------------------------------- */
.picker {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  z-index: 1070;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.picker__input {
  cursor: default;
}

.picker__holder {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  display: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top-width: 0;
  border-bottom-width: 0;
  max-width: 18.13rem;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.picker--opened .picker__holder {
  max-height: 480px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  display: block;
}

/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Date picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
* ---------------------------------------------------------------------------- */
.picker__box {
  padding: 0.9375rem;
}

.picker__header {
  text-align: center;
  position: relative;
  font-size: 0.9375rem;
  line-height: 1;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

.picker__year {
  color: #999;
  font-size: 0.75rem;
  font-weight: normal;
}

.picker__select--month,
.picker__select--year {
  border-color: #ddd;
  height: 2.25003rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  outline: 0;
  white-space: nowrap;
}

.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: 0.5rem;
  top: 50%;
  margin-top: -1rem;
  line-height: 1;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker__nav--prev,
  .picker__nav--next {
    transition: none;
  }
}

.picker__nav--prev:before,
.picker__nav--next:before {
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
}

.picker__nav--prev {
  left: 0;
}

.picker__nav--prev:before {
  content: "\e9c8";
}

.picker__nav--next {
  right: 0;
}

.picker__nav--next:before {
  content: "\e9cb";
}

.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background-color: transparent;
}

.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-bottom: 0.9375rem;
}

.picker__table td {
  margin: 0;
  padding: 0;
}

.picker__weekday {
  width: 14.285714286%;
  text-align: center;
  padding-bottom: 0.46875rem;
  padding-top: 0.9375rem;
  color: #999;
  font-weight: 400;
}

.picker__day {
  padding: 0.4375rem;
  min-width: 2.12503rem;
  border-radius: 0.1875rem;
}

.picker__day--today {
  position: relative;
  background-color: #f5f5f5;
}

.picker__day--today:before {
  content: "";
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  width: 0;
  height: 0;
  border-top: 0.375rem solid #26a69a;
  border-left: 0.375rem solid transparent;
}

.picker__day--outfocus {
  color: #ccc;
}

.picker__day--infocus,
.picker__day--outfocus {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker__day--infocus,
  .picker__day--outfocus {
    transition: none;
  }
}

.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
}

.picker__day--highlighted:before {
  border-top-color: #fff;
}

.picker__day--highlighted,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #26a69a;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #26a69a;
  color: #fff;
}

.picker__day--disabled,
.picker__day--disabled:hover {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.picker__day--disabled:before {
  border-top-color: #999;
}

.picker__day--highlighted .picker__day--disabled,
.picker__day--highlighted .picker__day--disabled:hover {
  background-color: transparent;
}

.picker__footer {
  text-align: center;
}

.picker__footer button {
  border: 0;
  padding: 0.4375rem 0.875rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker__footer button {
    transition: none;
  }
}

.picker__footer button:hover,
.picker__footer button:focus {
  outline: 0;
  color: #333;
  background-color: #f5f5f5;
}

.picker__footer button:before {
  height: 0;
}

.picker__footer button:disabled,
.picker__footer button:disabled:hover,
.picker__footer button:disabled:focus {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.picker__button--today:before {
  content: "";
  margin-right: 0.625rem;
  position: relative;
  display: inline-block;
  top: -0.0625rem;
  width: 0;
  border-top: 0.375rem solid #2196f3;
  border-left: 0.375rem solid transparent;
}

.picker__button--close:before {
  content: "\D7";
  display: inline-block;
  position: relative;
  margin-right: 0.625rem;
  top: 0.0625rem;
  line-height: 1;
  font-size: 1rem;
}

.picker__button--clear:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -0.1875rem;
  width: 0.5rem;
  margin-right: 0.625rem;
  border-top: 0.125rem solid #f44336;
}

/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Time picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
* ---------------------------------------------------------------------------- */
.picker--time {
  min-width: 16rem;
  max-width: 20rem;
}

.picker--time .picker__box {
  padding: 0;
}

.picker__list {
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.picker__list-item {
  position: relative;
  padding: 0.5rem 1rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker__list-item {
    transition: none;
  }
}

.picker__list-item:hover,
.picker__list-item:focus {
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
  z-index: 10;
}

.picker--time .picker__button--clear {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.4375rem 0.875rem;
  background-color: #fafafa;
  outline: 0;
  border: 0;
  border-top: 1px solid #ddd;
  text-align: center;
  cursor: pointer;
  margin-bottom: -0.5rem;
  margin-top: 0.5rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .picker--time .picker__button--clear {
    transition: none;
  }
}

.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus {
  background-color: #f5f5f5;
}

.picker__list-item--highlighted {
  z-index: 10;
}

.picker__list-item--highlighted,
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
}

.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  color: #fff;
  background-color: #2196f3;
  z-index: 10;
}

.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  color: #999;
  background-color: transparent;
  cursor: default;
  z-index: auto;
}

/* ------------------------------------------------------------------------------
*
*  # Anytime picker
*
*  jQuery datepicker/timepicker and a Date/String parse/format utility
*
* ---------------------------------------------------------------------------- */
.AnyTime-pkr {
  text-align: center;
}

.AnyTime-pkr * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.AnyTime-pkr .AnyTime-date {
  float: left;
}

.AnyTime-pkr .AnyTime-date + .AnyTime-time {
  margin-left: 3.125rem;
  float: left;
}

.AnyTime-win {
  padding: 0.9375rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: inline-block;
  z-index: 1070;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.AnyTime-cloak {
  position: absolute;
  opacity: 0.7;
}

.AnyTime-hdr {
  font-size: 0.9375rem;
  margin: 0.9375rem;
  line-height: 1;
}

.AnyTime-x-btn {
  display: none;
  font-size: 0;
  cursor: pointer;
  float: right;
  opacity: 0.6;
  border-radius: 0.1875rem;
}

.AnyTime-x-btn:hover {
  opacity: 1;
}

.AnyTime-x-btn:after {
  content: "\ed6b";
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AnyTime-lbl {
  font-size: 0.8125rem;
  margin-bottom: 0.625rem;
  font-weight: 500;
  margin-top: 1.25rem;
}

.AnyTime-lbl:first-child {
  margin-top: 0.5rem;
}

.AnyTime-yrs .AnyTime-cur-btn,
.AnyTime-yrs .AnyTime-cur-btn:hover {
  background-color: #f44336;
  color: #fff;
}

.AnyTime-mons .AnyTime-cur-btn,
.AnyTime-mons .AnyTime-cur-btn:hover {
  background-color: #26a69a;
  color: #fff;
}

.AnyTime-time .AnyTime-cur-btn,
.AnyTime-time .AnyTime-cur-btn:hover {
  background-color: #607d8b;
  color: #fff;
}

.AnyTime-dom-table .AnyTime-cur-btn,
.AnyTime-dom-table .AnyTime-cur-btn:hover,
.AnyTime-body-yr-selector .AnyTime-cur-btn,
.AnyTime-body-yr-selector .AnyTime-cur-btn:hover {
  background-color: #26a69a;
  color: #fff;
}

.AnyTime-yrs-past-btn,
.AnyTime-yrs-ahead-btn {
  display: inline-block;
}

.AnyTime-yr-prior-btn,
.AnyTime-yr-cur-btn,
.AnyTime-yr-next-btn {
  display: inline-block;
  min-width: 3rem;
}

.AnyTime-mons::after {
  display: block;
  clear: both;
  content: "";
}

.AnyTime-dom-table {
  width: 100%;
}

.AnyTime-pkr th.AnyTime-dow {
  color: #999;
  padding: 0.4375rem;
  font-weight: 400;
}

.AnyTime-mon-btn {
  float: left;
  width: 16.66667%;
}

.AnyTime-mon7-btn {
  clear: left;
}

.AnyTime-time,
.AnyTime-hrs-am,
.AnyTime-hrs-pm,
.AnyTime-mins-tens,
.AnyTime-mins-ones,
.AnyTime-secs-tens,
.AnyTime-secs-ones,
.AnyTime-hrs,
.AnyTime-mins,
.AnyTime-secs,
.AnyTime-offs {
  display: inline-block;
}

.AnyTime-btn {
  padding: 0.4375rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .AnyTime-btn {
    transition: none;
  }
}

.AnyTime-btn:hover {
  color: #333;
  background-color: #f5f5f5;
}

.AnyTime-btn.ui-state-disabled {
  cursor: default;
  color: #999;
  background-color: transparent;
}

.AnyTime-hr-btn,
.AnyTime-min-ten-btn,
.AnyTime-min-one-btn,
.AnyTime-sec-ten-btn,
.AnyTime-sec-one-btn {
  min-width: 2.5rem;
}

.AnyTime-hrs-pm,
.AnyTime-mins-ones,
.AnyTime-secs-ones {
  margin-left: 0.125rem;
}

.AnyTime-mins-tens,
.AnyTime-secs-tens {
  margin-left: 1.25rem;
}

.AnyTime-off-cur-btn {
  display: inline-block;
  overflow: hidden;
}

.AnyTime-off-select-btn {
  display: inline-block;
  vertical-align: top;
}

.AnyTime-off-selector {
  margin: 0.625rem;
  position: absolute;
}

.AnyTime-body-off-selector {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}

.AnyTime-off-off-btn {
  text-align: left;
}

.AnyTime-yr-selector {
  position: absolute;
  width: 90%;
}

.AnyTime-body-yr-selector {
  margin: 0;
}

.AnyTime-yr-mil,
.AnyTime-yr-cent,
.AnyTime-yr-dec,
.AnyTime-yr-yr,
.AnyTime-yr-era {
  display: inline-block;
  vertical-align: top;
  width: 20%;
}

@media (max-width: 575.98px) {
  .AnyTime-pkr .AnyTime-date {
    float: none;
  }

  .AnyTime-mon-btn {
    float: none;
    display: inline-block;
  }

  .AnyTime-date + .AnyTime-time {
    margin-left: 0;
    margin-top: 1.25rem;
    float: none;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Spectrum color picker
*
*  Flexible and powerful jQuery colorpicker library
*
* ---------------------------------------------------------------------------- */
.sp-sat,
.sp-val,
.sp-top-inner,
.sp-color,
.sp-hue,
.sp-clear-enabled .sp-clear,
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sp-container.sp-input-disabled .sp-input-container,
.sp-container.sp-buttons-disabled .sp-button-container,
.sp-container.sp-palette-buttons-disabled .sp-palette-button-container,
.sp-palette-only .sp-picker-container,
.sp-palette-disabled .sp-palette-container,
.sp-initial-disabled .sp-initial {
  display: none;
}

.sp-hidden {
  display: none !important;
}

.sp-cf::after {
  display: block;
  clear: both;
  content: "";
}

.sp-preview,
.sp-alpha,
.sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}

.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block;
}

.sp-container {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  display: inline-block;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  box-sizing: content-box;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.sp-container.sp-flat {
  position: relative;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.sp-picker-container,
.sp-palette-container {
  display: block;
  white-space: nowrap;
  vertical-align: top;
  position: relative;
  padding: 0.625rem;
}

@media (min-width: 576px) {
  .sp-picker-container,
  .sp-palette-container {
    display: inline-block;
  }
}

.sp-picker-container {
  width: 13.75rem;
}

.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sp-top {
  position: relative;
  width: 100%;
  display: block;
}

.sp-color {
  right: 20%;
}

.sp-hue {
  left: 85%;
  height: 100%;
}

.sp-clear-enabled .sp-hue {
  top: 2.5rem;
  height: 75%;
}

.sp-fill {
  padding-top: 80%;
}

.sp-alpha-enabled .sp-top {
  margin-bottom: 1.625rem;
}

.sp-alpha-enabled .sp-alpha {
  display: block;
}

.sp-alpha-handle {
  position: absolute;
  top: -0.25rem;
  bottom: -0.25rem;
  width: 0.3125rem;
  /*rtl:ignore*/
  left: 50%;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0.25rem;
}

.sp-alpha {
  display: none;
  position: absolute;
  bottom: -1rem;
  right: 0;
  left: 0;
  height: 0.375rem;
}

.sp-clear {
  display: none;
}

.sp-clear-display {
  cursor: pointer;
}

.sp-clear-display:after {
  content: "\ee6e";
  display: block;
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-preview .sp-clear-display:after,
.sp-initial .sp-clear-display:after {
  content: none;
}

.sp-clear-enabled .sp-clear {
  display: block;
  left: 85%;
  height: 1.875rem;
  text-align: center;
  color: #999;
  box-shadow: 0 0 0 1px #ddd inset;
}

.sp-input-container {
  margin-top: 0.625rem;
}

.sp-initial-disabled .sp-input-container {
  width: 100%;
}

.sp-input {
  border: 1px solid #ddd;
  padding: 0.4375rem 0.875rem;
  width: 100%;
  background-color: #fff;
  outline: 0;
  color: #333;
  border-radius: 0.1875rem;
}

.sp-initial {
  margin-top: 0.625rem;
}

.sp-initial span {
  width: 50%;
  height: 1.563rem;
  display: block;
  float: left;
}

.sp-initial span .sp-thumb-inner {
  height: 1.563rem;
  width: 100%;
  display: block;
}

.sp-dragger {
  border: 1px solid #fff;
  background-color: #333;
  cursor: pointer;
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
}

.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 0.25rem;
  left: -0.125rem;
  right: -0.125rem;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0.1875rem;
}

.sp-replacer {
  overflow: hidden;
  cursor: pointer;
  padding: 0.3125rem;
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fafafa;
  color: #999;
  vertical-align: middle;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sp-replacer {
    transition: none;
  }
}

.sp-replacer:not([class*="bg-"]):hover,
.sp-replacer:not([class*="bg-"]).sp-active {
  background-color: #f5f5f5;
  color: #333;
}

.sp-replacer[class*="bg-"],
.sp-replacer[class*="bg-"]:hover,
.sp-replacer[class*="bg-"]:focus {
  border-color: transparent;
}

.sp-replacer.sp-disabled {
  cursor: default;
  opacity: 0.8;
}

.sp-replacer.sp-disabled:hover,
.sp-replacer.sp-disabled:focus {
  background-color: #fafafa;
  color: #999;
}

.sp-preview {
  position: relative;
  width: 1.625rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
  float: left;
  z-index: 0;
}

.sp-preview,
.sp-preview .sp-preview-inner {
  border-radius: 0.1875rem;
}

.sp-preview .sp-preview-inner,
.sp-preview .sp-clear-display {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.sp-replacer[class*="bg-"] .sp-preview .sp-preview-inner {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.sp-dd {
  float: left;
  font-size: 0;
  position: relative;
  margin: 0.25rem;
}

.sp-dd:after {
  content: "\e9c5";
  display: block;
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*rtl:begin:ignore*/
.sp-sat {
  background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
  box-shadow: 0 0 0 1px #ccc inset;
}

.sp-val {
  background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}

.sp-hue {
  background: linear-gradient(
    to bottom,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
}

/*rtl:end:ignore*/
/*rtl:begin:ignore*/
.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}

.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}

.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}

.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}

.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}

.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}

/*rtl:end:ignore*/
.sp-palette {
  max-width: 13.75rem;
}

.sp-thumb-el {
  position: relative;
}

.sp-palette .sp-thumb-el {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.sp-thumb-el .sp-thumb-inner {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.sp-thumb-el .sp-thumb-inner:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}

.sp-palette .sp-thumb-el {
  width: 1rem;
  height: 1rem;
}

.sp-palette .sp-thumb-el + .sp-thumb-el {
  margin-left: 0.3125rem;
}

.sp-palette .sp-thumb-el.sp-thumb-active {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}

.sp-palette .sp-thumb-el.sp-thumb-active .sp-thumb-inner {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}

.sp-palette .sp-thumb-active.sp-thumb-dark .sp-thumb-inner:after,
.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  content: "\e600";
  display: block;
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  color: #333;
}

.sp-palette-row {
  font-size: 0;
}

.sp-palette-row + .sp-palette-row {
  margin-top: 0.3125rem;
}

.sp-palette-row:empty {
  margin-top: 0;
}

.sp-cancel,
.sp-choose,
.sp-palette-toggle {
  border: 0;
  padding: 0.4375rem 0.875rem;
  float: left;
  width: 48%;
  text-align: center;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sp-cancel,
  .sp-choose,
  .sp-palette-toggle {
    transition: none;
  }
}

.sp-cancel:focus,
.sp-choose:focus,
.sp-palette-toggle:focus {
  outline: 0;
}

.sp-palette-button-container,
.sp-button-container {
  margin-top: 0.625rem;
  text-align: center;
}

.sp-container[class*="bg-"] .sp-palette-button-container a,
.sp-container[class*="bg-"] .sp-palette-button-container button,
.sp-container[class*="bg-"] .sp-button-container a,
.sp-container[class*="bg-"] .sp-button-container button {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-color: transparent;
}

.sp-container[class*="bg-"] .sp-palette-button-container .sp-cancel:hover,
.sp-container[class*="bg-"] .sp-palette-button-container .sp-cancel:focus,
.sp-container[class*="bg-"]
  .sp-palette-button-container
  .sp-palette-toggle:hover,
.sp-container[class*="bg-"]
  .sp-palette-button-container
  .sp-palette-toggle:focus,
.sp-container[class*="bg-"] .sp-button-container .sp-cancel:hover,
.sp-container[class*="bg-"] .sp-button-container .sp-cancel:focus,
.sp-container[class*="bg-"] .sp-button-container .sp-palette-toggle:hover,
.sp-container[class*="bg-"] .sp-button-container .sp-palette-toggle:focus {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

.sp-cancel,
.sp-palette-toggle {
  border: 1px solid transparent;
}

.sp-cancel:hover,
.sp-cancel:focus,
.sp-palette-toggle:hover,
.sp-palette-toggle:focus {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.sp-choose {
  border: 1px solid transparent;
}

.sp-choose:hover,
.sp-choose:focus {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

.sp-cancel,
.sp-palette-toggle {
  color: #333;
  margin-right: 0.3125rem;
  background-color: #fafafa;
  margin-right: 4%;
  border-color: #ddd;
}

.sp-cancel:hover,
.sp-cancel:focus,
.sp-palette-toggle:hover,
.sp-palette-toggle:focus {
  color: #333;
}

.sp-palette-toggle {
  margin-right: 0;
  width: auto;
  float: none;
}

.sp-choose {
  color: #fff;
  background-color: #607d8b;
}

/* ------------------------------------------------------------------------------
 *
 *  # Plupload multiple file uploader
 *
 *  Styles for plupload.min.js - multi runtime single and multiple file uploader
 *
 * ---------------------------------------------------------------------------- */
.plupload_wrapper {
  background-color: #fcfcfc;
  border: 2px dashed #ddd;
  border-radius: 0.1875rem;
}

.plupload_header:after,
.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before,
.plupload_file_action:after,
.plupload_delete a:after,
.plupload_failed a:after,
.plupload_done a:after {
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.plupload_header {
  display: none;
  position: relative;
  background-color: #37474f;
  color: #fff;
  margin: -4px -2px;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.plupload_header:after {
  content: "\ec78";
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
  margin-top: -1rem;
}

.plupload_header_content {
  padding: 0.9375rem 1.25rem;
  padding-left: 4.5rem;
}

.plupload_header_title {
  font-size: 0.9375rem;
  font-weight: 500;
}

.plupload_header_text {
  font-size: 0.75rem;
  line-height: 1.6667;
}

.plupload_clear,
.plupload_clearer {
  clear: both;
}

.plupload_clearer,
.plupload_progress_bar {
  display: block;
  font-size: 0;
  line-height: 0;
}

.plupload_button {
  display: inline-block;
  border: 1px solid transparent;
  font-weight: 400;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .plupload_button {
    transition: none;
  }
}

.plupload_button:not(:last-child) {
  margin-right: 0.625rem;
}

.plupload_add {
  color: #333;
  background-color: #fafafa;
  border-color: #ddd;
}

.plupload_add:hover {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.plupload_start {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.plupload_start:hover {
  color: #fff;
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

a.plupload_disabled {
  cursor: default;
  opacity: 0.65;
}

a.plupload_disabled:hover {
  box-shadow: none;
}

.plupload_filelist {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.plupload_scroll .plupload_filelist {
  height: 250px;
  overflow-y: auto;
}

.plupload_filelist li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1.25rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .plupload_filelist li {
    transition: none;
  }
}

.plupload_filelist li:hover {
  background-color: #f5f5f5;
}

.plupload_filelist li .plupload_file_name {
  margin-right: auto;
}

.plupload_filelist li .plupload_file_action {
  -ms-flex-order: 12;
  order: 12;
}

.plupload_filelist li .plupload_file_status {
  -ms-flex-order: 11;
  order: 11;
}

.plupload_filelist li .plupload_file_size {
  -ms-flex-order: 10;
  order: 10;
}

.plupload_filelist:empty,
.plupload_filelist li.plupload_droptext {
  background-color: transparent;
  font-size: 0;
}

.plupload_filelist:empty:before,
.plupload_filelist li.plupload_droptext:before {
  content: "\ea0e";
  font-size: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.25rem;
  z-index: 2;
  color: #ccc;
  text-indent: 0;
  font-weight: normal;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.plupload_filelist:empty:after,
.plupload_filelist li.plupload_droptext:after {
  content: "Drag files to upload";
  font-size: 1.0625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 3.75rem;
  color: #777;
  text-indent: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.plupload_filelist:empty:after {
  content: "Add files to upload";
}

@media (max-width: 767.98px) {
  .plupload_filelist {
    padding: 0.625rem 0;
  }

  .plupload_filelist li .plupload_file_size {
    display: none;
  }
}

.plupload_filelist_header {
  padding: 0.9375rem 1.25rem;
}

.plupload_filelist_header .plupload_file_name,
.plupload_filelist_header .plupload_file_size,
.plupload_filelist_header .plupload_file_status,
.plupload_filelist_header .plupload_file_action {
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .plupload_filelist_header {
    display: none;
  }
}

.plupload_filelist_footer {
  padding: 0.9375rem 1.25rem;
}

.plupload_filelist_footer .plupload_file_size,
.plupload_filelist_footer .plupload_file_status,
.plupload_filelist_footer .plupload_upload_status {
  padding: 0.4375rem 0;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .plupload_filelist_footer {
    text-align: center;
  }

  .plupload_filelist_footer .plupload_file_action,
  .plupload_filelist_footer .plupload_file_status,
  .plupload_filelist_footer .plupload_file_size {
    display: none;
  }

  .plupload_filelist_footer .plupload_progress {
    margin: auto;
  }
}

@media (min-width: 576px) {
  .plupload_filelist_header,
  .plupload_filelist_footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .plupload_filelist_header .plupload_file_name,
  .plupload_filelist_footer .plupload_file_name {
    margin-right: auto;
  }

  .plupload_filelist_header .plupload_file_action,
  .plupload_filelist_footer .plupload_file_action {
    -ms-flex-order: 12;
    order: 12;
  }

  .plupload_filelist_header .plupload_file_status,
  .plupload_filelist_footer .plupload_file_status {
    -ms-flex-order: 11;
    order: 11;
  }

  .plupload_filelist_header .plupload_file_size,
  .plupload_filelist_footer .plupload_file_size {
    -ms-flex-order: 10;
    order: 10;
  }
}

.plupload_file_action {
  margin-left: 1.875rem;
  line-height: 1;
  font-size: 0;
  text-align: right;
}

.plupload_file_action * {
  display: none;
}

.plupload_file_action:after {
  content: "\ec67";
}

.plupload_filelist .plupload_file_action:after {
  content: none;
}

.plupload_delete .plupload_file_action > a {
  color: #333;
  line-height: 1;
  opacity: 0.5;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .plupload_delete .plupload_file_action > a {
    transition: none;
  }
}

.plupload_delete .plupload_file_action > a:hover {
  opacity: 1;
}

.plupload_uploading {
  background-color: #e8f5e9;
}

.plupload_delete a:after {
  content: "\ed6b";
}

.plupload_failed a {
  color: #f44336;
  cursor: default;
}

.plupload_failed a:after {
  content: "\e9cf";
}

.plupload_done {
  color: #999;
}

.plupload_done a {
  color: #4caf50;
  cursor: default;
}

.plupload_done a:after {
  content: "\e9db";
}

.plupload_progress,
.plupload_upload_status {
  display: none;
}

.plupload_progress_container {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.1875rem;
}

.plupload_progress_bar {
  width: 0;
  height: 0.25rem;
  background: #4caf50;
  border-radius: 0.1875rem;
}

.plupload_file_size,
.plupload_file_status,
.plupload_progress {
  width: 5rem;
  text-align: right;
}

/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  Styles for fileinput.min.js - an enhanced HTML 5 file input for Bootstrap
 *
 * ---------------------------------------------------------------------------- */
.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
  visibility: visible;
}

.file-caption-name {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 0;
  background: none;
  outline: 0;
  pointer-events: none;
}

.file-input-new .file-caption-name,
.file-input-ajax-new .file-caption-name {
  color: #999;
}

.file-error-message {
  position: relative;
  background-color: #ffebee;
  color: #c62828;
  text-align: center;
  padding: 0.4375rem 0.875rem;
  margin: 0.5rem;
  margin-top: 0;
  border-radius: 0.1875rem;
}

.file-drop-zone .file-error-message {
  margin-top: 0.4375rem;
}

.file-error-message .close {
  color: #c62828;
}

.file-error-message pre {
  background-color: #fff;
  margin-top: 0.4375rem;
  text-align: left;
}

.file-error-message ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-caption-disabled {
  background-color: #fafafa;
  cursor: default;
}

.file-preview-detail-modal {
  text-align: left;
}

.file-preview {
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 1.25rem;
  position: relative;
  text-align: center;
  border-radius: 0.1875rem;
}

.file-preview .close {
  font-size: 1.25003rem;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  line-height: 1;
  color: #333;
  text-shadow: none;
  opacity: 0.75;
  border-radius: 0.1875rem;
}

.file-preview .close:hover,
.file-preview .close:focus {
  color: #333;
  text-decoration: none;
  opacity: 1;
}

.file-preview-thumbnails {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

@media (max-width: 767.98px) {
  .file-preview-thumbnails {
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.file-preview-frame {
  margin: 0.5rem;
  margin-right: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 0.1875rem;
}

.file-preview-frame .kv-file-content {
  position: relative;
  height: 10rem;
}

.file-preview-image {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.file-thumbnail-footer {
  position: relative;
  background-color: #fafafa;
  border-top: 1px solid #ddd;
}

.file-preview-text {
  color: #2196f3;
  border: 0;
  overflow-x: hidden;
}

.file-preview-other {
  width: 10rem;
}

.file-preview-other:after {
  content: "\ea0c";
  font-family: "icomoon";
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 4rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.file-preview-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  font-size: 0.75rem;
  line-height: 1.6667;
  padding: 0.4375rem 0.875rem;
  z-index: 1070;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.file-preview-status:empty {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.file-thumb-loading {
  min-height: 6.62503rem;
}

.file-thumb-loading:before,
.file-uploading:before {
  content: "";
  display: inline-block;
  background-color: #263238;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1069;
  margin-top: -2.06252rem;
  margin-left: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.1875rem;
}

.file-thumb-loading:after,
.file-uploading:after {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  margin-top: -1.56252rem;
  margin-left: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  z-index: 1070;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.file-upload-indicator {
  cursor: default;
  float: left;
  padding: 0.625rem;
  line-height: 1;
}

.file-upload-indicator i {
  top: 0;
}

.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
  display: none;
}

.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .close {
  display: none;
}

.kv-hidden,
.file-caption-icon,
.file-zoom-dialog .modal-header:before,
.file-zoom-dialog .modal-header:after,
.hide-content .kv-file-content {
  display: none;
}

.file-footer-buttons {
  float: right;
}

.file-footer-buttons button {
  padding: 0.625rem;
  background-color: transparent;
  border: 0;
  line-height: 1;
  cursor: pointer;
  opacity: 0.6;
  outline: 0;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .file-footer-buttons button {
    transition: none;
  }
}

.file-footer-buttons button:hover {
  opacity: 1;
}

.file-footer-buttons button i {
  top: 0;
}

.file-footer-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  margin-left: auto;
  margin-right: auto;
}

.file-size-info samp {
  font-size: 0.6875rem;
}

.file-drop-zone {
  border: 1px dashed #ddd;
  height: 100%;
  vertical-align: middle;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.1875rem;
}

.file-drop-zone-title {
  font-size: 1.1875rem;
  font-weight: 300;
  padding: 5rem;
  opacity: 0.5;
}

.kv-upload-progress {
  margin-bottom: 1.25rem;
}

.file-thumb-progress {
  position: absolute;
  top: -0.375rem;
  left: 0;
  right: 0;
}

.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
  height: 0.375rem;
  border-radius: 0;
  font-size: 0;
}

.btn-file ::-ms-browse {
  width: 100%;
  height: 100%;
}

.file-zoom-fullscreen .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
}

.file-zoom-fullscreen .modal-content {
  border-radius: 0;
}

.file-zoom-fullscreen .modal-body {
  overflow-y: auto;
}

.file-zoom-dialog .btn-navigate {
  position: absolute;
  top: 50%;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  color: #fff;
  opacity: 0.7;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .file-zoom-dialog .btn-navigate {
    transition: none;
  }
}

.file-zoom-dialog .btn-navigate > i {
  display: block;
  font-size: 2rem;
}

.file-zoom-dialog .btn-navigate:not([disabled]):hover,
.file-zoom-dialog .btn-navigate:not([disabled]):focus {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
}

.file-zoom-dialog .btn-navigate[disabled] {
  opacity: 0.3;
}

.file-zoom-dialog .btn-prev {
  left: -3.25rem;
}

.file-zoom-dialog .btn-next {
  right: -3.25rem;
}

.file-zoom-dialog .floating-buttons {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background-color: #fff;
}

.file-zoom-dialog .kv-zoom-title {
  color: #999;
  margin-left: 0.625rem;
}

.file-zoom-content {
  text-align: center;
}

.file-drag-handle {
  cursor: move;
  float: left;
  line-height: 1;
  opacity: 0.6;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .file-drag-handle {
    transition: none;
  }
}

.file-drag-handle:hover {
  opacity: 1;
}

.file-drag-handle i {
  top: 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Styles for dropzone.min.js - open source library that provides drag’n’drop file uploads with image previews
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  border: 2px dashed rgba(0, 0, 0, 0.125);
  min-height: 18rem;
  background-color: #fff;
  padding: 0.3125rem;
  border-radius: 0.1875rem;
}

.card .dropzone {
  background-color: #fcfcfc;
  border-color: #ddd;
}

.dropzone .dz-message {
  opacity: 1;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-color: #999;
  background-color: #f5f5f5;
  color: #ddd;
}

.sidebar .dropzone {
  text-align: center;
}

.sidebar .dropzone .dz-default.dz-message:before {
  font-size: 4rem;
  width: 4rem;
  height: 4rem;
  margin-left: -2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar .dropzone .dz-default.dz-message span {
  font-size: 0.9375rem;
}

.dropzone .dz-default.dz-message {
  height: 18rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  border-radius: 0.1875rem;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-default.dz-message {
    transition: none;
  }
}

.dropzone .dz-default.dz-message:before {
  content: "\ea0e";
  font-family: "icomoon";
  font-size: 4rem;
  display: inline-block;
  position: absolute;
  top: 5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 1;
  z-index: 2;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropzone .dz-default.dz-message > span {
  font-size: 1.0625rem;
  color: #777;
  display: block;
  margin-top: 10.25rem;
}

.dropzone .dz-default.dz-message > span span {
  display: block;
  color: #9d9d9d;
  font-size: 0.9375rem;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.75;
}

.dropzone.dz-started .dz-message {
  display: block;
  opacity: 0;
}

.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin: 0.5rem;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem;
  border-radius: 0.1875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
  display: none;
}

.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
  position: relative;
}

.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
  overflow: hidden;
  margin-top: 0.625rem;
}

.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
  position: absolute;
  top: 0;
  left: 0;
}

.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
  margin-top: 0.625rem;
}

.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
  display: none;
}

.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  display: none;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  padding: 0.25rem;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 0 0.1875rem;
}

.dropzone .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  font-family: "icomoon";
  display: block;
  font-size: 0.8125rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropzone .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark svg {
  display: none;
}

.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
  color: #f44336;
}

.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
  content: "\ed6a";
}

.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  color: #4caf50;
}

.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  content: "\ed6e";
}

.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
  display: block;
  opacity: 1;
}

.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.25rem;
  display: none;
}

.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #4caf50;
  transition: width ease-in-out 0.15s;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview .dz-progress .dz-upload,
  .dropzone-previews .dz-preview .dz-progress .dz-upload {
    transition: none;
  }
}

.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
  display: block;
  opacity: 0;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview.dz-success .dz-progress,
  .dropzone-previews .dz-preview.dz-success .dz-progress {
    transition: none;
  }
}

.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0.5rem;
  padding: 0.5rem;
  color: #f44336;
  max-width: 100%;
  z-index: 5;
  opacity: 0;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview .dz-error-message,
  .dropzone-previews .dz-preview .dz-error-message {
    transition: none;
  }
}

.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
  display: block;
  opacity: 1;
}

.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
  background: #f44336;
}

.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
  display: block;
  opacity: 0.1;
}

.dropzone .dz-preview .dz-image img,
.dropzone-previews .dz-preview .dz-image img {
  width: 100%;
}

.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
  background-color: #fafafa;
  border: 1px solid #ddd;
  display: block;
  padding: 0.3125rem 0.75rem;
  text-align: center;
  color: #333;
  margin-top: 0.625rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview a.dz-remove,
  .dropzone-previews .dz-preview a.dz-remove {
    transition: none;
  }
}

.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

/* ------------------------------------------------------------------------------
*
*  # Noty notifications
*
*  Styles for noty.min.js - A dependency-free notification library
*
* ---------------------------------------------------------------------------- */
.noty_bar {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.noty_body {
  padding: 0.9375rem 1.25rem;
}

.noty_close_with_button .noty_body {
  padding-right: 2.5rem;
}

.noty_buttons {
  padding: 0.9375rem 1.25rem;
  padding-top: 0;
  text-align: right;
}

.noty_layout_mixin,
#noty_layout__top,
#noty_layout__topLeft,
#noty_layout__topCenter,
#noty_layout__topRight,
#noty_layout__center,
#noty_layout__centerLeft,
#noty_layout__centerRight,
#noty_layout__bottom,
#noty_layout__bottomLeft,
#noty_layout__bottomCenter,
#noty_layout__bottomRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 1050;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  max-width: 90%;
}

#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__top .noty_bar:first-child {
  margin-top: 1.25rem;
}

#noty_layout__topLeft {
  top: 1.25rem;
  left: 1.25rem;
  width: 20rem;
}

#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 20rem;
  -webkit-transform: translate(-50%) translateZ(0) scale(1, 1);
  transform: translate(-50%) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
  top: 1.25rem;
  right: 1.25rem;
  width: 20rem;
}

#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 20rem;
  -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1, 1);
  transform: translate(-50%, -50%) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
  top: 50%;
  left: 1.25rem;
  width: 20rem;
  -webkit-transform: translate(0, -50%) translateZ(0) scale(1, 1);
  transform: translate(0, -50%) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
  top: 50%;
  right: 1.25rem;
  width: 20rem;
  -webkit-transform: translate(0, -50%) translateZ(0) scale(1, 1);
  transform: translate(0, -50%) translateZ(0) scale(1, 1);
}

#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%;
}

#noty_layout__bottom .noty_bar:last-child {
  margin-bottom: 1.25rem;
}

#noty_layout__bottomLeft {
  bottom: 1.25rem;
  left: 1.25rem;
  width: 20rem;
}

#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 20rem;
  -webkit-transform: translate(calc(-50% - 1px)) translateZ(0) scale(1, 1);
  transform: translate(calc(-50% - 1px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
  bottom: 1.25rem;
  right: 1.25rem;
  width: 20rem;
}

.noty_progressbar {
  display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.1875rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.noty_effects_open {
  opacity: 0;
  -webkit-transform: translate(50%);
  transform: translate(50%);
  -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.noty_effects_close {
  -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.noty_fix_effects_height {
  -webkit-animation: noty_anim_height 75ms ease-out;
  animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
  cursor: pointer;
}

.noty_close_button {
  position: absolute;
  top: 0.9375rem;
  right: 1.25rem;
  background-color: transparent;
  font-size: 1.25003rem;
  font-weight: 400;
  color: inherit;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .noty_close_button {
    transition: none;
  }
}

.noty_close_button:hover {
  opacity: 1;
}

.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1040;
  opacity: 0.5;
  left: 0;
  top: 0;
}

.noty_modal.noty_modal_open {
  opacity: 0;
  -webkit-animation: noty_modal_in 0.3s ease-out;
  animation: noty_modal_in 0.3s ease-out;
}

.noty_modal.noty_modal_close {
  -webkit-animation: noty_modal_out 0.3s ease-out;
  animation: noty_modal_out 0.3s ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes noty_modal_in {
  100% {
    opacity: 0.5;
  }
}

@keyframes noty_modal_in {
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}

@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}

@-webkit-keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
    transform: translate(50%);
    opacity: 0;
  }
}

@keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
    transform: translate(50%);
    opacity: 0;
  }
}

@-webkit-keyframes noty_anim_height {
  100% {
    height: 0;
  }
}

@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}

.noty_theme__limitless.noty_bar {
  margin: 0.25rem 0;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.noty_theme__limitless.noty_type__alert {
  background-color: #546e7a;
  color: #fff;
  border-color: #546e7a;
}

.noty_theme__limitless.noty_type__warning {
  background-color: #ff7043;
  color: #fff;
  border-color: #ff7043;
}

.noty_theme__limitless.noty_type__error {
  background-color: #ef5350;
  color: #fff;
  border-color: #ef5350;
}

.noty_theme__limitless.noty_type__info {
  background-color: #03a9f4;
  color: #fff;
  border-color: #03a9f4;
}

.noty_theme__limitless.noty_type__success {
  background-color: #4caf50;
  color: #fff;
  border-color: #4caf50;
}

.noty_theme__limitless.noty_type__confirm {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.noty_theme__limitless.noty_type__confirm .noty_body {
  padding: 1.25rem;
}

.noty_theme__limitless.noty_type__confirm .noty_close_button {
  top: 1.25rem;
}

/* ------------------------------------------------------------------------------
*
*  # PNotify notifications
*
*  Styles for pnotify.min.js - a flexible JavaScript notification plugin
*
* ---------------------------------------------------------------------------- */
.ui-pnotify {
  top: 1.25rem;
  right: 1.25rem;
  position: absolute;
  height: auto;
  z-index: 2;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

body > .ui-pnotify {
  position: fixed;
  z-index: 1070;
}

.ui-pnotify.alert-rounded > .ui-pnotify-container {
  border-radius: 100px;
}

.ui-pnotify[class*="bg-"] > .ui-pnotify-container {
  background-color: inherit;
  border-color: transparent;
  color: #fff;
}

.ui-pnotify[class*="text-"] > .ui-pnotify-container,
.ui-pnotify[class*="alpha-"] > .ui-pnotify-container {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}

.ui-pnotify.stack-top-left,
.ui-pnotify.stack-bottom-left {
  left: 1.25rem;
  right: auto;
}

.ui-pnotify.stack-bottom-right,
.ui-pnotify.stack-bottom-left {
  bottom: 1.25rem;
  top: auto;
}

.ui-pnotify.stack-modal {
  left: 50%;
  right: auto;
  margin-left: -10rem;
}

.ui-pnotify.stack-custom-right {
  top: auto;
  left: auto;
  bottom: 15rem;
  right: 15rem;
}

.ui-pnotify.stack-custom-left {
  top: 15rem;
  left: 15rem;
  right: auto;
  bottom: auto;
}

.ui-pnotify.stack-custom-top {
  right: 0;
  left: 0;
  top: 0;
}

.ui-pnotify.stack-custom-bottom {
  right: 0;
  left: 0;
  bottom: 0;
  top: auto;
}

.ui-pnotify.ui-pnotify-in {
  display: block !important;
}

.ui-pnotify.ui-pnotify-move {
  transition: left 0.5s ease, top 0.5s ease, right 0.5s ease, bottom 0.5s ease;
}

.ui-pnotify.ui-pnotify-fade-slow {
  opacity: 0;
  transition: opacity linear 0.6s;
}

.ui-pnotify.ui-pnotify-fade-slow.ui-pnotify.ui-pnotify-move {
  transition: opacity 0.6s linear, left 0.5s ease, top 0.5s ease,
    right 0.5s ease, bottom 0.5s ease;
}

.ui-pnotify.ui-pnotify-fade-normal {
  opacity: 0;
  transition: opacity linear 0.4s;
}

.ui-pnotify.ui-pnotify-fade-normal.ui-pnotify.ui-pnotify-move {
  transition: opacity 0.4s linear, left 0.5s ease, top 0.5s ease,
    right 0.5s ease, bottom 0.5s ease;
}

.ui-pnotify.ui-pnotify-fade-fast {
  transition: opacity 0.2s linear;
  opacity: 0;
}

.ui-pnotify.ui-pnotify-fade-fast.ui-pnotify.ui-pnotify-move {
  transition: opacity 0.2s linear, left 0.5s ease, top 0.5s ease,
    right 0.5s ease, bottom 0.5s ease;
}

.ui-pnotify.ui-pnotify-fade-in {
  opacity: 1;
}

.ui-pnotify-container {
  padding: 0.9375rem 1.25rem;
  height: 100%;
  position: relative;
  left: 0;
  margin: 0;
  border-radius: 0.1875rem;
}

.ui-pnotify-container::after {
  display: block;
  clear: both;
  content: "";
}

.ui-pnotify-container.ui-pnotify-sharp {
  border-radius: 0;
}

.ui-pnotify-title {
  display: block;
  margin-top: 0;
  margin-bottom: 0.3125rem;
  font-size: 0.9375rem;
}

.ui-pnotify-text {
  display: block;
}

.ui-pnotify-icon {
  display: block;
  float: left;
  line-height: 1;
}

.ui-pnotify-icon > [class^="icon-"] {
  margin-top: 0.25003rem;
  margin-right: 0.625rem;
}

.ui-pnotify-closer,
.ui-pnotify-sticker {
  float: right;
  margin-left: 0.625rem;
  margin-top: 0.25003rem;
  line-height: 1;
  outline: 0;
}

.ui-pnotify-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

body > .ui-pnotify-modal-overlay {
  position: fixed;
  z-index: 1040;
}

.brighttheme {
  border: 1px solid;
}

.ui-pnotify[class*="bg-"] > .brighttheme {
  background-color: inherit;
  border-color: inherit;
  color: inherit;
}

.brighttheme-notice {
  background-color: #fff3e0;
  border-color: #ff9800;
  color: #bf360c;
}

.brighttheme-info {
  background-color: #e1f5fe;
  border-color: #03a9f4;
  color: #01579b;
}

.brighttheme-success {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: #1b5e20;
}

.brighttheme-error {
  background-color: #ffebee;
  border-color: #f44336;
  color: #b71c1c;
}

.brighttheme-icon-closer,
.brighttheme-icon-sticker {
  position: relative;
  display: inline-block;
  outline: 0;
  width: 0.75rem;
  height: 0.75rem;
}

.brighttheme-icon-closer:after,
.brighttheme-icon-sticker:after {
  content: "";
  font-family: "icomoon";
  font-size: 0.75rem;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brighttheme-icon-closer:after {
  content: "\ed6a";
}

.brighttheme-icon-sticker:after {
  content: "\ee70";
}

.brighttheme-icon-sticker.brighttheme-icon-stuck:after {
  content: "\ee75";
}

.ui-pnotify[class*="alert-styled-"] {
  border-width: 0;
  padding: 0;
}

.ui-pnotify.alert-styled-left .brighttheme {
  border-left-width: 2.875rem;
}

.ui-pnotify.alert-styled-left:after {
  left: 0;
}

.ui-pnotify.alert-styled-right .brighttheme {
  border-right-width: 2.875rem;
}

.ui-pnotify.alert-styled-right:after {
  right: 0;
}

.brighttheme .ui-pnotify-action-bar textarea,
.brighttheme .ui-pnotify-action-bar input {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-bottom: 1.25rem !important;
  color: #333;
  padding: 0.4375rem 0.875rem;
  outline: 0;
}

.ui-pnotify[class*="bg-"] .brighttheme .ui-pnotify-action-bar textarea,
.ui-pnotify[class*="bg-"] .brighttheme .ui-pnotify-action-bar input {
  border-color: transparent;
  color: #fff;
}

.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  textarea::-webkit-input-placeholder,
.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  textarea::-moz-placeholder,
.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  textarea:-ms-input-placeholder,
.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  input:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  textarea::-ms-input-placeholder,
.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  input::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  textarea::placeholder,
.ui-pnotify[class*="bg-"]
  .brighttheme
  .ui-pnotify-action-bar
  input::placeholder {
  color: #fff;
  opacity: 1;
}

.ui-pnotify-history-container {
  position: absolute;
  top: 0;
  right: 1.25rem;
  border-top: none;
  padding: 0;
  z-index: 1070;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ui-pnotify-history-container.ui-pnotify-history-fixed {
  position: fixed;
}

.ui-pnotify-history-container .ui-pnotify-history-header {
  text-align: center;
  margin-bottom: 0.3125rem;
}

.ui-pnotify-history-container button {
  cursor: pointer;
  display: block;
  width: 100%;
}

.ui-pnotify-history-container .ui-pnotify-history-pulldown {
  display: block;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .ui-pnotify-mobile-able.ui-pnotify {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: auto !important;
    font-smoothing: antialiased;
  }

  .ui-pnotify-mobile-able.ui-pnotify .ui-pnotify-shadow {
    border-bottom-width: 5px;
    box-shadow: none;
  }

  .ui-pnotify-mobile-able.ui-pnotify.stack-top-left,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
  }

  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
  }

  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-right .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow,
  .ui-pnotify-mobile-able.ui-pnotify.stack-bottom-left .ui-pnotify-shadow {
    border-top-width: 5px;
    border-bottom-width: 1px;
  }

  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-fade {
    opacity: 0.2;
  }

  .ui-pnotify-mobile-able.ui-pnotify.ui-pnotify-nonblock-hide {
    display: none !important;
  }

  .ui-pnotify-mobile-able .ui-pnotify-container {
    border-radius: 0;
  }
}

/* ------------------------------------------------------------------------------
*
*  # jGrowl notifications
*
*  Styles for jgrowl.min.js - an unobtrusive notification system for web applications
*
* ---------------------------------------------------------------------------- */
.jGrowl {
  z-index: 1070;
  position: absolute;
}

body > .jGrowl {
  position: fixed;
}

.jGrowl.top-left {
  left: 1.25rem;
  top: 1.25rem;
}

.jGrowl.top-center {
  left: 50%;
  top: 1.25rem;
  margin-left: -10rem;
}

.jGrowl.top-right {
  right: 1.25rem;
  top: 1.25rem;
}

.jGrowl.center {
  top: 40%;
  width: 20rem;
  left: 50%;
  margin-left: -10rem;
  margin-top: -1.87502rem;
}

.jGrowl.center .jGrowl-notification,
.jGrowl.center .jGrowl-closer {
  margin-left: auto;
  margin-right: auto;
}

.jGrowl.bottom-left {
  left: 1.25rem;
  bottom: 1.25rem;
}

.jGrowl.bottom-center {
  left: 50%;
  bottom: 1.25rem;
  margin-left: -10rem;
}

.jGrowl.bottom-right {
  right: 1.25rem;
  bottom: 1.25rem;
}

@media print {
  .jGrowl {
    display: none;
  }
}

.jGrowl-notification {
  margin-bottom: 0.625rem;
  width: 20rem;
  text-align: left;
  display: none;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.jGrowl-notification .jGrowl-header {
  font-size: 0.9375rem;
  margin-bottom: 0.3125rem;
}

.jGrowl-notification .jGrowl-header:empty {
  margin: 0;
}

.jGrowl-notification .jGrowl-close {
  font-weight: 400;
  background: none;
  border: 0;
  font-size: 1.25003rem;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  float: right;
  color: inherit;
  outline: 0;
  margin-left: 0.625rem;
  opacity: 0.75;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .jGrowl-notification .jGrowl-close {
    transition: none;
  }
}

.jGrowl-notification .jGrowl-close:hover {
  opacity: 1;
}

.jGrowl-closer {
  padding: 0.3125rem 0;
  cursor: pointer;
  margin-top: 0.3125rem;
  text-align: center;
  background-color: #fff;
  width: 20rem;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
}

/* ------------------------------------------------------------------------------
*
*  # Sweet Alerts component
*
*  Styles for sweet_alert.min.js - notification library
*
* ---------------------------------------------------------------------------- */
.swal2-shown:not(.swal2-no-backdrop) {
  overflow-y: hidden;
}

.swal2-shown.swal2-iosfix {
  position: fixed;
  left: 0;
  right: 0;
}

.swal2-shown.swal2-no-backdrop .swal2-shown {
  background-color: transparent;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.swal2-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1070;
}

.swal2-container.swal2-top {
  -ms-flex-align: start;
  align-items: flex-start;
}

.swal2-container.swal2-top .swal2-modal {
  margin-top: 1.25rem;
}

.swal2-container.swal2-top-left {
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.swal2-container.swal2-top-left .swal2-modal {
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}

.swal2-container.swal2-top-right {
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.swal2-container.swal2-top-right .swal2-modal {
  margin-top: 1.25rem;
  margin-right: 1.25rem;
}

.swal2-container.swal2-center {
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
}

.swal2-container.swal2-center-left {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.swal2-container.swal2-center-left .swal2-modal {
  margin-left: 1.25rem;
}

.swal2-container.swal2-center-right {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.swal2-container.swal2-center-right .swal2-modal {
  margin-right: 1.25rem;
}

.swal2-container.swal2-bottom {
  -ms-flex-align: end;
  align-items: flex-end;
}

.swal2-container.swal2-bottom .swal2-modal {
  margin-bottom: 1.25rem;
}

.swal2-container.swal2-bottom-left {
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.swal2-container.swal2-bottom-left .swal2-modal {
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
}

.swal2-container.swal2-bottom-right {
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.swal2-container.swal2-bottom-right .swal2-modal {
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-container.swal2-fade {
  transition: background-color 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .swal2-container.swal2-fade {
    transition: none;
  }
}

body:not(.swal2-no-backdrop) .swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.5);
}

.swal2-grow-fullscreen .swal2-modal {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1.25rem 1.25rem;
}

.swal2-grow-row .swal2-modal {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.swal2-grow-column {
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}

.swal2-grow-column.swal2-center-left {
  -ms-flex-align: start;
  align-items: flex-start;
}

.swal2-grow-column.swal2-center-right {
  -ms-flex-align: end;
  align-items: flex-end;
}

.swal2-grow-column .swal2-modal {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.swal2-popup {
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  text-align: center;
  display: none;
  position: relative;
  max-width: 100%;
  padding: 1.25rem;
  width: 31.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.swal2-popup:focus {
  outline: none;
}

.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

@media (min-width: 576px) {
  .swal2-popup {
    margin-left: 0;
    margin-right: 0;
  }
}

.swal2-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}

.swal2-title {
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.5385;
  text-align: center;
  position: relative;
  word-wrap: break-word;
  margin-top: 0.3125rem;
  margin-bottom: 0;
}

.swal2-content {
  text-align: center;
  position: relative;
  word-wrap: break-word;
}

.swal2-actions {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 1.25rem;
}

.swal2-actions > button + button {
  margin-left: 0.625rem;
}

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  cursor: no-drop;
  opacity: 0.4;
}

.swal2-loading .swal2-confirm:before {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  -ms-flex-item-align: center;
  align-self: center;
  vertical-align: middle;
  position: relative;
  line-height: 1;
  margin-right: 0.625rem;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-close {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  opacity: 0.5;
  width: 1.5rem;
  height: 1.5rem;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .swal2-close {
    transition: none;
  }
}

.swal2-close:hover,
.swal2-close:focus {
  opacity: 1;
  outline: 0;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  display: none;
  margin: 1.25rem auto 0 auto;
}

.swal2-popup .select2-container,
.swal2-popup .btn-group,
.swal2-popup .uniform-uploader {
  margin: 1.25rem auto 0 auto;
}

.swal2-checkbox {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.swal2-checkbox > span:not(.switchery) {
  margin-left: 0.625rem;
}

.swal2-radio {
  -ms-flex-pack: center;
  justify-content: center;
}

.swal2-radio label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.swal2-radio label + label {
  margin-left: 1.25rem;
}

.swal2-radio label input,
.swal2-radio label .uniform-choice {
  margin-right: 0.625rem;
}

.swal2-range {
  margin-top: 1.25rem;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swal2-range input {
  display: block;
  width: 100%;
}

.swal2-range output {
  font-size: 0.9375rem;
  font-weight: 500;
  margin-top: 0.625rem;
}

.swal2-inputerror,
.swal2-inputerror:hover,
.swal2-inputerror:focus {
  border-color: #f44336;
}

.swal2-validationerror {
  background-color: #f5f5f5;
  overflow: hidden;
  padding: 0.4375rem 0.875rem;
  display: none;
  margin-top: 1.25rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-radius: 0.1875rem;
}

.swal2-validationerror:before {
  content: "\ed68";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  -ms-flex-item-align: center;
  align-self: center;
  line-height: 1;
  margin-right: 0.625rem;
  color: #f44336;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-image {
  margin: 0.625rem auto;
  max-width: 100%;
  height: auto;
}

.swal2-icon {
  border: 0.25rem solid transparent;
  margin: 0.625rem auto 1.25rem auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.swal2-icon.swal2-success {
  border-color: #66bb6a;
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  height: 2.5rem;
  width: 1.25rem;
  /*rtl:ignore*/
  border-right: 0.25rem solid #66bb6a;
  border-top: 0.25rem solid #66bb6a;
  position: absolute;
  /*rtl:ignore*/
  left: 1.25rem;
  top: 2.625rem;
  opacity: 1;
  -webkit-animation: animate-checkmark ease 0.75s;
  animation: animate-checkmark ease 0.75s;
  /*rtl:begin:ignore*/
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  /*rtl:end:ignore*/
}

.swal2-icon.swal2-error {
  border-color: #ef5350;
}

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  display: block;
  -ms-flex: 1;
  flex: 1;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  position: absolute;
  height: 0.25rem;
  width: 3rem;
  background-color: #ef5350;
  display: block;
  top: 2.375rem;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 1rem;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 1rem;
}

.swal2-icon.swal2-warning {
  color: #ff7043;
  border-color: #ff7043;
  font-size: 4rem;
  line-height: 5rem;
  -ms-flex-pack: center;
  justify-content: center;
}

.swal2-icon.swal2-info {
  color: #03a9f4;
  border-color: #03a9f4;
  font-size: 4rem;
  line-height: 5rem;
  -ms-flex-pack: center;
  justify-content: center;
}

.swal2-icon.swal2-question {
  color: #455a64;
  border-color: #455a64;
  font-size: 4rem;
  line-height: calc(5rem + 0.25rem);
  -ms-flex-pack: center;
  justify-content: center;
}

.swal2-progresssteps {
  font-weight: 500;
  margin: 0 0 1.25rem;
  padding: 0;
}

.swal2-progresssteps li {
  display: inline-block;
  position: relative;
}

.swal2-progresscircle {
  color: #2196f3;
  text-align: center;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.swal2-progresscircle.swal2-activeprogressstep {
  color: #2196f3;
}

.swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  color: #999;
}

.swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background-color: #ddd;
}

.swal2-progressline {
  background-color: #2196f3;
  height: 0.0625rem;
  vertical-align: middle;
}

[class^="swal2"] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.swal2-show {
  -webkit-animation: show-notification 0.15s;
  animation: show-notification 0.15s;
}

.swal2-show.swal2-noanimation {
  -webkit-animation: none;
  animation: none;
}

.swal2-hide {
  -webkit-animation: hide-notification 0.15s forwards;
  animation: hide-notification 0.15s forwards;
}

.swal2-hide.swal2-noanimation {
  -webkit-animation: none;
  animation: none;
}

.swal2-animate-success-icon,
.swal2-animate-error-icon,
.swal2-warning,
.swal2-info,
.swal2-question {
  -webkit-animation: animate-circle 0.5s;
  animation: animate-circle 0.5s;
}

.swal2-animate-x-mark {
  -webkit-animation: animate-x-mark 0.5s;
  animation: animate-x-mark 0.5s;
}

@-webkit-keyframes show-notification {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes show-notification {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hide-notification {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes hide-notification {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@-webkit-keyframes animate-checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  30% {
    height: 0;
    width: 1.25rem;
    opacity: 0;
  }
  60% {
    height: 2.5rem;
    width: 1.25rem;
    opacity: 1;
  }
  100% {
    height: 2.5rem;
    width: 1.25rem;
    opacity: 1;
  }
}

@keyframes animate-checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  30% {
    height: 0;
    width: 1.25rem;
    opacity: 0;
  }
  60% {
    height: 2.5rem;
    width: 1.25rem;
    opacity: 1;
  }
  100% {
    height: 2.5rem;
    width: 1.25rem;
    opacity: 1;
  }
}

@-webkit-keyframes animate-circle {
  0% {
    color: transparent;
    border-color: transparent;
  }
  25% {
    color: transparent;
  }
}

@keyframes animate-circle {
  0% {
    color: transparent;
    border-color: transparent;
  }
  25% {
    color: transparent;
  }
}

@-webkit-keyframes animate-x-mark {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-x-mark {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI slider pips
 *
 *  Styles for jQuery UI slider pips extension
 *
 * ---------------------------------------------------------------------------- */
.ui-slider-pips.ui-slider-horizontal {
  margin-bottom: 2.0625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.ui-slider-pips .ui-slider-label,
.ui-slider-pips .ui-slider-pip-hide {
  display: none;
}

.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
  display: block;
}

.ui-slider-pips .ui-slider-pip {
  height: 0.6875rem;
  line-height: 1;
  font-size: 0.6875rem;
  width: 1.25rem;
  margin-left: -0.6875rem;
  position: absolute;
  overflow: visible;
  text-align: center;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-slider-pips .ui-slider-line {
  background-color: #777;
  width: 0.0625rem;
  height: 0.1875rem;
  position: absolute;
  left: 50%;
}

.ui-slider-pips .ui-slider-label {
  position: absolute;
  top: 0.66667rem;
  left: 50%;
  width: 1.25rem;
  margin-left: -0.625rem;
  white-space: nowrap;
  color: #777;
}

.ui-slider-pips.ui-slider-vertical {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-pip {
  text-align: left;
  top: auto;
  left: 1rem;
  margin-left: 0;
  height: auto;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-line {
  width: 0.1875rem;
  height: 0.0625rem;
  position: absolute;
  top: 50%;
  left: 0;
}

.ui-slider-pips.ui-slider-vertical .ui-slider-label {
  top: 50%;
  margin-left: 0;
  margin-top: -0.34375rem;
  left: 0.66667rem;
}

.ui-slider-float .ui-slider-handle:focus,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
  outline: 0;
}

.ui-slider-float .ui-slider-tip,
.ui-slider-float .ui-slider-tip-label {
  position: absolute;
  visibility: hidden;
  top: -3rem;
  display: block;
  left: 50%;
  background-color: #000;
  text-align: center;
  font-size: 0.8125rem;
  color: #fff;
  padding: 0.5rem 0.75rem;
  opacity: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-slider-float .ui-slider-tip,
  .ui-slider-float .ui-slider-tip-label {
    transition: none;
  }
}

.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
.ui-slider-float .ui-slider-handle:focus .ui-slider-tip {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.15s;
}

.ui-slider-float .ui-slider-tip:after,
.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
  content: "";
  border: 0.25rem solid transparent;
  border-top-color: #000;
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  margin-left: -0.25rem;
  width: 0;
  height: 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # NoUI slider
 *
 *  Styles for nouislider.min.js - range slider plugin
 *
 * ---------------------------------------------------------------------------- */
.noUi-target {
  position: relative;
  background-color: #eee;
  border-radius: 100px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.noUi-target,
.noUi-target * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noUi-base,
.noUi-connects {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
  border-radius: 100px;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  /*rtl:begin:ignore*/
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  /*rtl:end:ignore*/
  width: 100%;
  height: 100%;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-connect {
  background-color: #607d8b;
}

[disabled] .noUi-target {
  opacity: 0.75;
}

[disabled] .noUi-handle {
  cursor: default;
}

.noUi-horizontal {
  height: 0.375rem;
}

.noUi-horizontal .noUi-handle {
  top: -0.375rem;
  right: -0.5625rem;
}

.noUi-horizontal .noUi-origin {
  height: 0;
  left: auto;
  right: 0;
}

.noUi-horizontal.has-pips {
  margin-bottom: 2.25003rem;
}

.noUi-vertical {
  display: inline-block;
  width: 0.375rem;
  height: 10rem;
}

.noUi-vertical + .noUi-vertical {
  margin-left: 1.875rem;
}

.noUi-vertical .noUi-handle {
  top: -0.5625rem;
  /*rtl:ignore*/
  left: -0.375rem;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-vertical.has-pips {
  margin-right: 2.25003rem;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  background-color: #fff;
  cursor: pointer;
  top: -0.375rem;
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  outline: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.noUi-handle,
.noUi-handle:after {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .noUi-handle,
  .noUi-handle:after {
    transition: none;
  }
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-handle:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.1875rem;
  margin-left: -0.1875rem;
  background-color: #333;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
}

.noUi-handle:hover,
.noUi-handle:focus {
  background-color: #fafafa;
}

.noUi-handle:hover:after,
.noUi-handle:focus:after {
  background-color: #555;
}

.noui-slider-white .noUi-handle:after {
  content: none;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .noUi-state-tap .noUi-connect,
  .noUi-state-tap .noUi-origin {
    transition: none;
  }
}

.noui-slider-lg .noUi-handle {
  top: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.noui-slider-lg .noUi-handle:after {
  margin-top: -0.25rem;
  margin-left: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
}

.noui-slider-lg.noUi-horizontal {
  height: 0.5rem;
}

.noui-slider-lg.noUi-horizontal .noUi-handle {
  right: -0.75rem;
}

.noui-slider-lg.noUi-vertical {
  width: 0.5rem;
}

.noui-slider-lg.noUi-vertical .noUi-handle {
  top: -0.75rem;
  right: -0.5rem;
}

.noui-slider-sm .noUi-handle {
  top: -0.25rem;
  width: 0.75rem;
  height: 0.75rem;
}

.noui-slider-sm .noUi-handle:after {
  margin-top: -0.125rem;
  margin-left: -0.125rem;
  width: 0.25rem;
  height: 0.25rem;
}

.noui-slider-sm.noUi-horizontal {
  height: 0.25rem;
}

.noui-slider-sm.noUi-horizontal .noUi-handle {
  top: -0.25rem;
  right: -0.375rem;
}

.noui-slider-sm.noUi-vertical {
  width: 0.25rem;
}

.noui-slider-sm.noUi-vertical .noUi-handle {
  top: -0.375rem;
  right: -0.25rem;
}

.noui-slider-solid .noUi-handle:after {
  background-color: #fff;
}

.noui-slider-solid .noUi-handle:hover:after,
.noui-slider-solid .noUi-handle:focus:after {
  opacity: 0.75;
}

.noui-slider-solid.noui-slider-primary .noUi-handle {
  background-color: #2196f3;
  border-color: #2196f3;
}

.noui-slider-solid.noui-slider-danger .noUi-handle {
  background-color: #f44336;
  border-color: #f44336;
}

.noui-slider-solid.noui-slider-success .noUi-handle {
  background-color: #4caf50;
  border-color: #4caf50;
}

.noui-slider-solid.noui-slider-warning .noUi-handle {
  background-color: #ff7043;
  border-color: #ff7043;
}

.noui-slider-solid.noui-slider-info .noUi-handle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.noui-slider-primary .noUi-connect {
  background-color: #2196f3;
}

.noui-slider-danger .noUi-connect {
  background-color: #f44336;
}

.noui-slider-success .noUi-connect {
  background-color: #4caf50;
}

.noui-slider-warning .noUi-connect {
  background-color: #ff7043;
}

.noui-slider-info .noUi-connect {
  background-color: #00bcd4;
}

.noUi-tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0.75rem;
  bottom: 0.25rem;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  visibility: hidden;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .noUi-tooltip {
    transition: none;
  }
}

.noUi-tooltip:after {
  content: "";
  border: 0.25rem solid transparent;
  border-top-color: #000;
  position: absolute;
  left: 50%;
  bottom: -0.5rem;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  width: 0;
  height: 0;
}

.noUi-handle:hover .noUi-tooltip {
  opacity: 1;
  visibility: visible;
}

.noUi-pips {
  position: absolute;
  color: #777;
}

.noUi-value {
  width: 2.5rem;
  position: absolute;
  text-align: center;
  font-size: 0.6875rem;
  line-height: 1.82;
}

.noUi-value-sub {
  color: #aaaaaa;
}

.noUi-marker {
  position: absolute;
  background-color: #777;
}

.noUi-pips-horizontal {
  padding-top: 0.66667rem;
  height: 2.25003rem;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  margin-left: -1.25rem;
  padding-top: 1rem;
}

.noUi-value-horizontal.noUi-value-sub {
  padding-top: 0.66667rem;
}

.noUi-marker-horizontal.noUi-marker {
  width: 0.0625rem;
  height: 0.1875rem;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 0.375rem;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 0.5625rem;
}

.noUi-pips-vertical {
  padding-left: 0.66667rem;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  margin-top: -0.62502rem;
  padding-left: 0.5rem;
}

.noUi-rtl .noUi-value-vertical {
  margin-top: 0;
  margin-bottom: -0.62502rem;
}

.noUi-marker-vertical.noUi-marker {
  width: 0.1875rem;
  height: 0.0625rem;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 0.375rem;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 0.5625rem;
}

/* ------------------------------------------------------------------------------
*
*  # ION Range Slider
*
*  Styles for ion_rangeslider.min.js - range slider plugin
*
* ---------------------------------------------------------------------------- */
.irs {
  position: relative;
  display: block;
  height: 3.4375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*rtl:ignore*/
  direction: ltr;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
  height: 0.375rem;
  top: 2.6875rem;
  background-color: #eee;
  border-radius: 100px;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  /*rtl:ignore*/
  left: 0;
  width: 11%;
}

.irs-line-mid {
  /*rtl:ignore*/
  left: 9%;
  width: 82%;
}

.irs-line-right {
  /*rtl:ignore*/
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  top: 2.6875rem;
  /*rtl:ignore*/
  left: 0;
  width: 0;
  height: 0.375rem;
  background-color: #607d8b;
  border-radius: 100px;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 2.6875rem;
  /*rtl:ignore*/
  left: 0;
  height: 0.75rem;
  width: 0.5625rem;
}

.irs-shadow {
  position: absolute;
  display: none;
  top: 2.6875rem;
  /*rtl:ignore*/
  left: 0;
  width: 0;
  height: 0.375rem;
  background-color: #333;
  opacity: 0.25;
  border-radius: 100px;
}

.irs-slider {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  top: 2.3125rem;
  cursor: pointer;
  z-index: 1;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100px;
}

.irs-slider:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.1875rem;
  margin-left: -0.1875rem;
  background-color: #333;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100px;
}

.irs-slider,
.irs-slider:after {
  transition: background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .irs-slider,
  .irs-slider:after {
    transition: none;
  }
}

.irs-slider:hover,
.irs-slider:focus {
  background-color: #fafafa;
}

.irs-slider:hover:after,
.irs-slider:focus:after {
  background-color: #555;
}

.irs-slider.type_last {
  z-index: 2;
}

.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min,
.irs-max {
  color: #777;
  font-size: 0.6875rem;
  line-height: 1;
  top: 0;
  padding: 0.5rem;
  background-color: #eee;
  border-radius: 0.1875rem;
}

.irs-min {
  /*rtl:ignore*/
  left: 0;
}

.irs-max {
  /*rtl:ignore*/
  right: 0;
}

.irs-from,
.irs-to,
.irs-single {
  top: 0;
  /*rtl:ignore*/
  left: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 0.6875rem;
  line-height: 1;
  padding: 0.5rem;
  top: 0;
  background-color: #000;
  border-radius: 0.1875rem;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -0.5rem;
  left: 50%;
  margin-left: -0.25rem;
  overflow: hidden;
  border: 0.25rem solid transparent;
  border-top-color: #000;
  width: 0;
  height: 0;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  width: 100%;
  height: 1.6875rem;
}

.irs-with-grid {
  height: 5.5625rem;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  width: 0.0625rem;
  height: 0.375rem;
  background-color: #777;
}

.irs-grid-pol.small {
  height: 0.1875rem;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 0.6875rem;
  color: #777;
  line-height: 1;
  padding: 0 0.1875rem;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  /*rtl:ignore*/
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: transparent;
  z-index: 2;
}

.irs-disabled {
  opacity: 0.75;
}

/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-sortable .ui-state-disabled {
  color: #999;
  cursor: default;
  opacity: 0.75;
}

.sortable-placeholder {
  position: relative;
}

.sortable-placeholder:before {
  content: "";
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.card + .sortable-placeholder {
  margin-bottom: 1.25rem;
}

.sidebar .card + .sortable-placeholder {
  margin-bottom: 0;
}

.sortable-placeholder + .card {
  margin-top: 1.25rem;
}

.sidebar .sortable-placeholder + .card {
  margin-top: 0;
}

.card-group > .sortable-placeholder:before {
  border-radius: 0.1875rem;
}

.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0;
}

.sidebar-dark .sortable-placeholder:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-dark .card:not([class*="bg-"]):not(.fixed-top).ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-light .sortable-placeholder:before {
  background-color: #fafafa;
}

.sidebar-light .card:not([class*="bg-"]):not(.fixed-top).ui-sortable-helper {
  background-color: #fff;
}

.table .ui-sortable-helper {
  width: 100%;
  background-color: transparent;
  display: table;
}

.table .sortable-placeholder {
  margin: 0;
}

.table .sortable-placeholder:before {
  content: none;
}

.table.ui-sortable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-handle.ui-icon {
  display: inline-block;
  border-style: solid;
  border-width: 0 0 0.375rem 0.375rem;
  border-color: transparent transparent #333 transparent;
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 0.4375rem;
  width: 100%;
  top: -0.3125rem;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 0.4375rem;
  width: 100%;
  bottom: -0.3125rem;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 0.4375rem;
  right: -0.3125rem;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 0.4375rem;
  left: -0.3125rem;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  right: 0.0625rem;
  bottom: 0.0625rem;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  left: -0.3125rem;
  bottom: -0.3125rem;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  left: -0.3125rem;
  top: -0.3125rem;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  right: -0.3125rem;
  top: -0.3125rem;
}

.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dashed #333;
}

/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Widgets
 *
 *  Styles for jQuery UI widgets
 *
 * ---------------------------------------------------------------------------- */
.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  margin: 0;
  outline: 0;
  position: relative;
  background-color: #fff;
  padding: 0.9375rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 0.5rem;
  border-radius: 0.1875rem;
}

.ui-accordion .ui-accordion-header:first-child {
  margin-top: 0;
}

.ui-accordion .ui-accordion-header.ui-accordion-header-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  margin-top: -0.5rem;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
  content: "\e9b8";
  font-family: "icomoon";
  display: block;
  width: 1rem;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-accordion .ui-accordion-header-active .ui-accordion-header-icon:before {
  content: "\e9b7";
}

.ui-accordion .ui-accordion-icons {
  padding-right: 3.5rem;
}

.ui-accordion .ui-accordion-content {
  padding: 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  overflow: auto;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.accordion-sortable-group + .accordion-sortable-group {
  margin-top: 0.5rem;
}

.ui-autocomplete {
  position: absolute;
  display: none;
  padding: 0.5rem 0;
  z-index: 1000;
  max-height: 250px;
  overflow: auto;
}

.ui-autocomplete .ui-autocomplete-category {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1.6667;
  font-weight: 700;
}

.ui-autocomplete
  .ui-autocomplete-category
  ~ .ui-menu-item
  .ui-menu-item-wrapper {
  padding-left: 2rem;
}

.ui-autocomplete-processing:after {
  content: "\eb51";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  width: 1rem;
  text-align: right;
  margin-top: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  color: inherit;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-group .ui-autocomplete-input + .input-group-append > .btn,
.input-group .ui-autocomplete-input + .input-group-append > .ui-button,
.input-group
  .ui-datepicker-buttonpane
  .ui-autocomplete-input
  + .input-group-append
  > button,
.ui-datepicker-buttonpane
  .input-group
  .ui-autocomplete-input
  + .input-group-append
  > button {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ui-button,
.ui-datepicker-buttonpane button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px solid #ddd;
  color: #333;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-button,
  .ui-datepicker-buttonpane button {
    transition: none;
  }
}

.ui-button:focus,
.ui-datepicker-buttonpane button:focus {
  outline: 0;
}

.ui-button:not([class*="bg-"]):hover,
.ui-datepicker-buttonpane button:not([class*="bg-"]):hover,
.ui-button:not([class*="bg-"]):focus,
.ui-datepicker-buttonpane button:not([class*="bg-"]):focus,
.ui-button:not([class*="bg-"]):active,
.ui-datepicker-buttonpane button:not([class*="bg-"]):active {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.ui-button.ui-state-disabled,
.ui-datepicker-buttonpane button.ui-state-disabled {
  cursor: default;
  opacity: 0.65;
  box-shadow: none;
}

.ui-button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):hover,
.ui-datepicker-buttonpane
  button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):hover,
.ui-button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):focus,
.ui-datepicker-buttonpane
  button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):focus {
  color: #fff;
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

.ui-button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):active,
.ui-datepicker-buttonpane
  button[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light):active {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
}

.ui-button.ui-button-link,
.ui-datepicker-buttonpane button.ui-button-link {
  background-color: transparent;
  border-color: transparent;
}

.ui-button.ui-button-link:hover,
.ui-datepicker-buttonpane button.ui-button-link:hover,
.ui-button.ui-button-link:focus,
.ui-datepicker-buttonpane button.ui-button-link:focus {
  color: #000000;
}

.ui-button.ui-button-link:hover,
.ui-datepicker-buttonpane button.ui-button-link:hover,
.ui-button.ui-button-link:focus,
.ui-datepicker-buttonpane button.ui-button-link:focus,
.ui-button.ui-button-link:active,
.ui-datepicker-buttonpane button.ui-button-link:active {
  box-shadow: none;
}

.ui-button::-moz-focus-inner,
.ui-datepicker-buttonpane button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ui-button .ui-button-icon-space,
.ui-datepicker-buttonpane button .ui-button-icon-space {
  display: inline-block;
  width: 0.625rem;
}

.ui-button-icon-only {
  text-indent: -9999px;
  padding-left: 1.06252rem;
  padding-right: 1.06252rem;
  overflow: hidden;
}

.ui-button-icon-only .ui-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  text-indent: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ui-button-icon-only .ui-button-icon-space,
.ui-button-icon-only .ui-button-text {
  display: none;
}

.ui-controlgroup {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.ui-controlgroup .ui-button,
.ui-controlgroup .ui-datepicker-buttonpane button,
.ui-datepicker-buttonpane .ui-controlgroup button {
  border-radius: 0;
  margin-left: -1px;
  float: left;
}

.ui-controlgroup .ui-button:first-child,
.ui-controlgroup .ui-datepicker-buttonpane button:first-child,
.ui-datepicker-buttonpane .ui-controlgroup button:first-child,
.ui-controlgroup .ui-helper-hidden-accessible:first-child + .ui-button,
.ui-controlgroup
  .ui-datepicker-buttonpane
  .ui-helper-hidden-accessible:first-child
  + button,
.ui-datepicker-buttonpane
  .ui-controlgroup
  .ui-helper-hidden-accessible:first-child
  + button {
  margin-left: 0;
}

.ui-controlgroup .ui-button:first-child,
.ui-controlgroup .ui-datepicker-buttonpane button:first-child,
.ui-datepicker-buttonpane .ui-controlgroup button:first-child,
.ui-controlgroup
  .ui-helper-hidden-accessible:first-child
  + .ui-button:not(:last-child),
.ui-controlgroup
  .ui-datepicker-buttonpane
  .ui-helper-hidden-accessible:first-child
  + button:not(:last-child),
.ui-datepicker-buttonpane
  .ui-controlgroup
  .ui-helper-hidden-accessible:first-child
  + button:not(:last-child) {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.ui-controlgroup .ui-button:last-of-type,
.ui-controlgroup .ui-datepicker-buttonpane button:last-of-type,
.ui-datepicker-buttonpane .ui-controlgroup button:last-of-type {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.ui-checkboxradio-label.ui-checkboxradio-checked {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ddd;
}

.ui-checkboxradio-label.ui-checkboxradio-checked[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.125) inset;
}

.ui-checkboxradio-label.ui-checkboxradio-disabled {
  pointer-events: none;
}

.ui-datepicker {
  min-width: 270px;
  padding: 0.9375rem;
}

.ui-datepicker:not(.ui-datepicker-inline) {
  display: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1070 !important;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.sidebar .ui-datepicker {
  min-width: 0;
}

.ui-datepicker a {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-datepicker a {
    transition: none;
  }
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
}

.ui-datepicker .ui-datepicker-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
  font-size: 0.9375rem;
  margin-left: 2.8125rem;
  margin-right: 2.8125rem;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  font-weight: 500;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  font-size: 85%;
  color: #999;
  margin-left: 0.46875rem;
}

.ui-datepicker .ui-datepicker-title select {
  outline: 0;
  height: 2.00002rem;
  border-color: #ddd;
  -ms-flex: 1;
  flex: 1;
}

.ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #333;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 50%;
  margin-top: -0.9375rem;
  line-height: 1;
  color: #333;
  padding: 0.4375rem;
  cursor: pointer;
  border-radius: 0.1875rem;
}

.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: none;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0;
}

.ui-datepicker .ui-datepicker-prev:after {
  content: "\e9c8";
}

.ui-datepicker .ui-datepicker-next {
  right: 0;
}

.ui-datepicker .ui-datepicker-next:after {
  content: "\e9cb";
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  color: #333;
  background-color: #f5f5f5;
}

.ui-datepicker .ui-datepicker-calendar {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}

.ui-datepicker .ui-datepicker-calendar th {
  text-align: center;
  font-weight: 400;
  padding-top: 0.9375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.75rem;
  color: #999;
}

.ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
}

.ui-datepicker .ui-datepicker-calendar td span,
.ui-datepicker .ui-datepicker-calendar td a {
  display: block;
  position: relative;
  text-decoration: none;
  min-width: 2.12503rem;
  color: #333;
  padding: 0.4375rem;
  border-radius: 0.1875rem;
}

.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
  color: #333;
  background-color: #f5f5f5;
}

.ui-datepicker .ui-datepicker-calendar td.ui-state-disabled a,
.ui-datepicker .ui-datepicker-calendar td.ui-state-disabled span {
  color: #999;
  background-color: transparent;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-col {
  min-width: 2.12503rem;
  padding: 0.4375rem;
  color: #999;
}

.ui-datepicker
  .ui-datepicker-calendar
  td.ui-datepicker-current-day
  .ui-state-active {
  color: #fff;
  background-color: #26a69a;
}

.ui-datepicker
  .ui-datepicker-calendar
  td.ui-datepicker-today
  .ui-state-highlight {
  color: #333;
  background-color: #f5f5f5;
}

.ui-datepicker
  .ui-datepicker-calendar
  td.ui-datepicker-today
  .ui-state-highlight:after {
  content: "";
  position: absolute;
  top: 0.14583rem;
  right: 0.14583rem;
  border-top: 0.4375rem solid #767676;
  border-left: 0.4375rem solid transparent;
  width: 0;
  height: 0;
}

.ui-datepicker
  .ui-datepicker-calendar
  .ui-datepicker-other-month
  .ui-priority-secondary {
  color: #999;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
  text-align: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto !important;
}

.ui-datepicker-buttonpane {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.9375rem;
}

.ui-datepicker-inline {
  max-width: 100%;
  overflow-x: auto;
}

.ui-datepicker-trigger {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
  padding: 6px;
  cursor: pointer;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group + .ui-datepicker-group {
  padding-left: 0.9375rem;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-dialog {
  position: fixed;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .ui-dialog {
    width: 90% !important;
  }
}

.ui-dialog-titlebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.25rem;
  padding-bottom: 0;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-dialog-title {
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5385;
}

.ui-dialog-titlebar-close {
  margin-left: auto;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 0;
  text-indent: 0;
}

.ui-dialog-titlebar-close:after {
  content: "\ed6a";
  font-family: "icomoon";
  display: block;
  width: 0.8125rem;
  font-size: 0.8125rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-dialog-titlebar-close:hover,
.ui-dialog-titlebar-close:focus {
  background-color: transparent !important;
  box-shadow: none;
}

.ui-dialog-content {
  position: relative;
  overflow: auto;
  padding: 1.25rem;
}

.ui-dialog-content .form-group:last-child,
.ui-dialog-content p:last-child {
  margin-bottom: 0;
}

.ui-dialog-buttonpane {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem;
  padding-top: 0;
}

.ui-dialog-buttonpane button + button {
  margin-left: 0.625rem;
}

.ui-menu {
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  display: block;
  outline: none;
  min-width: 11.25rem;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.ui-menu .ui-menu {
  position: absolute;
  top: -0.4375rem !important;
}

.ui-menu .ui-menu-item {
  margin: 0;
  position: relative;
}

.ui-menu .ui-menu-item i {
  margin-right: 1rem;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  padding: 0.5rem 1rem;
  outline: 0;
  color: #333;
  overflow: hidden;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-menu .ui-menu-item-wrapper {
    transition: none;
  }
}

.ui-menu .ui-menu-item-wrapper > .ui-menu-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 1rem;
}

.ui-menu .ui-menu-item-wrapper > .ui-menu-icon:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-menu .ui-menu-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1.6667;
  color: #999;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ui-menu .ui-menu-header.highlight {
  margin-top: 0;
  background-color: #f8f8f8;
}

.ui-menu .ui-menu-item + .highlight,
.ui-menu .highlight + .ui-menu-item {
  margin-top: 0.5rem;
}

.ui-menu .ui-menu-item-wrapper > i,
.ui-menu .ui-menu-header > i {
  top: 0;
  margin-top: 0.12502rem;
  margin-right: 1rem;
}

.ui-menu .ui-menu-divider {
  margin: 0.5rem 0;
  height: 0;
  font-size: 0;
  overflow: hidden;
  border-top: 1px solid #ddd;
}

.ui-menu .ui-state-disabled .ui-menu-item-wrapper {
  color: #999;
  background-color: transparent;
  cursor: default;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  color: #333;
  background-color: #f5f5f5;
}

.ui-progressbar {
  display: -ms-flexbox;
  display: flex;
  height: 1.125rem;
  overflow: hidden;
  font-size: 0.60938rem;
  background-color: #eee;
  border-radius: 0.1875rem;
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1);
}

.ui-progressbar-value {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #2196f3;
  background-size: 1.125rem 1.125rem;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-progressbar-value {
    transition: none;
  }
}

.ui-progressbar-striped .ui-progressbar-value {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.ui-progressbar-active .ui-progressbar-value {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  display: none;
}

.ui-selectmenu-menu .ui-menu {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.6667;
  padding: 0.5rem 1rem;
  height: auto;
  border: 0;
}

.ui-selectmenu-menu
  .ui-menu
  .ui-selectmenu-optgroup
  ~ .ui-menu-item
  .ui-menu-item-wrapper {
  padding-left: 2rem;
}

.ui-selectmenu-open {
  display: block;
}

.ui-selectmenu-button {
  text-decoration: none;
  text-align: left;
  outline: 0;
}

.ui-selectmenu-button:not(.ui-controlgroup-item) {
  display: inline-block;
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 0.1875rem;
}

.ui-selectmenu-button:not(.ui-button-icon-only) {
  padding: 0.4375rem 0.875rem;
  padding-right: 2.75rem;
}

.ui-selectmenu-button:hover,
.ui-selectmenu-button:focus {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset;
}

.ui-selectmenu-button:not([class*="bg-"]):hover,
.ui-selectmenu-button:not([class*="bg-"]):focus {
  background-color: #fff;
}

.ui-selectmenu-button:after {
  content: "\e9c5";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  text-align: right;
  margin-top: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-selectmenu-button .ui-selectmenu-text {
  display: block;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui-selectmenu-button.ui-selectmenu-button-open {
  box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset;
}

.ui-selectmenu-disabled {
  cursor: default;
  background-color: #fafafa;
}

.ui-selectmenu-disabled:hover,
.ui-selectmenu-disabled:focus {
  box-shadow: none;
}

.ui-selectmenu-lg .ui-selectmenu-button .ui-selectmenu-text {
  padding: 0.5625rem 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.4286;
}

.ui-selectmenu-sm .ui-selectmenu-button .ui-selectmenu-text {
  padding: 0.3125rem 0.75rem;
  padding-right: 2.5rem;
  font-size: 0.75rem;
  line-height: 1.6667;
}

.ui-selectmenu-images .ui-menu-item span {
  display: inline-block;
  margin-right: 1rem;
  background-size: 1.25003rem 1.25003rem;
  float: left;
  width: 1.25003rem;
  height: 1.25003rem;
  border-radius: 0.1875rem;
}

.ui-selectmenu-images .demo-img-amazon {
  background: url("/assets/images/brands/amazon.png") no-repeat;
}

.ui-selectmenu-images .demo-img-youtube {
  background: url("/assets/images/brands/youtube.png") no-repeat;
}

.ui-selectmenu-images .demo-img-twitter {
  background: url("/assets/images/brands/twitter.png") no-repeat;
}

.ui-selectmenu-images .demo-img-bing {
  background: url("/assets/images/brands/bing.png") no-repeat;
}

.ui-selectmenu-images .demo-img-spotify {
  background: url("/assets/images/brands/spotify.png") no-repeat;
}

.ui-slider {
  position: relative;
  text-align: left;
  background-color: #eee;
  border-radius: 100px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  outline: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100px;
  transition: background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-slider .ui-slider-handle {
    transition: none;
  }
}

.ui-slider .ui-slider-handle.ui-state-hover,
.ui-slider .ui-slider-handle.ui-state-focus {
  background-color: #fafafa;
}

.ui-slider .ui-slider-handle.ui-state-hover:after,
.ui-slider .ui-slider-handle.ui-state-focus:after {
  background-color: #555;
}

.ui-slider .ui-slider-handle:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.1875rem;
  margin-left: -0.1875rem;
  background-color: #333;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100px;
  transition: background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-slider .ui-slider-handle:after {
    transition: none;
  }
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #607d8b;
  border-radius: 100px;
}

.ui-slider.ui-slider-disabled {
  opacity: 0.75;
}

.ui-slider.ui-slider-disabled .ui-slider-handle {
  cursor: default;
}

.ui-slider-horizontal {
  height: 0.375rem;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -0.375rem;
  /*rtl:ignore*/
  margin-left: -0.5625rem;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: 0.375rem;
  height: 150px;
  display: inline-block;
  margin: 0 0.625rem;
}

.ui-slider-vertical .ui-slider-handle {
  left: -0.375rem;
  margin-bottom: -0.5625rem;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-slider-solid .ui-slider-handle:after {
  background-color: #fff;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-slider-solid .ui-slider-handle:after {
    transition: none;
  }
}

.ui-slider-solid .ui-slider-handle.ui-state-hover:after,
.ui-slider-solid .ui-slider-handle.ui-state-active:after {
  background-color: #fff;
  opacity: 0.75;
}

.ui-handle-white .ui-slider-handle:after {
  content: none;
}

.ui-slider-lg .ui-slider-handle {
  width: 1.5rem;
  height: 1.5rem;
}

.ui-slider-lg .ui-slider-handle:after {
  margin-top: -0.25rem;
  margin-left: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
}

.ui-slider-lg.ui-slider-horizontal {
  height: 0.5rem;
}

.ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
  top: -0.5rem;
  /*rtl:ignore*/
  margin-left: -0.75rem;
}

.ui-slider-lg.ui-slider-vertical {
  width: 0.5rem;
}

.ui-slider-lg.ui-slider-vertical .ui-slider-handle {
  left: -0.5rem;
  margin-bottom: -0.75rem;
}

.ui-slider-sm .ui-slider-handle {
  width: 0.75rem;
  height: 0.75rem;
}

.ui-slider-sm .ui-slider-handle:after {
  margin-top: -0.125rem;
  margin-left: -0.125rem;
  width: 0.25rem;
  height: 0.25rem;
}

.ui-slider-sm.ui-slider-horizontal {
  height: 0.25rem;
}

.ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
  top: -0.25rem;
  /*rtl:ignore*/
  margin-left: -0.375rem;
}

.ui-slider-sm.ui-slider-vertical {
  width: 0.25rem;
}

.ui-slider-sm.ui-slider-vertical .ui-slider-handle {
  left: -0.25rem;
  margin-bottom: -0.375rem;
}

.ui-slider-primary .ui-slider-range,
.ui-slider-solid.ui-slider-primary .ui-slider-handle {
  background-color: #2196f3;
  border-color: #2196f3;
}

.ui-slider-danger .ui-slider-range,
.ui-slider-solid.ui-slider-danger .ui-slider-handle {
  background-color: #f44336;
  border-color: #f44336;
}

.ui-slider-success .ui-slider-range,
.ui-slider-solid.ui-slider-success .ui-slider-handle {
  background-color: #4caf50;
  border-color: #4caf50;
}

.ui-slider-warning .ui-slider-range,
.ui-slider-solid.ui-slider-warning .ui-slider-handle {
  background-color: #ff7043;
  border-color: #ff7043;
}

.ui-slider-info .ui-slider-range,
.ui-slider-solid.ui-slider-info .ui-slider-handle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.ui-spinner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.ui-spinner-input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui-spinner-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0;
}

.ui-spinner-button:after {
  font-family: "icomoon";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1rem;
  font-size: 1rem;
  line-height: 1;
  text-indent: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-spinner-button:last-child {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.ui-spinner-button > span {
  display: none;
}

.ui-spinner-button + .ui-spinner-button {
  border-left: 0;
}

.ui-spinner-up:after {
  content: "\e9f7";
}

.ui-spinner-down:after {
  content: "\e9e2";
}

.ui-tabs {
  position: relative;
}

.ui-tabs .ui-tabs-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #ddd;
}

.ui-tabs .ui-tabs-tab {
  list-style: none;
  position: relative;
  padding: 0;
  margin-bottom: -1px;
}

.ui-tabs .ui-tabs-anchor {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #777;
  padding: 0.625rem 1.25rem;
  border: 1px solid transparent;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-tabs .ui-tabs-anchor {
    transition: none;
  }
}

.ui-tabs .ui-tabs-anchor:before {
  content: "";
  position: absolute;
  height: 2px;
  top: -1px;
  left: -1px;
  right: -1px;
  transition: background-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-tabs .ui-tabs-anchor:before {
    transition: none;
  }
}

.ui-tabs .ui-tabs-anchor i {
  top: 0;
  margin-top: 0.12502rem;
}

.ui-tabs .ui-state-hover .ui-tabs-anchor:not(.ui-state-disabled),
.ui-tabs .ui-state-focus .ui-tabs-anchor:not(.ui-state-disabled) {
  color: #333;
}

.ui-tabs .ui-tabs-active .ui-tabs-anchor {
  color: #333;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}

.ui-tabs .ui-tabs-active .ui-tabs-anchor:before {
  background-color: #2196f3;
}

.ui-tabs .ui-state-disabled {
  color: #999;
  background-color: transparent;
  border-color: transparent;
  cursor: default;
}

.ui-tabs .ui-sortable-helper:not(.ui-tabs-active) .ui-tabs-anchor {
  border-color: #ddd;
}

.ui-tabs .ui-tabs-panel {
  display: block;
}

.ui-tooltip {
  position: absolute;
  z-index: 1070;
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
}

.ui-helper-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.ui-helper-zfix {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.ui-front {
  z-index: 1040;
}

/* ------------------------------------------------------------------------------
 *
 *  # Prism
 *
 *  Styles for prism.min.js - lightweight, extensible syntax highlighter
 *
 * ---------------------------------------------------------------------------- */
code[class*="language-"],
pre[class*="language-"] {
  text-shadow: 0 1px #fff;
  word-break: break-all;
  line-height: 1.5;
  background-color: transparent;
  /*rtl:ignore*/
  direction: ltr;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.punctuation {
  color: #999;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function {
  color: #dd4a68;
}

.token.regex,
.token.important {
  color: #e90;
}

.token.important {
  font-weight: bold;
}

.token.entity {
  cursor: help;
}

.namespace {
  opacity: 0.7;
}

.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 1.25rem;
  background: rgba(103, 139, 220, 0.12);
  pointer-events: none;
  white-space: pre;
  line-height: inherit;
}

.line-highlight:before,
.line-highlight:after {
  content: attr(data-start);
  position: absolute;
  top: 0px;
  /*rtl:ignore*/
  left: 10px;
  padding: 2px;
  text-align: center;
  font-size: 10px;
  color: #999;
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: 0;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  width: 36px;
  letter-spacing: -1px;
  /*rtl:begin:ignore*/
  left: -50px;
  border-right: 1px solid #999;
  /*rtl:end:ignore*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  /*rtl:begin:ignore*/
  padding-right: 10px;
  text-align: right;
  /*rtl:end:ignore*/
}

pre.line-numbers {
  position: relative;
  /*rtl:ignore*/
  padding-left: 50px;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
}

pre[class*="language-"] {
  overflow: auto;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(
    transparent 50%,
    rgba(25, 118, 210, 0.025) 50%
  );
  background-size: 3em 3em;
  background-origin: content-box;
  /*rtl:begin:ignore*/
  border-left-width: 0;
  box-shadow: 3px 0 0 0 #2196f3 inset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

pre[data-line] {
  position: relative;
  /*rtl:ignore*/
  padding-left: 36px;
}

pre.code-toolbar {
  position: relative;
}

pre.code-toolbar > .toolbar {
  background-color: #f8f8f8;
  display: inline-block;
  position: absolute;
  top: 0;
  text-shadow: none;
  color: #333;
  border-bottom: 1px solid #ddd;
  /*rtl:begin:ignore*/
  border-left: 1px solid #ddd;
  right: 0;
  border-radius: 0 0 0 0.1875rem;
  /*rtl:end:ignore*/
}

pre.code-toolbar > .toolbar span,
pre.code-toolbar > .toolbar .toolbar-item {
  display: inline-block;
}

pre.code-toolbar > .toolbar a {
  cursor: pointer;
}

pre.code-toolbar > .toolbar button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

pre.code-toolbar > .toolbar a,
pre.code-toolbar > .toolbar button,
pre.code-toolbar > .toolbar span {
  padding: 0.25rem 0.5rem;
  font-size: 0.6875rem;
  outline: 0;
}

pre.code-toolbar > .toolbar a:hover,
pre.code-toolbar > .toolbar a:focus,
pre.code-toolbar > .toolbar button:hover,
pre.code-toolbar > .toolbar button:focus,
pre.code-toolbar > .toolbar span:hover,
pre.code-toolbar > .toolbar span:focus {
  color: inherit;
  text-decoration: none;
}

/* ------------------------------------------------------------------------------
 *
 *  # Slinky
 *
 *  Styles for slinky.min.js - animated multi level navigation
 *
 * ---------------------------------------------------------------------------- */
.slinky-menu {
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.slinky-menu > ul {
  /*rtl:ignore*/
  left: 0;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.slinky-menu ul {
  width: 100%;
}

.slinky-menu ul.active {
  max-height: 340px;
  overflow-y: auto;
}

.slinky-menu li + li:not(.dropdown-item):not(.dropdown-divider) {
  margin-top: 1px;
}

.slinky-menu li ul {
  display: none;
  /*rtl:ignore*/
  left: 100%;
  position: absolute;
  top: 0;
}

.slinky-menu .header {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  color: #2196f3;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .slinky-menu .header {
    transition: none;
  }
}

.slinky-menu .header:hover,
.slinky-menu .header:focus {
  color: #000000;
}

.slinky-menu .header .title {
  -ms-flex: 1;
  flex: 1;
  padding: 0.5rem 1rem;
  padding-left: 3rem;
}

.slinky-menu .next:after,
.slinky-menu .next:before,
.slinky-menu .back:after,
.slinky-menu .back:before {
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slinky-menu .next {
  padding-right: 2.5rem;
}

.slinky-menu .next:after {
  content: "\e9c7";
  right: 1rem;
}

.slinky-menu .back {
  position: absolute;
  padding: 0.5rem 1rem;
  color: inherit;
  width: 100%;
  height: 100%;
}

.slinky-menu .back:before {
  content: "\ede7";
  left: 1rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # Sticky kit
 *
 *  Styles for sticky.min.js - extension that makes elements sticky
 *
 * ---------------------------------------------------------------------------- */
.is_stuck {
  z-index: 1030 !important;
}

.navbar-top .navbar.is_stuck {
  margin-top: 3.12503rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # Headroom
 *
 *  Styles for headroom.min.js - hides BS navbar component on page scroll
 *
 * ---------------------------------------------------------------------------- */
.headroom {
  transition: box-shadow ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s, box-shadow ease-in-out 0.2s,
    -webkit-transform ease-in-out 0.2s;
  will-change: transform;
}

.navbar-slide-top.headroom--pinned {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.navbar-slide-top.headroom--unpinned {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  box-shadow: none;
}

.navbar-slide-bottom.headroom--pinned {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  box-shadow: none;
}

.navbar-slide-bottom.headroom--unpinned {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* ------------------------------------------------------------------------------
 *
 *  # Dragula - drag and drop library
 *
 *  Styles for Dragula Drag and drop plugin
 *
 * ---------------------------------------------------------------------------- */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
}

.gu-unselectable.dropdown-menu li:nth-last-child(2) {
  margin-bottom: 0;
}

.nav-pills-toolbar > li.gu-mirror:not(.active) > a {
  border: 0;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gu-transit {
  opacity: 0.5;
}

.dragula-handle {
  color: #999;
  cursor: pointer;
}

.dragula-handle:hover,
.dragula-handle:focus {
  color: #333;
}

/* ------------------------------------------------------------------------------
 *
 *  # Perfect Scrollbar
 *
 *  Styles for perfect_scrollbar.min.js - custom scrollbar extension
 *
 * ---------------------------------------------------------------------------- */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-touch-action: auto;
  touch-action: auto;
}

.ps__rail-y,
.ps__rail-x {
  opacity: 0;
  position: absolute;
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ps__rail-y,
  .ps__rail-x {
    transition: none;
  }
}

.ps__rail-x {
  bottom: 0;
}

.ps__rail-y {
  /*rtl:ignore*/
  right: 0;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.75;
}

.ps__thumb-y,
.ps__thumb-x {
  background-color: #999;
  position: absolute;
  pointer-events: none;
  border-radius: 100px;
}

.ps__thumb-y {
  width: 0.25rem;
  right: 0.125rem;
}

.ps__thumb-x {
  height: 0.25rem;
  bottom: 0.125rem;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Floating action buttons
*
*  Styles for fab.min.js - material design floating action button with menu
*
* ---------------------------------------------------------------------------- */
.fab-menu {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1029;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

.page-header > .fab-menu {
  position: absolute;
  bottom: -1.5rem;
}

@media (min-width: 768px) {
  .fab-menu {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fab-menu-absolute {
  position: absolute;
}

.fab-menu-fixed {
  position: fixed;
  z-index: 1030;
}

.content-wrapper > .fab-menu-top-left,
.content-wrapper > .fab-menu-top-right {
  top: -1.5rem;
}

.fab-menu-bottom-left,
.fab-menu-bottom-right {
  bottom: 1.25rem;
  transition: bottom ease-in-out 0.15s;
}

.fab-menu-bottom-left.reached-bottom,
.fab-menu-bottom-right.reached-bottom {
  bottom: 5.62503rem;
}

.fab-menu-top-right,
.fab-menu-bottom-right {
  right: 1.25rem;
}

.fab-menu-inner {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fab-menu-inner > li {
  display: block;
  position: absolute;
  top: 0.125rem;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 1.25rem;
  margin-top: -1.25rem;
}

.fab-menu-inner > li .btn {
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fab-menu-inner > li .btn:hover,
.fab-menu-inner > li .btn:focus,
.fab-menu-inner > li .btn.focus,
.fab-menu-inner > li .btn:not(:disabled):not(.disabled):active,
.fab-menu-inner > li .btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.fab-menu-inner > li .btn-float {
  padding: 0;
  width: 2.75rem;
  height: 2.75rem;
}

.fab-menu-inner > li .btn-float > i {
  margin: 0.875rem;
}

.fab-menu-inner > li .show > .btn-float.dropdown-toggle {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab-menu-inner > li .dropleft .btn:before,
.fab-menu-inner > li .dropleft .btn:after,
.fab-menu-inner > li .dropright .btn:before,
.fab-menu-inner > li .dropright .btn:after {
  content: none;
}

.fab-menu-inner > li .badge {
  position: absolute;
  top: 0.75rem;
  right: -0.25rem;
}

.fab-menu-inner > li .badge-mark {
  top: 1.25rem;
  right: 0rem;
}

.fab-menu-bottom-left .fab-menu-inner > li,
.fab-menu-bottom-right .fab-menu-inner > li {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 1.25rem;
  margin-bottom: -1.25rem;
}

.fab-menu-bottom-left .fab-menu-inner > li .badge,
.fab-menu-bottom-right .fab-menu-inner > li .badge {
  top: -0.5rem;
}

.fab-menu-bottom-left .fab-menu-inner > li .badge-mark,
.fab-menu-bottom-right .fab-menu-inner > li .badge-mark {
  top: 0rem;
}

.fab-menu-btn {
  z-index: 1031;
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fab-menu-btn.btn-float {
  padding: 1.5rem;
}

.fab-menu-btn.btn-float > i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}

.fab-menu-btn:hover,
.fab-menu-btn:focus,
.fab-menu-btn:not(:disabled):not(.disabled):active,
.fab-menu-btn:not(:disabled):not(.disabled).active,
.fab-menu-btn:not(:disabled):not(.disabled):active:focus,
.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-btn,
.fab-menu[data-fab-state="open"] .fab-menu-btn {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.fab-menu-btn:disabled,
.fab-menu-btn.disabled {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fab-icon-close,
.fab-icon-open {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all ease-in-out 0.3s;
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-close,
.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-open {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-open {
  opacity: 0;
}

.fab-icon-close {
  opacity: 0;
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
.fab-menu[data-fab-state="open"] .fab-icon-close {
  opacity: 1;
}

.fab-menu .fab-menu-inner > li {
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.fab-menu .fab-menu-inner > li:nth-child(1) {
  transition-delay: 0.05s;
}

.fab-menu .fab-menu-inner > li:nth-child(2) {
  transition-delay: 0.1s;
}

.fab-menu .fab-menu-inner > li:nth-child(3) {
  transition-delay: 0.15s;
}

.fab-menu .fab-menu-inner > li:nth-child(4) {
  transition-delay: 0.2s;
}

.fab-menu .fab-menu-inner > li:nth-child(5) {
  transition-delay: 0.25s;
}

.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-inner > li,
.fab-menu[data-fab-state="open"] .fab-menu-inner > li {
  visibility: visible;
  opacity: 1;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(1),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(1),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
  top: 4.25rem;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(2),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(2),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
  top: 8.25rem;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(3),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(3),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
  top: 12.25rem;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(4),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(4),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
  top: 16.25rem;
}

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(5),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(5),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
  top: 20.25rem;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(1),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(1),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(1),
.fab-menu-bottom-right[data-fab-state="open"]
  .fab-menu-inner
  > li:nth-child(1) {
  top: -3.75rem;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(2),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(2),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(2),
.fab-menu-bottom-right[data-fab-state="open"]
  .fab-menu-inner
  > li:nth-child(2) {
  top: -7.75rem;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(3),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(3),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(3),
.fab-menu-bottom-right[data-fab-state="open"]
  .fab-menu-inner
  > li:nth-child(3) {
  top: -11.75rem;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(4),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(4),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(4),
.fab-menu-bottom-right[data-fab-state="open"]
  .fab-menu-inner
  > li:nth-child(4) {
  top: -15.75rem;
}

.fab-menu-bottom[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(5),
.fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(5),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover
  .fab-menu-inner
  > li:nth-child(5),
.fab-menu-bottom-right[data-fab-state="open"]
  .fab-menu-inner
  > li:nth-child(5) {
  top: -19.75rem;
}

.fab-menu-inner div[data-fab-label]:after {
  content: attr(data-fab-label);
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 4.125rem;
  color: #fff;
  background-color: #000;
  padding: 0.5rem 0.75rem;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all ease-in-out 0.3s;
  border-radius: 0.1875rem;
}

.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-right .fab-menu-inner div[data-fab-label]:after {
  margin-top: -1.75rem;
}

.fab-menu-top-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-inner div[data-fab-label].fab-label-right:after {
  right: auto;
  left: 4.125rem;
}

.fab-menu[data-fab-toggle="hover"]
  .fab-menu-inner
  div[data-fab-label]:hover:after,
.fab-menu[data-fab-state="open"]
  .fab-menu-inner
  div[data-fab-label]:hover:after {
  visibility: visible;
  opacity: 1;
}

.fab-menu-inner div[data-fab-label].fab-label-light:after {
  background-color: #fff;
  color: #333;
}

.fab-menu-inner div[data-fab-label].fab-label-visible:after {
  visibility: visible;
  opacity: 1;
}

/* ------------------------------------------------------------------------------
*
*  # Fancytree
*
*  Styles for fancytree_all.min.js - tree plugin for jQuery
*
* ---------------------------------------------------------------------------- */
.fancytree-container {
  list-style: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  overflow: auto;
  position: relative;
}

.fancytree-container:focus {
  outline: 0;
}

.fancytree-container ul {
  list-style: none;
  padding-left: 1rem;
  margin: 0;
}

.ui-fancytree-disabled .fancytree-container {
  opacity: 0.6;
}

.ui-fancytree-disabled .fancytree-container,
.ui-fancytree-disabled .fancytree-title,
.ui-fancytree-disabled .fancytree-expander {
  cursor: default;
}

.ui-fancytree-disabled
  .fancytree-treefocus
  .fancytree-selected
  .fancytree-title {
  background-color: #eee;
  color: #333;
}

.fancytree-expander:after,
.fancytree-icon:after,
.fancytree-checkbox:after,
.fancytree-drag-helper-img:after,
.fancytree-drop-before:after,
.fancytree-drop-after:after,
.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancytree-icon,
.fancytree-custom-icon,
.fancytree-expander {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5rem;
  margin-top: 0.37502rem;
  width: 1rem;
  height: 1rem;
}

.fancytree-checkbox {
  vertical-align: top;
  margin-top: 0.25002rem;
  margin-left: 0.5rem;
}

img.fancytree-icon {
  border: 0;
}

.fancytree-expander {
  cursor: pointer;
}

.fancytree-exp-c .fancytree-expander:after {
  content: "\e9c7";
}

.fancytree-exp-cl .fancytree-expander:after {
  content: "\e9c7";
}

.fancytree-exp-cd .fancytree-expander:after,
.fancytree-exp-cdl .fancytree-expander:after {
  content: "\e9c7";
}

.fancytree-exp-e .fancytree-expander:after,
.fancytree-exp-ed .fancytree-expander:after {
  content: "\e9c5";
}

.fancytree-exp-el .fancytree-expander:after,
.fancytree-exp-edl .fancytree-expander:after {
  content: "\e9c5";
}

.fancytree-statusnode-error .fancytree-icon:after {
  content: "\ed63";
}

.fancytree-loading .fancytree-expander,
.fancytree-statusnode-wait .fancytree-icon {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
  width: 1rem;
  height: 1rem;
}

.fancytree-loading .fancytree-expander:after,
.fancytree-statusnode-wait .fancytree-icon:after {
  content: "\ed6c";
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancytree-loading .fancytree-expander:after,
  .fancytree-statusnode-wait .fancytree-icon:after {
    transition: none;
  }
}

.fancytree-ico-c .fancytree-icon:after,
.fancytree-ico-e .fancytree-icon:after {
  content: "\ea1a";
}

.fancytree-has-children.fancytree-ico-c .fancytree-icon:after {
  content: "\ea0f";
}

.fancytree-has-children.fancytree-ico-e .fancytree-icon:after {
  content: "\ea11";
}

.fancytree-ico-cf .fancytree-icon:after,
.fancytree-ico-ef .fancytree-icon:after {
  content: "\ea3d";
}

.fancytree-has-children.fancytree-ico-cf .fancytree-icon:after {
  content: "\ea41";
}

.fancytree-has-children.fancytree-ico-ef .fancytree-icon:after {
  content: "\ea43";
}

.fancytree-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid #455a64;
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 0.125rem;
}

.fancytree-checkbox:after {
  content: "";
  position: absolute;
}

.fancytree-partsel .fancytree-checkbox:after,
.fancytree-radio .fancytree-selected .fancytree-checkbox:after {
  content: "";
  top: 0.1875rem;
  left: 0.1875rem;
  border: 0.3125rem solid;
  border-color: inherit;
  width: 0;
  height: 0;
}

.fancytree-selected .fancytree-checkbox:after {
  content: "\e600";
  border: 0;
  top: 0rem;
  left: 0rem;
}

.fancytree-radio .fancytree-checkbox,
.fancytree-radio .fancytree-checkbox:after {
  border-radius: 50%;
}

.fancytree-drag-helper .fancytree-title {
  padding-right: 2rem;
  border: 1px solid #ddd;
  background-color: #fff;
  opacity: 0.8;
  margin: 0;
}

.fancytree-drag-helper-img:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.fancytree-drop-accept .fancytree-drag-helper-img:after {
  content: "\ee73";
  color: #4caf50;
}

.fancytree-drop-reject .fancytree-drag-helper-img:after {
  content: "\ed63";
  color: #f44336;
}

#fancytree-drop-marker.fancytree-drop-before,
#fancytree-drop-marker.fancytree-drop-after {
  width: 15rem;
  border-top: 1px solid #999;
  position: absolute !important;
}

.fancytree-drag-source {
  opacity: 0.5;
}

.fancytree-drop-target.fancytree-drop-accept a {
  background-color: #2196f3 !important;
  color: #fff !important;
  text-decoration: none;
}

.fancytree-node {
  display: inherit;
  width: 100%;
  position: relative;
  margin-bottom: 1px;
}

.fancytree-title {
  border: 0;
  padding: 0.25rem 0.5rem;
  margin-left: 0.625rem;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancytree-title {
    transition: none;
  }
}

.fancytree-title > input {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}

.fancytree-selected .fancytree-title,
.fancytree-active .fancytree-title {
  background-color: #eee;
  color: #333;
}

.fancytree-treefocus .fancytree-selected .fancytree-title {
  background-color: #2196f3;
  color: #fff;
}

.sidebar:not(.sidebar-light) .fancytree-selected .fancytree-title,
.sidebar:not(.sidebar-light) .fancytree-active .fancytree-title {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.sidebar:not(.sidebar-light)
  .fancytree-treefocus
  .fancytree-selected
  .fancytree-title {
  background-color: #fff;
  color: #333;
}

.fancytree-ext-table .fancytree-node {
  display: inline-block;
  width: auto;
  margin: 0;
}

.fancytree-ext-table .fancytree-title {
  display: inline;
}

.fancytree-ext-table.fancytree-container {
  white-space: normal;
}

.fancytree-ext-table .fancytree-expander,
.fancytree-ext-table .fancytree-icon,
.fancytree-ext-table .fancytree-custom-icon {
  margin-top: 0.09375rem;
}

.fancytree-ext-table .fancytree-checkbox {
  margin: 0 auto;
  float: none;
}

.fancytree-ext-table .uniform-checker {
  display: block;
  margin: 0 auto;
}

.fancytree-ext-table tbody tr {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancytree-ext-table tbody tr {
    transition: none;
  }
}

.fancytree-ext-table tbody tr td:first-child {
  text-align: center;
}

.fancytree-ext-table tbody tr .fancytree-title {
  background-color: transparent;
  color: inherit !important;
  padding: 0;
  transition: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancytree-ext-table tbody tr .fancytree-title {
    transition: none;
  }
}

.fancytree-ext-table tbody tr.fancytree-focused,
.fancytree-ext-table tbody tr.fancytree-active {
  background-color: rgba(0, 0, 0, 0.02);
}

.fancytree-ext-table tbody tr.fancytree-selected {
  background-color: #2196f3;
  color: #fff;
}

.fancytree-ext-table tbody tr.fancytree-selected .fancytree-checkbox,
.fancytree-ext-table tbody tr.fancytree-selected .uniform-checker span {
  color: #fff;
  border-color: #fff;
}

.fancytree-ext-table tbody tr.fancytree-selected .fancytree-title {
  background-color: transparent;
}

.fancytree-treefocus
  .fancytree-ext-table
  tbody
  tr.fancytree-selected
  .fancytree-title {
  transition: color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancytree-treefocus
    .fancytree-ext-table
    tbody
    tr.fancytree-selected
    .fancytree-title {
    transition: none;
  }
}

.fancytree-ext-childcounter .fancytree-childcounter {
  position: absolute;
  right: 0;
  top: 0.25rem;
  color: #999;
}

.fancytree-helper-hidden {
  display: none;
}

.fancytree-helper-indeterminate-cb {
  color: #777;
}

.fancytree-helper-disabled {
  color: #333;
}

.fancytree-helper-spin {
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

/* ------------------------------------------------------------------------------
 *
 *  # Ladda progress buttons
 *
 *  Styles for ladda.min.js - buttons with built-in loading indicators
 *
 * ---------------------------------------------------------------------------- */
.btn-ladda,
.btn-ladda .ladda-spinner,
.btn-ladda .ladda-label {
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-ladda,
  .btn-ladda .ladda-spinner,
  .btn-ladda .ladda-label {
    transition: none;
  }
}

.btn-ladda .ladda-spinner {
  position: absolute;
  z-index: 2;
  display: inline-block;
  top: 50%;
  pointer-events: none;
  opacity: 0;
}

.btn-ladda .ladda-label {
  z-index: 3;
}

.btn-ladda .ladda-progress {
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: all linear 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-ladda .ladda-progress {
    transition: none;
  }
}

.btn-ladda[data-loading] .ladda-progress {
  visibility: visible;
  opacity: 1;
}

.btn-ladda[data-style="zoom-in"],
.btn-ladda[data-style="zoom-out"] {
  overflow: hidden;
}

.btn-ladda[data-style="zoom-in"],
.btn-ladda[data-style="zoom-in"] .ladda-spinner,
.btn-ladda[data-style="zoom-in"] .ladda-label,
.btn-ladda[data-style="zoom-out"],
.btn-ladda[data-style="zoom-out"] .ladda-spinner,
.btn-ladda[data-style="zoom-out"] .ladda-label {
  transition: 0.3s ease all;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-ladda[data-style="zoom-in"],
  .btn-ladda[data-style="zoom-in"] .ladda-spinner,
  .btn-ladda[data-style="zoom-in"] .ladda-label,
  .btn-ladda[data-style="zoom-out"],
  .btn-ladda[data-style="zoom-out"] .ladda-spinner,
  .btn-ladda[data-style="zoom-out"] .ladda-label {
    transition: none;
  }
}

.btn-ladda[data-style="zoom-in"] .ladda-label,
.btn-ladda[data-style="zoom-out"] .ladda-label {
  position: relative;
  display: inline-block;
}

.btn-ladda[data-style="zoom-in"] .ladda-spinner,
.btn-ladda[data-style="zoom-out"] .ladda-spinner {
  left: 50%;
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-label,
.btn-ladda[data-style="zoom-out"][data-loading] .ladda-label {
  opacity: 0;
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-spinner,
.btn-ladda[data-style="zoom-out"][data-loading] .ladda-spinner {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.btn-ladda[data-style="zoom-in"] .ladda-spinner {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
}

.btn-ladda[data-style="zoom-in"][data-loading] .ladda-label {
  -webkit-transform: scale(2.2);
  transform: scale(2.2);
}

.btn-ladda[data-style="zoom-out"] .ladda-spinner {
  -webkit-transform: scale(2.5);
  transform: scale(2.5);
}

.btn-ladda[data-style="zoom-out"][data-loading] .ladda-label {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.btn-ladda[data-style="expand-left"] {
  overflow: hidden;
}

.btn-ladda[data-style="expand-left"] .ladda-spinner {
  left: 20px;
}

.btn-ladda[data-style="expand-left"][data-loading] {
  padding-left: 35px;
}

.btn-ladda[data-style="expand-left"][data-loading] .ladda-spinner {
  opacity: 1;
}

.btn-ladda[data-style="expand-right"] {
  overflow: hidden;
}

.btn-ladda[data-style="expand-right"] .ladda-spinner {
  right: 20px;
}

.btn-ladda[data-style="expand-right"][data-loading] {
  padding-right: 35px;
}

.btn-ladda[data-style="expand-right"][data-loading] .ladda-spinner {
  opacity: 1;
}

.btn-ladda[data-style="expand-up"] {
  overflow: hidden;
}

.btn-ladda[data-style="expand-up"] .ladda-spinner {
  top: -16px;
  left: 50%;
}

.btn-ladda[data-style="expand-up"][data-loading] {
  padding-top: 40px;
}

.btn-ladda[data-style="expand-up"][data-loading] .ladda-spinner {
  top: 12px;
  margin-top: 8px;
  opacity: 1;
}

.btn-ladda[data-style="expand-down"] {
  overflow: hidden;
}

.btn-ladda[data-style="expand-down"] .ladda-spinner {
  top: 44px;
  left: 50%;
}

.btn-ladda[data-style="expand-down"][data-loading] {
  padding-bottom: 40px;
}

.btn-ladda[data-style="expand-down"][data-loading] .ladda-spinner {
  opacity: 1;
}

.btn-ladda[data-style="slide-left"] {
  overflow: hidden;
}

.btn-ladda[data-style="slide-left"] .ladda-label {
  left: 0;
  position: relative;
}

.btn-ladda[data-style="slide-left"] .ladda-spinner {
  right: 0;
}

.btn-ladda[data-style="slide-left"][data-loading] .ladda-label {
  left: -100%;
  opacity: 0;
}

.btn-ladda[data-style="slide-left"][data-loading] .ladda-spinner {
  right: 50%;
  opacity: 1;
}

.btn-ladda[data-style="slide-right"] {
  overflow: hidden;
}

.btn-ladda[data-style="slide-right"] .ladda-label {
  right: 0;
  position: relative;
}

.btn-ladda[data-style="slide-right"] .ladda-spinner {
  left: 0;
}

.btn-ladda[data-style="slide-right"][data-loading] .ladda-label {
  right: -100%;
  opacity: 0;
}

.btn-ladda[data-style="slide-right"][data-loading] .ladda-spinner {
  left: 50%;
  opacity: 1;
}

.btn-ladda[data-style="slide-up"] {
  overflow: hidden;
}

.btn-ladda[data-style="slide-up"] .ladda-label {
  top: 0;
  position: relative;
}

.btn-ladda[data-style="slide-up"] .ladda-spinner {
  top: 100%;
  left: 50%;
}

.btn-ladda[data-style="slide-up"][data-loading] .ladda-label {
  top: -16px;
  opacity: 0;
}

.btn-ladda[data-style="slide-up"][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
}

.btn-ladda[data-style="slide-down"] {
  overflow: hidden;
}

.btn-ladda[data-style="slide-down"] .ladda-label {
  top: 0;
  position: relative;
}

.btn-ladda[data-style="slide-down"] .ladda-spinner {
  top: -100%;
  left: 50%;
}

.btn-ladda[data-style="slide-down"][data-loading] .ladda-label {
  top: 16px;
  opacity: 0;
}

.btn-ladda[data-style="slide-down"][data-loading] .ladda-spinner {
  top: 16px;
  opacity: 1;
}

.btn-ladda[data-style="fade"] {
  overflow: hidden;
}

.btn-ladda[data-style="fade"] .ladda-spinner {
  left: 50%;
}

.btn-ladda[data-style="fade"][data-loading] .ladda-label {
  opacity: 0;
}

.btn-ladda[data-style="fade"][data-loading] .ladda-spinner {
  opacity: 1;
}

.btn-ladda[data-style="radius"] {
  overflow: hidden;
  transition: all ease-in-out 0.5s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-ladda[data-style="radius"] {
    transition: none;
  }
}

.btn-ladda[data-style="radius"] .ladda-spinner {
  left: 50%;
}

.btn-ladda[data-style="radius"][data-loading] {
  border-radius: 100px;
}

.btn-ladda[data-style="radius"][data-loading] .ladda-label {
  opacity: 0;
}

.btn-ladda[data-style="radius"][data-loading] .ladda-spinner {
  opacity: 1;
}

/* ------------------------------------------------------------------------------
 *
 *  # FullCalendar
 *
 *  Styles for fullcalendar.min.js - JavaScript event calendar
 *
 * ---------------------------------------------------------------------------- */
.fc {
  direction: ltr;
  text-align: left;
}

.fc-events-container {
  background-color: #fafafa;
  border: 1px solid #ddd;
  padding: 0.625rem;
  border-radius: 0.1875rem;
}

.fc-events-container .fc-event {
  padding: 0.25rem 0.5rem;
}

.fc-events-container .fc-event + .fc-event {
  margin-top: 0.625rem;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #ddd;
}

.fc-unthemed thead,
.fc-unthemed thead td,
.fc-unthemed thead th {
  border-bottom-color: #b7b7b7;
}

.fc-unthemed .fc-popover {
  background-color: #fff;
  border-radius: 0.1875rem;
}

.fc-unthemed td.fc-today {
  background-color: #e8f5e9;
}

.fc-highlight {
  background-color: rgba(221, 221, 221, 0.3);
}

.fc-bgevent {
  background-color: rgba(129, 199, 132, 0.3);
  opacity: 0.3;
}

.fc-nonbusiness {
  background-color: rgba(221, 221, 221, 0.3);
}

.fc-icon {
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fc-icon {
    transition: none;
  }
}

.fc-icon:after {
  display: inline-block;
  font-family: "icomoon";
  line-height: 1;
  font-size: 1rem;
  vertical-align: middle;
  position: relative;
  top: -0.09375rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-icon-left-single-arrow:after {
  content: "\e9c0";
}

.fc-rtl .fc-icon-left-single-arrow:after {
  content: "\e9c0";
}

.fc-icon-right-single-arrow:after {
  content: "\e9c3";
}

.fc-rtl .fc-icon-right-single-arrow:after {
  content: "\e9c3";
}

.fc-icon-left-double-arrow:after {
  content: "\000AB";
}

.fc-rtl .fc-icon-left-double-arrow:after {
  content: "\000AB";
}

.fc-icon-right-double-arrow:after {
  content: "\000BB";
}

.fc-rtl .fc-icon-right-double-arrow:after {
  content: "\000BB";
}

.fc-icon-x:after {
  content: "\ed6a";
  font-size: 0.8125rem;
  display: block;
  margin-top: 0.21877rem;
  top: 0;
}

.fc-button {
  margin: 0;
  padding: 0.4375rem 0.875rem;
  white-space: nowrap;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fc-button {
    transition: none;
  }
}

.fc-button:focus {
  outline: 0;
}

.fc-button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}

.fc-state-default {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ddd;
}

.fc-state-default.fc-corner-left {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.fc-state-default.fc-corner-right {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.fc-state-down,
.fc-state-hover {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.fc-state-active {
  background-image: none;
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.fc-state-disabled {
  cursor: default;
  opacity: 0.65;
}

.fc-button-group {
  display: inline-block;
}

.fc .fc-button-group > * {
  float: left;
  margin: 0;
  margin-left: -1px;
}

.fc .fc-button-group > :first-child {
  margin-left: 0;
}

.fc-popover {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1060;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.fc-popover .fc-header {
  padding: 0.9375rem 0.9375rem;
}

.fc-popover .fc-header .fc-title {
  float: left;
}

.fc-popover .fc-header .fc-close {
  cursor: pointer;
  float: right;
  margin-left: 0.625rem;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  opacity: 0.6;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fc-unthemed .fc-popover .fc-header .fc-close {
    transition: none;
  }
}

.fc-unthemed .fc-popover .fc-header .fc-close:hover {
  opacity: 1;
}

.fc-divider {
  border-style: solid;
  border-width: 1px;
}

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0;
  border-width: 1px 0;
}

.fc-clear {
  clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-bg {
  bottom: 0;
}

.fc-bg table {
  height: 100%;
}

.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
}

.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}

.fc th {
  padding: 0.75rem 1.25rem;
  padding-left: 0;
  padding-right: 0;
  font-weight: 500;
  text-align: center;
}

.fc td.fc-today {
  border-style: double;
}

a[data-goto] {
  cursor: pointer;
  color: #333;
}

.fc-row {
  border-style: solid;
  border-width: 0;
  position: relative;
}

.fc-row table {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.fc-row:first-child table {
  border-top: 0;
}

.fc-row .fc-bg {
  z-index: 1;
}

.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}

.fc-row .fc-bgevent-skeleton td,
.fc-row .fc-highlight-skeleton td {
  border-color: transparent;
  border: 0;
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}

.fc-row .fc-highlight-skeleton {
  z-index: 3;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: none;
  border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0;
}

.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
}

.fc-row .fc-helper-skeleton {
  z-index: 5;
}

.fc-scroller {
  -webkit-overflow-scrolling: touch;
}

.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  width: 100%;
}

.fc-event {
  position: relative;
  display: block;
  border: 1px solid #039be5;
  color: #fff !important;
  border-radius: 0.1875rem;
}

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
}

.fc-event .fc-bg {
  z-index: 1;
}

.fc-event .fc-content {
  position: relative;
  z-index: 2;
}

.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
  display: none;
}

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block;
}

.fc-event.fc-selected .fc-resizer:before {
  content: "";
  position: absolute;
  z-index: 1070;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1.75003rem;
  height: 1.75003rem;
}

.fc-event .fc-event.fc-selected {
  z-index: 1070 !important;
}

.fc-event,
.fc-event-dot {
  background-color: #039be5;
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: default;
}

.fc-h-event .fc-end-resizer {
  cursor: e-resize;
  right: -1px;
}

.fc-h-event.fc-selected .fc-end-resizer {
  margin-right: calc(-0.25rem - 1px);
}

.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 0.5rem;
  top: -1px;
  bottom: -1px;
}

.fc-h-event.fc-selected .fc-resizer {
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
}

.fc-day-grid-event {
  margin: 0.5rem;
  margin-top: 0;
  padding: 0.25rem 0.5rem;
}

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
}

.fc-day-grid-event > .fc-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-day-grid-event .fc-time {
  font-weight: 500;
}

.fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-right: -1px;
}

@media (min-width: 768px) {
  .fc-day-grid-event .fc-time {
    float: right;
    padding-left: 0.625rem;
    display: inline-block;
    z-index: 2;
    position: relative;
    background-color: inherit;
  }

  .fc-day-grid-event .fc-title {
    z-index: 1;
    position: relative;
  }
}

a.fc-more {
  margin: 0 0.5rem;
  background-color: #f5f5f5;
  display: block;
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.6667;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  a.fc-more {
    transition: none;
  }
}

a.fc-more:hover {
  background-color: #eee;
}

.fc-limited {
  display: none;
}

.fc-day-grid .fc-row {
  z-index: 1;
}

.fc-more-popover {
  z-index: 2;
  width: 15rem;
}

.fc-more-popover .fc-event-container {
  padding: 0.9375rem 0.9375rem;
  padding-top: 0;
}

.fc-now-indicator {
  position: absolute;
}

.fc-unselectable {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fc-toolbar {
  text-align: center;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.25rem;
}

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1.25rem;
}

.fc-toolbar h2 {
  margin: 0.30769rem 0;
  font-size: 1.0625rem;
}

.fc-toolbar .fc-left {
  float: left;
  width: 33%;
}

.fc-toolbar .fc-right {
  float: right;
  width: 33%;
}

.fc-toolbar .fc-right .fc-button-group {
  float: right;
}

.fc-toolbar .fc-left > .fc-button,
.fc-toolbar .fc-right > .fc-button {
  margin-left: 0.625rem;
}

.fc-toolbar .fc-center {
  display: inline-block;
  vertical-align: middle;
}

.fc-toolbar > * > * {
  float: left;
}

.fc-toolbar > * > :first-child {
  margin-left: 0;
}

.fc-toolbar button {
  position: relative;
}

.fc-toolbar button:focus {
  z-index: 5;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}

.fc-toolbar .fc-state-down {
  z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}

.fc-view-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
}

.fc-view-container .fc-view > table th,
.fc-view-container .fc-view > table td {
  border-right-width: 0;
}

.fc-view-container .fc-view > table tr:first-child th:first-child,
.fc-view-container .fc-view > table tr:first-child td:first-child {
  border-left-width: 0;
}

.fc-view-container .fc-view > table .fc-head tr:first-child > th,
.fc-view-container .fc-view > table .fc-head tr:first-child > td {
  border-top: 0;
}

.fc-view-container .fc-view > table .fc-head-container {
  border-top: 0;
  border-bottom: 0;
}

.fc-view-container .fc-view > table .fc-head-container .fc-widget-header {
  border-top: 0;
}

.fc-view-container .fc-view > table .fc-body > tr:first-child > th,
.fc-view-container .fc-view > table .fc-body > tr:first-child > td {
  border-top: 0;
}

.fc-view-container .fc-view > table .fc-body > tr:last-child > th,
.fc-view-container .fc-view > table .fc-body > tr:last-child > td {
  border-bottom: 0;
}

.fc-view-container
  .fc-view
  > table
  .fc-body
  .fc-row:first-child
  tr:first-child
  td,
.fc-view-container
  .fc-view
  > table
  .fc-body
  .fc-row:first-child
  tr:first-child
  th {
  border-top: 0;
}

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  box-sizing: content-box;
}

.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}

.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fc-basic-view tbody .fc-row {
  min-height: 6em;
}

.fc-row.fc-rigid {
  overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-basic-view .fc-day-number {
  padding: 0.5rem;
}

.fc-basic-view .fc-week-number {
  color: rgba(51, 51, 51, 0.5);
}

.fc-basic-view td.fc-week-number,
.fc-basic-view th.fc-week-number {
  padding: 0.75rem 1.25rem;
  background-color: #fafafa;
}

.fc-basic-view td.fc-week-number {
  font-size: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fc-basic-view span.fc-week-number {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.5rem;
}

.fc-basic-view .fc-day-number {
  text-align: right;
}

.fc-basic-view .fc-day-top .fc-day-number {
  float: right;
}

.fc-other-month.fc-day-top,
.fc-other-month.fc-day-number {
  opacity: 0.3;
}

.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.fc-axis {
  text-align: right;
}

.fc .fc-axis {
  vertical-align: middle;
  padding: 0 0.5rem;
  height: 2.25003rem;
  white-space: nowrap;
}

.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}

.fc-time-grid {
  min-height: 100%;
}

.fc-time-grid table {
  border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
  z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-content-col {
  position: relative;
}

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}

.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6;
}

.fc-time-grid .fc-slats td {
  border-bottom: 0;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}

.fc-time-grid .fc-highlight-container {
  position: relative;
}

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}

.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  position: relative;
}

.fc-time-grid .fc-event {
  box-shadow: 0 0 0 1px #fff;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  padding: 0.5rem;
}

.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}

.fc-v-event.fc-not-start {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-time-grid-event {
  overflow: hidden;
}

.fc-time-grid-event.fc-selected {
  overflow: visible;
}

.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
}

.fc-time-grid-event .fc-content {
  position: relative;
  overflow: hidden;
}

.fc-time-grid-event .fc-time {
  font-weight: 500;
}

.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: 0.75rem;
  padding: 0;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  line-height: 1;
  text-align: center;
  cursor: s-resize;
  background-color: rgba(0, 0, 0, 0.1);
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
  display: inline-block;
  vertical-align: middle;
}

.fc-time-grid-event.fc-selected .fc-resizer {
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  background-color: #fff;
  left: 50%;
  bottom: 0;
  margin-bottom: calc(-0.25rem - 1px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}

.fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  margin-top: -0.5rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc-event-dot {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.fc-list-view {
  border-width: 0;
  border-style: solid;
  direction: ltr;
}

.fc .fc-list-table {
  table-layout: auto;
}

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 0.75rem 1.25rem;
}

.fc-list-table tr:first-child td {
  border-top-width: 0;
}

.fc-list-heading {
  border-bottom-width: 1px;
}

.fc-list-heading td {
  font-weight: 500;
}

.fc-list-heading-main {
  float: left;
}

.fc-list-heading-alt {
  float: right;
}

.fc-list-item td {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fc-list-item td {
    transition: none;
  }
}

.fc-list-item.fc-has-url {
  cursor: pointer;
}

.fc-list-item:hover td {
  background-color: rgba(0, 0, 0, 0.03);
}

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}

.fc-list-item-marker {
  padding-right: 0;
}

.fc-list-item-title a {
  text-decoration: none;
  color: inherit;
}

.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-list-empty-wrap1 {
  display: table;
  width: 100%;
  height: 100%;
}

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: rgba(221, 221, 221, 0.3);
}

@media (max-width: 767.98px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-left > .fc-button,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-right > .fc-button,
  .fc-toolbar .fc-left .fc-button-group,
  .fc-toolbar .fc-right .fc-button-group {
    float: none;
    width: auto;
    vertical-align: top;
  }

  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    display: block;
    margin-bottom: 0.625rem;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Image cropper
 *
 *  Styles for cropper.min.js - a simple jQuery image cropping plugin
 *
 * ---------------------------------------------------------------------------- */
.image-cropper-container {
  /*rtl:ignore*/
  direction: ltr;
  height: 400px;
  width: 100%;
  overflow: hidden;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.eg-preview .preview {
  /*rtl:ignore*/
  direction: ltr;
}

.eg-preview .preview-lg {
  width: 150px;
  height: 150px;
}

.eg-preview .preview-md {
  width: 120px;
  height: 120px;
}

.eg-preview .preview-sm {
  width: 90px;
  height: 90px;
}

.eg-preview .preview-xs {
  width: 60px;
  height: 60px;
}

.eg-preview .preview-xxs {
  width: 40px;
  height: 40px;
}

.cropper-container {
  /*rtl:ignore*/
  direction: ltr !important;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.cropper-dashed {
  position: absolute;
  display: block;
  border: 0 dashed #eee;
  opacity: 0.5;
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  /*rtl:ignore*/
  left: 0;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  /*rtl:ignore*/
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  /*rtl:ignore*/
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cropper-center:before,
.cropper-center:after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  /*rtl:ignore*/
  left: -4px;
  width: 9px;
  height: 1px;
}

.cropper-center:after {
  top: -4px;
  /*rtl:ignore*/
  left: 0;
  width: 1px;
  height: 9px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.cropper-face {
  top: 0;
  /*rtl:ignore*/
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #2196f3;
}

.cropper-line.line-e {
  top: 0;
  /*rtl:ignore*/
  right: -0.1875rem;
  width: 0.375rem;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -0.1875rem;
  /*rtl:ignore*/
  left: 0;
  height: 0.375rem;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  /*rtl:ignore*/
  left: -0.1875rem;
  width: 0.375rem;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -0.1875rem;
  /*rtl:ignore*/
  left: 0;
  height: 0.375rem;
  cursor: s-resize;
}

.cropper-point {
  background-color: #2196f3;
  opacity: 0.75;
  width: 0.375rem;
  height: 0.375rem;
}

.cropper-point.point-e {
  top: 50%;
  /*rtl:ignore*/
  right: -0.1875rem;
  margin-top: -0.1875rem;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -0.1875rem;
  /*rtl:ignore*/
  left: 50%;
  margin-left: -0.1875rem;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  /*rtl:ignore*/
  left: -0.1875rem;
  margin-top: -0.1875rem;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -0.1875rem;
  /*rtl:ignore*/
  left: 50%;
  margin-left: -0.1875rem;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -0.1875rem;
  /*rtl:ignore*/
  right: -0.1875rem;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -0.1875rem;
  /*rtl:ignore*/
  left: -0.1875rem;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -0.1875rem;
  /*rtl:ignore*/
  left: -0.1875rem;
  cursor: sw-resize;
}

.cropper-point.point-se {
  /*rtl:ignore*/
  right: -0.1875rem;
  bottom: -0.1875rem;
  cursor: se-resize;
  opacity: 1;
  width: 0.375rem;
  height: 0.375rem;
}

.cropper-point.point-se:before {
  content: " ";
  position: absolute;
  /*rtl:ignore*/
  right: -50%;
  bottom: -50%;
  display: block;
  background-color: #2196f3;
  opacity: 0;
  width: 200%;
  height: 200%;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-invisible {
  opacity: 0;
}

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: default;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

/* ------------------------------------------------------------------------------
 *
 *  # Fancybox lightbox
 *
 *  Styles for fancybox.min.js - Mac-style "lightbox" plugin
 *
 * ---------------------------------------------------------------------------- */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background-color: #fff;
  border-radius: 0.1875rem;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.fancybox-outer,
.fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 8060;
  color: #fff;
  opacity: 0.8;
}

#fancybox-loading div:after {
  content: "\eb55";
  font-family: "icomoon";
  font-size: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  display: block;
  line-height: 1;
  -webkit-animation: rotation 1s ease infinite;
  animation: rotation 1s ease infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1rem;
  height: 1rem;
}

.fancybox-close {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  padding: 0.375rem;
  z-index: 8061;
  cursor: pointer;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancybox-close {
    transition: none;
  }
}

.fancybox-close:hover,
.fancybox-close:focus {
  background-color: #333;
  color: #fff;
}

.fancybox-close:after {
  content: "\ed6a";
  font-family: "icomoon";
  font-size: 1rem;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancybox-error {
  color: #f44336;
  font-weight: 500;
  margin: 0;
  padding: 1.25rem;
  white-space: nowrap;
}

.fancybox-title {
  visibility: hidden;
  position: relative;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -2.25003rem;
  z-index: 8050;
  text-align: center;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 0.5rem 0.625rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  text-align: center;
  margin-top: 0.625rem;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding: 0.5rem 0.625rem;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  padding: 0.5rem 0.625rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  margin-top: -0.875rem;
  cursor: pointer;
  padding: 0.375rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  line-height: 1;
  min-width: 1.75rem;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 8040;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fancybox-nav span {
    transition: none;
  }
}

.fancybox-nav span:after {
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancybox-nav span:hover,
.fancybox-nav span:focus {
  background-color: #333;
  color: #fff;
}

.fancybox-nav:hover span {
  visibility: visible;
  opacity: 1;
}

.fancybox-prev {
  left: 0;
}

.fancybox-prev span {
  left: 0.625rem;
}

.fancybox-prev span:after {
  content: "\e9c8";
}

.fancybox-next {
  right: 0;
}

.fancybox-next span {
  right: 0.625rem;
}

.fancybox-next span:after {
  content: "\e9cb";
}

.fancybox-lock {
  overflow: visible !important;
  width: auto;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background-color: rgba(0, 0, 0, 0.8);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # Footable
 *
 *  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
 *
 * ---------------------------------------------------------------------------- */
.footable {
  width: 100%;
}

.footable > thead > tr > th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footable.breakpoint > tbody > tr.footable-detail-show > td {
  border-bottom: 0;
}

.footable.breakpoint > tbody > tr.footable-row-detail:hover {
  background-color: transparent;
}

.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}

.footable.breakpoint > tbody > tr > .footable-cell-detail {
  background-color: #fafafa;
  border-top: 0;
}

.footable.breakpoint > tbody > tr .footable-toggle:before {
  content: "\e9e4";
  display: inline-block;
  font-family: "icomoon";
  font-size: 1rem;
  vertical-align: middle;
  position: relative;
  top: -0.09375rem;
  line-height: 1;
  padding-right: 0.625rem;
  color: #43425d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footable.breakpoint > tbody > tr.footable-detail-show .footable-toggle:before {
  content: "\e9e7";
}

.footable-row-detail-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.footable-row-detail-row {
  display: table-row;
}

.footable-row-detail-group {
  display: block;
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.footable-row-detail-group:first-child {
  margin-top: 0.5rem;
}

.footable-row-detail-name {
  display: table-cell;
  font-weight: 500;
  padding-right: 2.5rem;
}

.footable-row-detail-value {
  display: table-cell;
  padding: 0.5rem 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # Handsontable
 *
 *  Styles for handsontable.min.js - JavaScript Spreadsheet library
 *
 * ---------------------------------------------------------------------------- */
/*rtl:begin:ignore*/
.hot-container {
  position: relative;
  box-shadow: 0 0 0 1px #ddd;
  overflow: hidden;
  text-align: left;
}

.hot-container.hot-scrollable {
  height: 22.5rem;
}

.hot-container:not(.hot-scrollable) > .handsontable > .ht_master > .wtHolder {
  height: 100% !important;
}

@media (max-width: 767.98px) {
  .hot-container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .hot-container .ht_master .wtHolder {
    max-width: 100%;
    min-width: auto;
  }

  .hot-container:not(.hot-scrollable) .wtHolder {
    height: 100% !important;
  }
}

.handsontable {
  position: relative;
  direction: ltr;
}

.handsontable .hide {
  display: none;
}

.handsontable .relative {
  position: relative;
}

.handsontable.htAutoSize {
  visibility: hidden;
  left: -99000px;
  position: absolute;
  top: -99000px;
}

.handsontable .wtHider {
  position: relative;
  width: 0;
}

.handsontable .wtSpreader {
  position: relative;
  width: 0;
  height: auto;
}

.handsontable .htDimmed {
  color: #999;
}

.handsontable table,
.handsontable tbody,
.handsontable thead,
.handsontable td,
.handsontable th,
.handsontable input,
.handsontable textarea,
.handsontable div {
  box-sizing: content-box;
}

.handsontable input,
.handsontable textarea {
  min-height: initial;
}

.handsontable table.htCore {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  border-width: 0;
  table-layout: fixed;
  width: 0;
  outline-width: 0;
  max-width: none;
  max-height: none;
}

.handsontable col,
.handsontable col.rowHeader {
  width: 5rem;
}

.handsontable th,
.handsontable td {
  position: relative;
  border-top-width: 0;
  border-left-width: 0;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 1.875rem;
  empty-cells: show;
  padding: 0 0.25rem;
  overflow: hidden;
  outline-width: 0;
  white-space: pre-line;
}

.handsontable td:not([class*="bg-"]) {
  background-color: #fff;
}

.handsontable td.htInvalid {
  background-color: #ffebee !important;
}

.handsontable td.htNoWrap {
  white-space: nowrap;
}

.handsontable td.active {
  background-color: rgba(0, 0, 0, 0.015);
}

.handsontable td.area {
  background-color: #e3f2fd;
}

.handsontable th {
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
}

.handsontable th:not([class*="bg-"]) {
  background-color: #f5f5f5;
}

.handsontable thead th {
  padding: 0;
}

.handsontable thead th .relative {
  padding: 0 0.25rem;
}

.handsontable tbody th.ht__highlight,
.handsontable thead th.ht__highlight {
  background-color: #efefef;
}

.handsontable.ht__selection--columns thead th.ht__highlight,
.handsontable.ht__selection--rows tbody th.ht__highlight {
  background-color: #e3f2fd;
}

.handsontable tr:first-child th.htNoFrame,
.handsontable th:first-child.htNoFrame,
.handsontable th.htNoFrame {
  border-left-width: 0;
  background-color: #fff;
  border-color: #fff;
}

.handsontable th:first-child,
.handsontable th:nth-child(2),
.handsontable td:first-of-type,
.handsontable .htNoFrame + th,
.handsontable .htNoFrame + td {
  border-left: 1px solid #ddd;
}

.handsontable.htRowHeaders thead tr th:nth-child(2) {
  border-left: 1px solid #ddd;
}

.handsontable tr:first-child th,
.handsontable tr:first-child td {
  border-top: 1px solid #ddd;
}

.ht_master:not(.innerBorderLeft):not(.emptyColumns) ~ .handsontable tbody tr th,
.ht_master:not(.innerBorderLeft):not(.emptyColumns)
  ~ .handsontable:not(.ht_clone_top)
  thead
  tr
  th:first-child {
  border-right-width: 0;
}

.ht_master:not(.innerBorderTop) thead tr:last-child th,
.ht_master:not(.innerBorderTop) thead tr.lastChild th,
.ht_master:not(.innerBorderTop) ~ .handsontable thead tr:last-child th,
.ht_master:not(.innerBorderTop) ~ .handsontable thead tr.lastChild th {
  border-bottom-width: 0;
}

.htRowHeaders
  .ht_master.innerBorderLeft
  ~ .ht_clone_top_left_corner
  th:nth-child(2),
.htRowHeaders .ht_master.innerBorderLeft ~ .ht_clone_left td:first-of-type {
  border-left-width: 0;
}

.ht_master tbody tr:first-child td {
  border-top-color: transparent;
}

.ht_clone_top tr:first-child th,
.ht_clone_top tr:first-child td {
  border-top-color: transparent;
}

.ht_clone_left thead tr:first-child th,
.ht_clone_left tbody tr:first-child td,
.ht_clone_left tbody tr:first-child th,
.ht_clone_top_left_corner thead tr:first-child th,
.ht_clone_top_left_corner tbody tr:first-child td,
.ht_clone_top_left_corner tbody tr:first-child th {
  border-top-color: transparent;
}

.handsontable th:first-child,
.handsontable td:first-child {
  border-left-color: transparent;
}

.ht_master td:last-child,
.ht_clone_top th:last-child,
.ht_clone_top td:last-child {
  border-right-color: transparent;
}

.ht_master tbody tr:last-child th,
.ht_master tbody tr:last-child td,
.ht_clone_left tbody tr:last-child th,
.ht_clone_left tbody tr:last-child td {
  border-bottom-color: transparent;
}

.htRowHeaders.htColumnHeaders .ht_clone_top_left_corner tbody tr:first-child th,
.htRowHeaders.htColumnHeaders .ht_clone_left tbody tr:first-child th {
  border-top-color: #ddd;
}

.htColumnHeaders .handsontable tr:first-child td {
  border-top-color: #ddd;
}

.manualColumnResizer.active,
.manualRowResizer.active {
  display: block;
  z-index: 199;
}

.manualColumnResizer:hover,
.manualColumnResizer.active,
.manualRowResizer:hover,
.manualRowResizer.active {
  background-color: rgba(0, 0, 0, 0.015);
}

.manualColumnResizer {
  position: fixed;
  top: 0;
  cursor: col-resize;
  z-index: 110;
  width: 0.3125rem;
  height: 1.875rem;
}

.manualRowResizer {
  position: fixed;
  left: 0;
  cursor: row-resize;
  z-index: 110;
  height: 0.3125rem;
  width: 3.75rem;
}

.manualColumnResizerGuide {
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.015);
  display: none;
  width: 0;
  border-right: 1px dashed #ddd;
  margin-left: 0.3125rem;
}

.manualRowResizerGuide {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.015);
  display: none;
  height: 0;
  border-bottom: 1px dashed #ddd;
  margin-top: 0.3125rem;
}

.handsontable .columnSorting {
  position: relative;
  cursor: pointer;
}

.handsontable .columnSorting:before,
.handsontable .columnSorting:after {
  font-family: "icomoon";
  font-size: 0.75rem;
  position: absolute;
  display: inline-block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -1.625rem;
  line-height: 1;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .handsontable .columnSorting:before,
  .handsontable .columnSorting:after {
    transition: none;
  }
}

.handsontable .columnSorting:before {
  content: "\e9c1";
  margin-top: 0.25rem;
}

.handsontable .columnSorting:after {
  content: "\e9c2";
  margin-top: -0.25rem;
}

.handsontable .columnSorting.ascending:before {
  content: none;
}

.handsontable .columnSorting.ascending:after {
  margin-top: 0;
}

.handsontable .columnSorting.descending:before {
  margin-top: 0;
}

.handsontable .columnSorting.descending:after {
  content: none;
}

.handsontable .columnSorting:hover:before,
.handsontable .columnSorting:hover:after {
  opacity: 1;
}

.wtBorder {
  position: absolute;
  font-size: 0;
}

.wtBorder.hidden {
  display: none !important;
}

.wtBorder.corner {
  font-size: 0;
  cursor: crosshair;
}

.htBorder.htFillBorder {
  background-color: #2196f3;
  width: 1px;
  height: 1px;
}

.handsontableInput {
  border: 0;
  outline-width: 0;
  margin: 0;
  padding: 0 0.3125rem;
  padding-top: 0.0625rem;
  font-family: inherit;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: inherit;
  resize: none;
  display: block;
  width: auto !important;
  background-color: #fff;
  box-shadow: 0 0 0 2px #2196f3 inset;
}

.handsontableInputHolder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.htSelectEditor {
  -webkit-appearance: menulist-button !important;
  position: absolute;
  width: auto;
  outline: 0;
  border-radius: 0;
}

.htSubmenu {
  position: relative;
}

.htSubmenu > div:after {
  content: "\e9c7";
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 1rem;
  font-size: 1rem;
  line-height: 1;
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.handsontable .htLeft {
  text-align: left;
}

.handsontable .htCenter {
  text-align: center;
}

.handsontable .htRight {
  text-align: right;
}

.handsontable .htJustify {
  text-align: justify;
}

.handsontable .htTop {
  vertical-align: top;
}

.handsontable .htMiddle {
  vertical-align: middle;
}

.handsontable .htBottom {
  vertical-align: bottom;
}

.htPlaceholder {
  color: #999;
}

.htAutocompleteArrow {
  cursor: default;
  text-indent: -9999999px;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0.3em;
  margin-top: -0.15em;
  display: inline-block;
  margin-right: 0.3em;
  border-top: 0.3em dashed;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  opacity: 0.75;
}

.handsontable td:hover .htAutocompleteArrow,
.handsontable th:hover .htAutocompleteArrow {
  opacity: 1;
}

.handsontable td.area .htAutocompleteArrow {
  color: #e3f2fd;
}

.htCheckboxRendererInput {
  display: inline-block;
  vertical-align: middle;
}

.htCheckboxRendererInput.noValue {
  opacity: 0.5;
}

.htCheckboxRendererLabel {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.htCheckboxRendererLabel > .htCheckboxRendererInput {
  margin-right: 0.625rem;
}

.handsontable.hide-tween {
  -webkit-animation: opacity-hide 0.3s;
  animation: opacity-hide 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.handsontable.show-tween {
  -webkit-animation: opacity-show 0.3s;
  animation: opacity-show 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes opacity-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes opacity-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.handsontable.listbox {
  margin: 0;
}

.handsontable.listbox .ht_master table {
  border-collapse: separate;
  border: 1px solid #ddd;
  background-color: #fff;
}

.handsontable.listbox th,
.handsontable.listbox tr:first-child th,
.handsontable.listbox tr:last-child th,
.handsontable.listbox tr:first-child td,
.handsontable.listbox td {
  border: 0;
}

.handsontable.listbox tr,
.handsontable.listbox th,
.handsontable.listbox td {
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .handsontable.listbox tr,
  .handsontable.listbox th,
  .handsontable.listbox td {
    transition: none;
  }
}

.handsontable.listbox th,
.handsontable.listbox td {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.handsontable.listbox td.htDimmed {
  cursor: pointer;
  color: inherit;
  font-style: inherit;
}

.handsontable.listbox .wtBorder {
  visibility: hidden;
}

.handsontable.listbox tr td.current,
.handsontable.listbox tr:hover td {
  background-color: #f5f5f5;
}

.changeType {
  border: 0;
  background-color: transparent;
  padding: 0;
  float: right;
  margin-right: 0.25rem;
  line-height: 1rem;
  margin-top: 0.12502rem;
  cursor: pointer;
  opacity: 0.75;
}

.changeType:hover {
  opacity: 1;
}

.changeTypeMenu {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.5rem 0;
  display: none;
  width: 11.25rem;
  z-index: 1000;
}

.changeTypeMenu li {
  list-style: none;
  padding: 0.5rem 1rem;
  padding-right: 2.5rem;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .changeTypeMenu li {
    transition: none;
  }
}

.changeTypeMenu li:hover {
  background-color: #f5f5f5;
}

.changeTypeMenu li.active:after {
  content: "\e600";
  font-family: "icomoon";
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -0.5rem;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ht_clone_top {
  z-index: 101;
}

.ht_clone_left {
  z-index: 102;
}

.ht_clone_top_left_corner,
.ht_clone_bottom_left_corner {
  z-index: 103;
}

.ht_clone_debug {
  z-index: 103;
}

.handsontable td.htSearchResult {
  background-color: #fff3e0;
}

.htBordered {
  border-width: 1px;
}

.htBordered.htTopBorderSolid {
  border-top-style: solid;
  border-top-color: #ddd;
}

.htBordered.htRightBorderSolid {
  border-right-style: solid;
  border-right-color: #ddd;
}

.htBordered.htBottomBorderSolid {
  border-bottom-style: solid;
  border-bottom-color: #ddd;
}

.htBordered.htLeftBorderSolid {
  border-left-style: solid;
  border-left-color: #ddd;
}

.handsontable thead tr:nth-last-child(2) th.htGroupIndicatorContainer {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.3125rem;
}

.handsontable tbody tr th:nth-last-child(2),
.ht_clone_top_left_corner thead tr th:nth-last-child(2) {
  border-right: 1px solid #ddd;
}

.htCollapseButton {
  line-height: 0.625rem;
  text-align: center;
  border: 0;
  cursor: pointer;
  margin-bottom: 0.1875rem;
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.1875rem;
}

.htCollapseButton:after {
  content: "";
  height: 300%;
  width: 1px;
  display: block;
  background-color: #ccc;
  margin-left: 0.25rem;
  position: absolute;
  bottom: 0.625rem;
}

thead .htCollapseButton {
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;
  background-color: #fff;
}

thead .htCollapseButton:after {
  height: 1px;
  width: 700%;
  right: 0.625rem;
  top: 0.25rem;
}

.handsontable th .htExpandButton {
  position: absolute;
  line-height: 0.625rem;
  text-align: center;
  border: 0;
  cursor: pointer;
  top: 0;
  display: none;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.1875rem;
}

.handsontable th .htExpandButton.clickable {
  display: block;
}

.handsontable thead th .htExpandButton {
  top: 0.3125rem;
}

.collapsibleIndicator {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  right: 0.3125rem;
  border: 0;
  line-height: 0.625rem;
  color: #333;
  font-size: 0.8125rem;
  cursor: pointer;
  background-color: #eee;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100px;
}

.handsontable col.hidden {
  width: 0 !important;
}

.handsontable table tr th.lightRightBorder {
  border-right: 1px solid white;
}

.handsontable tr.hidden,
.handsontable tr.hidden td,
.handsontable tr.hidden th {
  display: none;
}

.ht_master,
.ht_clone_left,
.ht_clone_top,
.ht_clone_bottom {
  overflow: hidden;
}

.ht_master .wtHolder {
  overflow: auto;
}

.ht_clone_left .wtHolder {
  overflow-x: hidden;
  overflow-y: auto;
}

.ht_clone_top .wtHolder,
.ht_clone_bottom .wtHolder {
  overflow-x: hidden;
  overflow-y: hidden;
}

.wtDebugHidden {
  display: none;
}

.wtDebugVisible {
  display: block;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: wtFadeInFromNone;
  animation-name: wtFadeInFromNone;
}

@keyframes wtFadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-webkit-keyframes wtFadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.handsontable.mobile,
.handsontable.mobile .wtHolder {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.htMobileEditorContainer {
  display: none;
  position: absolute;
  top: 0;
  width: 70%;
  height: 3.375rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  z-index: 999;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  border-radius: 100px;
}

.htMobileEditorContainer.active {
  display: block;
}

.htMobileEditorContainer .inputs {
  position: absolute;
  right: 13.13rem;
  bottom: 0.625rem;
  top: 0.625rem;
  left: 0.875rem;
  height: 2.25003rem;
}

.htMobileEditorContainer .inputs textarea {
  font-size: 0.8125rem;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  position: absolute;
  left: 0.875rem;
  right: 0.875rem;
  top: 0;
  bottom: 0;
  padding: 0.4375rem;
  box-shadow: none;
}

.htMobileEditorContainer .cellPointer {
  position: absolute;
  top: -0.8125rem;
  height: 0;
  width: 0;
  left: 1.875rem;
  border-left: 0.8125rem solid transparent;
  border-right: 0.8125rem solid transparent;
  border-bottom: 0.8125rem solid #eee;
}

.htMobileEditorContainer .cellPointer.hidden {
  display: none;
}

.htMobileEditorContainer .cellPointer:before {
  content: "";
  display: block;
  position: absolute;
  top: 0.125rem;
  height: 0;
  width: 0;
  left: -0.8125rem;
  border-left: 0.8125rem solid transparent;
  border-right: 0.8125rem solid transparent;
  border-bottom: 0.8125rem solid #f5f5f5;
}

.htMobileEditorContainer .moveHandle {
  position: absolute;
  top: 0.625rem;
  left: 0.3125rem;
  width: 1.875rem;
  bottom: 0px;
  cursor: move;
  z-index: 9999;
}

.htMobileEditorContainer .moveHandle:after {
  content: "..\a..\a..\a..";
  white-space: pre;
  line-height: 0.625rem;
  font-size: 1.25rem;
  display: inline-block;
  margin-top: -0.5rem;
  color: #eee;
}

.htMobileEditorContainer .positionControls {
  width: 12.81rem;
  position: absolute;
  right: 0.3125rem;
  top: 0;
  bottom: 0;
}

.htMobileEditorContainer .positionControls > div {
  width: 3.125rem;
  height: 100%;
  float: left;
}

.htMobileEditorContainer .positionControls > div:after {
  content: " ";
  display: block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 3.125rem;
}

.htMobileEditorContainer .leftButton:after,
.htMobileEditorContainer .rightButton:after,
.htMobileEditorContainer .upButton:after,
.htMobileEditorContainer .downButton:after {
  margin: 1.313rem 0 0 1.313rem;
  -webkit-transform-origin: 0.3125rem;
  transform-origin: 0.3125rem;
}

.htMobileEditorContainer .leftButton:after {
  border-top: 0.125rem solid #2196f3;
  border-left: 0.125rem solid #2196f3;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.htMobileEditorContainer .leftButton:active:after {
  border-color: #ddd;
}

.htMobileEditorContainer .rightButton:after {
  border-top: 0.125rem solid #2196f3;
  border-left: 0.125rem solid #2196f3;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.htMobileEditorContainer .rightButton:active:after {
  border-color: #ddd;
}

.htMobileEditorContainer .upButton:after {
  border-top: 0.125rem solid #2196f3;
  border-left: 0.125rem solid #2196f3;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.htMobileEditorContainer .upButton:active:after {
  border-color: #ddd;
}

.htMobileEditorContainer .downButton:after {
  border-top: 0.125rem solid #2196f3;
  border-left: 0.125rem solid #2196f3;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.htMobileEditorContainer .downButton:active:after {
  border-color: #ddd;
}

.topLeftSelectionHandle:not(.ht_master .topLeftSelectionHandle),
.topLeftSelectionHandle-HitArea:not(.ht_master
    .topLeftSelectionHandle-HitArea) {
  z-index: 9999;
}

.topLeftSelectionHandle,
.topLeftSelectionHandle-HitArea,
.bottomRightSelectionHandle,
.bottomRightSelectionHandle-HitArea {
  left: -10000px;
  top: -10000px;
}

.htCommentCell {
  position: relative;
}

.htCommentCell:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-left: 0.375rem solid transparent;
  border-top: 0.375rem solid red;
}

.htComments {
  display: none;
  z-index: 1070;
  position: absolute;
}

.htCommentTextArea {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0.4375rem 0.875rem;
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.htCommentTextArea:focus {
  outline: 0;
}

.htContextMenu {
  display: none;
  position: absolute;
  z-index: 1051;
}

.htContextMenu .ht_clone_top,
.htContextMenu .ht_clone_left,
.htContextMenu .ht_clone_corner,
.htContextMenu .ht_clone_debug {
  display: none;
}

.htContextMenu table.htCore {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.5rem 0;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.htContextMenu .wtBorder {
  visibility: hidden;
}

.htContextMenu table tbody tr td {
  position: relative;
  border-width: 0;
  padding: 0.25rem 1rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .htContextMenu table tbody tr td {
    transition: none;
  }
}

.htContextMenu table tbody tr td:first-child {
  border: 0;
}

.htContextMenu table tbody tr td.htDimmed {
  color: #333;
}

.htContextMenu table tbody tr td.current,
.htContextMenu table tbody tr td.zeroclipboard-is-hover {
  background-color: #f5f5f5;
}

.htContextMenu table tbody tr td.htSeparator {
  height: 0;
  padding: 0;
  background-color: transparent;
  cursor: default;
}

.htContextMenu table tbody tr td.htSeparator > div {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0.5rem 0;
}

.htContextMenu table tbody tr td.htDisabled {
  color: #999;
}

.htContextMenu table tbody tr td.htDisabled,
.htContextMenu table tbody tr td.htDisabled:hover {
  cursor: default;
}

.htContextMenu table tbody tr td div span.selected {
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -0.5rem;
  font-size: 0;
  color: #777;
}

.htContextMenu table tbody tr td div span.selected:after {
  content: "\e600";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.htContextMenu table tbody tr.htHidden {
  display: none;
}

.htContextMenu .ht_master .wtHolder {
  overflow: hidden;
}

.htContextMenuSub_Alignment {
  margin-top: -0.5rem;
  margin-left: -1px;
}

.global-zeroclipboard-container,
.global-zeroclipboard-flash-bridge {
  cursor: pointer;
}

textarea#HandsontableCopyPaste {
  position: fixed !important;
  top: 0 !important;
  right: 100% !important;
  overflow: hidden;
  opacity: 0;
  outline: 0 none !important;
}

.ht__manualColumnMove.after-selection--columns thead th.ht__highlight,
.ht__manualColumnMove.on-moving--columns,
.ht__manualColumnMove.on-moving--columns thead th.ht__highlight,
.ht__manualRowMove.after-selection--rows tbody th.ht__highlight,
.ht__manualRowMove.on-moving--rows,
.ht__manualRowMove.on-moving--rows tbody th.ht__highlight {
  cursor: move;
}

.ht__manualColumnMove.on-moving--columns .manualColumnResizer,
.ht__manualRowMove.on-moving--rows .manualRowResizer {
  display: none;
}

.ht__manualColumnMove--guideline,
.ht__manualColumnMove--backlight,
.ht__manualRowMove--guideline,
.ht__manualRowMove--backlight {
  position: absolute;
  height: 100%;
  display: none;
}

.ht__manualColumnMove--guideline,
.ht__manualRowMove--guideline {
  background-color: rgba(0, 0, 0, 0.015);
  z-index: 105;
}

.ht__manualColumnMove--guideline {
  width: 2px;
  margin-left: -1px;
  top: 0;
}

.ht__manualRowMove--guideline {
  height: 2px;
  margin-top: -1px;
  left: 0;
}

.ht__manualColumnMove--backlight,
.ht__manualRowMove--backlight {
  background-color: rgba(0, 0, 0, 0.015);
  display: none;
  z-index: 105;
  pointer-events: none;
}

.on-moving--columns.show-ui .ht__manualColumnMove--guideline,
.on-moving--columns .ht__manualColumnMove--backlight,
.on-moving--rows.show-ui .ht__manualRowMove--guideline,
.on-moving--rows .ht__manualRowMove--backlight {
  display: block;
}

.pika-single {
  z-index: 1000;
  display: block;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px;
  margin-left: -1px;
  background-clip: padding-box;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.pika-single::after {
  display: block;
  clear: both;
  content: "";
}

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
}

.pika-lendar {
  float: left;
  max-width: 15.63rem;
  margin: 0.9375rem;
}

.pika-title {
  position: relative;
  text-align: center;
}

.pika-title select {
  cursor: pointer;
  position: absolute;
  z-index: 999;
  margin: 0;
  left: 0;
  height: 2.25003rem;
  padding: 0.4375rem 0.875rem;
  border: 1px solid #ddd;
  opacity: 0;
}

.pika-label {
  display: inline-block;
  position: relative;
  z-index: 1000;
  overflow: hidden;
  margin: 0;
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
  font-size: 0.9375rem;
  line-height: 1;
}

.pika-label + .pika-label {
  margin-left: 0.46875rem;
  top: -0.0625rem;
  font-size: 0.8125rem;
  color: #999;
}

.pika-prev,
.pika-next {
  display: block;
  position: relative;
  border: 0;
  padding: 0.4375rem;
  font-size: 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .pika-prev,
  .pika-next {
    transition: none;
  }
}

.pika-prev:after,
.pika-next:after {
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pika-prev:hover,
.pika-next:hover {
  color: #333;
  background-color: #f5f5f5;
}

.pika-prev.is-disabled,
.pika-next.is-disabled {
  cursor: default;
  opacity: 0.2;
}

.pika-prev {
  float: left;
}

.pika-prev:after {
  content: "\e9c8";
}

.pika-next {
  float: right;
}

.pika-next:after {
  content: "\e9cb";
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  table-layout: fixed;
}

.pika-table th,
.pika-table td {
  position: relative;
}

.pika-table th {
  text-align: center;
  font-weight: 400;
  padding-top: 0.9375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.75rem;
  color: #999;
}

.pika-table abbr {
  border-bottom: 0;
  cursor: default;
  text-decoration: none;
}

.pika-button {
  display: block;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 0.4375rem;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .pika-button {
    transition: none;
  }
}

.pika-button:hover {
  color: #333;
  background-color: #f5f5f5;
}

.is-today .pika-button {
  color: #333;
  background-color: #f5f5f5;
}

.is-selected .pika-button {
  color: #fff;
  background-color: #26a69a;
}

.is-today .pika-button:before,
.is-selected .pika-button:before,
.is-today.is-selected .pika-button:before {
  content: "";
  position: absolute;
  top: 0.14583rem;
  right: 0.14583rem;
  border-top: 0.4375rem solid #fff;
  border-left: 0.4375rem solid transparent;
  width: 0;
  height: 0;
}

.is-startrange .pika-button,
.is-endrange .pika-button {
  color: #fff;
  background-color: #26a69a;
}

.is-inrange .pika-button {
  color: #333;
  background-color: #f5f5f5;
}

.is-disabled .pika-button {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.pika-select {
  display: inline-block;
}

.pika-week {
  font-size: 0.75rem;
  color: #999;
}

/*rtl:end:ignore*/
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
}

.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}

.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}

.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 2.5rem;
}

.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: "";
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  right: 1.25rem;
  font-size: 0.75rem;
  margin-top: -0.375rem;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dataTable thead .sorting:before {
  content: "\e9c1";
  margin-top: -0.125rem;
  opacity: 0.5;
}

.dataTable thead .sorting:after {
  content: "\e9c2";
  margin-top: -0.625rem;
  opacity: 0.5;
}

.dataTable thead .sorting_asc:after {
  content: "\e9c2";
  opacity: 1;
}

.dataTable thead .sorting_desc:after {
  content: "\e9c1";
  opacity: 1;
}

.dataTable thead .sorting_asc_disabled:after {
  content: "\e9c2";
  opacity: 0.25;
}

.dataTable thead .sorting_desc_disabled:after {
  content: "\e9c1";
  opacity: 0.25;
}

.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > td {
  border-top: 0;
}

.dataTable tbody th,
.dataTable tbody td {
  outline: 0;
}

.dataTable tbody th.active,
.dataTable tbody td.active {
  background-color: rgba(0, 0, 0, 0.03);
}

.dataTable .dataTables_empty {
  text-align: center;
}

.dataTable.table-bordered {
  border-collapse: collapse;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
}

.dataTables_wrapper::after {
  display: block;
  clear: both;
  content: "";
}

.dataTables_wrapper .table-bordered {
  border-top: 0;
}

.dataTables_wrapper + .card-body {
  border-top: 1px solid #ddd;
}

.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 0.9) 75%,
    rgba(255, 255, 255, 0) 100%
  );
}

.datatable-header,
.datatable-footer {
  padding: 1.25rem 1.25rem 0 1.25rem;
}

.datatable-header::after,
.datatable-footer::after {
  display: block;
  clear: both;
  content: "";
}

.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}

.card-body .datatable-header,
.card-body .datatable-footer {
  padding-left: 0;
  padding-right: 0;
}

.datatable-header-accent,
.datatable-footer-accent {
  background-color: rgba(0, 0, 0, 0.02);
}

.datatable-header {
  border-bottom: 1px solid #ddd;
}

.datatable-footer {
  border-top: 1px solid #b7b7b7;
}

.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 1.25rem 1.25rem;
}

.dataTables_length > label {
  margin-bottom: 0;
}

.dataTables_length > label > span:first-child {
  float: left;
  margin: 0.5rem 0.83333rem;
  margin-left: 0;
}

.dataTables_length select {
  height: 2.25003rem;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  outline: 0;
}

.length-left .dataTables_length {
  float: left;
}

.dataTables_length .select2-container {
  width: auto;
}

.dataTables_length .select2-choice {
  min-width: 3.75rem;
}

.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 1.25rem 1.25rem;
}

.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}

.dataTables_filter > label:after {
  content: "\e98e";
  font-family: "icomoon";
  font-size: 0.75rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  margin-top: -0.375rem;
  line-height: 1;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dataTables_filter > label > span {
  float: left;
  margin: 0.5rem 0.83333rem;
  margin-left: 0;
}

.dataTables_filter input {
  outline: 0;
  width: 12.5rem;
  padding: 0.4375rem 0.875rem;
  padding-right: 2rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
}

.dataTables_filter input::-webkit-input-placeholder {
  color: #989898;
  opacity: 1;
}

.dataTables_filter input::-moz-placeholder {
  color: #989898;
  opacity: 1;
}

.dataTables_filter input:-ms-input-placeholder {
  color: #989898;
  opacity: 1;
}

.dataTables_filter input::-ms-input-placeholder {
  color: #989898;
  opacity: 1;
}

.dataTables_filter input::placeholder {
  color: #989898;
  opacity: 1;
}

.filter-right .dataTables_filter {
  float: right;
}

.dataTables_info {
  float: left;
  padding: 0.4375rem 0;
  margin-bottom: 1.25rem;
}

.info-right .dataTables_info {
  float: right;
}

.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 1.25rem 1.25rem;
}

.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 0.4375rem;
  min-width: 2.25003rem;
  margin-left: 0.125rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dataTables_paginate .paginate_button {
    transition: none;
  }
}

.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}

.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}

.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #37474f;
}

.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: #fff;
  color: #999;
}

.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.dataTables_paginate.paging_simple .paginate_button:not(.disabled):hover,
.dataTables_paginate.paging_simple .paginate_button:not(.disabled):focus {
  color: #fff;
  background-color: #37474f;
}

.paginate-left .dataTables_paginate {
  float: left;
}

.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #2196f3;
}

.dataTables_scroll {
  clear: both;
}

.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}

.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}

.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}

.dataTables_scroll
  .dataTables_scrollBody
  table
  thead
  th[class*="sorting"]:before,
.dataTables_scroll
  .dataTables_scrollBody
  table
  thead
  th[class*="sorting"]:after {
  content: none;
}

.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}

.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}

.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.card-body + .dataTables_wrapper,
.card-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd;
}

.card-body > .dataTables_wrapper .datatable-footer {
  border-top: 0;
}

.card-body > .dataTables_wrapper .datatable-footer .dataTables_length,
.card-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
.card-body > .dataTables_wrapper .datatable-footer .dataTables_info,
.card-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}

.card-header:not([class*="bg-"]) + .dataTables_wrapper > .datatable-header {
  padding-top: 0;
}

.card > .dataTables_wrapper .table-bordered {
  border: 0;
}

.card > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.card > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.card > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.card > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.card > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.card > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}

.card > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.card > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.card > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.card > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.card > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.card > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}

.card > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.card > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}

.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: 0.01%;
}

.datatable-scroll-wrap {
  width: 100%;
  min-height: 0.01%;
  overflow-x: auto;
}

@media (max-width: 767.98px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }

  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}

@media (max-width: 991.98px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }

  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}

@media (max-width: 1199.98px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }

  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}

@media (max-width: 767.98px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none !important;
    text-align: center;
    margin-left: 0;
  }

  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }

  .datatable-header {
    text-align: center;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Columns reorder
 *
 *  Easily modify the column order of a table through drop-and-drag of column headers
 *
 * ---------------------------------------------------------------------------- */
.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 202;
  cursor: move;
}

.DTCR_clonedTable th,
.DTCR_clonedTable td {
  border: 1px solid #ddd !important;
}

.DTCR_pointer {
  width: 1px;
  background-color: #2196f3;
  z-index: 201;
}

/* ------------------------------------------------------------------------------
 *
 *  # Row Reorder extension
 *
 *  RowReorder adds the ability for rows in a DataTable to be reordered through
 *  user interaction with the table.
 *
 * ---------------------------------------------------------------------------- */
.dt-rowReorder-float {
  position: absolute !important;
  table-layout: static;
  outline: 1px dashed #777;
  outline-offset: -1px;
  background-color: #fff;
  z-index: 1030;
  cursor: move;
  opacity: 0.9;
}

.dt-rowReorder-moving {
  outline: 1px solid #777;
  outline-offset: -1px;
}

.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

/* ------------------------------------------------------------------------------
 *
 *  # Fixed columns
 *
 *  Extension that "freezes" in place the left most columns in a scrolling DataTable
 *
 * ---------------------------------------------------------------------------- */
.DTFC_ScrollWrapper thead th {
  white-space: nowrap;
}

.DTFC_Cloned {
  background-color: #fff;
  border-bottom: 0;
}

.DTFC_LeftWrapper .DTFC_LeftHeadWrapper,
.DTFC_LeftWrapper .DTFC_LeftBodyWrapper,
.DTFC_LeftWrapper .DTFC_LeftFootWrapper {
  border-right: 1px solid #ddd;
}

.DTFC_RightWrapper .DTFC_RightHeadWrapper,
.DTFC_RightWrapper .DTFC_RightBodyWrapper,
.DTFC_RightWrapper .DTFC_RightFootWrapper {
  border-left: 1px solid #ddd;
}

.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_LeftBodyWrapper .DTFC_Cloned thead th:after,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:before,
.DTFC_RightBodyWrapper .DTFC_Cloned thead th:after {
  content: none;
}

.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_LeftBodyWrapper .DTFC_Cloned tbody > tr:first-child > th,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > td,
.DTFC_RightBodyWrapper .DTFC_Cloned tbody > tr:first-child > th {
  border-top: 0;
}

.DTFC_Blocker {
  background-color: white;
}

/* ------------------------------------------------------------------------------
 *
 *  # Fixed Header extension
 *
 *  This extension lets your users quickly determine what each column refers to
 *  rather than needing to scroll back to the top of the table.
 *
 * ---------------------------------------------------------------------------- */
.fixedHeader-floating,
.fixedHeader-locked {
  background-color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}

.fixedHeader-floating {
  position: fixed;
}

.fixedHeader-floating + .fixedHeader-floating,
.fixedHeader-locked + .fixedHeader-floating {
  border-top: 0 !important;
}

@media (max-width: 767.98px) {
  .fixedHeader-floating {
    display: none;
  }
}

.fixedHeader-locked {
  position: absolute;
}

@media (max-width: 767.98px) {
  .fixedHeader-locked {
    display: none;
  }
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Autofill extension
 *
 *  Spreadsheets such as Excel and Google Docs have a very handy data duplication
 *  option of an auto fill tool
 *
 * ---------------------------------------------------------------------------- */
.dt-autofill-handle {
  position: absolute;
  z-index: 102;
  border: 1px solid #2196f3;
  background-color: #2196f3;
  width: 0.5rem;
  height: 0.5rem;
}

.dt-autofill-select {
  position: absolute;
  z-index: 1001;
  background-color: #2196f3;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 0.3125rem,
    rgba(255, 255, 255, 0.75) 0.3125rem,
    rgba(255, 255, 255, 0.75) 0.625rem
  );
}

.dt-autofill-select.top,
.dt-autofill-select.bottom {
  height: 1px;
}

.dt-autofill-select.left,
.dt-autofill-select.right {
  width: 1px;
}

.dt-autofill-list {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 31.25rem;
  margin-left: -15.63rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1050;
  padding: 0.5rem 0;
  border-radius: 0.1875rem;
}

.dt-autofill-list ul {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.dt-autofill-list ul li {
  display: table-row;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dt-autofill-list ul li {
    transition: none;
  }
}

.dt-autofill-list ul li:hover {
  color: #333;
  background-color: #f5f5f5;
}

.dt-autofill-list .dt-autofill-question {
  display: table-cell;
  padding: 0.5rem 1rem;
}

.dt-autofill-list .dt-autofill-question input[type="number"] {
  padding: 0.375rem;
  width: 1.875rem;
  margin: -0.125rem 0;
}

.dt-autofill-list .dt-autofill-button {
  display: table-cell;
  padding: 0.5rem 1rem;
  text-align: right;
}

.dt-autofill-list .dt-autofill-button .btn {
  padding: 0.1875rem;
  background-color: #2196f3;
  color: #fff;
  font-size: 0;
}

.dt-autofill-list .dt-autofill-button .btn:after {
  content: "\e9c3";
  font-family: "icomoon";
  display: block;
  font-size: 1rem;
  width: 1rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dt-autofill-list .dt-autofill-button .btn:hover {
  opacity: 0.85;
}

.dt-autofill-background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 1040;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------------------------------------
 *
 *  # Select extension
 *
 *  Adds item selection capabilities to a DataTable
 *
 * ---------------------------------------------------------------------------- */
.dataTable tbody > tr.selected,
.dataTable tbody > tr > .selected {
  background-color: #e8f5e9;
}

.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
  margin-left: 0.625rem;
}

@media (max-width: 767.98px) {
  .dataTables_wrapper .select-info,
  .dataTables_wrapper .select-item {
    margin-left: 0;
    display: block;
  }
}

.dataTable tbody .select-checkbox {
  position: relative;
}

.dataTable tbody .select-checkbox:before,
.dataTable tbody .select-checkbox:after {
  display: inline-block;
  color: #455a64;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}

.dataTable tbody .select-checkbox:before {
  content: "";
  background-color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
  border: 0.125rem solid #455a64;
  border-radius: 0.125rem;
}

.dataTable tbody .selected .select-checkbox:after {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------------------------------
 *
 *  # Buttons extension
 *
 *  The Buttons extension for DataTables provides a common set of options, API
 *  methods and styling to display buttons that will interact with a DataTable
 *
 * ---------------------------------------------------------------------------- */
.dt-buttons-full .dt-buttons {
  float: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  border-bottom: 1px solid #ddd;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
  background-color: rgba(0, 0, 0, 0.02);
}

.dt-buttons-full .dt-buttons > .btn {
  margin-bottom: 0.625rem;
  float: none;
}

.dt-buttons {
  position: relative;
  float: right;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 1.25rem 1.25rem;
}

.dt-buttons-left .dt-buttons {
  float: left;
}

.dt-buttons > .btn {
  border-radius: 0;
}

.dt-buttons > .btn:first-child {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.dt-buttons > .btn:last-of-type {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.dt-buttons > .btn + .btn {
  margin-left: -1px;
}

@media (max-width: 767.98px) {
  .dt-buttons {
    float: none;
    text-align: center;
    display: block;
  }

  .dt-buttons .btn {
    float: none;
  }
}

.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 1040;
  opacity: 0.5;
}

.dt-button-collection {
  position: absolute;
  background-color: #fff;
  display: block;
  z-index: 1050;
  padding: 0.5rem 0;
  overflow: hidden;
  min-width: 11.25rem;
  -webkit-column-gap: 0.125rem;
  -moz-column-gap: 0.125rem;
  column-gap: 0.125rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.dt-button-collection:not(.fixed) {
  top: 100% !important;
  left: auto !important;
  right: 0 !important;
}

.dt-button-collection > .dt-button {
  padding: 0.5rem 1rem;
  color: #333;
  background-color: transparent;
  border: 0;
  display: block;
  outline: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .dt-button-collection > .dt-button {
    transition: none;
  }
}

.dt-button-collection > .dt-button + .dt-button {
  margin-top: 1px;
}

.dt-button-collection > .dt-button:hover,
.dt-button-collection > .dt-button:focus {
  color: #333;
  background-color: #f5f5f5;
}

.dt-button-collection > .dt-button.active {
  color: #fff;
  background-color: #2196f3;
}

.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -4.6875rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.dt-button-collection.fixed.two-column {
  margin-left: -9.375rem;
}

.dt-button-collection.fixed.three-column {
  margin-left: -14.065rem;
}

.dt-button-collection.fixed.four-column {
  margin-left: -18.75rem;
}

.dt-button-collection > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

.dt-button-collection.two-column {
  width: 18.75rem;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.dt-button-collection.three-column {
  width: 28.13rem;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

.dt-button-collection.four-column {
  width: 37.5rem;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}

/* ------------------------------------------------------------------------------
 *
 *  # Key Table extension
 *
 *  KeyTable provides Excel like cell navigation on any table. Events (focus, blur,
 *  action etc) can be assigned to individual cells, columns, rows or all cells.
 *
 * ---------------------------------------------------------------------------- */
.dataTable th.focus,
.dataTable td.focus {
  outline: 2px solid #2196f3;
  outline-offset: -1px;
}

.dataTable th.focus-success,
.dataTable td.focus-success {
  background-color: #e8f5e9;
  outline-color: #4caf50;
}

.dataTable th.focus-info,
.dataTable td.focus-info {
  background-color: #e3f2fd;
  outline-color: #2196f3;
}

.dataTable th.focus-warning,
.dataTable td.focus-warning {
  background-color: #fbe9e7;
  outline-color: #ff5722;
}

.dataTable th.focus-danger,
.dataTable td.focus-danger {
  background-color: #ffebee;
  outline-color: #f44336;
}

/* ------------------------------------------------------------------------------
 *
 *  # Datatables Scroller
 *
 *  Drawing the rows required for the current display only, for fast operation
 *
 * ---------------------------------------------------------------------------- */
.DTS tbody th,
.DTS tbody td {
  white-space: nowrap;
}

.DTS .DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12.5rem;
  height: 1.25rem;
  margin-top: -1.25rem;
  margin-left: -6.25rem;
  z-index: 1;
  border: 1px solid #ddd;
  padding: 1.25rem 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.DTS .dataTables_scrollHead,
.DTS .dataTables_scrollFoot {
  background-color: #fff;
}

.DTS .dataTables_scrollBody {
  z-index: 2;
}

/* ------------------------------------------------------------------------------
 *
 *  # Responsive extension
 *
 *  Optimise the table's layout for different screen sizes through the dynamic
 *  insertion and removal of columns from the table
 *
 * ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 0.625rem;
}

.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
  display: none;
}

.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}

.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: "\e9e4";
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  width: 1rem;
  line-height: 1;
  position: relative;
  top: -0.09375rem;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: "\e9e7";
}

.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}

.dataTable tr.child:hover {
  background-color: transparent;
}

.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 2.5rem;
}

.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 0.5rem 0;
}

.dataTable tr td.child {
  white-space: normal;
  position: relative;
}

.dataTable tr td.child > ul {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dataTable tr td.child > ul > li {
  display: table-row;
}

/* ------------------------------------------------------------------------------
*
*  # Google Maps overrides
*
*  Override Google Maps default styles
*
* ---------------------------------------------------------------------------- */
.map-container {
  height: 500px;
}

.gm-style {
  font-size: 0.8125rem;
}

.gm-style .gm-style-iw {
  font-size: 0.8125rem;
  font-weight: 400;
}

/* ------------------------------------------------------------------------------
*
*  # Vector maps
*
*  Vector map container, tooltip and zooming
*
* ---------------------------------------------------------------------------- */
.vector-map-container {
  height: 500px;
}

.jvectormap-label {
  position: absolute;
  display: none;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.1875rem;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  left: 1.25rem;
  padding: 0.5rem;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 300;
  line-height: 0.625rem;
  background-color: #455a64;
  text-align: center;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    transition: none;
  }
}

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  background-color: #37474f;
}

.jvectormap-zoomin {
  top: 0.625rem;
}

.jvectormap-zoomout {
  top: 2.5rem;
}

/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
* ---------------------------------------------------------------------------- */
.chart-container {
  position: relative;
  width: 100%;
}

.chart-container.has-scroll {
  overflow-x: scroll;
  overflow-y: visible;
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .chart-container {
    overflow-x: scroll;
    overflow-y: visible;
    max-width: 100%;
  }
}

.chart {
  position: relative;
  display: block;
  width: 100%;
}

.chart.has-minimum-width {
  min-width: 37.5rem;
}

.has-fixed-height {
  height: 400px;
}

.chart-pie {
  width: 100%;
  height: 400px;
  min-width: 31.25rem;
}

/* ------------------------------------------------------------------------------
*
*  # C3 charts
*
*  Styles for C3.js visualization library
*
* ---------------------------------------------------------------------------- */
.c3 svg {
  font-size: 0.75rem;
}

.c3 path,
.c3 line {
  fill: none;
  stroke: #999;
}

.c3 path.domain {
  shape-rendering: crispEdges;
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 0.8125rem;
}

.c3-grid line {
  stroke: #ccc;
}

.c3-grid text {
  fill: #ccc;
}

.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}

.c3-text {
  font-weight: 500;
}

.c3-text.c3-empty {
  fill: #777;
  font-size: 2em;
}

.c3-line {
  stroke-width: 2px;
}

.c3-area {
  stroke-width: 0;
  opacity: 0.4;
}

.c3-circle._expanded_ {
  stroke-width: 1.5px;
  stroke: #fff;
}

.c3-selected-circle {
  fill: #fff;
  stroke-width: 2px;
}

.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 0.75;
}

.c3-chart-arcs-title {
  font-size: 0.9375rem;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #eee;
  stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #333;
  font-size: 0.9375rem;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max,
.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #f5f5f5;
}

.c3-chart-arc .c3-gauge-value {
  fill: #333;
  font-size: 1.5625rem;
}

.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

.c3-region {
  fill: #546e7a;
  fill-opacity: 0.1;
}

.c3-brush .extent {
  fill-opacity: 0.1;
}

.c3-legend-item {
  font-size: 0.75rem;
}

.c3-legend-background {
  fill: #fff;
  stroke: lightgray;
  stroke-width: 1;
  opacity: 0.75;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  opacity: 0.95;
}

.c3-tooltip th {
  background-color: #333;
  padding: 0.5rem 1.25rem;
  text-align: center;
  color: #fff;
  border: 1px solid #333;
  font-weight: 500;
}

.c3-tooltip td {
  padding: 0.5rem 1.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
}

.c3-tooltip td > span {
  display: inline-block;
  margin-right: 0.625rem;
  width: 0.5rem;
  height: 0.5rem;
}

.c3-tooltip td.value {
  text-align: right;
}

/* ------------------------------------------------------------------------------
*
*  # D3.js library
*
*  Basic styles for D3.js visualization library
*
* ---------------------------------------------------------------------------- */
.d3-axis path {
  fill: none;
  stroke: #999;
  shape-rendering: crispEdges;
}

.d3-axis line {
  stroke: #f5f5f5;
  shape-rendering: crispEdges;
}

.d3-axis .tick,
.d3-axis .tick text {
  font-size: 0.75rem;
  fill: #333;
}

.d3-axis-solid path,
.d3-axis-solid line {
  stroke: #ccc;
}

.d3-axis-solid .tick {
  fill: #999;
}

.d3-axis-solid .tick text {
  fill: #999;
}

.d3-axis-strong path,
.d3-axis-strong line {
  stroke: #ccc;
}

.d3-axis-strong .tick {
  fill: #333;
}

.d3-axis-strong .tick text {
  fill: #333;
}

.d3-axis-transparent path {
  stroke: none;
}

.d3-axis-transparent line {
  stroke: #eee;
}

.d3-axis-transparent .tick {
  fill: #999;
}

.d3-axis-transparent .tick text {
  fill: #999;
}

.d3-tip {
  position: absolute;
  padding: 0.5rem 0.75rem;
  background-color: #000;
  color: #fff;
  margin-bottom: -0.25rem;
  font-size: 0.8125rem;
  z-index: 1070;
  border-radius: 0.1875rem;
}

.d3-tip .d3-tip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.d3-tip.n .d3-tip-arrow {
  bottom: -0.25rem;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem 0.25rem 0;
  border-top-color: #000;
}

.d3-tip.e .d3-tip-arrow {
  top: 50%;
  margin-top: -0.25rem;
  /*rtl:begin:ignore*/
  left: -0.25rem;
  border-width: 0.25rem 0.25rem 0.25rem 0;
  border-right-color: #000;
  /*rtl:end:ignore*/
}

.d3-tip.w .d3-tip-arrow {
  top: 50%;
  margin-top: -0.25rem;
  /*rtl:begin:ignore*/
  right: -0.25rem;
  border-width: 0.25rem 0 0.25rem 0.25rem;
  border-left-color: #000;
  /*rtl:end:ignore*/
}

.d3-tip.s .d3-tip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0 0.25rem 0.25rem;
  border-bottom-color: #000;
}

.venntooltip {
  position: absolute;
  text-align: center;
  white-space: nowrap;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0.75rem;
  display: none;
  border-radius: 0.1875rem;
}

.d3-line {
  fill: none;
}

.d3-line-thin {
  stroke-width: 1px;
}

.d3-line-medium {
  stroke-width: 1.5px;
}

.d3-line-strong {
  stroke-width: 2px;
}

.d3-line-circle {
  fill: none;
  cursor: pointer;
}

.d3-line-circle-thin {
  stroke-width: 1px;
}

.d3-line-circle-medium {
  stroke-width: 1.5px;
}

.d3-line-circle-strong {
  stroke-width: 2px;
}

.d3-grid .tick line {
  stroke-width: 1px;
  stroke: #eee;
  shape-rendering: crispEdges;
}

.d3-grid .tick text {
  fill: #333;
  stroke: none;
}

.d3-grid-dashed .tick {
  stroke-dasharray: 4, 2;
  stroke-width: 1px;
  stroke: #eee;
  shape-rendering: crispEdges;
}

.d3-grid-dashed path {
  stroke-width: 0;
}

.d3-crosshair-overlay {
  fill: none;
  pointer-events: all;
}

.bullet-tick line {
  stroke: #999;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.bullet-tick text {
  fill: #999;
  font-size: 0.75rem;
}

.bullet-marker {
  stroke-width: 2px;
  shape-rendering: crispEdges;
}

.bullet-title {
  font-size: 0.8125rem;
  font-weight: 500;
}

.bullet-subtitle {
  fill: #999;
}

.bullet-1 .bullet-range-1 {
  fill: rgba(96, 125, 139, 0.8);
}

.bullet-1 .bullet-range-2 {
  fill: rgba(96, 125, 139, 0.4);
}

.bullet-1 .bullet-range-3 {
  fill: #607d8b;
}

.bullet-1 .bullet-measure-1 {
  fill: rgba(84, 110, 122, 0.9);
}

.bullet-1 .bullet-measure-2 {
  fill: #fff;
}

.bullet-1 .bullet-marker {
  stroke: #37474f;
}

.bullet-2 .bullet-range-1 {
  fill: rgba(255, 112, 67, 0.6);
}

.bullet-2 .bullet-range-2 {
  fill: rgba(255, 112, 67, 0.3);
}

.bullet-2 .bullet-range-3 {
  fill: #ff7043;
}

.bullet-2 .bullet-measure-1 {
  fill: rgba(244, 81, 30, 0.9);
}

.bullet-2 .bullet-measure-2 {
  fill: #fff;
}

.bullet-2 .bullet-marker {
  stroke: #d84315;
}

.bullet-3 .bullet-range-1 {
  fill: rgba(102, 187, 106, 0.7);
}

.bullet-3 .bullet-range-2 {
  fill: rgba(102, 187, 106, 0.35);
}

.bullet-3 .bullet-range-3 {
  fill: #66bb6a;
}

.bullet-3 .bullet-measure-1 {
  fill: rgba(67, 160, 71, 0.9);
}

.bullet-3 .bullet-measure-2 {
  fill: #fff;
}

.bullet-3 .bullet-marker {
  stroke: #2e7d32;
}

.counter-icon {
  font-size: 2rem;
  position: absolute;
  left: 50%;
  margin-left: -1rem;
}

.chart-widget-legend {
  margin: 0.625rem 0 0 0;
  padding: 0;
  font-size: 0.75rem;
  text-align: center;
}

.chart-widget-legend li {
  margin: 0.3125rem 0.625rem 0;
  padding: 0.4375rem 0.5rem 0.3125rem;
  display: inline-block;
}

/* ------------------------------------------------------------------------------
 *
 *  # Ecommerce stuff
 *
 *  Styles for Ecommerce set of pages
 *
 * ---------------------------------------------------------------------------- */
.color-selector-active {
  position: relative;
}

.color-selector-active i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}

.pricing-table-price {
  font-size: 2.625rem;
}

.pricing-table-price > span {
  font-size: 1.875rem;
}

.pricing-table-list li {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card[class*="bg-"]:not(.bg-transparent):not(.bg-white):not(.bg-light)
  .pricing-table-list
  li {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.ribbon-container {
  width: 6.5rem;
  height: 6.625rem;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: -1px;
}

.ribbon {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.6667;
  font-weight: 500;
  position: relative;
  padding: 0.3125rem 0;
  left: -0.6875rem;
  top: 1.563rem;
  width: 9.375rem;
  background-color: #4caf50;
  color: #fff;
  z-index: 10;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* ------------------------------------------------------------------------------
 *
 *  # Blog layouts
 *
 *  Blog layouts with various blog layouts
 *
 * ---------------------------------------------------------------------------- */
.blog-horizontal .card-img-actions {
  width: 100%;
}

@media (min-width: 576px) {
  .blog-horizontal .card-img-actions {
    width: 45%;
    float: left;
    max-width: 25rem;
    z-index: 10;
  }
}

@media (min-width: 576px) {
  .blog-horizontal-xs .card-img-actions {
    width: 35%;
    max-width: 12.5rem;
  }
}

@media (min-width: 576px) {
  .blog-horizontal-sm .card-img-actions {
    width: 40%;
    max-width: 18.75rem;
  }
}

@media (min-width: 576px) {
  .blog-horizontal-lg .card-img-actions {
    width: 50%;
    max-width: 31.25rem;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Task manager
 *
 *  Styles related to task manager - detailed task, list and grid
 *
 * ---------------------------------------------------------------------------- */
.timer-number {
  font-size: 2.875rem;
  line-height: 1;
}

.timer-dots {
  font-size: 1.4375rem;
  padding: 0.71875rem 0;
  line-height: 1;
}

/* ------------------------------------------------------------------------------
 *
 *  # Mail list
 *
 *  Inbox page - list, read and write
 *
 * ---------------------------------------------------------------------------- */
.table-inbox {
  table-layout: fixed;
  min-width: 768px;
}

.table-inbox tr {
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .table-inbox tr {
    transition: none;
  }
}

.table-inbox tr:not([class*="alpha-"]):not([class*="bg-"]) {
  background-color: #fafafa;
}

.table-inbox tr td:not(:first-child) {
  padding-left: 0;
}

.table-inbox tr.unread {
  font-weight: 700;
}

.table-inbox tr.unread:not([class*="alpha-"]):not([class*="bg-"]) {
  background-color: #fff;
}

.table-inbox-checkbox {
  width: 3.75rem;
}

.table-inbox-star,
.table-inbox-attachment {
  width: 2.25rem;
}

.table-inbox-image {
  width: 3.25006rem;
}

.table-inbox-name {
  width: 11.25rem;
}

@media (max-width: 767.98px) {
  .table-inbox-subject {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-inbox-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-inbox-time {
  text-align: right;
  width: 5rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # User profile
 *
 *  Styles for all user profile layouts
 *
 * ---------------------------------------------------------------------------- */
.profile-cover {
  position: relative;
}

.profile-cover .media {
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.profile-cover .media .btn {
  box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5) !important;
}

@media (min-width: 768px) {
  .profile-cover .media {
    bottom: 1.25rem;
    -webkit-transform: none;
    transform: none;
  }
}

.profile-cover .media-body {
  text-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
}

.profile-cover-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 21.88rem;
}

.profile-thumb img {
  border: 3px solid #fff;
  box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
  width: 6.25rem;
  height: 6.25rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  background: url(/assets/images/login_cover.jpg) no-repeat;
  background-size: cover;
}

.login-form {
  width: 100%;
}

.login-form .nav-item:first-child .nav-link {
  border-top-left-radius: 0.125rem;
}

.login-form .nav-item:last-child .nav-link {
  border-top-right-radius: 0.125rem;
}

@media (min-width: 576px) {
  .login-form {
    width: 20rem;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Timeline
 *
 *  Styles for timeline in 3 layouts: left, right and centered
 *
 * ---------------------------------------------------------------------------- */
.timeline {
  position: relative;
}

.timeline:before,
.timeline:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  z-index: 1;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.timeline:before {
  top: 0;
}

.timeline:after {
  bottom: 0;
}

.timeline-container {
  position: relative;
  padding-top: 0.625rem;
  margin-top: -0.625rem;
  padding-bottom: 1px;
}

.timeline-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  background-color: #ccc;
  height: 100%;
  width: 2px;
  z-index: -1;
}

.timeline-row {
  position: relative;
}

.timeline-date {
  text-align: center;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}

.timeline-date:before,
.timeline-date:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  z-index: 1;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.timeline-date:before {
  top: 0;
}

.timeline-date:after {
  bottom: 0;
}

.card .timeline-date {
  background-color: #fff;
}

.timeline-time {
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 1.25rem;
}

.timeline-time:before,
.timeline-time:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -2px;
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.timeline-time:before {
  top: 0;
}

.timeline-time:after {
  bottom: 0;
}

.card .timeline-time {
  background-color: #fff;
}

@media (min-width: 768px) {
  .timeline-time:before,
  .timeline-time:after {
    content: none;
  }
}

.timeline-icon {
  margin: 0 auto 1.25rem auto;
  background-color: #fff;
  border: 4px solid #f5f5f5;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.card .timeline-icon {
  border-color: #fff;
}

.timeline-icon div {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0 0 0 2px #ccc inset;
  border-radius: 50%;
}

.timeline-icon
  div[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
  box-shadow: none;
}

.timeline-icon div > i {
  top: 0;
}

.timeline-icon img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .timeline-center .timeline-row-left {
    margin-right: 50%;
    padding-right: 2.75rem;
  }

  .timeline-center .timeline-row-right {
    margin-left: 50%;
    padding-left: 2.75rem;
  }

  .timeline-left .timeline-container:after,
  .timeline-left:before,
  .timeline-left:after,
  .timeline-left .timeline-date:before,
  .timeline-left .timeline-date:after {
    left: 1.5rem;
  }

  .timeline-left .timeline-row,
  .timeline-left .timeline-date {
    padding-left: 4.25rem;
  }

  .timeline-right .timeline-container:after,
  .timeline-right:before,
  .timeline-right:after,
  .timeline-right .timeline-date:before,
  .timeline-right .timeline-date:after {
    left: auto;
    right: 1.5rem;
    margin-left: 0;
    margin-right: -2px;
  }

  .timeline-right .timeline-container:after {
    margin-right: -1px;
  }

  .timeline-right .timeline-row,
  .timeline-right .timeline-date {
    padding-right: 4.25rem;
  }

  .timeline-left .timeline-date,
  .timeline-right .timeline-date {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .timeline-icon {
    position: absolute;
    top: 0.625rem;
  }

  .timeline-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    height: 2px;
    width: 1rem;
    background-color: #ccc;
    z-index: 1;
  }

  .timeline-left .timeline-icon {
    left: 0;
  }

  .timeline-left .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }

  .timeline-right .timeline-icon {
    right: 0;
  }

  .timeline-right .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }

  .timeline-center .timeline-row-left .timeline-icon {
    left: 100%;
    margin-left: -1.5rem;
  }

  .timeline-center .timeline-row-left .timeline-icon:after {
    right: 100%;
    margin-right: 4px;
  }

  .timeline-center .timeline-row-right .timeline-icon {
    right: 100%;
    margin-right: -1.5rem;
  }

  .timeline-center .timeline-row-right .timeline-icon:after {
    left: 100%;
    margin-left: 4px;
  }

  .timeline-center .timeline-row-full .timeline-icon {
    position: static;
  }

  .timeline-center .timeline-row-full .timeline-icon:after {
    content: none;
  }

  .timeline-time {
    padding: 0;
    text-align: inherit;
    background-color: transparent;
  }

  .timeline-time:before {
    content: none;
  }

  .timeline-left .timeline-time,
  .timeline-right .timeline-time {
    padding-top: 0.625rem;
    margin-bottom: 1.25rem;
    padding-left: 0.625rem;
  }

  .timeline-right .timeline-time {
    text-align: right;
    padding-left: 0;
    padding-right: 0.625rem;
  }

  .timeline-center .timeline-time,
  .timeline-center .timeline-row-full .timeline-time {
    position: absolute;
    left: 100%;
    top: 0.875rem;
    width: 100%;
    padding-left: 2.75rem;
  }

  .timeline-center .timeline-row-right .timeline-time,
  .timeline-center .timeline-row-full .timeline-time {
    left: auto;
    right: 100%;
    padding-left: 0;
    padding-right: 2.75rem;
    text-align: right;
  }

  .timeline-center .timeline-row-full .timeline-time {
    right: 50%;
    top: 4px;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Chat layouts
 *
 *  Conversation chat styles - layouts, chat elements, colors, options
 *
 * ---------------------------------------------------------------------------- */
.media-chat-scrollable {
  max-height: 520px;
  overflow: auto;
}

@media (min-width: 576px) {
  .media-chat .media {
    margin-right: 20%;
  }

  .media-chat .media.media-chat-item-reverse {
    margin-right: 0;
    margin-left: 20%;
  }
}

.media-chat-item {
  position: relative;
  padding: 0.625rem 1rem;
  width: auto;
  display: inline-block;
  color: #333;
  border-radius: 0.1875rem;
}

.media-chat-item:not([class*="bg-"]) {
  background-color: #eee;
  border-color: #eee;
}

.media-chat-item:before {
  content: "";
  left: -5px;
  top: 0.93752rem;
  position: absolute;
  margin-left: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid;
  border-right-color: inherit;
  border-bottom: 5px solid transparent;
}

.media-chat-item-reverse .media-chat-item:before {
  left: auto;
  right: -5px;
  border-right: 0;
  border-left: 5px solid;
  border-left-color: inherit;
}

.media-chat-item-reverse .media-chat-item {
  text-align: left;
  color: #fff;
}

.media-chat-item-reverse .media-chat-item:not([class*="bg-"]) {
  background-color: #42a5f5;
  border-color: #42a5f5;
}

.media-chat-item-reverse .media-body {
  text-align: right;
}

.media-chat-inverse .media-chat-item {
  color: #fff;
}

.media-chat-inverse .media-chat-item:not([class*="bg-"]) {
  background-color: #42a5f5;
  border-color: #42a5f5;
}

.media-chat-inverse .media-chat-item-reverse .media-chat-item {
  color: #333;
}

.media-chat-inverse
  .media-chat-item-reverse
  .media-chat-item:not([class*="bg-"]) {
  background-color: #eee;
  border-color: #eee;
}

/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 8.125rem;
  line-height: 1;
  margin-bottom: 2.5rem;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

@media (min-width: 576px) {
  .error-title {
    font-size: 12.5rem;
  }
}

.offline-title {
  font-size: 6.875rem;
}

@media (min-width: 576px) {
  .offline-title {
    font-size: 10rem;
  }
}

/* ------------------------------------------------------------------------------
*
*  # Header elements
*
*  Display default and custom components in page header, card header and breadcrumbs
*
* ---------------------------------------------------------------------------- */
.card-header .header-elements {
  padding-top: 0.9375rem;
}

/* .page-header-content .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1.25rem 1.25rem;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  background-color: #efefef;
} */

.page-header-light .header-elements {
  background-color: #fafafa;
  border-color: #ddd;
  border-bottom: 0;
}

.page-header-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: 0;
}

.breadcrumb-line .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 0 1.25rem;
  border-top: 1px solid transparent;
}

.breadcrumb-line-light .header-elements {
  background-color: transparent;
  border-color: #ddd;
}

.breadcrumb-line-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.25);
}

.header-elements .form-group:last-child {
  margin-bottom: 0;
}

.header-elements-toggle,
.footer-elements-toggle {
  margin-left: auto;
  -ms-flex-item-align: center;
  align-self: center;
}

.header-elements-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}


.header-elements-inline .header-elements {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin-left: 0;
  margin-right: 0;
}

.header-elements-inline .card-header-tabs .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 576px) {
  .header-elements-sm-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-sm-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-sm-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .header-elements-md-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-md-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-md-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .header-elements-lg-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-lg-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-lg-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .header-elements-xl-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-xl-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-xl-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Helper classes
 *
 *  Custom helper classes used in the template.
 *
 * ---------------------------------------------------------------------------- */
.font-size-lg {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 0.8125rem;
}

.font-size-sm {
  font-size: 0.75rem;
}

.font-size-xs {
  font-size: 0.6875rem;
}

.line-height-lg {
  line-height: 1.4286;
}

.line-height-base {
  line-height: 1.5385;
}

.line-height-sm {
  line-height: 1.6667;
}

.line-height-xs {
  line-height: 1.82;
}

.line-height-1 {
  line-height: 1;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-0 {
  opacity: 0;
}

.top-0 {
  top: 0;
}

.top-auto {
  top: auto;
}

.bottom-0 {
  bottom: 0;
}

.bottom-auto {
  bottom: auto;
}

.left-0 {
  left: 0;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.right-auto {
  right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-disabled {
  cursor: default;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

.border-width-0 {
  border-width: 0;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-1 {
  border: 1px solid;
}

.border-top-1 {
  border-top: 1px solid;
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

.border-left-1 {
  border-left: 1px solid;
}

.border-right-1 {
  border-right: 1px solid;
}

.border-y-1 {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-x-1 {
  border-left: 1px solid;
  border-right: 1px solid;
}

.border-2 {
  border: 2px solid;
}

.border-top-2 {
  border-top: 2px solid;
}

.border-bottom-2 {
  border-bottom: 2px solid;
}

.border-left-2 {
  border-left: 2px solid;
}

.border-right-2 {
  border-right: 2px solid;
}

.border-y-2 {
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.border-x-2 {
  border-left: 2px solid;
  border-right: 2px solid;
}

.border-3 {
  border: 3px solid;
}

.border-top-3 {
  border-top: 3px solid;
}

.border-bottom-3 {
  border-bottom: 3px solid;
}

.border-left-3 {
  border-left: 3px solid;
}

.border-right-3 {
  border-right: 3px solid;
}

.border-y-3 {
  border-top: 3px solid;
  border-bottom: 3px solid;
}

.border-x-3 {
  border-left: 3px solid;
  border-right: 3px solid;
}

.border-dashed {
  border-style: dashed;
}

.border-top-dashed {
  border-top-style: dashed;
}

.border-bottom-dashed {
  border-bottom-style: dashed;
}

.border-left-dashed {
  border-left-style: dashed;
}

.border-right-dashed {
  border-right-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.rounded-round {
  border-radius: 100px !important;
}

.rounded-top-round {
  border-top-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

.rounded-bottom-round {
  border-bottom-right-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.rounded-left-round {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.rounded-right-round {
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.wmin-0 {
  min-width: 0 !important;
}

.wmin-200 {
  min-width: 200px !important;
}

.wmin-250 {
  min-width: 250px !important;
}

.wmin-300 {
  min-width: 300px !important;
}

.wmin-350 {
  min-width: 350px !important;
}

.wmin-400 {
  min-width: 400px !important;
}

.wmin-450 {
  min-width: 450px !important;
}

.wmin-500 {
  min-width: 500px !important;
}

.wmin-550 {
  min-width: 550px !important;
}

.wmin-600 {
  min-width: 600px !important;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .wmin-sm-0 {
    min-width: 0 !important;
  }

  .wmin-sm-200 {
    min-width: 200px !important;
  }

  .wmin-sm-250 {
    min-width: 250px !important;
  }

  .wmin-sm-300 {
    min-width: 300px !important;
  }

  .wmin-sm-350 {
    min-width: 350px !important;
  }

  .wmin-sm-400 {
    min-width: 400px !important;
  }

  .wmin-sm-450 {
    min-width: 450px !important;
  }

  .wmin-sm-500 {
    min-width: 500px !important;
  }

  .wmin-sm-550 {
    min-width: 550px !important;
  }

  .wmin-sm-600 {
    min-width: 600px !important;
  }

  .flex-sm-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .wmin-md-0 {
    min-width: 0 !important;
  }

  .wmin-md-200 {
    min-width: 200px !important;
  }

  .wmin-md-250 {
    min-width: 250px !important;
  }

  .wmin-md-300 {
    min-width: 300px !important;
  }

  .wmin-md-350 {
    min-width: 350px !important;
  }

  .wmin-md-400 {
    min-width: 400px !important;
  }

  .wmin-md-450 {
    min-width: 450px !important;
  }

  .wmin-md-500 {
    min-width: 500px !important;
  }

  .wmin-md-550 {
    min-width: 550px !important;
  }

  .wmin-md-600 {
    min-width: 600px !important;
  }

  .flex-md-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .wmin-lg-0 {
    min-width: 0 !important;
  }

  .wmin-lg-200 {
    min-width: 200px !important;
  }

  .wmin-lg-250 {
    min-width: 250px !important;
  }

  .wmin-lg-300 {
    min-width: 300px !important;
  }

  .wmin-lg-350 {
    min-width: 350px !important;
  }

  .wmin-lg-400 {
    min-width: 400px !important;
  }

  .wmin-lg-450 {
    min-width: 450px !important;
  }

  .wmin-lg-500 {
    min-width: 500px !important;
  }

  .wmin-lg-550 {
    min-width: 550px !important;
  }

  .wmin-lg-600 {
    min-width: 600px !important;
  }

  .flex-lg-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .wmin-xl-0 {
    min-width: 0 !important;
  }

  .wmin-xl-200 {
    min-width: 200px !important;
  }

  .wmin-xl-250 {
    min-width: 250px !important;
  }

  .wmin-xl-300 {
    min-width: 300px !important;
  }

  .wmin-xl-350 {
    min-width: 350px !important;
  }

  .wmin-xl-400 {
    min-width: 400px !important;
  }

  .wmin-xl-450 {
    min-width: 450px !important;
  }

  .wmin-xl-500 {
    min-width: 500px !important;
  }

  .wmin-xl-550 {
    min-width: 550px !important;
  }

  .wmin-xl-600 {
    min-width: 600px !important;
  }

  .flex-xl-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

.no-transitions *,
.no-transitions *:before,
.no-transitions *:after {
  transition: none !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  .no-transitions *,
  .no-transitions *:before,
  .no-transitions *:after {
    transition: none;
  }
}

.caret-0::before,
.caret-0::after {
  content: none;
}

.shadow-0 {
  box-shadow: none !important;
}

.outline-0,
.outline-0:hover,
.outline-0:focus {
  outline: 0;
}

.text-shadow-dark {
  text-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
}

.text-shadow-light {
  text-shadow: 0 0 0.1875rem rgba(255, 255, 255, 0.5);
}

/* ------------------------------
 *
 *  Theme specific styles
 *
 * ------------------------------ */
/* ------------------------------------------------------------------------------
 *
 *  # Demo styles
 *
 *  Styles used for demostration purposes only
 *
 * ---------------------------------------------------------------------------- */
.jqueryui-demo-element {
  background-color: #fafafa;
  border: 2px dashed #ddd;
  color: #777;
  position: relative;
  z-index: 10;
  width: 90px;
  height: 90px;
}

.jqueryui-demo-element > span {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.selectable-demo-list {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 200px;
}

.selectable-demo-list > .ui-sortable-handle,
.selectable-demo-list > .ui-selectee {
  background-color: #fafafa;
  border: 1px solid #ddd;
}

.selectable-demo-list > .ui-sortable-handle.ui-selecting,
.selectable-demo-list > .ui-sortable-handle.ui-selected,
.selectable-demo-list > .ui-selectee.ui-selecting,
.selectable-demo-list > .ui-selectee.ui-selected {
  background-color: #03a9f4 !important;
  border-color: #0288d1 !important;
  color: #fff;
}

.selectable-demo-list > .ui-sortable-helper:first-child + li {
  margin-top: 0;
}

.selectable-demo-connected + .selectable-demo-connected {
  margin-top: 1.25rem;
}

@media (min-width: 576px) {
  .selectable-demo-connected + .selectable-demo-connected {
    margin-top: 0;
    margin-left: 1.25rem;
  }
}

.droppable-demo-target {
  width: 110px;
  height: 110px;
  background-color: #e3f2fd;
  border-color: #2196f3;
  color: #1565c0;
  z-index: 9;
}

.droppable-demo-drop {
  background-color: #e8f5e9;
  border-color: #4caf50;
  color: #2e7d32;
}

.demo-color {
  height: 10rem;
  position: relative;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.demo-color > span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0.375rem;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
  color: #fff;
}

.glyphs > div > div {
  padding: 0.5rem 1rem;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .glyphs > div > div {
    transition: none;
  }
}

.glyphs > div > div:hover {
  background-color: #324148;
  color: #fff;
  border-radius: 0.1875rem;
}

.glyphs > div > div:hover .text-muted {
  color: rgba(255, 255, 255, 0.75);
}

.demo-velocity-box {
  padding: 0.75rem 1rem;
  margin-bottom: 1.25rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #fafafa;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0 0 #2196f3;
}
