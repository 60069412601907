/* New fonts start */
@font-face {
  font-family: "Aktiv-Grotesk";
  src: url("/assets/fonts/AktivGrotesk-Regular.eot");
  src: url("/assets/fonts/AktivGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGrotesk-Regular.woff2") format("woff2"),
    url("/assets/fonts/AktivGrotesk-Regular.woff") format("woff"),
    url("/assets/fonts/AktivGrotesk-Regular.ttf") format("truetype"),
    url("/assets/fonts/AktivGrotesk-Regular.svg#AktivGrotesk-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk-boldI";
  src: url("/assets/fonts/AktivGroteskApp-BoldItalic.eot");
  src: url("/assets/fonts/AktivGroteskApp-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-BoldItalic.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-BoldItalic.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-BoldItalic.svg#AktivGroteskApp-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk-i";
  src: url("/assets/fonts/AktivGroteskApp-Italic.eot");
  src: url("/assets/fonts/AktivGroteskApp-Italic.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-Italic.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-Italic.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-Italic.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-Italic.svg#AktivGroteskApp-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk-thin";
  src: url("/assets/fonts/AktivGroteskApp-Thin.eot");
  src: url("/assets/fonts/AktivGroteskApp-Thin.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-Thin.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-Thin.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-Thin.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-Thin.svg#AktivGroteskApp-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk-thinI";
  src: url("/assets/fonts/AktivGroteskApp-ThinItalic.eot");
  src: url("/assets/fonts/AktivGroteskApp-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-ThinItalic.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-ThinItalic.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-ThinItalic.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-ThinItalic.svg#AktivGroteskApp-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk-bold";
  src: url("/assets/fonts/AktivGroteskApp-Bold.eot");
  src: url("/assets/fonts/AktivGroteskApp-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-Bold.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-Bold.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-Bold.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-Bold.svg#AktivGroteskApp-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/assets/fonts/AktivGroteskApp-Regular.eot");
  src: url("/assets/fonts/AktivGroteskApp-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/AktivGroteskApp-Regular.woff2") format("woff2"),
    url("/assets/fonts/AktivGroteskApp-Regular.woff") format("woff"),
    url("/assets/fonts/AktivGroteskApp-Regular.ttf") format("truetype"),
    url("/assets/fonts/AktivGroteskApp-Regular.svg#AktivGroteskApp-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* New fonts end */

/*---- Marketing Banner Fonts------*/

@font-face {
  font-family: "Opens Sans Light";
  src: url("/assets/fonts/Open_Sans/OpenSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Opens Sans Light";
  src: url("/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Opens Sans";
  src: url("/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Opens Sans";
  src: url("/assets/fonts/Open_Sans/OpenSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Opens Sans";
  src: url("/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: url("/assets/fonts/Archivo_Narrow/ArchivoNarrow-Regular.otf");
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: url("/assets/fonts/Archivo_Narrow/ArchivoNarrow-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Knockout-28";
  src: url("/assets/fonts/Knockout/Knockout-28.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Knockout-48";
  src: url("/assets/fonts/Knockout/Knockout-48.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Knockout-68";
  src: url("/assets/fonts/Knockout/Knockout-68.otf");
  font-weight: 900;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Aktiv-Grotesk", sans-serif !important;
  font-weight: normal;
}
.Login-logo{
  display: flex;
  align-items: center;
}
.Login-logo  img {
    height: 50px; 
}
.Login-logo span{
  color: #060a45;
  font-size: 50px;
  font-weight: 700;
  padding-left: 5px;
  letter-spacing: 1px;
 
}

/*--- End Marketing Banner Fonts*/

.card-body+.table,
.card-body+div[class*="table-responsive"],
.card-header:not([class*="bg-"])+.table,
.card-header:not([class*="bg-"])+div[class*="table-responsive"],
.navbar-light+.table,
.navbar-light+div[class*="table-responsive"],
.table+.card-body,
div[class*="table-responsive"]+.card-body {
  border-top: 1px solid #ddd;
}
.navbar{
  background-color: #fff;
  border-bottom: 1px solid #2d6df6;
  box-shadow: 0 0 8px 0 gray;
  display: flex;
  min-height: 51px;
  padding: 0 45px 0 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 25;
}
.navbar-brand a span{
  color: #060a45;
  font-size: 30px;
  font-weight: 700;
  margin-left: 5px;
}

/* carousel start */

.carousel {
  position: relative !important;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 450px;
  border-radius: 1px;
}

.carousel {
  position: absolute;
  width: 100%;
}

.carousel-container {
  max-height: 450px;
  overflow: hidden;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #fff;
}

.carousel-indicators .active {
  border: solid 2px #fff;
  background-color: #161616;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 25px;
  width: 25px;
  outline: white;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid white;
  background: #ffffff96;
}

.carousel-control-next,
.carousel-control-prev {
  width: 6%;
}

.carousel-control-next-icon:after {
  content: "\e9cb";
  font-size: 15px;
  color: #fff;
  font-family: icomoon;
}

.carousel-control-prev-icon:after {
  content: "\e9c8";
  font-size: 15px;
  color: #fff;
  font-family: icomoon;
}

.carousel-caption h1 {
  color: #fff !important;
  font-size: 42px !important;
}

.carousel-caption p {
  color: #fff !important;
  font-size: 24px !important;
}

.carousel-caption {
  bottom: unset !important;
  top: 15%;
}

/* Progress Bar CSS */

.slide-progress {
  width: 0;
  max-width: 100%;
  height: 4px;
  background: #7fc242;
}

.slide-start {
  width: 0;
  transition: width 0ms;
}

.slide-end {
  width: 100%;
  transition: width 5000ms;
}

/* New Marketing CSS */

.banner-caption {
  height: 71%;
  margin: 0 auto;
  left: 7%;
  right: 7%;
}

.banner-text {
  position: absolute;
  width: 100%;
}

.banner-caption-centre {
  margin-left: auto;
  margin-right: auto;
  top: 43%;
  -ms-transform: translateY(-43%);
  transform: translateY(-43%);
}

.banner-caption-centre-top {
  margin: 0 auto;
  top: 0;
  text-align: center;
}

.banner-caption-left {
  left: 0;
  top: 43%;
  text-align: left;
  -ms-transform: translateY(-43%);
  transform: translateY(-43%);
}

.banner-caption-left-top {
  left: 0;
  top: 0;
  text-align: left;
}

.banner-caption-centre-bottom {
  margin: 0 auto;
  bottom: 0;
  text-align: center;
}

.banner-caption-left-bottom {
  left: 0;
  bottom: 0;
  text-align: left;
}

.banner-caption-right {
  right: 0;
  top: 43%;
  text-align: end;
  -ms-transform: translateY(-43%);
  transform: translateY(-43%);
}

.banner-caption-right-top {
  right: 0;
  top: 0;
  text-align: end;
}

.banner-caption-right-bottom {
  right: 0;
  bottom: 0;
  text-align: end;
}

/* Slider end */

/* navbar start */

.page-header {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
  padding: 15px 0;
  animation-duration: 0.8s;
  transition: 0.8s;
 
}
@keyframes stickySlideDown {
  0% {
      opacity: 0.7;
      transform: translateY(-100%);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
.sticky-header {
  background-color: #ffffff;
  position: sticky;
  top: 52px;
  width: 100%;
  z-index: 21;
  animation-name: stickySlideDown;

}

.nbbheader .breadcrumb {
  margin-top: 1.875rem !important;
}

.dropdown-user .navbar-nav-link>span,
.navbar-light .navbar-nav-link {
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
}

.navbar-light.footer-custom .navbar-text a {
  font-size: 15px;
}

.navbar-light.footer-custom .navbar-text a {
  font-size: 15px;
}

.navbar-nav .dropdown:nth-child(1) {
  border-left: solid 1px #d9d9d9;
}

.navbar-nav .dropdown {
  border-right: solid 1px #d9d9d9;
}

/* .sticky-header>.page-header-content>.breadcrumb {
  padding-top: 35px;
} */

.notification-msg {
  min-width: 350px !important;
  padding: 0 !important;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgb(0 0 0 / 20%) !important;
  background-color: #fff3cd;
  border-radius: 4px !important;
}

.notification-msg p {
  margin: 0;
  padding: 10px;
  border-bottom: solid 1px #f1f1f3;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  border-color: #e0dfdf;
  margin-bottom: 5px;
  color: #856404;
}

.notification-msg::after {
  right: 12px !important;
  top: -15px !important;
  border-right: 12px solid transparent !important;
  border-left: 12px solid transparent !important;
}

.num-count {
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  font-size: 0.6rem;
  background: #2D6DF6;
  width: 15px;
  height: 15px;
  color: #ecf0f1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 3px;
  right: 8px;
  box-sizing: border-box;
}

.notification-icon-nav i {
  font-size: 20px;
}

.nav-with-crousel.divider-after li:first-child:after {
  border-right: solid 1px #fff;
}

.navbar_scroll .nav-with-crousel.divider-after li:first-child:after {
  border-right: solid 1px #252b4d;
}



.dropdown-menu-right a {
  color: #666666 !important;
}

.nav-with-crousel li .language-dropdown {
  color: #fff !important;
}

.logo-brand,
.logo-brand:hover {
  background-color: #161616 !important;
  padding: 16px 0 !important;
}

.filter-header {
  padding: 10px 20px !important;
}



/* navbar End */

.content-wrapper,
.page-content {
  display: -webkit-flex;
  display: flex;
}



/* header start */

.navbar_scroll .nav-with-crousel li a,
.navbar_scroll .nav-with-crousel li a span {
  color: #252b4d !important;
}

.header-elements-md-inline .header-elements {
  padding-right: 0 !important;
}

.deshboard-header {
  margin-top: 35px;
}

.page-title {
  padding: 0;
  position: relative;
}

.page-header-content {
  position: relative;
  padding: 0 1.25rem;
}

.header-bg {
  background-color: #f8f9fe;
  height: 200px;
  margin-bottom: -90px;
  position: relative;
}

.header-elements-md-inline {
  align-items: start;

}

.header-details-page {
  margin-bottom: -45px !important;
}

.non-coursel {
  margin-bottom: 25px;
}



.tab-btn-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* header end */

/* login section start */

.login-section strong u {
  color: #161616;
}

.login-section {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .login-section img {
  width: 100%;
  max-width: 40%;
} */

.login-section p {
  font-size: 1.5rem;
  color: #707070;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
}

.login-section .login-btn {
  margin-top: 15px;
  font-size: 19px;
  padding: 4px 40px;
}

/* Login Section end */

/* profile Page start */

.profile-card {
  padding: 50px 20px;
}

.profile-text-section {
  border-right: solid 1px #d5d5df;
  padding: 80px 0;
}

.profile-text-section h3 {
  font-size: 30px;
  font-weight: 700;
  color: #161616;
  padding: 0;
  margin: 0;
}

.profile-text-section p {
  color: #161616;
  font-size: 15px;
  margin: 0;
}

.profile-text-section p span {
  text-transform: lowercase;
}

.card>.card-header>.card-title>a {
  font-size: 20px;
}

.selected-checkbox-text {
  font-size: 16px;
  color: #666666;
}

.profile-form-section {
  padding: 115px 0;
}

.profile-form-section a {
  color: #161616;
  font-size: 16px;
  text-decoration: underline;
  padding: 10px;
}

.profile-form-section .label-section .first-label {
  font-size: 14px;
  font-weight: 700;
  color: #161616;
  padding: 0;
  display: inline-block;
  width: 170px;
}

.profile-form-section .label-section p span {
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  text-align: left;
}

.access-btn-section {
  display: grid;
}

.profile-form-section .profile-btn {
  background-color: #f5f5f7;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  width: 210px;
  text-align: left;
}

/* Profile page end */

/* sidebar start */

.sidebar-close-tab {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.sidebar-close-tab .closeafter {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  text-align: center;
  padding: 3px 0;
  z-index: 999;
  color: #161616 !important;
}

.sidebar-close-tab .closeafter em {
  font-size: 14px;
  position: relative;
  right: 2px;
}

.language-menu {
  display: none;
}

.sidebar-close-tab .closeafter2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  text-align: center;
  background-color: #ccc;
  padding: 3px 0;
  z-index: 999;
  color: #161616 !important;
}

.sidebar-close-tab .closeafter2 em {
  font-size: 16px;
  width: 16px;
  position: relative;
  top: -2px;
}

.sidebar-close-tab .closeafter3 {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  text-align: center;
  border-left: 1px solid #ccc;
  padding: 3px 0;
  z-index: 999;
  color: #161616 !important;
}

.sidebar-close-tab .closeafter3 em {
  font-size: 16px;
  width: 16px;
  position: relative;
  top: -2px;
  right: 1px;
}

.sidebar-close-tab .closeafter4 {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 16px;
  text-align: center;
  background-color: #2d6df6;
  padding: 4px 0;
  z-index: 999;
  color: #fff !important;
}

.sidebar-close-tab .closeafter4 em {
  font-size: 16px;
  width: 16px;
  position: relative;
  top: -2px;
}

.sidebar-close-tab .closeafter4:hover {
  background-color: #060a45;
}

.sidebar-xs .secondary-toggle {
  position: absolute;
  right: 16px;
  z-index: 999;
  color: #161616;
}

.custom-info-icon {
  font-size: 13px !important;
  color: #161616 !important;
}

.sidebar-xs.sidebar-secondary-hidden .secondary-toggle {
  position: relative;
  float: right;
  right: inherit;
  margin-right: 10px;
}

.sidebar-xs .secondary-toggle::before {
  /* content: "\e9c0"; */
  content: url(/assets/images/icons-svg/slide-in.svg);
  background-repeat: no-repeat;
  font-family: "icomoon", serif !important;
  font-size: 25px;
  overflow: hidden;
  transition: 0.5s;
}

.sidebar-xs.sidebar-secondary-hidden .secondary-toggle::before {
  /* content: "\e9c3"; */
  content: url(/assets/images/icons-svg/slide-out.svg);
  background-repeat: no-repeat;
  font-family: "icomoon", serif !important;
  transition: 0.5s;
}

.filter-section .heading {
  color: #161616;
  font-size: 20px;
  font-weight: 400;
}

.filter-section .uniform-checker span {
  position: absolute;
  left: -17px;
  top: 6px;
  border-radius: 0;
}

.nav-sidebar .nav-item a img {
  min-width: 22px;
  width: 22px;
}

.create-products-tabs .nav-sidebar .nav-item a img {
  min-width: 16px;
  width: 16px;
}

.sidebar:not(.sidebar-component) .sidebar-content {
  overflow-y: unset;
}

.textarea-sidebar {
  color: rgba(61, 59, 59, 0.8) !important;
}

.rct-icon-uncheck,
.rct-icon-check {
  width: 16px !important;
  height: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  content: "" !important;
  position: relative;
  top: 4px;
  background-color: #FFF;
  border: solid 1px #161616;
}



.rct-icons-fa4 .rct-icon-uncheck::before,
.rct-icons-fa4 .rct-icon-check::before {
  content: "" !important;
}

.rct-icons-fa4 .rct-icon-check::before {
  content: "\ed6c" !important;
  font-family: "icomoon", serif;
  position: absolute;
  left: 2px;
  top: 1px;
  transform: translate(-50%, -50%);
  color: #ffffff;
  width: 0px;
  font-size: 10px;
  height: 4px;
}

.rct-node {
  padding-bottom: 5px;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
  -webkit-appearance: none !important;
}

/* Sidebar End */

/* language Sidebar start */

.language-sidebar>.sortable>.card>.card-header {
  padding: 25px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.language-sidebar>.sortable>.card>.card-body {
  background-color: #ffffff;
}

.language-sidebar>.sortable>.card>.card-body>.nav-sidebar>.nav-item>.nav-link {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 14px 25px 14px 10px;
}

.language-sidebar>.sortable>.card>.card-body>.nav-sidebar>.product-tab {
  width: 100%;
}

.multipart-submenu .products-tab {
  background: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.multipart-submenu .product-tab a {
  background-color: transparent !important;
  padding: 0 10px;
}

.multipart-submenu .product-tab .nav-link {
  font-size: 15px;
  color: #161616;
  padding-left: 15px;
}

.multipart-submenu .product-tab a.active {
  border-radius: 0px !important;
  font-weight: 600 !important;
}

.multipart-submenu {
  margin: 0 !important;
}

.create-products-tabs .product-tab a:hover,
.create-products-tabs .product-tab a.active .create-products-tabs .product-tab a:focus,
.create-products-tabs .product-tab a:active {
  background: #f2f2f2;
  color: #161616;
}

.sidebar-light .nav-sidebar .nav-link {
  color: #262626;
  border-right: solid 3px transparent;
}

.create-products-tabs .product-tab a.active,
.create-products-tabs .product-tab a:hover {
  font-weight: 600;
  color: #161616 !important;
  background-color: #E7EEFF !important;
  border-right: solid 3px #2D6DF6 !important;
  border-radius: 0 !important;
}

.rsc-navigation .product-tab a:hover {
  color: #161616 !important;
  font-weight: 600 !important;
  border-left: solid 3px #161616;
  background-color: #f3f3fb !important;
}

.rsc-navigation .product-tab a.active .closeafter5 {
  display: block;
}

.rsc-tab:hover .closeafter5 {
  display: block;
}

.rsc-tab>.closeafter5 {
  border-radius: 5px;
  height: 16px;
  width: 18px !important;
  top: 10px !important;
  right: 17px !important;
  display: none;
  padding: 5px 0 !important;
}

/* .rsc-tab .closeafter5 .icon-cross3 {
  top: -8px;
} */

.create-products-tabs .multipart-submenu .product-tab a {
  padding: 13px 16px !important;
  font-size: 14px;
  font-weight: 400;
}

.create-products-tabs .multipart-submenu .product-tab a:hover {
  font-weight: 600;
  color: #161616;
}

.rsc-pading35 {
  padding-left: 35px !important;
}

.create-products-tabs .product-tab a {
  border-radius: 3px !important;
  padding: 8px 16px;
  font-size: 15px;
  background-color: transparent !important;
}

.content-completeness-score {
  padding: 0px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}



.content-completeness-score p {
  font-size: 16px;
  color: #161616;
  text-align: left;
  font-weight: normal;
}

.content-completeness-score a {
  font-size: 14px;
  color: #161616;
}

.content-completeness-score a span {
  font-weight: bold;
}

.css-6q0nyr-Svg {
  color: #000;
}

/* language Sidebar End */

/* Modal section start */

.modal-body {
  max-height: 510px;
  overflow: auto;
}

#preview-version-modal .modal-body,
#compare-version-modal .modal-body {
  max-height: unset;
  overflow: unset;
}

.modal-footer {
  padding: 10px 20px !important;
  z-index: 0;
}

.popup-icons {
  height: 22px;
}

.approve-modal {
  background: rgba(0, 0, 0, 0.2);
}

.approve-modal .modal-header {
  padding: 5px 20px;
  border: 1px solid #e8e8e8;
}

.approve-modal .modal-content {
  border-radius: 0px;
  box-shadow: 0px 3px 6px #16161629;
  max-height: auto;
}

.approve-modal .modal-title,
.approve-modal .close {
  font-size: 22px;
  color: #161616;
  text-align: left;
  font-weight: normal;
}

.position-btn {
  position: relative;
}

.position-btn img {
  padding: 0;
  position: absolute;
  top: 6px;
  left: 8px;
}

.popup-list-btn img {
  position: absolute;
  top: 9px;
  left: 12px;
}

.approve-btn-popup img {
  top: 7px;
}

.popup-submit {
  height: 38px !important;
  top: -1px;
}

.product-success-btn {
  top: 11px !important;
}

.modal.show {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.modal-dialog {
  max-width: 800px;
  width: 800px;
  margin: 0 auto;
}

.preview-table td img {
  cursor: pointer;
}

.preview-table .selected-version-tr td img {
  pointer-events: none;
  cursor: not-allowed;
}

.preview-popup .modal.show {
  max-width: 600px !important;
  margin-left: auto !important;
}

.popup-import .styled-file-select {
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  position: relative;
  width: 90%;
}

.popup-import .styled-file-select input[type="file"] {
  position: absolute;
  width: 90%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  color: #ecf0f1;
  opacity: 0;
}

.popup-import .styled-file-select input[type="text"] {
  box-sizing: border-box;
  height: 36px;
  width: 90%;
  display: block;
  border: 1px solid #d9d9d9;
  outline: none;
  text-indent: 10px;
  background-color: #f3f3f3;
  padding: 0;
}

.popup-import .styled-file-select span {
  display: block;
  position: absolute;
  right: -50px;
  top: 0;
  text-align: center;
  line-height: 30px;
  z-index: 0;
  font-size: 24px;
}

.popup-import .field-row input[type="text"].no-border {
  border: none;
}

.popup-import .field-row input[type="text"].no-bg {
  background-color: #fff;
}

.preview-popup .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.preview-popup .modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.preview-popup .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.custom-cards-width {
  width: 20% !important;
}

.preview-popup .modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.preview-popup .modal.right.fade.in .modal-dialog {
  right: 0;
}

.highlighted-versions {
  background-color: #bdffbb !important;
  padding: 15px 60px;
}

.compare-versions {
  width: 23%;
}

.compare-width-versions {
  width: 38.5%;
  padding: 15px 8px !important;
}

.modified-date {
  display: inline-block;
  padding: 15px 8px;
}

.head-text {
  background-color: #f0f0f7;
  font-weight: bold;
  padding: 8px 0;
}

.preview-basic-data,
.preview-compare-basic-data {
  position: sticky;
  top: 40px;
  z-index: 99;
  background-color: #ffffff;
}

.benelux-head_description {
  color: #fff;
  text-align: center;
}

.head_description-text,
.head_description-text p {
  max-width: 500px;
  width: 100%;
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  color: #000;
  line-height: normal;
  opacity: 1;
}

.content-wrapper {
  margin-top: -2px;
}

.benelux-header-img path {
  fill: #fff;
}

.prev-page {
  left: 0;
  position: absolute;
  top: 28px !important;
}

.page-header-content h4 .prev-page {
  top: -4px;
}

.selected-version-tr {
  background: #d2ffd1 !important;
}

.preview-popup .modal-content {
  border-radius: 0;
  border: none;
}

.preview-popup .modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.widget-area {
  float: left;
  position: relative;
  width: 100%;
}

.status-upload form textarea {
  background: none repeat scroll 0 0 #fff;
  float: left;
  font-size: 14px;
  height: 142px;
  letter-spacing: 0.3px;
  padding: 20px;
  width: 100%;
  outline: none;
  border: 1px solid #f2f2f2;
  resize: none;
}

.product-btn.product-calendar-date span {
  font-size: 13px;
  margin-right: 8px;
  min-width: 200px;
  width: 100%;
}

/* ----------------upload-item-modal-css--------------- */

.wrapperItem {
  display: grid;
  grid-template-columns: calc(100% - 50px) 50px;
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 8px;
}

.progressBar {
  width: 100%;
  height: 15px;
  background-color: lightgray;
  margin-bottom: 8px;
  border-radius: 20px;
}

.progressBar>div {
  height: 15px;
  background-color: #2cab2c;
  border-radius: 20px;
}

.leftSide>label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.percentage {
  margin-left: 12px;
  margin-top: -4px;
}

/* --------------------------mandatory-modal-css---------------------- */

.my-modal-custom-class {
  position: fixed;
  height: 310px;
  width: 600px;
  top: 0;
  box-sizing: border-box;
  border: 2px solid #cdcdcd;
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1000;
  transition: 0.1s;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #16161629;
}

.mandatory_header_container {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  align-items: center;
  top: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 5;
}

.header_title {
  height: auto;
  width: 85%;
  padding: 8px 20px;
  box-sizing: border-box;
}

.header_action {
  display: flex;
  width: 16%;
  z-index: 1;
  box-sizing: border-box;
  justify-content: flex-end;
  padding-right: 5px;
}

.header_title h3 {
  color: #43425d;
  letter-spacing: 0.5px;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  z-index: 1;
  margin: 0;
}

.header_action img {
  cursor: pointer;
}

.header_action i {
  cursor: pointer;
}

.header_action button {
  border: none;
  background-color: transparent;
}

.my-modal-custom-class .body {
  height: 400px;
  width: 100%;
  padding: 45px 0 10px 0;
  box-sizing: border-box;
  overflow: auto;
  background: #fff;
}

.feild_table_container {
  height: auto;
  width: 100%;
  background: #fff;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  margin: 0 auto;
}

.table_header {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 2px solid #eee;
}

.table_header #th {
  display: flex;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: gray;
}

.table_body {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #eee;
}

.table_body:last-child {
  border-bottom: none;
}

.table_body:hover {
  background: #eee;
  transition: 0.2s ease-in-out;
}

.table_body #td {
  display: flex;
  height: 40px;
  margin: 1px;
  justify-content: flex-start;
  align-items: center;
}

.table_body #td .field_name {
  color: #161616;
  cursor: pointer;
}

.table_body #td .field_name:hover {
  color: #161616;
}

.mandatory-table thead th {
  color: #a3a6b4 !important;
  font-size: 13px;
  font-weight: bold;
  border: none;
  padding: 10px 14px;
  line-height: normal;
}

.mandatory-table .table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.mandatory-table .table :nth-of-type(even) {
  background-color: #fff;
}

.mandatory_modal_header button:focus {
  outline: none !important;
}

.re-size {
  margin-top: -2px;
  margin-right: 5px;
  height: 18px;
}

.add-language {
  min-height: 150px;
}
.btn:not(.bg-transparent):not([class*="btn-outline"]):not(.btn-light):not(.btn-link):not([class*="alpha-"]):not(.fab-menu-btn):active{
  box-shadow: unset !important;
  color: #0056FF !important;
}
.btn-secondary:active
.btn-primary:focus,
.btn-secondary:focus {
  outline: unset !important;
 background-color: transparent !important;
 box-shadow: none !important;
 color: #0056FF !important;
}
button:focus{
  outline: unset !important;
 
}
.progress-modal {
  position: fixed;
  z-index: 2;
  right: 75px;
  bottom: 0;
  min-width: 300px;
  width: 400px;
  overflow: hidden;
}

.progress-modal .errorMsg {
  font-size: 14px;
}

.progress-notification {
  background-color: rgb(255 255 255);
  border: 1px solid #5b6856;
  color: #5b6856;
  width: auto !important;
  float: left;
  font-weight: bold;
  border-left: 1px solid #5b6856 !important;
  padding-top: 0.3125rem !important;
  right: 0;
}

.progress-notification-syncing {
  background-color: rgb(255 255 255);
  border: 1px solid #5b6856;
  font-weight: bold;
  color: #5b6856;
  width: auto !important;
  float: left;
  border-left: 1px solid #5b6856 !important;
  top: 2px;
  padding-top: 0.3125rem !important;
  right: 0;
}

.progress-notification-success {
  background-color: rgb(255 255 255);
  border: 1px solid #469920;
  width: auto !important;
  color: #41901c;
  font-weight: bold;
  float: left;
  border-left: 1px solid #41901c !important;
  top: 2px;
  padding-top: 0.3125rem !important;
  right: 0;
}

.progress-notification-failed {
  background-color: rgb(255 255 255);
  border: 1px solid #eb3e16;
  width: auto !important;
  color: #eb3e16;
  font-weight: bold;
  float: left;
  border-left: 1px solid #eb3e16 !important;
  top: 2px;
  padding-top: 0.3125rem !important;
  right: 0;
}

.progress-notification-failed i,
.progress-notification i,
.progress-notification-success i {
  text-align: left !important;
}

.errorMsg {
  color: #eb3e16 !important;
  min-width: 340px;
  margin-top: 5px;
  font-size: 16px !important;
}

.card-body.uncertified-product-form.for-progress label {
  text-transform: none !important;
}

.card-body.uncertified-product-form.for-progress {
  padding: 0 !important;
}

.loader-popup-body {
  max-height: 280px;
  overflow: auto;
}

.uploaditem-card {
  width: 97%;
  margin: 6px auto;
  padding: 0 !important;
  max-width: 95%;
}

.uploaditem-body {
  padding: 10px 12px !important;
}

.header-title {
  font-size: 16px !important;
  color: #43425d !important;
}

.progressbar-header {
  background: #e5e5e5 !important;
}

.uncertified-product-asset-upload {
  background: #e0e0eb !important;
  padding: 5px 0 6px 15px !important;
}

.colorCls {
  color: #43425d !important;
}

.cursor-pointer {
  cursor: pointer;
  color: #0056ff;
}

.fieldcollection-item-remove {
  position: absolute;
  right: 3px;
  top: 5px;
}

.field-icon-control {
  position: absolute;
  right: 16px;
}

/* Modal section end */
.deshboard-meter {
  max-height: 20px;
}

/* Card Section Start */

.card {
  margin-bottom: 20px;
  border-radius: 0 !important;
}

.global-table .card-header .card-title {
  color: #161616;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.card-title {
  color: #161616;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.global-card {
  border: solid 1px #d9d9d9;
  border-radius: 0;
  padding: 0;
}

.first-cars-section {
  min-height: 133px;
  margin-bottom: 35px;
}

.uncertified-product .card-header .card-title {
  color: #161616;
  font-size: 22px;
}

.card-right-text {
  color: #ff1b01;
  font-size: 16px;
  padding-right: 20px;
}

.products-bars .card-title {
  color: #a1a0ae;
  font-size: 0.95rem !important;
}

.card-status {
  border-bottom: #f0f0f7 solid 1px;
  position: relative;
  padding: 12px 0;
}

.card p {
  color: #161616;
  font-size: 14px;
  opacity: 1;
}

/* Card Section End */

/* breadcrumb Start */

.breadcrumb-item.active {
  color: #161616;
}

.breadcrumb-item {
  font-size: 14px;
  font-family: "Aktiv-Grotesk";
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 4px;
}

a.breadcrumb-item,
a.breadcrumb-elements-item {
  color: #0056ff;
}

.breadcrumb-item+.breadcrumb-item::before {
  padding-right: 4px;
}

.active-breadcrumb {
  color: #ffffff !important;
}

/* breadcrumb End */

/* All button start */

a,
a:hover {
  color: #0056ff;
  text-decoration: none;
}

.view-all {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 9s ease;
  color: #2D6DF6;
  font-weight: bold;
  font-size: 14px;
}

.view-all::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #2D6DF6;
  transition: width 0.5s;
  position: absolute;
  bottom: 0;
}

.view-all:hover::after {
  width: 100%;
}

.arrow-hide {
  margin-left: 5px;
}

/*.view-all:hover .arrow-hide {
  display: block;
} */

.btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border: 0;
  border-radius: 0;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  transition: all 0.7s;
  margin-bottom: 5px;
  white-space: normal;
  word-wrap: break-word;
  text-transform: capitalize;
}

.btn {
  margin-left: 0.75rem;
}

.btn:first-child {
  margin-left: 0rem;
}

/* .btn::after {
  content: "";
  background: #060a45;
  position: absolute;
  z-index: -1;
  /* padding: 0.85em 0.75em;
  display: block;
  transition: all 0.7s;
  height: 100%;
  left: -100%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 1.1s;
  transform-origin: top left;
  width: 0;
} */

.filter-icon-btn::after,
.filter-submit-btn::after {
  transition-duration: 0.6s;
}

.btn:hover::after {
  height: 100%;
  width: 230%;
}


.page-title .list-icons {
  height: 10px;
  margin-left: 5px !important;
}

.list-icons-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.list-page-icon {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 23px;
  width: 23px;
  display: block;
  border: solid 1.5px #161616;
}

.list-page-icon img {
  padding: 3px;
}

.list-page-icon::after,
.list-icons-item::after {
  content: "\e9c9";
  font-family: "icomoon";
  padding: 0.85em 0.75em;
  background: #2D6DF6;
  position: absolute;
  z-index: -1;
  display: block;
  transition: all 0.7s;
  height: 100%;
  left: -195%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.4s;
  transform-origin: top left;
  width: 0;
}

.list-icons-item:hover::after,
.list-page-icon:hover::after {
  height: 100%;
  width: 290%;
}

/* .filter-submit-btn::after {
  left: -75%;
}
.filter-submit-btn:hover::after {
  height: 100%;
  width: 190%;
} */
.btn-primary {
  padding: 9px 32px; 
  background-color: transparent;
  background: linear-gradient(45deg,#060a45 50%,#2d6df6 0);
  background-position: 100% 100%;
  background-size: 400% 100%;
  border-color: #2d6df6;
  color: #fff;
  transition: all .7s;
}

.btn-primary:hover {
  background-position: 0 100%;
  border-color: #060a45!important;

}

.btn-secondary {
  background: linear-gradient(45deg,#2D6DF6 50%,#fff 0);
  background-position: 100% 100%;
  background-size: 400% 100%; 
  color: #2D6DF6;
  border: solid 1px #2D6DF6;
  background-color: #fff;
}

.btn-secondary:hover {
  background-color: transparent;
    background-position: 0 100%;
    border-color: #2d6df6;
    color: #fff;
}

.choose-file {
  color: #666666;
  background: #fff;
  border: 1px solid #b3b3b3;
  font-weight: normal;
  border-radius: 3px;
  margin-top: 4px;
  font-size: 14px;
}

.nav-tabs {
  border-bottom: none;
  border-top: 1px solid #B3B3B3;
}

.dashboard-product .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
  position: absolute;
  top: -2px;
  left: 0;
}
.remove-arrow:hover .filter-icon-btn,
.search-attribute-conainer:hover .filter-icon-btn{
  border-color: #000;
    border-bottom: solid 1px #2D6DF6 !important;
}
span.filter-icon-btn:hover {
  background: #2D6DF6;
  z-index: 1;
}
.filter-icon-btn:hover i{
  color: #FFF;
}
.form-control:focus~span.filter-icon-btn {
  border-top: solid 1px #333333;
  border-right: solid 1px #333333;
  border-bottom: 2px solid #2D6DF6;
}

.form-control:focus~input[type="reset"] {
  border-top: solid 1px #333333;
  border-bottom: 2px solid #2D6DF6;
}

.filter-icon-btn,
.filter-submit-btn {
  font-size: 12px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 0;
  cursor: pointer;
}

.product-btn {
  width: auto !important;
  display: flex;
  justify-content: flex-end;
  position: initial;
}

.error-btn {
  border: 1px solid #ba2121;
  color: #ba2121;
}

.filter-icon-btn {
  background-color: transparent;
  border: 1px solid #C0C0C0;
  border-left: 0;
  height: auto !important;
  z-index: 1;
}

.filter-icon-btn .fa {
  color: #000;
  padding-top: 10px;
}

.filter-icon-btn .fa-search {
  padding-top: 2px !important;
}

/* All button End */

/* product status */

.product-status ol {
  position: absolute;
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex;
  justify-content: left;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 1px;
  list-style: none;
  margin-bottom: 1px;
}

.product-status li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 15px;
  height: 4px;
  margin-right: 3px;
  margin-left: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  transition: opacity 0.6s ease;
}

.card-status>.product-status ol {
  margin-left: 0;
}

.product-status:hover .product_status_tooltip {
  display: block !important;
  transition: 0.3s ease-in-out;
}

.product-status ol .approved {
  background: #00a346;
}

.product-status .in_progress {
  background: #875c00;
}

.product-status .draft {
  background: #875c00;
}

.product-status .error {
  background: #ba2121;
}

.error {
  color: #E2133D;
  font-size: 13px;
}

.product-status .awaiting_approval {
  background: #b5cc18;
}

.product-status .pending_live {
  background: #eb9045;
}

.product-status .queued_for_publishing {
  background: #eb9045;
}

.product-status .approval_processing {
  background: #b5cc18;
}

.product-status .delisted,
.product-status .delist_requested {
  background: #6e6e6f;
  color: #6e6e6f !important;
}

.product-status .locked_for_delisting {
  background: #6e6e6f;
}

.product-status .rejected {
  background: #161616;
}

.product-status .awaiting_core_data_approval {
  background: #004085;
}

.product-status .outstanding_rejection {
  background: #885e13;
}

.product-status .awaiting_review {
  background: #00aaed;
}

.product-status .awaiting_pre_approval {
  background: #ff00ee;
}

/* -----------end-of-product-status */

/* completeness start */

.completeness {
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 5px;
}

.completeness a,
.completeness span {
  color: #333 !important;
  font-weight: normal !important;
}

.square {
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
}

.completeness_0_20,
.bg-completeness_0_20 {
  background-color: rgb(224, 45, 0);
  color: #161616 !important;
}

.completeness_21_40,
.bg-completeness_21_40 {
  background-color: rgb(246, 150, 30);
  color: #161616 !important;
}

.completeness_41_60,
.bg-completeness_41_60 {
  background-color: rgb(236, 219, 35);
  color: #161616 !important;
}

.completeness_61_80,
.bg-completeness_61_80 {
  background-color: rgb(174, 226, 40);
  color: #161616 !important;
}

.completeness_81_100,
.bg-completeness_81_100 {
  background-color: rgb(106, 215, 45);
  color: #161616 !important;
}

.completeness_chp_0_20,
.bg-completeness_chp_0_20 {
  background-color: #FF7553;
  border: 1px solid #FF471A;
  color: #000000 !important;
}

.completeness_chp_21_40,
.bg-completeness_chp_21_40 {
  background-color: #F8B056;
  border: 1px solid #F6961E;
  color: #000000 !important;
}

.completeness_chp_41_60,
.bg-completeness_chp_41_60 {
  background-color: #F1E45A;
  border: 1px solid #ECDB23;
  color: #000000 !important;
}

.completeness_chp_61_80,
.bg-completeness_chp_61_80 {
  background-color: #C2E95E;
  border: 1px solid #AEE228;
  color: #000000 !important;
}

.completeness_chp_81_100,
.bg-completeness_chp_81_100 {
  background-color: #8FE162;
  border: 1px solid #4FEB11;
  color: #000000 !important;
}

.progress-bar {
  padding: 0px 15px 0px 3px;
}

/* completeness end */

/* product edit page start */

.approval-view-accordion .base-type-field-header {
  padding-bottom: 5px;
}

.base-type-field-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #d1d9df;
}

.base-type-field-header-top {
  margin-top: 15px;
  border-top: 1px solid #d1d9df;
}

.base-type-field-header-top h2 {
  margin-top: 15px;
}

.base-type-field-header-top h2,
.base-type-field-header h2 {
  color: #161616;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;

}

.approval-view-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #d1d9df;
}

.approval-view-header-top {
  margin-top: 15px;
  border-top: 1px solid #d1d9df;
}

.approval-view-header-top h2 {
  margin-top: 15px;
}

.approval-view-header-top h2,
.approval-view-header h2 {
  color: #161616;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

.comment-section p {
  margin: 0;
  font-size: 16px;
}

.comment-section-textarea {
  margin-bottom: 15px;
}

/* product edit page end */

/* spedo section */

/* .speedometer{
  height:130px;
  max-width:250px;
} */
.certifiedSpedMetr > div >.speedometer{
  height: 160px !important;
  margin: 0 auto;

}

.speedometer-section {
  min-height: 28px;
  position: relative;
  margin-left: 35px;
  width: 265px;
  margin-top: 10px;
  transition: 0.5s;
}

.banner-show span.banner-toggle {
  position: absolute;
  top: 0;
  background: #f0f0f7;
  left: -20px;
  height: 30px;
  cursor: pointer;
  border: 1px solid #494dc1;
}

.banner-show span.banner-toggle-txt {
  font-size: 16px;
  margin: 2px 5px;
  color: #494dc1;
}

.speedometer-section span {
  position: absolute;
  top: 0;
  background: #f0f0f7;
  left: -27px;
  height: 38px;
  cursor: pointer;
  transition: all 5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speedometer-section span img {
  height: 30px;
  transition: 0.5s all;
}

.upArrow {
  transform: rotate(180deg);
  padding-bottom: 3px;
}

.speedometer-section p {
  font-size: 14px;
  padding: 7px;
  color: #161616;
  margin: 0;
}

.speedometer svg {
  margin-left: 6px;
}

.item-top {
  align-items: start !important;
}

/* spedo section  end*/

/* scroll section */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f0f0f7;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #999696;
  border: 1px solid #999696;
}

/* scroll section end */

/* Table start */

table {
  font-size: 14px;
  border: solid 1px #d9d9d9;
}

.custom-style-table>thead {
  border-top: solid 1px #d9d9d9;
}

.table-striped tbody tr {
  border: solid 1px #d9d9d9;
}

.breakpoint {
  cursor: default !important;
}

.custom-style-table>thead {
  background-color: #ffffff;
}

.custom-style-table>thead>tr>th {
  color: #161616 !important;
  font-weight: bold;
  border: none;
  padding: 8px;
  line-height: normal;
}

.custom-style-table>tbody>tr>td {
  color: #161616;
  line-height: normal;
  border: none;
  padding: 10px 6px;
  position: relative;
  text-align: left;
  cursor: default;
}

.custom-style-table tbody tr td a {
  color: #0056ff;
}

.custom-style-table tbody tr td a img {
  display: block;
  margin: 0 auto;
}

.list-thumbnail-img {
  border: solid 1px #f0f0f7;
  padding: 1px;
  background: #fff;
  height: 70px;
  width: 70px;
}

.assets-table-main .list-thumbnail-img {
  height: 90px;
  width: 90px;
}

.table-striped tbody tr:nth-of-type(odd),
.version-preview .table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even),
.version-preview .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.dataTables_info {
  display: none;
}

.dataTables_length {
  position: absolute;
  bottom: -20px;
  right: 0;
}

.dataTables_length>label>span:first-child {
  display: none;
}

.paging_simple_numbers {
  margin-right: 130px;
}

.datatable-header {
  border: none;
}

.select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
  border-color: #d5d5df;
  padding: 8px 0;
  color: rgba(61, 59, 59, 0.8);
  font-size: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04) !important;
}

.select2-selection__rendered {
  line-height: 17px !important;
}

.select2-container .select2-selection--single {
  height: 35px !important;
}

.select2-selection__arrow {
  height: 34px !important;
}

.dataTables_paginate .paginate_button,
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  border: solid 1px #e8e9ec;
  color: rgba(77, 79, 92, 0.5);
  background: #fff;
  font-size: 14px;
  margin-left: 10px;
}

#selected-row {
  outline: solid 1px #333333;
  background: #d9d9d9 0% 0% no-repeat padding-box;
}

table .form-check {
  padding-left: 0;
}

table .form-group {
  margin-bottom: 0;
}

.custom-style-table.dataTable thead .no-sort:after,
.custom-style-table.dataTable thead .no-sort:before {
  content: "" !important;
  opacity: 0 !important;
}

.nutri-table-control .breadcrumb-elements-item+.breadcrumb-elements-item {
  margin-left: unset !important;
}

.nutri-table-control .breadcrumb-elements-item em {
  font-size: 20px;
  margin-right: 4px;
}

.nutri-table-control .breadcrumb-elements-item+.breadcrumb-elements-item::before {
  content: "|";
  margin: 0 6px;
  font-weight: bold;
}

.sort-table h4 {
  color: rgba(67, 66, 93, 0.61);
  font-size: 18px;
  font-weight: 400;
  padding: 5px 0;
}

.nutritional-table.custom-style-table>tbody>tr>td:nth-child(2) {
  padding: 6px 14px !important;
}

.inner-page-products .dataTables_filter {
  display: none;
}

.inner-page-products .datatable-scroll td,
.inner-page-products .datatable-scroll td span.badge,
.inner-page-products .datatable-scroll th {
  white-space: normal;
}

.table .table-actions {
  width: 50px;
}

th.sortable {
  position: relative;
  cursor: pointer;
}

th.sortable::after {
  font-family: FontAwesome, serif;
  content: "\f0dc";
  position: relative;
  color: #999;
  font-size: 11px;
  margin-top: 1px;
  margin-left: 5px;
}

th.sortable.asc::after {
  content: "\f0d8";
}

th.sortable.desc::after {
  content: "\f0d7";
}

th.sortable:hover::after {
  color: #333;
}

.nutritional-table>thead>tr>th,
.nutritional-table tr,
.nutritional-table .table th,
.nutritional-table .table td {
  border: 1px solid #d5d5df !important;
}

.nutritional-table .sub-head-footable th {
  border: 1px solid #d5d5df !important;
  background-color: #e9edf9 !important;
}

.nutritional-table .footable-row-detail-group {
  font-weight: bold;
}

.nutritional-table .footable-row-detail-name {
  padding-left: 9px;
}

.nutritional-table .footable-row-detail-name,
.nutritional-table .footable-row-detail-value {
  background-color: #e9edf9 !important;
}

.custom-table-actions {
  margin: 10px;
}

.nutritional-table td.footable-first-column {
  display: inline-flex;
  width: 100%;
}

.nutritional-table td.footable-visible {
  border-right: 1px solid #d5d5df !important;
}

.nutritional-table td.footable-visible.footable-last-column {
  border-right: 0;
}

.nutritional-table td.footable-visible.footable-first-column {
  border-left: 0;
}

.edit-icon-list {
  height: 20px;
  position: relative;
  top: -3px;
}

.edit-icon-list-ssa {
  /* display: inline-block !important;
  position: relative;
  top: -7px; */
  font-size: unset !important;
}

.no-data-found-container {
  display: flex;
  background: #cce5ff;
  border: solid 1px #b8daff;
  padding: 10px;
  align-items: center;
}

.no-data-found-container p {
  color: #004085;
  font-size: 14px;
  margin: 0px;
}

/* Table End */

/* pagination start */

.page-item.active .page-link,
.page-item.active .page-link:hover {
  background-color: transparent;
  border-bottom: #2D6DF6 solid 1px;
  color: #2D6DF6;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-weight: 600;
}

.page-link {
  color: #575757;
  border: none;
  padding: 0;
  min-width: 0;
}

.page-link:hover {
  z-index: 2;
  color: #333;
  text-decoration: none;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #161616;
  border-radius: 0;
}

.css-txyhzt-control {
  border-radius: 0px !important;
  border-color: #b3b3b3 !important;
}

.css-1uccc91-singleValue {
  color: #000 !important;
}

/* pagination end */

/* select menu start  */

.css-g1d714-ValueContainer {
  padding: 2px 0.875rem !important;
}

.select__indicator,
.select__value-container {
  padding: 0px 0.875rem !important;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.custom-dropdown select {
  border: solid 1px #d5d5df;
  color: rgba(61, 59, 59, 0.8);
  font-size: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04) !important;
  margin: 0;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  min-width: 90px;
}

.revision-dropdown {
  width: 25%;
  margin-top: -2px;
}

.custom-dropdown select:focus {
  outline: none;
}

.custom-dropdown select option {
  padding: 10px 15px;
  height: 20px;
  font: inherit;
  margin: 10px;
  line-height: 35px;
  display: block;
}

.custom-dropdown select::-ms-expand {
  display: none;
}

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after {
  color: #a4afb7;
}

.custom-dropdown select[disabled] {
  color: rgba(61, 59, 59, 0.8);
  font-size: 16px;
  background-color: #fafafa;
  opacity: 1;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 34px;
  margin-top: 1px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 4px 10px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: 1px solid #d5d5df;
}

.select-styled:after {
  content: "\e9c5";
  font-family: "icomoon", serif;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #a4afb7;
  font-size: 22px;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d5d5df;
}

.select-options li {
  margin: 0;
  padding: 6px 0;
  text-indent: 15px;
  border-top: 1px solid #d5d5df;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  color: rgba(61, 59, 59, 0.8);
  font-size: 15px;
}

.select-options li:hover {
  background: #fff;
}

.select-options li[rel="hide"] {
  display: none;
}

.select-combo {
  width: 100%;
}

.select-combo>.select {
  width: 75%;
}

.select-combo>.select+.select {
  width: 21%;
  margin-left: 1%;
}

.select-combo>.select+.select>.select-styled {
  padding: 0 15px 0 5px;
  line-height: 33px;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  top: 55px;
  padding: 5px;
  border: solid 1.5px #dad5d5;
  box-shadow: none !important;
}

.dropdown-menu:after {
  content: " ";
  position: absolute;
  right: 15px;
  top: -7px;
  width: 10px;
  height: 10px;
  border: solid 1.5px #dad5d5;
  transform: rotate(45deg);
  border-right: 0;
  border-bottom: 0;
  background: #fff;
}

.dropdown-item.active {
  color: #4d565c !important;
  background-color: #ffffff !important;
  font-size: 14px;
  font-weight: 400;
}

.dropdown-item {
  text-align: left;
  display: block;

  color: #333 !important;
}

.dropdown-item>img {
  max-height: 10px;
  margin-right: 2px;
}

/* .dropdown-item:hover {
  background-color: #ffffff !important;
} */

.rc-tree-select-selection__rendered {
  padding: 4px;
}

.rc-tree-select-allow-clear .rc-tree-select-selection-selected-value {
  color: #000;
}

.rc-tree-select-selection__clear {
  margin: 10px !important;
}

.rc-tree-select-arrow:after {
  content: "\e9c1" !important;
  font-size: 22px;
  color: #000;
  font-family: icomoon;
  border-color: unset !important;
  border-style: unset !important;
  top: 7% !important;
}

.rc-tree-select-arrow {
  height: 35px !important;
  /* top: 9px !important; */
  width: 35px !important;
  right: 10px !important;
  /* border-left: 1px solid #ccc; */
}

.padd-0 {
  padding-left: 0 !important;
}

.notification-msg-dropdown {
  width: 325px;
  padding: 0;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31);
}

.notification-msg-dropdown:after {
  right: 18px !important;
}

.notification-section {
  background: #fff;
}

.notification-section p {
  margin: 0;
  font-size: 14px;
  padding: 10px;
}

/* Select menu end */

/* comment page start */

.comment-card-body {
  border-top: 1px solid #d8d8de;
  width: 98%;
  margin: 0 auto;
  padding: 10px;
}

.comment-card {
  padding-bottom: 0 !important;
}

.comment-card p,
.comment-card-body p {
  font-size: 14px;
  color: rgba(61, 59, 59, 0.8);
  margin: 5px 0;
}

/* comment page end */

/* Assect page accordion start */

.asset-header {
  background: #f5f6fa;
  padding: 8px 20px;
}

.asset-accordion {
  border: solid 1px #d1d9e0;
  margin: 20px;
}

.asset-header .card-title {
  font-weight: 600;
  margin: 0;
}

[data-action]:after {
  color: #666;
}

/* Assect page accordion End */

/* approval page start */

.approval-view-accordion .form-row .custom-checkbox,
.approval-view-accordion .panel .form-check-label .custom-checkbox {
  top: 4px;
}

.image-gallery-slides {
  /* border: solid 1px #d1d9e0; */
  /* padding: 70px; */
  background-color: #161616;
  min-height: 250px;
  /* max-height: 325px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-slides::before {
  /* content: ""; */
  position: absolute;
  top: -46px;
  right: -14px;
  margin: 0 auto;
  transform: rotateZ(-43deg);
  width: 63px;
  height: 99px;
  border-left: solid 70px #2D6DF6;
  border-bottom: solid 70px transparent;
  border-top: solid 70px transparent;
}

.image-gallery-slides::after {
  /* content: ""; */
  position: absolute;
  bottom: -46px;
  left: -10px;
  margin: 0 auto;
  transform: rotateZ(136deg);
  width: 63px;
  height: 99px;
  border-left: solid 70px #666666;
  border-bottom: solid 70px transparent;
  border-top: solid 70px transparent;
}

.custom-approval-view .title {
  padding: 0px 0 0 40px;
}

.approval-main-section {
  padding: 20px 0;
}

.subtext-approval {
  padding-left: 50px;
}

.approval-main-section b,
.brandbank_info_container b,
.downloads-section b {
  min-width: 160px;
  display: inline-block;
}

.brandbank_info_container h3 {
  color: #161616;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  margin: 0;
}

.downloads-section b {
  color: #161616;
  font-size: 14px;
  opacity: 1;
}

.d-assets {
  display: flex;
  flex-wrap: wrap;
}

.d-assets-section {
  display: table;
}

.d-assets-section .doc-section {
  display: table-cell;
}

.custom-approval-view .tab_header {
  background-color: transparent !important;
  margin: 30px 21px;
}

.rsc-container .tab_header {
  background-color: transparent !important;
  margin: 0px;
}

.custom-approval-view .nav-link.active {
  color: #333;
  border-color: #ddd0 #ddd0 #fff0;
  border-bottom: solid 3px #161616;
}

.rsc-container .nav-link.active {
  color: #333;
  border-color: #ddd0 #ddd0 #fff0;
  border-bottom: solid 3px #161616;
}

.fop-block-main-container {
  border: solid 1px #707070;
  border-radius: 90px;
  margin: 5px 3px;
  min-width: 200px;
  max-width: 250px;
  overflow: hidden;
}

.fop-block-main-container p {
  padding: 0 !important;
  margin: 0;
}

.block-left-container {
  width: 70%;
  padding: 7px 0px 0 35px;
}

.block-right-container {
  width: 30%;
  background-color: #666666;
  color: #ffffff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 24px 10px;
  display: flex;
  align-items: center;
}

.fop-block-lozenge-select p {
  color: #43425d;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.fop-box_start span {
  color: #727176;
  font-size: 13px;
}

.Low .fop-block-main-container {
  border: 1px solid #61ba13;
}

.Low .block-right-container {
  background-color: #61ba13;
}

.Low .fop-block-lozenge-select p {
  color: #61ba13;
}

.Medium .fop-block-main-container {
  border: 1px solid #f89300;
}

.Medium .fop-block-lozenge-select p {
  color: #f89300;
}

.Medium .block-right-container {
  background-color: #f89300;
}

.High .fop-block-main-container {
  border: 1px solid #e3001b;
}

.High .block-right-container {
  background-color: #e3001b;
}

.High .fop-block-lozenge-select p {
  color: #e3001b;
}

.uncertified-product-form .custom-checkbox.checked:before {
  left: -1px;
  top: -1px;
  font-size: 15px;
}

.uncertified-product-form .custom-checkbox {
  top: 4px;
}

.approval-view-accordion .custom-checkbox,
.approval-view-accordion .custom-checkbox.checked {
  border: solid 1px #666666;
}

.fop-block-percentage-container span {
  min-height: 30px;
  height: 100%;
  display: block;
}

.custom-approval-view label {
  color: #1a1a1a;
  margin-bottom: 0px;
  font-weight: bold !important;
}

.custom-approval-view label~ul li {
  color: #333333;
  font-size: 14px;
}

.tab-dash-chart div:first-child {
  border-top: 1px solid #d7dae2 !important;
}

.tab-dash-chart div.product_view {
  border-bottom: 1px solid #d7dae2 !important;
}

.custom-approval-view .tab-dash-chart div.product_view {
  border: none !important;
}

.image-gallery-slide-wrapper img {
  max-width: 100%;
  width: auto;
  max-height: 300px;
  object-fit: cover !important;
  overflow: hidden !important;
  object-position: center center !important;
}

.custom-approval-view .image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 1px solid #6d71f9 !important;
  border-radius: 1px;
  box-shadow: inset 0px 0px 0px 1px #6d71f9;
}

.custom-approval-view .image-gallery-thumbnail {
  border: solid 1px #ccc;
}

.cross-icon .custom-checkbox.checked:before {
  content: "\ed6b";
}

.custom-approval-view .tab-content label {
  min-width: auto !important;
}

.rejection-comments-popup {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 12px;
  left: 12px;
  z-index: 90;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 65px;
  width: 65px;
  overflow: hidden;
  padding: 3px 2px 0px 4px;
}

.image-gallery-thumbnail {
  width: 69px !important;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  left: 10px !important;
}

/* approval img section */

/* The actual popup */

.rejection-comments-popup .popuptext {
  visibility: hidden;
  padding: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  bottom: 125%;
  left: 10%;
  box-shadow: 0px 10px 30px #16161629;
}

.rejection-comments-popup textarea {
  height: 100px;
  width: 350px !important;
  resize: none;
  margin: 10px 0;
}

.markdown span {
  color: #161616;
  font-size: 15px;
}

.rejection-comments-popup .popuptext::after {
  content: "";
  position: absolute;
  top: 80%;
  left: -4%;
  margin-left: 0px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
}

.rejection-comments-popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.message-list {
  position: relative;
}

.svg-icon-message {
  cursor: pointer;
  height: 18px;
}

/* approval page End */

/* revision page End */

.revision-page span {
  font-size: 16px;
  color: #161616;
  font-weight: 600;
}

/* revision page End */

/* form section start */
.modal-sub-heading label {
  font-weight: 600;
  margin-top: 20px;
}

.form-control {
  border: 1px solid #C0C0C0;
  color: #161616;
  font-size: 14px;
  text-align: left;
  font: normal normal normal 13px/17px Aktiv-Grotesk;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.datewidth {
  width: 88% !important;
}

input[type="checkbox"] {
  line-height: 1.5em;
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  border: 1px solid #d0d8e8;
  border-radius: 0;
  background: #fff;
  box-sizing: border-box;
}

input[type="file"] {
  width: 90%;
  opacity: 0;
  background: none;
  border: none;
  outline: none;
  height: 33px;
  margin-top: -50px;
}

select.form-control:not([size]):not([multiple]),
.form-control,
.select__control,
.form-control-ui,
.default-height,
.product_view {
  min-height: 36px !important;
}

td.form-control-ui {
  height: 36px !important;
}

.multipart-checkbox .custom-checkbox.checked:before {
  left: 1px;
  top: -1px;
  font-size: 12px;
}

.disable-checkbox label,
.disable-checkbox-label {
  min-width: 0 !important;
  padding: 0px 5px 0 5px;
  font-size: 15px;
}

.disable-checkbox-label {
  color: #b3b3b3;
}

.disable-checkbox {
  margin-top: -5px;
  color: #b3b3b3;
}

/* .disable-opt {
  border: 1px solid #161616 !important;
} */

#val {
  width: 90%;
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 16px;
  text-indent: 10px;
  pointer-events: none;
}

.input-group-append-btn {
  position: absolute;
  right: -50px;
  top: -1px;
}

.form-control:disabled,
.form-control[readonly],
.disabled-input input,
.gpc-disable .rc-tree-select-selection-selected-value,
.disabled-input .select-styled,
.rc-tree-select-disabled,
.select__single-value--is-disabled {
  pointer-events: none;
  background-color: #f2f2f2 !important;
  color: #606060 !important;
  font-size: 14px;
}

.gpc-disable .rc-tree-select-selection__rendered {
  padding: 3px;
}

label {
  color: #161616;
  font-size: 14px;
  margin-bottom: 0 !important;

}


.choose-file-text {
  background: #f2f2f2;
  padding: 8px;
  width: 627px;
  margin-left: 5px;
}

.form-control:focus,
.st-brand .select__control--menu-is-open {
  border-bottom: 2px solid #2D6DF6;
  border-top: solid 1px #333333;
  border-right: solid 1px #333333;
  border-left: solid 1px #333333;
  border-color: #333333 !important;
  border-bottom-color: #2D6DF6 !important;
}

.data-filled-css {
  border: 1px solid #b3b3b3;
}

.data-filled-error {
  border: 1px solid #E2133D !important;
}

.identifi-data-filled-error .rc-tree-select-selection,
.identifi-data-filled-error .select__control,
.identifi-data-filled-error .benelux-group-btn {
  border: 1px solid #E2133D !important;
}

.data-filled-error:focus {
  border: 1px solid #E2133D !important;
  border-bottom: 2px solid #E2133D !important;
}

.data-filled-warning {
  border: 1px solid #f07c00 !important;
  -webkit-border: 1px solid #f07c00 !important;
  -moz-border: 1px solid #f07c00 !important;
  -ms-border: 1px solid #f07c00 !important;
  -o-border: 1px solid #f07c00 !important;
}

.data-filled-warning:focus {
  border: 1px solid #f07c00 !important;
  border-bottom: 2px solid #f07c00 !important;
  -webkit-border: 1px solid #f07c00 !important;
  -webkit-border-bottom: 2px solid #f07c00 !important;
  -moz-border: 1px solid #f07c00 !important;
  -moz-border-bottom: 2px solid #f07c00 !important;
  -ms-border: 1px solid #f07c00 !important;
  -ms-border-bottom: 2px solid #f07c00 !important;
  -o-border: 1px solid #f07c00 !important;
  -o-border-bottom: 2px solid #f07c00 !important;
}

.label-disable {
  color: #999999 !important;
}

/* palceholder start  */
::placeholder,
.css-1wa3eu0-placeholder {
  color: #666666 !important;
  opacity: 1;
  font-size: 14px;
}

.select__placeholder {
  color: #666 !important;
}

/* .rc-tree-select-selection--single .rc-tree-select-selection-selected-value {
  color: #666;
} */

.rc-tree-select-disabled .rc-tree-select-selection-selected-value {
  color: #666;
  padding: 2px;
}

.select-sm.select2-selection--single .select2-selection__rendered {
  color: #666;
  font-size: 14px;
  font-weight: 400;
}

.input-disable::placeholder {
  color: #b3b3b3 !important;
}

/*placeholder end */
.removable-input input[type="text"]:invalid+input[type="reset"] {
  display: none;
}

.removable-input input[type="reset"] {
  background-image: url(/assets/images/icons-svg/close1.svg);
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  border-top: 1px solid #d0d8e8;
  border-bottom: 1px solid #d0d8e8;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  /* margin-left: -24px; */
  background-size: 8px;
  /* left: -4px; */
}

.removable-input {
  width: 97%;
}

.removable-input input.search-filter {
  width: 100%;
  position: relative;
  border-right: 0;
}

.custom-listbox {
  background-color: #e8e8e8;
  height: 20em;
  padding: 1em 0;
  margin: 0 auto;
  width: 100%;
}

#customlist-options {
  height: 100%;
}

#customlist-options option {
  border-bottom: 1px solid #dddd;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
}

#customlist-options option:hover {
  background-color: #e8e8e8;
}

.filter-select .uniform-select {
  border-radius: 0;
}

textarea {
  resize: none;
  color: #161616 !important;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.wmin-sm-100 label {
  font-size: 14px;
  color: #161616;
  font-weight: bold;
}

.file-upload-section {
  padding: 0;
  position: relative;
}

.uniform-checker span,
.uniform-choice span {
  border: 1px solid rgba(0, 0, 0, 0.27);
  top: 0;
}

.uniform-checker,
.uniform-checker span,
.uniform-checker input,
.uniform-choice,
.uniform-choice span,
.uniform-choice input {
  width: 15px;
  height: 15px;
}

.uniform-checker span:after {
  top: -2px;
  left: -1px;
}

.upload-section {
  /* padding: 0 0 0 10px; */
}

.product-identity-panel {
  padding: 20px 27px 20px 20px;
}

.multipart-list .nav-item-submenu.product-tab a {
  padding: 6px !important;
}

.multipart-list .multipart-submenu .product-tab a {
  background: #f0f0f7 !important;
}

.multipart-list .multipart-submenu .product-tab a.active,
.multipart-list .multipart-submenu .product-tab a:hover {
  background: #e0e0eb !important;
}

.multipart-list .nav-item-submenu.product-tab a::after {
  color: #a4afb7 !important;
  font-size: 19px;
  right: 0.4rem;
}

.uniform-select span:after {
  color: #a4afb7;
}

.create-product .product-description {
  width: 100% !important;
}

.multipart-submenu.nav-group-sub {
  display: none;
}

.uncertified-product {
  background: rgba(224, 224, 235, 0.35);
  padding: 5px 0 6px 15px !important;
}

.uncertified-product-form {
  padding: 10px 30px !important;
}

.nav-sidebar .multipart-submenu .nav-item:not(.nav-item-divider) {
  margin-bottom: 0;
}

.checkbox-option .dropdown-toggle:after {
  top: -5px;
}

.custom-checkbox {
  line-height: 1.5em;
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-top: 4px;
  border: 1px solid #666666;
  border-radius: 0;
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.custom-check-skeleton {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: 4px;

}

.custom-checkbox.checked {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid #2D6DF6;
  background-color: #2D6DF6;
  box-sizing: border-box;
}

.d-none-check .custom-checkbox {
  opacity: 0 !important;
}

.label-checkbox input[type="checkbox"],
body input[type="checkbox"] {
  margin: 0;
  display: none;
  opacity: 0;
}

.label-checkbox .form-check {
  padding-left: 0;
}

.label-checkbox .custom-checkbox {
  margin-right: 4px;
  top: 4px;
}

.assets-block .form-group {
  margin-left: 12px;
}

.custom-checkbox:hover,
.rct-icon-uncheck:hover {
  border: 1px solid #2d6df6;
}

.custom-checkbox.checked:before {
  content: "\ed6c";
  font-family: "icomoon", serif;
  position: absolute;
  left: 2px;
  top: -1px;
  transform: translate(-50%, -50%);
  color: #FFF;
  width: 0px;
  font-size: 10px;
  height: 4px;
}

.custom-checkbox.checked.disable-checkbox,
.custom-checkbox.disable-checkbox {
  background-color: #b3b3b3 !important;
  opacity: 0.5;
  border: none;
  cursor: initial;
}

.custom-checkbox.disable-checkbox:before {
  color: #1a1a1a;
  left: 3px !important;
  top: 0px !important;

}

fieldset {
  border: 1px solid #9ba2af !important;
  padding: 10px !important;
  margin: 10px;
  position: relative;
  border-radius: 4px;
}

legend {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  width: auto;
  border: none;
  padding: 5px 5px 5px 10px;
}

.css-1uqeqgm-control,
.select__single-value,
.select__multi-value__label,
.rc-tree-select-selection,
.select__single-value,
.select__multi-value__label,
.rc-tree-select-selection {
  color: #161616;
  font-size: 14px;
}

.rc-tree-select-selection--single .rc-tree-select-selection__clear {
  right: 35px !important;
  top: -11px !important;
}

.approval_data_alignment {
  padding-left: 22px !important;
}

/* --------------------Quantity-input-css-------------------- */

.quant-warning {
  display: inline-flex;
  color: #666666 !important;
}

.quantity {
  font-size: 13px !important;
  color: #161616 !important;
}

/* --------------------form-table-css-------------------- */

.panel-body table th,
td {
  border: 1px solid #d0d8e8;
}

.table-select>.select__control {
  border: none;
}

/* ----------------tagged-table-css--------------- */

.table_editor_tabledata {
  overflow: hidden;
  width: 100% !important;
}

.table_editor_tabledata b {
  font-weight: normal;
}

.table_editor_tabledata i {
  font-style: normal;
}

.table_editor_tabledata u {
  text-decoration: none;
}

.td_tag_warning {
  font-size: 12px !important;
  padding: 5px !important;
  color: red !important;
}

/* ----------------tagged-input-css--------------- */

.editor_container {
  /* min-height: 42px;
  max-height: auto; */
  overflow-x: auto;
  line-height: 25px;
}

.editor_container b {
  font-weight: normal;
}

.editor_container i {
  font-style: normal;
}

.editor_container u {
  text-decoration: none;
}

.tag_warning {
  font-size: 12px !important;
  padding: 5px !important;
  color: red !important;
}

.form-check {
  padding-left: 0 !important;
}

/* form section End */

/* ---------------------------------start-context-menu-css--------------------------- */

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 14px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #161616;
  border-color: #161616;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

/* ---------------------------------end-context-menu-css--------------------------- */

/* massages start */

.alert {
  font-size: 14px;
}

p.alert-error {
  margin-bottom: 2px !important;
  line-height: 1.6;
}

.comments-info {
  background-color: #dadde6;
}

/* massages End */

/* tooltip section start */

.custom-tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.custom-tooltip-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.custom-tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
  z-index: 999 !important;
  margin-left: 14px;
}

.custom-tooltip-bottom::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, 0.7);
  top: 0;
  left: 50%;
  margin-left: -12px;
  transform: translateX(-50%);
  z-index: 999999 !important;
}

.custom-tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.custom-tooltip-left::after {
  border-left: 9px solid rgba(0, 0, 0, 0.7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.custom-tooltip-left-top {
  left: unset !important;
  right: -200px !important;
  min-width: unset !important;
  max-width: unset !important;
  top: 30px;

}

.custom-tooltip-left-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, 0.7);
  transform: translateX(-50%);
  top: -9px;
  right: 193px;


}

.custom-tooltip-left-top:after {
  border-bottom: 9px solid #161616 !important;
}

.custom-tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.custom-tooltip-right::after {
  border-right: 9px solid #393939;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.Tooltip_TooltipMessage__21wjw{
  background: #393939 !important;
}
/* Menu tooltip */

.nav-item:hover>.menu_tooltip {
  display: block;
}

.menu_tooltip {
  display: none;
  min-width: 150px;
  max-width: 210px;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}

.nav-item:hover>.menu_tooltip::after {
  border-right: 9px solid #393939;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "";
  position: absolute;
}

.Tooltip_TooltipMessage__1NOgU {
  background: #161616 !important;
}

.custom-tooltip-right::after {
  border-right: 9px solid #393939 !important;
}

.custom-tooltip-top::after {
  border-top: 9px solid #393939 !important;
}

.custom-tooltip-bottom::after {
  border-bottom: 9px solid #393939 !important;
}

.custom-tooltip-left::after {
  border-left: 9px solid #393939 !important;
}

.menu-tooltip-Message {
  background: #393939;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
  text-transform: initial;
}

/*----------------------------------tooltip-for-list-page---------------------------------*/

.product_status_tooltip {
  display: none;
  height: auto;
  min-width: 200px;
  max-width: 210px;
  opacity: 1;
  text-align: center;
  position: absolute;
  white-space: pre-line;
  left: -225px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 3px;
  background-color: #161616;
  font-size: 13px;
  color: #fff;
  min-height: 60px;
  border: 1px solid #161616;
  font-weight: normal !important;
  line-height: 15px;
  transition: 0.3s ease-in-out;
  text-transform: initial;
}

.position-top {
  left: 90px !important;
  top: 80% !important;
}

.position-top::before {
  border-right: 10px solid #161616 !important;
  right: 208px !important;
  top: 38% !important;
  border-left: 10px solid #fefeff00 !important;
}

.product_status_tooltip::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.8em solid transparent;
  position: absolute;
  right: -20px;
  top: 40%;
  border-left: 10px solid #161616;
}

.basic-select .select__single-value,
.select__multi-value__label,
.rc-tree-select-selection {
  color: #161616;
}

/*----------------------------------tooltip-for-edit-and-details---------------------------------*/

.page-title h4 .product-status-button .product_status_tooltip {
  top: 150%;
  left: -50%;
  z-index: 2000;
  transform: none;
}

.page-title h4 .product-status-button .product_status_tooltip::before {
  left: 45%;
  top: -20px;
  transform: rotate(-90deg);
}

.structured_td_container .product_status_tooltip {
  min-height: auto !important;
  font-weight: 600 !important;
}

.structured_td_container .product_status_tooltip::before {
  top: 30% !important;
  border-left: 10px solid #161616 !important;
}

.structured_td_container:hover .product_status_tooltip {
  display: block !important;
  transition: 0.3s ease-in-out;
}

.list-filter .custom-tooltip-top {
  left: 180% !important;
}

.list-filter .custom-tooltip-top::after {
  left: 32% !important;
}

/* tooltip section END */

/* tab section start */

.create-products-tabs .img-flag {
  vertical-align: middle;
}

.create-products-tabs ul {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.create-products-tabs .mCSB_container {
  height: 100%;
}

.tab-add i {
  color: #161616;
  font-size: 20px;
}

/* tab section end     */

/* switch slider strat */

.supplier-specific .switch,
.mrkt-content .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  top: 7px;
  left: 20px;
}

.mrkt-content .switch {
  left: 0;
}

.supplier-specific .switch input,
.mrkt-content .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.supplier-specific .switch .slider,
.mrkt-content .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.supplier-specific .switch .slider:before,
.mrkt-content .switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.supplier-specific .switch input:checked+.slider,
.mrkt-content .switch input:checked+.slider {
  background-color: #2D6DF6;
}

.supplier-specific .switch input:focus+.slider,
.mrkt-content .switch input:focus+.slider {
  box-shadow: 0 0 1px #2D6DF6;
}

.supplier-specific .switch input:checked+.slider:before,
.mrkt-content .switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: 1px !important;
}

.mrkt-content {
  margin-right: 20px;
}

.mrkt-toggle-txt {
  margin-left: 10px;
  padding: 0px;
  color: #161616;
}

/* Rounded sliders */

.supplier-specific .switch .slider.round,
.mrkt-content .switch .slider.round {
  border-radius: 11px;
}

.supplier-specific .switch .slider.round:before,
.mrkt-content .switch .slider.round:before {
  border-radius: 50%;
}

/* switch slider end */

/* Extra CSS */

.products-bars .select-sm.select2-selection--single {
  padding: 0.2rem 0;
  border-radius: 0;
  border: solid 1px #d7dae2;
}

.products-bars .bar-value {
  font-weight: bold;
  font-size: 25px;
  color: #161616;
}

.image-pie-chart {
  max-width: 85%;
  margin: 0 auto;
}

.service-pdg {
  padding: 3px 4px;
}

.product-stage-list {
  /* color: #161616; */
  font-size: 14px;
  width: 80%;
  display: inline-block;
  line-height: 1.2;
}

.product-stage-list-text {
  color: #161616;
  font-size: 14px;
}

a,
a img {
  outline: none;
  cursor: pointer;
}

/* .header-elements .list-icons .list-icons-item {
  padding-right: 10px;
} */

.current {
  background: #161616 !important;
  color: #fff !important;
}

.icon-database-export {
  font-size: 12px !important;
}

.list-icons span {
  color: #161616;
  font-size: 20px;
}

.main-heading {
  color: #161616;
  font-size: 36px;
  letter-spacing: -0.6px;
  line-height: normal;

}

.plus-icon {
  margin-top: -2px;
  width: 10px;
}

.list-inline-item a {
  color: #161616;
  font-size: 16px;
  font-weight: 400;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.icon-shield {
  margin-top: 5px !important;
  height: 30px;
  margin: 0 7px;
  cursor: pointer;
}

.icon-shield.mt5 {
  margin-top: 10px !important;
}

.padding-asset-btn {
  padding: 5px 20px;
}

.document-assets .select {
  float: right;
  width: 85%;
  min-height: 100%;
  margin-top: 0;
}

.select-section ul.select-lan.popup-lan {
  top: 2px;
  z-index: 2;
}

.select-section ul.select-lan.popup-lan li {
  padding: 0 15px;
}

.select-section ul.select-lan.popup-lan li.first-select {
  margin-bottom: 0;
}

.select-section ul.select-lan.popup-lan li .img-flag {
  vertical-align: middle;
}

.document-assets .select-styled::after {
  right: 4px;
}

.import-field {
  width: 90%;
  height: 37px;
  background-color: white;
  box-shadow: 1px 2px 3px #ededed;
  position: relative;
  border: 1px solid #d8d8d8;
}

.success-product .first-txt {
  padding-right: 30px;
  color: #161616 !important;
  font-size: 14px !important;
  line-height: normal;
  max-width: 200px;
  width: 100%;
}

.success-product .second-txt {
  color: #161616 !important;
  font-size: 14px !important;
  line-height: normal;
}

.pagination-version {
  padding: 25px;
  margin: 10px;
}

.language-sidebar {
  line-height: normal;
  background-color: #e0e0eb !important;
}

.language-sidebar .img-flag {
  margin: 4px;
  height: 10px;
}

.language-sidebar span strong {
  padding-left: 3px;
}

.language-sidebar em img {
  height: 15px;
}

.list-language-item {
  position: relative;
}

.list-language-item:after {
  color: #a4afb7;
}

.language-sidebar .english {
  color: #161616;
  font-size: 16px;
}

.marg1 {
  margin: 1px;
}

.multipart-card p {
  font-size: 18px;
  color: #52526b;
  margin: 0;
  line-height: normal;
  cursor: pointer;
}

.multipart-card p a {
  color: #161616;
}

.multipart-list {
  padding: 16px;
}

.multipart-list a {
  color: #161616;
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  padding: 10px 0;
  display: block;
}

.subscription-section h3 {
  color: #161616;
  font-size: 20px;
  font-weight: 600;
}

.subscription-section p {
  font-size: 14px;
  font-weight: 700;
  color: #161616;
  border-bottom: solid 1px #d5d5df;
  padding: 10px 0;
  margin: 0;
}

.subscription-section {
  padding: 5px;
}

.access-section {
  display: flex;
}

.Accessbtn-mrg {
  margin-top: 5px;
  margin-left: 0;
}

.pointer {
  cursor: pointer;
}

.select2-selection--single {
  border-radius: 0 !important;
}

select:disabled {
  color: #3d3b3b;
  font-size: 14px;
  background-color: #f0f0f7;
  opacity: 1;
}

.height-10 {
  height: 10px;
  top: -1px;
  position: relative;
}

.filter-icon-btn,
.input-group-append .btn,
.input-group-prepend .btn {
  border-radius: 0;
  left: 0px;
  height: 33px;
  padding: 7px 10px;
}

.page-title em {
  font-size: 22px;
}

.product-identity-image {
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 100%;
  width: auto;
  padding: 4px;
  max-width: 100%;
}

.select-section {
  position: relative;
}

.first-select {
  margin-bottom: 15px;
}

.select-section ul.select-lan {
  width: 180px;
  transition: 0.5s;
  position: absolute;
  right: 0;
}

.select-section ul.select-lan li {
  padding: 0 8px;
  z-index: 2;
  font-size: 16px;
  color: #161616;
}

.select-section ul.select-lan li:not(.init) {
  float: left;
  width: 130px;
  display: none;
  background: #fff;
  color: #161616;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}

.select-section ul.select-lan li:not(.init):hover,
ul.select-lan li.selected:not(.init) {
  background: #f6f6f8;
}

.select-section ul li.init {
  cursor: pointer;
}

.products-bars .select-sm.select2-selection--single {
  padding: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14) !important;
}

.products-bars .bar-value {
  font-weight: bold;
  font-size: 25px;
}

.image-pie-chart {
  max-width: 85%;
  margin: 0 auto;
}

hr {
  padding: 0 !important;
  margin: 0;
}

.icon-shield-check {
  color: #f8b365;
}

.filter-icon {
  filter: invert(41%) sepia(49%) saturate(2091%) hue-rotate(164deg) brightness(98%) contrast(105%);
}

.custom-datepicker.icon {
  display: none;
}

.custom-datepicker input[type="date"] {
  display: block;
  position: relative;
  font-size: 14px;
  background-size: 11px;
  background: url("/assets/images/icons-svg/calendar-gray.svg") right 0rem center no-repeat;
  cursor: pointer;
  background-size: 25px;
}

.filter-card .custom-datepicker input[type="date"] {
  background-image: none !important;
}

.custom-datepicker input[type="date"]:focus {
  outline: none;
}

.custom-datepicker input[type="date"][placeholder]:invalid::-webkit-datetime-edit {
  display: none;
}

.custom-datepicker input[type="date"][placeholder]:invalid::before,
input[type="month"][placeholder]:invalid::before,
input[type="week"][placeholder]:invalid::before {
  content: attr(placeholder);
  color: #161616;
}

.custom-datepicker ::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  background: transparent;
}

.custom-datepicker input[type="date"]:hover::-webkit-calendar-picker-indicator {
  opacity: 0.05;
}

.custom-datepicker input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
  opacity: 0.15;
}

.uploader {
  display: block;
  clear: both;
  text-align: center;
  width: 100%;
  max-width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.uploader label {
  clear: both;
  text-align: center;
  color: #4e91ff;
  margin: 0.5rem auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.img-flag {
  margin-top: 3px;
  padding-right: 2px;
}

.uploader label.hover #start .icon-upload {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.3;
}

.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}

.uploader #start.hidden {
  display: none;
}

.uploader #start em.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}

.uploader #response.hidden {
  display: none;
}

.uploader #response #messages {
  margin-bottom: 0.5rem;
}

.uploader #file-image {
  display: inline;
  margin: 0 auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

.uploader #file-image.hidden {
  display: none;
}

.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}

.uploader #notimage.hidden {
  display: none;
}

.uploader progress,
.uploader .progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.uploader input[type="file"] {
  display: none;
}

.uploader .btn {
  display: inline-block;
  clear: both;
  font-size: 16px;
  background: #fff;
  border-color: #4e91ff;
  cursor: pointer;
  color: #4e91ff !important;
  font-weight: 600;
  padding: 5px 15px;
}

.nav-group-sub .nav-item {
  border-bottom: 1px solid #c2c2c2;
  width: 100%;
}

/* .nav-group-sub .nav-item:last-child {
  border-bottom: 0;
} */

.right-menu {
  font-family: "Aktiv-Grotesk";
  font-weight: 400;
  color: #4d565c;
  font-size: 14px;
  text-align: left;
}

.right-menu .dropdown-divider {
  border-top: 1px solid #f1f1f3;
}

.filter-card em {
  font-size: 14px;
  margin-top: -4px;
}

.zoom-btn {
  position: absolute;
  left: 24px;
  top: 25px;
}

.classic-wash h3 {
  color: #161616;
  font-size: 26px;
  font-weight: 400;
  margin: 0;
}

.classic-wash p {
  color: #161616;
  font-size: 15px;
  font-weight: 400;
}

.devider-page {
  border-top: 1px solid #d6d6db;
}

.form-row p span {
  font-size: 14px;
  color: #161616;
  font-weight: bold;
}

.product-target-form {
  padding: 10px 0;
}

.preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  width: auto;
  margin: 10px auto;
}

.sort-section {
  padding-bottom: 30px;
}

.taggable h4 {
  color: #3d3b3b;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
}

.combo-one {
  width: 58%;
  margin-right: 2%;
}

.combo-two {
  width: 40%;
}

.nav-item-submenu>.product-tab>.nav-link::after {
  color: #a4afb7;
  font-size: 23px;
}

.chosen-select {
  min-height: 200px;
}

.chosen-container-multi .chosen-choices {
  border: 1px solid #d5d5df;
  min-height: 72px;
  background: transparent;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  top: 9px;
}

.chosen-container-multi .chosen-choices li.search-choice {
  padding: 7px 20px 7px 5px;
  border: 0;
  background-color: #ececf3;
}

.chosen-container {
  width: 100% !important;
}

.custom-style-table .scorebtn {
  max-width: 80px;
  border: none;
  padding: 3px 5px;

}
/* Extra css end */

/* Responsive Start */

@media (min-width: 1200px),
       (min-width: 600px) and (resolution: 200dpi)  {
  .mobile-card-header {
    flex-wrap: wrap !important;
      }
   
   
 }



 @media only screen and (min-device-width: 767px) and (max-device-width: 1300px) {  
  .mobile-card-header {
    flex-wrap: wrap !important;
      }

  }
@media only screen and (width: 1280px) {    
  table{
    font-size: 10px !important;
  }
  .completeness{
    font-size: 10px !important;
  }
  .mobile-card-header{
flex-wrap: wrap !important;
  }
  .speedometer-section{
    width: 250px !important;
  }
  .list-table-action-btn{
    font-size: 8px !important;
  }
} 

@media only screen and (min-device-width: 930px) and (max-device-width: 1300px) {
  .services-msg-withSidebar {
    left: 120px;
    max-width: 230px;
  }
  
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .image-section .list-thumbnail-img {
    height: 65px !important;
    width: 65px !important;
    border: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1080px) {
  .navbar-brand a {
    margin-left: 65px;
  }
 
  .mobile-hide,
  .header-elements-md-inline .header-elements.mobile-hide {
    display: none !important;
  }

  .custom-home-wrapper {
    padding-left: 56px;
  }

  .dashboard-slider .carousel-caption h3 {
    font-family: "Aktiv-Grotesk";
    font-weight: 400;
    font-size: 2rem;
    line-height: 28px;
  }

  .dashboard-slider .carousel-item .carousel-caption.left-caption {
    height: 90%;
    display: grid;
    justify-content: center;
    align-content: center;
    top: 0;
    width: 100%;
    left: 0;
    padding-left: 78px;
    text-align: left;
  }

  .dashboard-slider .carousel-item .carousel-caption {
    height: 85%;
  }

  .uploader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: inherit;
  }

  .custom-cards-width {
    width: 50% !important;
  }

  .zoom-btn {
    top: -37px;
  }

  label {
    font-size: 13px !important;
  }

  .sidebar-expand-md:not(.sidebar-component) {
    position: fixed;
  }

  .sidebar-secondary {
    padding-top: 54px;
    padding-left: 56px;
  }

  .content-wrapper.inner-page-products {
    /* padding-left: 50px;  */
    padding-left: 90px;
  }
  .card-title{
    white-space:unset;
  }
  .header-elements-inline {
    flex-wrap: wrap;
  }

  .navbar-brand img {
    max-width: 220px;
  }

  .main-heading {
    font-size: 24px;
  }

  .table .table-actions {
    width: 75px;
  }

  .table .footable-toggle {
    float: left;
    margin: 4px 0 !important;
  }

  .custom-style-table .form-check:not(.form-check-inline):last-child {
    display: inline-block;
  }

  .custom-checkbox {
    margin-left: 6px;
  }

  .document-assets .select {
    width: 100%;
  }

  .ssa-list-page {
    padding-left: 60px !important;
  }

  .sticky-header {
    top: 48px;
  }

  .nbb-accordion-header .revision-dropdown {
    margin-top: -23px !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .carousel-inner .carousel-item img {
    min-height: 150px;
    object-fit: cover;
  }

  .nbb-accordion-header .revision-dropdown {
    margin-top: -18px !important;
  }

  .white-space-wrap {
    width: 100%;
  }

  .tab-dash-chart {
    padding-left: 0;
  }

  .col-details-section {
    border-left: 0 !important;
  }

  .product-stage-list,
  .font-size-sm {
    font-size: 11px;
  }

  .activity-log-filter .dropdown-activity {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 8px;
  }

  .service-dd-wrapper {
    max-width: 160px;
    position: absolute;
    right: 18px;
    width: 160px;
    top: 28px;
  }

  .service-dd-wrapper .font-weight-bold {
    padding-top: 0 !important;
  }

  table {
    font-size: 12px;
  }

  .revision-dropdown {
    width: 100%;
    padding: 10px 0;
  }

  .asset-accordion {
    margin: 10px;
  }

  .modal-dialog {
    width: 100%;
  }

  .product-success-btn {
    margin: 10px 0;
  }

  .uncertified-product-form .form-group .form-control,
  .uncertified-product-form .form-group .custom-dropdown,
  .upload-section .form-group .custom-dropdown {
    width: 100%;
  }

  .styled-file-select {
    width: 85% !important;
  }

  .content-wrapper.inner-page-products {
    padding-top: 0;
    margin-top: -1px;
  }

  .footable.breakpoint>tbody>tr .footable-toggle::before {
    top: -1px !important;
  }

  .pagination-version {
    margin: 0;
  }

  .service-pdg label {
    margin: 0;
  }

  .service-dropdown {
    border: none;
    box-shadow: none !important;
    padding: 0 5px;
    height: 20px !important;
  }

  .mrgn10 {
    margin-left: 5px;
  }

  .p0m0 {
    padding: 0;
    margin: 0;
  }

  .pagination-separated {
    margin-bottom: 10px;
    margin-right: 0 !important;
    display: inline-flex;
  }

  .mobile-pagination-separated {
    display: block !important;
    margin: 0 auto;
    margin-left: auto;
    text-align: center;
  }

  .pagination-separated .page-link {
    padding: 2px;
    margin: 0 0 0 12px;
  }

  .upload-section {
    /* padding: 0 10px 0 10px; */
  }

  .file-upload-section {
    border-radius: 5px;
  }

  .create-product-lang.navbar-nav>.nav-item>.dropdown-menu {
    margin-top: 0;
  }

  .form-disable {
    padding: 10px;
  }

  .create-product-lang.navbar-nav .dropdown-menu {
    position: absolute;
  }

  .create-product-lang .navbar-nav-link.dropdown-toggle:not(.caret-0) {
    padding-bottom: 4px;
    color: #fff;
  }

  .sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover,
  .sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-link:not(.disabled):hover {
    color: #fff;
  }

  .create-product-lang .navbar-nav-link.dropdown-toggle:not(.caret-0)::after {
    position: absolute;
    top: 53%;
    right: 1.5rem;
    margin: 0;
    transform: inherit;
  }

  .product-btn {
    position: absolute;
    left: 17px;
    top: -10px;
  }

  .product-btn.product-calendar-date {
    display: block;
    justify-content: unset;
    position: initial;
  }

  .product-btn.product-calendar-date span {
    display: block;
    margin-right: 0;
  }

  .product-identity-panel {
    padding: 10px;
  }



  .border-transparent {
    border: 1px solid transparent;
  }

  .divider-after li:first-child {
    display: inline;
    border-right: 0 solid #ebebf2;
  }

  .nav-group-sub .nav-item {
    border-bottom: 0;
  }

  .inner-subhead {
    margin: 75px auto 0 auto;
    width: 100%;
    display: inline-block;
    text-align: center;
  }

  .inner-subhead span {
    color: #fff;
  }

  .product-count .list-icons {
    display: block;
  }

  .product-count .list-icons-item img {
    height: 32px;
  }

  .sidebar:not(.sidebar-component) .sidebar-content {
    overflow-y: auto;
  }

  .sidebar-mobile-secondary .sidebar-secondary .sidebar-content {
    left: 0;
    top: 0;
  }

  .sidebar-secondary,
  .sidebar-secondary .sidebar-content {
    top: 0;
    background: #ffffff;
    padding-top: 0;
  }

  .filter-icon {
    color: #333333;
  }

  .toggle-menu {
    color: #333333;
  }

  /* .page-header, */
  .navbar {
    background: #161616;
    border: 0;
  }

  /* .page-header .page-title {
    padding: 1rem 0;
    position: relative;
  } */

  .page-header .page-title h1 {
    display: flex;
    margin-bottom: 10px;
  }

  /* .page-header .page-title h1 span {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
  } */

  .icon-shield {
    margin-top: 7px;
    height: 20px;
  }

  .page-header .page-title .header-elements-toggle em {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 22px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
    color: #fff;
    position: relative;
    z-index: 999;
  }

  /* .page-header-content .header-elements.add-product-mob {
    background: #fff;
  } */
  /*
  .navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    height: 100%;
  } */

  .mob-menu-btn {
    position: relative;
    z-index: 999;
    width: 100%;
    display: inherit;
  }

  .product-stage-list-text {
    display: block;
    float: unset !important;
    margin-top: 0 !important;
  }

  .media-list {
    column-count: 2;
    margin-top: 15px;
  }

  .header-elements-sm-inline {
    display: inline-flex;
  }

  .main-heading span {
    display: block;
    line-height: 7px;
    text-transform: capitalize !important;
    margin-top: 15px;
    font-weight: 400 !important;
    font-size: 20px !important;
    text-align: center;
  }

  .products-bars .select-sm.select2-selection--single,
  .uniform-select .form-control,
  .uniform-select {
    border: 0 solid #caced5 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.04) !important;
    padding: 0;
  }

  .form-control-uniform {
    border: solid;
  }

  .select2-selection--single:not([class*="bg-"]):not([class*="border-"]) {
    padding: 5px 0;
  }

  .mobile-pdg {
    padding-right: 10px;
  }

  .header-elements .uniform-select span:after {
    right: 15px;
  }

  .card-header:not([class*="bg-"]):not([class*="alpha-"]) {
    padding: 10px;
  }

  .card-right-text {
    display: none;
  }

  .select-sm.select2-selection--single .select2-selection__rendered {
    color: #d1d1d7;
  }

  .select-sm.select2-selection--single .select2-selection__arrow:after {
    color: #d1d1d7;
  }

  .card-header .header-elements {
    padding: 0;
  }

  .wmin-sm-100 label {
    display: block;
    margin: 0;
    padding-left: 35px;
  }

  /* .page-header, */
  .navbar {
    background: #161616;
    border: 0;
  }




  .plus-action-btn {
    position: absolute;
    right: 0;
    top: 12px;
  }

  .back-action-btn {
    position: absolute;
    left: 0;
    top: 12px;
  }

  /* .page-header .page-title h4 span {
    color: #161616;
    font-size: 14px;
    padding: 0;
  } */

  .rc-tree-select-selection--single .rc-tree-select-selection-selected-value {
    white-space: normal !important;
  }

  .page-header .page-title .header-elements-toggle p,
  .header-elements-toggle p {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 27px;
    font-size: 22px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
    color: #fff;
    position: relative;
    z-index: 999;
    text-align: center;
  }

  /* .page-header-content .header-elements.add-product-mob {
    background: #fff;
    margin-top: 25px;
  } */

  /* .navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    height: 100%;
  } */

  .navbar-brand img {
    height: 30px;
  }

  /* .mob-header {
    padding:  20px 0;

  } */

  .navbar-brand .mob-logo {
    max-height: inherit;
    height: 73px;
  }

  .mob-menu-btn {
    position: relative;
    z-index: 999;
    width: 100%;
    display: inherit;
  }

  .navbar-light.footer-custom .navbar-text a {
    color: #fff !important;
    font-size: 14px;
  }

  .dataTables_paginate .paginate_button {
    margin-left: 2px !important;
    min-width: 30px;
    padding: 10px;
  }

  .mob-profile {
    padding: 0;
    margin: 0;
  }

  .mob-profile li:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  .mob-profile li:nth-child(2) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  .user-lang {
    font-size: 21px;
    color: #fff;
  }

  .uploader {
    position: relative;
    bottom: 10px;
    padding-top: 25px;
    left: unset;
    top: unset;
    transform: unset;
  }

  .file-upload-section {
    margin-bottom: 20px;
  }

  .profile-card .profile-text-section {
    border-right: solid 0 #d5d5df;
    padding: 13px 0 0;
    text-align: center;
  }

  .profile-form-section a {
    display: none;
  }

  .profile-card {
    padding: 0;
  }

  .profile-form-section .label-section .first-label {
    width: auto;
    padding: 0 10px 0 0;
  }

  .profile-form-section .label-section p {
    border-top: solid 1px #ccc;
    padding: 10px;
  }

  .access-section {
    background-color: #f0f0f7;
  }

  .access-section-lbl {
    width: 100% !important;
    text-align: center;
    padding: 15px 0 0 !important;
  }

  .access-section {
    display: block;
  }

  .access-btn-section {
    display: inline-flex;
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .profile-form-section .profile-btn {
    width: unset;
    font-size: 10px;
  }

  .Accessbtn-mrg {
    margin-left: 5px;
    margin-top: 0;
  }

  .profile-form-section {
    padding: 15px 0 0;
  }

  .profile-text-section h3,
  .subscription-section h3 {
    color: #161616;
    font-size: 20px;
    font-weight: 600;
  }

  .subscription-code {
    padding: 10px;
  }

  .profile-form-section .label-section .first-label,
  .profile-form-section .label-section p span,
  .profile-text-section p,
  .subscription-section p {
    font-size: 14px;
  }

  .multipart-submenu.nav-group-sub {
    display: none;
  }

  .create-products-tabs .multipart-submenu li.product-tab:nth-last-child(2),
  .create-products-tabs .multipart-submenu li.product-tab:last-child {
    margin-top: auto;
    margin-bottom: unset;
    /* width: 90%; */
  }

  .nav-sidebar .multipart-submenu .nav-item:not(.nav-item-divider) {
    margin-bottom: 0;
  }

  .create-products-tabs .multipart-submenu .product-tab a {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    margin: 4px 0;
  }

  .multipart-list .nav-item-submenu.product-tab a {
    padding: 6px !important;
  }

  .multipart-list .multipart-submenu .product-tab a {
    background: #f0f0f7 !important;
  }

  .multipart-list .multipart-submenu .product-tab a.active,
  .multipart-list .multipart-submenu .product-tab a:hover {
    background: #e0e0eb !important;
  }

  .multipart-list .nav-item-submenu.product-tab a::after {
    color: #a4afb7 !important;
    font-size: 19px;
    right: 1rem;
  }

  .nav-sidebar .nav-item a img {
    padding: 2px;
    margin-right: 5px;
  }

  #fade-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  /* .divider-after li:nth-child(1):after,
  .divider-after li:nth-child(2):after {
    border-right: none !important;
  } */
  .notification-msg {
    min-width: 100% !important;
  }

  .nav-sidebar>.nav-item>.nav-link span,
  .nav-sidebar .nav-link {
    font-size: 14px;

  }

  .nav-sidebar .nav-item {
    border-bottom: solid 1px #514e4e;
  }

  .card-sidebar-mobile .nav-sidebar {
    margin-top: 60px;
  }

  .card-sidebar-mobile .nav-sidebar .logo-brand-main:first-child {
    display: none;
  }

  .navbar_brand_logo {
    padding-left: 5px;
  }

  .sidebar-dark .sidebar-mobile-toggler a {
    margin-left: auto;
    z-index: 9;
    border-left: solid 1px #707070;
    border-bottom: solid 1px #2D6DF6;
    min-height: 50px;
  }

  .dashboard-service .btn {
    padding: 7px 30px !important;
  }


  .dashboard-status-list {
    padding: 0;
  }

  .retailer-specific .chartjs-render-monitor {
    width: 285px !important;
  }

  .dashboard-service {
    margin-top: 0px;
  }

  .preview-version-modal .modal-body {
    padding: 0;
  }

  .speedometer>.header-elements-inline {
    display: unset;
    align-items: unset;
    justify-content: unset;
    flex-wrap: unset;
  }

  .custom-approval-view .title {
    padding: 0px 0 0 10px;
  }

  .card-title,
  .revision-page span {
    font-size: 12px;
    white-space: break-spaces;
  }

  .asset-header {
    padding: 5px;
  }

  .approval-view-accordion .media-list {
    column-count: 1;
  }

  .data-collection,
  .data-collection .d-flex {
    display: unset !important
  }

  .card-last-section {
    padding: 0 15px !important;
  }

  .benelux-edit-mobile {
    margin: 5px 15px !important;
  }

  .info-data-section .status-col {
    display: flex !important;
    min-height: 50px !important;
  }

  .info-data-section .status-col strong {
    display: flex;
  }

  .info-data-section .status-col strong span {
    padding: 2px !important;
  }

  .advance-search {
    position: absolute !important;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  }
}



@media (max-width: 320px) {
  .profile-form-section .profile-btn {
    padding: 5px;
  }

  .nowrap-col .product-status-button {
    white-space: normal !important;
  }

  .profile-btn .icon-shield {
    height: 20px;
  }

  .mobile-flex .create-product-btn {
    font-size: 13px !important;
  }

  .mobile-flex .create-product-btn img {
    width: 20px !important;
  }

  .profile-form-section .label-section .first-label {
    width: 140px;
  }

  .canvas_container {
    width: 240px !important;
    height: 240px !important;
  }
}

@media (max-width: 767px) {
  .mobile-card-header {
    display: block !important;
  }

  .width50 {
    max-width: 100% !important;
  }

  /* benelux list Start */
  .add-product-mob {
    display: flex;
    justify-content: flex-end;
  }

  .sticky-header {
    top: 50px;
  }

  .sticky-header>.page-header-content>.breadcrumb {
    padding: 15px 0 0 0;
  }

  .data-section {
    overflow: hidden;
    box-shadow: 0 0 5px #F2F1F1;
    margin-bottom: 15px !important;
    position: relative;

  }

  .image-section {
    padding: 15px !important;
    border-bottom: solid 1px #D9D9D9;
    justify-content: unset !important;

  }

  .info-data-section {
    padding: 15px 0;

  }

  .info-data-section b::after,
  .info-data-section ul li::after {
    content: "" !important;
  }

  .advance-search {
    margin: 10px 0;
  }

  .advance-search-main {
    display: block !important;
    padding: 0 !important;

  }

  .search-list:hover,
  .advance_search:hover {
    border-bottom: solid 2px #2D6DF6;
    transition: 0.5s;
    cursor: pointer;
  }

  .filter-section {
    margin: 0 auto;
    width: 100%;
    display: inline-block;
    padding-bottom: 10px;
  }

  .advance_search_section {
    margin-top: 0 !important;
    margin-left: 0 !important;
    padding-bottom: 5px;
  }

  .benelux-list-card {
    border: 0 !important;
  }

  .benelux-list-card .card-body {
    padding: 0 !important;

  }

  .search-gtin {
    padding: 10px 0;
    transition: all 2s ease;
  }

  .advance-search {
    position: unset;
    box-shadow: unset;
    border: solid 1px #D9D9D9;
  }

  .advance-search.arrow-bottom:after {
    border: unset !important;
  }

  .advance-search-body {
    padding: 10px !important;
  }



  .search-list {
    font-size: 14px;
    color: #0056FF;
    white-space: nowrap;
  }

  .advance-search-bottom-section h4 {
    font-size: 16px;
    font-weight: 600;
  }

  .advance-search-bottom-section {
    border-top: solid 1px #d9d9d9;
    padding: 10px 0 !important;
    margin: 10px;
  }

  .btn-alignment-reset {
    align-items: flex-start !important;
    justify-content: left !important;
    margin-left: 15px;
  }

  .table-head-data ul>li {
    margin: 5px 0 !important;
  }

  .last-details-section {
    position: absolute;
    top: -218px;
    left: 0px;
    width: 100%;

  }

  .info-data-section .status-col {
    display: block;
    border-bottom: solid 1px #CCC;
    margin-bottom: 15px;
    padding-bottom: 15px;
    min-height: 100px;
  }

  .status-benelux {
    font-size: 12px;
    margin-bottom: 2px !important;
  }

  .status-benelux ol {
    left: 0px;
  }

  .card-last-section {
    padding: 15px;
    margin-top: 5px !important;
  }

  .benelux-completeness {
    border-radius: 0 !important;
    position: absolute;
    right: -8px;
    top: 15px;
  }

  .benelux-head_description {
    text-align: left;
  }

  .date-section strong,
  .date-section span {
    font-size: 12px;
  }

  .info-data-section ul li strong,
  .info-data-section ul li span {
    font-size: 11px;
  }

  .info-data-section ul li {
    padding: 0 !important;
  }

  .nav-item-submenu>.nav-link:after {
    color: #ffffff;
  }

  .list-icons-item {
    margin-right: 5px;
  }

  /* benelux list End */
  /* navbar edit start */
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-main .sidebar-main .sidebar-content {
    height: 100vh;
  }

  .sidebar-content .language-menu {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: block;
  }

  .language-menu .nav-sidebar .nav-item {
    border: none;
  }

  .language-menu .nav-item-submenu>.nav-link:after {

    right: 8.75rem;
  }

  /* navbar edit start */

  .deshboard-header {
    margin-top: 0 !important;
  }

  .info-data-section a {
    display: block;
    padding: 2px;
  }

  .navbar-brand a {
    margin-left: 0px;
  }

  .head_description,
  .p_date_section {
    text-align: left;
    display: block !important;
  }

  .image-section .list-thumbnail-img {
    margin-left: 0px !important;
  }

  #multistepsform #progressbar {
    padding: 0;
    margin: 10px;
  }

  .main-headnavbar-branding {
    max-width: 255px;
    display: block;
    margin: 0 0 0 20px;
    line-height: 0;
    padding: 10px 0 0 0;
  }

  .page-header-content {
    /* color: #fff; */
  }

  .ssa-head h1 {
    margin-top: 7px;
  }

  .ssa-btn-section {
    padding: 5px !important;
    margin-top: 0 !important;
  }

  .main-heading {
    font-size: 20px;
  }

  .num-count {
    left: 33px;
  }

  .padd-0 {
    padding-left: 0.625rem !important;
  }

  .blinking {
    top: -33px !important;
    left: 12px !important;
  }

  .table-actions .checkbox-option .check-box-option {
    min-width: 15rem;
  }

  .table td,
  .table th {
    padding: 6px 4px;
  }

  .table .table-actions {
    width: 50px;
    padding: 1px;
  }

  .navbar {
    box-shadow: none !important;
  }

  .dropdown-menu,
  .notification-msg-dropdown {
    max-width: 100%;
    width: 100%;
  }

  .dropdown-menu:after {
    display: none;
  }

  .navbar-expand-md .navbar-nav>.nav-item>.dropdown-menu {
    margin-top: 0;
  }

  .hierarchy_modal .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }

  .custom-validation-modal {
    left: 0 !important;
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .subBoxCont{
    padding: 0 10px !important;
  }
  .subimagewrapper {
    height: 98px !important;
    width: 98px !important;
  }
  .subimagewrapper img {
    max-height: 80px!important;
}
  .page-header .page-title h4 span.mob-head-status {
    width: 100%;
    top: 0;
    position: relative;
    font-size: 0.9rem !important;
    display: block;
    margin: 0 auto;
  }

  .custom-cards-width {
    width: 96% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .mobile-flex {
    display: flex !important;
    margin-right: auto !important;
    justify-content: center !important;
    /* padding: 0.625rem !important; */
    margin-left: auto !important;
  }

  .mobile-flex .create-product-btn {
    font-size: 14px;
    margin-top: 10px;
  }

  .mt-1-minus {
    margin-top: -1px !important;
  }

  .mobile-hide,
  .header-elements-md-inline .header-elements.mobile-hide {
    display: none !important;
  }

  .tab-btn-inline button {
    /* margin: 2px !important;
    padding: 4px 8px; */
  }

  .benelux-btn-section {
    border-top: 0 !important;
  }

  .tab-btn-inline button .icon-blocked {
    font-size: 12px !important;
  }

  .filter-card em {
    font-size: 14px;
    margin-top: -5px;
  }

  .create-products-tabs .nav-item-submenu.product-tab a.active {
    font-weight: bold;
    color: #161616 !important;
    background: none;
  }

  .create-products-tabs .nav-item-submenu.product-tab {
    background: none;
  }

  .import-field {
    width: 80%;
  }

  .nutritional-table.table td,
  .nutritional-table.table th {
    padding: 6px 5px;
  }

  .table.nutritional-table .footable-toggle {
    margin-top: 0 !important;
    margin-right: 4px !important;
  }

  .table .footable-toggle {
    float: left;
    margin: 6px 0 0 0 !important;
  }

  .speedometer-section {
    margin-bottom: 10px;
    margin-left: 30px;
  }

  .nutritional-table .footable-toggle {
    display: inline-block;
    margin-right: 0;
  }

  th.table-actions .custom-checkbox {
    text-align: right;
  }

  .table th.table-actions {
    text-align: right;
    width: 50px;
  }

  .footable.breakpoint>tbody>tr .footable-toggle::before {
    top: 0;
    left: 6px;
  }

  .table .table-actions {
    width: 60px;
    padding: 5px;
  }

  .custom-checkbox {
    margin-left: 6px;
    margin-top: 7px;
  }

  .product-sidebar-toggle {
    margin-left: 0.5rem !important;
  }

  .product-sidebar-toggle-sample {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
  }

  .product-sidebar-toggle-sample i,
  .header-elements-toggle i {
    color: #161616;
    font-size: 18px;
  }

  .ml-mob-1 {
    margin-left: 0.3125rem !important;
  }

  .p-mob-0 {
    padding: 0 !important;
  }

  .custom-style-table .form-check:not(.form-check-inline):last-child {
    display: inline-block;
  }

  .custom-style-table .form-check .uniform-checker,
  .form-check .uniform-choice {
    position: absolute;
    top: 0;
    left: 4px;
  }

  .carousel-inner .carousel-item>div {
    display: none;
  }

  .carousel-inner .carousel-item>div:first-child {
    display: block;
  }

  .nutrition-card-section {
    padding: 5px !important;
  }

  .structured_nutrition_table table thead .sub-head-footable .active_th {
    outline: 2px solid red !important;
    outline-offset: -1px;
    z-index: 11;
  }

  .nutrition-container td,
  .nutrition-container th {
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
    padding: 10px;
    min-width: 200px;
    background: white;
    box-sizing: border-box;
    text-align: left;
  }

  .nutrition-container {
    position: relative;
    max-height: 362px;
    width: 100%;
    max-width: 100%;
    overflow: scroll;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container .nutritional-table>thead>tr>th {
    border: 0 !important;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -2px;
    z-index: 10;
  }

  .nutrition-container .fixed-col-1 {
    min-width: 125px !important;
    z-index: 15;
    background: #eee;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container .fixed-col-2 {
    min-width: 125px !important;
    z-index: 15;
    background: #eee;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container tbody {
    overflow: scroll;
    height: 200px;
  }

  .table-scroll {
    position: relative !important;
    width: 100% !important;
    margin: auto !important;
    overflow: auto !important;
  }

  .table-scroll table {
    width: 100% !important;
    min-width: 1280px !important;
    margin: auto !important;
    border-collapse: separate !important;
    border-spacing: 0 !important;
  }

  .table-scroll th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .table-scroll .fixed-col-1 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 2;
    background: #eee;
  }

  .table-scroll .fixed-col-2 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 125px;
    z-index: 2;
    background: #eee;
  }

  .table-scroll .fixed-col-1 {
    z-index: 5;
  }

  .table-scroll .fixed-col-2 {
    z-index: 5;
  }

  .structured_nutrition_table {
    table-layout: fixed;
    display: table;
  }

  .carousel-indicators {
    bottom: -10px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: auto;
    height: auto;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: auto;
  }

  .carousel-control-prev {
    left: 3px;
  }

  .carousel-control-next {
    right: 3px;
  }

  .nutritional-table-main {
    max-width: 275px;
    overflow-x: auto;
  }

  .assets-table-main {
    max-width: 255px;
    overflow-x: auto;
  }

  .tab-btn-inline {
    justify-content: center;
  }

  .sidebar-xs.sidebar-secondary-hidden .secondary-toggle {
    position: absolute !important;
    right: 0;
  }

  .data-collection {
    padding: 0px 20px !important;
  }

  .delist-checkbox-section {
    padding: 10px !important;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

@media (min-width: 768px) {
  #root {
    display: flex;
    flex-grow: 1;
  }

  .custom-cards-width {
    width: 18% !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .d-desktop-none {
    display: none !important;
  }

  .filter-card {
    background-color: transparent;
    padding: 0 !important;
  }

  .product-count.card {
    margin-top: -39px;
    text-align: left;
    position: absolute;
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .product-count .card-body {
    padding: 0;
  }

  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33.333%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33.333%);
  }

  .carousel-inner .carousel-item-right,
  .carousel-inner .carousel-item-left {
    transform: translateX(0);
  }

  .sidebar-expand-md:not(.sidebar-component) {
    left: 0;
    transition: 0.5s;
  }

  .sidebar-secondary {
    padding-top: 54px;
  }

  .sidebar:not(.sidebar-component) .sidebar-content {
    background: #060a45;
    border-right: 1px solid #2d6df6;
    box-shadow: 0 4px 8px 0 gray;
  }

  .sidebar-secondary-hidden .sidebar-expand-md.sidebar-secondary {
    display: inherit;
    margin-left: -225px;
    transition: 0.5s;
  }

  .sidebar-secondary-hidden .sidebar-expand-md.sidebar-secondary .sidebar-content .filter-container {
    display: none;
    transition: 0.3s;
  }

  .structured_nutrition_table table thead .sub-head-footable .active_th {
    outline: 2px solid red !important;
    outline-offset: -1px;
    z-index: 11;
  }

  .nutrition-container td,
  .nutrition-container th {
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
    padding: 10px;
    min-width: 200px;
    background: white;
    box-sizing: border-box;
    text-align: left;
  }

  .nutrition-container {
    position: relative;
    max-height: 362px;
    width: 100%;
    max-width: 100%;
    overflow: scroll;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container .nutritional-table>thead>tr>th {
    border: 0 !important;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
    z-index: 10;
  }

  .nutrition-container thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .nutrition-container tr.second-head th {
    top: 35px;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container .fixed-col-1 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 15;
    background: #eee;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container .fixed-col-2 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 125px;
    z-index: 15;
    background: #eee;
    outline: 2px solid #d5d5df !important;
    border: none !important;
    outline-offset: -1px;
  }

  .nutrition-container tbody {
    overflow: scroll;
    height: 200px;
  }

  .zIndex-first2-cols {
    z-index: 20 !important;
  }

  .zIndex-th-tooltip {
    z-index: 15 !important;
  }

  .table-scroll {
    position: relative !important;
    width: 100% !important;
    margin: auto !important;
    overflow: auto !important;
  }

  .table-scroll table {
    width: 100% !important;
    min-width: 1280px !important;
    margin: auto !important;
    border-collapse: separate !important;
    border-spacing: 0 !important;
  }

  .table-scroll th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .table-scroll .fixed-col-1 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    z-index: 2;
    background: #eee;
  }

  .table-scroll .fixed-col-2 {
    min-width: 125px !important;
    position: -webkit-sticky;
    position: sticky;
    left: 125px;
    z-index: 2;
    background: #eee;
  }

  .table-scroll .fixed-col-1 {
    z-index: 5;
  }

  .table-scroll .fixed-col-2 {
    z-index: 5;
  }

  .structured_nutrition_table {
    table-layout: fixed;
    display: table;
  }

  .page-title h4 .product-status-button {
    margin-left: 15px;
    top: -5px;
    position: relative;
  }
  
}



.box-shadow {
  box-shadow: 0 3px 15px rgba(214, 214, 214, 0.93) !important;
  max-width: 360px !important;
  margin: 0 auto;
}

.icon-size {
  font-size: 14px !important;
}

.heading-bottom {
  border-bottom: 1px solid #d5d5df;
}

.main-container {
  padding: 20px 8px;
}

.nutrition-card-section {
  background: #f3f3f3;
  padding: 10px;
  border-radius: 0px;
}

.nutrition-card-section .card-body {
  padding: 1.25rem 0 0;
}

@media screen and (min-device-width: 481px) and (max-device-width: 767px) {
  .nbb-accordion-header .revision-dropdown {
    margin-top: -25px !important;
  }

  .activity-log-filter .dropdown-activity {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 8px;
  }

  .sidebar:not(.sidebar-component) .sidebar-content {
    overflow-y: auto;
  }

  .sidebar-mobile-secondary .sidebar-secondary .sidebar-content {
    left: 0;
    top: 0;
  }

  .sidebar-secondary,
  .sidebar-secondary .sidebar-content {
    top: 0;
    background: #ffffff;
    padding-top: 0;
  }

  .filter-icon {
    color: #161616;
  }

  .toggle-menu {
    color: #161616;
  }

  /* .page-header, */
  .navbar {
    background: #161616;
    border: 0;
  }

  /* .page-header .page-title {
    padding: 0 0 1rem 0;
    position: relative;
  } */

  .page-header .page-title h4 {
    text-align: center;
    width: 85%;
  }

  .plus-action-btn {
    position: absolute;
    right: 0;
    top: 12px;
  }

  .back-action-btn {
    position: absolute;
    left: 0;
    top: 12px;
  }

  .page-header .page-title h4 span {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .page-header .page-title .header-elements-toggle p,
  .header-elements-toggle p {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 27px;
    font-size: 22px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
    color: #fff;
    position: relative;
    z-index: 999;
    text-align: center;
  }

  .page-header-content .header-elements.add-product-mob {
    background: #fff;
  }

  /* .navbar-brand {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    height: 100%;
    transition: 0.5s;
  } */

  .mob-header {
    padding-bottom: 53px;
    margin-bottom: -55px;
  }

  .navbar-brand .mob-logo {
    max-height: inherit;
    height: 73px;
  }

  .inner-subhead {
    margin: 1% auto;
    width: 100%;
    display: inline-block;
    text-align: center;
  }

  .inner-subhead span {
    color: #fff;
  }

  /* .page-header, */
  .navbar {
    background: #161616;
    border: 1px solid #161616;
  }

  .navbar-dark {
    color: rgba(255, 255, 255, 0.9);
    background-color: #161616;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  .mob-menu-btn {
    position: relative;
    z-index: 999;
    width: 100%;
    display: inherit;
  }

  .canvas_container {
    margin: 0 auto;
    text-align: center;
  }

  .tab-dash-chart {
    max-width: 35%;
    margin: 20px auto;
  }

  .navbar-brand {
    position: absolute;
    z-index: 9999;
    width: 80px;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  .page-title {
    padding: 0.3rem 0 1rem;
    position: relative;
    display: flex;
  }

  .page-header.page-titleh1span {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .prev-icon {
    right: 0;
    position: absolute;
  }

  .landscape-btn {
    margin-bottom: 0 !important;
  }

  /*
  .page-header .page-title h1 span,
  .page-header .page-title h4 span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
  } */

  .user-lang {
    color: #fff;
  }

  .product-btn {
    display: block;
  }

  /* .page-header .page-title h4 {
    text-align: center;
  } */

  .document-assets {
    max-width: 225px;
    width: 100%;
  }

  .file-upload-section {
    height: 250px;
  }

  .add-product-mob .m-2 {
    margin: 0 !important;
  }

  .page-title {
    text-align: center;
  }

  .mob-profile li:first-child {
    border: none;
  }
}

.auditlog-customcss {
  width: 350px;
}

.auditlogcss-adjust-div {
  display: flex;
}

.custom-dropdown-algs select {
  border: solid 1px #d5d5df;
  padding: 7px 5px;
  color: rgba(61, 59, 59, 0.8);
  font-size: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04) !important;
  margin: 0;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  min-width: 90px;
}

.custom-dropdown-algs select:focus {
  outline: none;
}

.custom-dropdown-algs select option {
  padding: 10px 15px;
  height: 20px;
  font: inherit;
  margin: 10px;
  line-height: 35px;
  display: block;
}

.custom-dropdown-algs select::-ms-expand {
  display: none;
}

.custom-dropdown-algs select option {
  padding: 10px 15px;
  height: 20px;
  font: inherit;
  margin: 10px;
  line-height: 35px;
  display: block;
}

.custom-dropdown-algs select::-ms-expand {
  display: none;
}

.custom-dropdown-algs::before,
.custom-dropdown-algs::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.custom-dropdown-algs::after {
  content: "\e9c5";
  font-family: "icomoon", serif;
  line-height: 1;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
}

.custom-dropdown-algs::after {
  color: #a4afb7;
}

.custom-dropdown-algs select[disabled] {
  color: rgba(61, 59, 59, 0.8);
  font-size: 18px;
  background-color: #fafafa;
  opacity: 1;
}

.auditlogs-select-css {
  width: 180px !important;
  position: relative;
  display: table;
}

span.first-label {
  font-weight: 600 !important;
}

.item-per-page-dropdown .dropdown-info {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  font-style: normal;
}

.item-per-page-dropdown .dropdown-box {
  display: inline-block;
  width: 80px;
}

.product_view_content_editable {
  height: 100% !important;
  /* min-height: 32px !important; */
}

h5 .selected-checkbox-text {
  font-size: 16px;
  color: #161616;
  padding: 0 10px;
  font-weight: 600;
}

.nutrition-card-section {
  background: 0 !important;
}

.nutrition-card-section .global-card {
  border-radius: 10px !important;
  border: 1px solid #161616;
  overflow: hidden;
  max-width: 180px !important;
}

.nutrition-card-section .card-body {
  padding: 0px !important;
  outline: 2px solid #161616;
}

.nutrition-card-section .global-card .form-group:first-of-type,
.nutrition-card-section .global-card .form-group:last-of-type {
  border-bottom: 0 solid #161616 !important;
}

.nutrition-card-section .global-card .form-group {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #161616 !important;
}

.nutrition-card-section .global-card .form-group .block-error {
  color: #E2133D;
}

/*.nutrition-card-section .global-card input,
.nutrition-card-section .global-card div,
.nutrition-card-section .global-card input:focus,
.nutrition-card-section .global-card div:focus {
  border: 0 solid #ffffff !important;
}*/

.nutrition-card-section .global-card .select__control--is-disabled,
.nutrition-card-section .global-card .product_view {
  background: #ffffff !important;
  border: 0;
  outline: none;
}

.nutrition-card-section .global-card .select__control {
  border: 0 solid #ffffff !important;
}

.nutrition-card-section .global-card table td {
  border: 0 solid #ffffff !important;
  border-bottom: 1px solid #161616 !important;
}

.nutrition-card-section .global-card table tr:last-child td {
  border-bottom: 0 solid #ffffff !important;
}

.nutrition-card-section .global-card .select__control--is-disabled .select__indicators {
  display: none;
}

.nutrition-card-section .High>.card-body .form-group:first-of-type,
.nutrition-card-section .High>.card-body .form-group:first-of-type div,
.nutrition-card-section .High>.card-body .form-group:first-of-type input,
.nutrition-card-section .High>.card-body .form-group:nth-of-type(2),
.nutrition-card-section .High>.card-body .form-group:nth-of-type(2) .css-sbae5h-control,
.nutrition-card-section .High>.card-body .form-group:nth-of-type(2) .select__control--is-focused,
.nutrition-card-section .High>.card-body .form-group:nth-of-type(2) .select__single-value--is-disabled,
.nutrition-card-section .High>.card-body .form-group:nth-of-type(2) .select__control--is-disabled,
.nutrition-card-section .High>.card-body .form-group:last-of-type,
.nutrition-card-section .High>.card-body .form-group:last-of-type input,
.nutrition-card-section .High>.card-body .form-group:last-of-type .product_view {
  background: #e3001b !important;
  color: #ffffff;
}

.nutrition-card-section .Medium>.card-body .form-group:first-of-type,
.nutrition-card-section .Medium>.card-body .form-group:first-of-type div,
.nutrition-card-section .Medium>.card-body .form-group:first-of-type input,
.nutrition-card-section .Medium>.card-body .form-group:nth-of-type(2),
.nutrition-card-section .Medium>.card-body .form-group:nth-of-type(2) .css-sbae5h-control,
.nutrition-card-section .Medium>.card-body .form-group:nth-of-type(2) .select__control--is-focused,
.nutrition-card-section .Medium>.card-body .form-group:nth-of-type(2) .select__single-value--is-disabled,
.nutrition-card-section .Medium>.card-body .form-group:nth-of-type(2) .select__control--is-disabled,
.nutrition-card-section .Medium>.card-body .form-group:last-of-type,
.nutrition-card-section .Medium>.card-body .form-group:last-of-type input,
.nutrition-card-section .Medium>.card-body .form-group:last-of-type .product_view {
  background: #f89300 !important;
  color: #ffffff;
}

.nutrition-card-section .Low>.card-body .form-group:first-of-type,
.nutrition-card-section .Low>.card-body .form-group:first-of-type div,
.nutrition-card-section .Low>.card-body .form-group:first-of-type input,
.nutrition-card-section .Low>.card-body .form-group:nth-of-type(2),
.nutrition-card-section .Low>.card-body .form-group:nth-of-type(2) .css-sbae5h-control,
.nutrition-card-section .Low>.card-body .form-group:nth-of-type(2) .select__control--is-focused,
.nutrition-card-section .Low>.card-body .form-group:nth-of-type(2) .select__single-value--is-disabled,
.nutrition-card-section .Low>.card-body .form-group:nth-of-type(2) .select__control--is-disabled,
.nutrition-card-section .Low>.card-body .form-group:last-of-type,
.nutrition-card-section .Low>.card-body .form-group:last-of-type input,
.nutrition-card-section .Low>.card-body .form-group:last-of-type .product_view {
  background: #61ba13 !important;
  color: #ffffff;
}

.nutrition-card-section .High>.card-body .form-group:last-of-type .block-error,
.nutrition-card-section .Medium>.card-body .form-group:last-of-type .block-error,
.nutrition-card-section .Low>.card-body .form-group:last-of-type .block-error {
  color: #ffffff !important;
}

.nutrition-card-section .card-body .form-group:first-of-type .basic-multi-select .select__value-container {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.nutrition-card-section .global-card .select__menu {
  font-size: 10px !important;
}

.assets-block {
  display: flex;
  flex-wrap: wrap;
}

.assets-msg {
  font-size: 15px !important;
  padding: 3px 10px;
  color: #f00 !important;
}

.descriptive-message {
  font-weight: normal !important;
  top: 0px !important;
  left: 10px;
  font-size: 13px !important;
  text-transform: none;
}

.with-placehoder[placeholder]:empty:before {
  content: attr(placeholder);
  color: #878787;
  font-weight: 400;
  font-size: 14px;
}

.disabled-field {
  background-color: #d0d8e8;
}

/*--- SSA---*/

/* .tab_header {
  background: rgba(224, 224, 235, 0.35) !important;
} */

.tab_content_css {
  padding: 10px 5px;
}

.pd-10 {
  padding: 10px;
}

.option-card {
  border: 1px solid #cfcfe5;
  margin-bottom: 10px;
}

.tab-content label {
  min-width: 100px;
}

.option-card label {
  min-width: 90px;
}

.nav-tabs .nav-link {
  color: #666666;
  font-size: 17px;
  position: relative;
}

.tabIcon-flag {
  width: 20px;
  height: 13px;
}

/* --------------------------------for-revision----------------------------- */

.revision-form h4 {
  color: #161616;
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 0px;
  line-height: normal;
}

.revision-form p {
  padding: 0px;
  color: #161616;
  font-size: 14px;
  line-height: normal;
}

.revision-form .form-control {
  color: #3d3b3b;
  font-size: 14px;
  padding: 4px 10px;
}

.multipart-section h3 {
  padding: 5px 15px;
  color: #161616;
  font-size: 18px;
}

.revision-item-accordion {
  padding: 4px 0 4px 15px !important;
}

.revision-card .card-title {
  font-size: 13px !important;
  font-weight: bold;
}

.table-striped-footable tbody tr:nth-of-type(odd) {
  background-color: #fefeff;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
}

.table-striped-footable tbody td {
  padding: 10px 0px 10px 10px;
}

.custom-approval-view,
.tab_header {
  margin-bottom: 1rem !important;
}

.revision-button {
  padding: 2px 5px;
  border-radius: 0;
}

.approval-product-view div {
  font-size: 14px;
}

.err-option-border {
  border: 1px solid #ff0000;
}

.check-box-option::after {
  right: auto !important;
}

@media screen and (min-width: 1199px) {
  .checkbox-option {
    width: 40px;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1980px) {
  .alert-service-message {
    left: 125px;
  }
}

/* Responsive End */

/*---Reset Filter---*/

.filter-reset-div {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.filter-rest-btn {
  width: 29px;
  padding-right: 10px;
}

/*---End Reset Filter CSS---*/

.core-conflict {
  margin: 0px 15px;
  padding: 15px 0px;
  border-bottom: 1px solid #ddd;
}

/* version section start */

.head-text {
  background-color: #f0f0f7;
  font-weight: bold;
  padding: 8px 0;
}

.preview-basic-data {
  position: sticky;
  top: 44px;
  z-index: 99;
  background-color: #ffffff;
}

/* version section end */

.sidebar-close-tab .closeafter5 {
  position: absolute;
  right: 8px !important;
  top: 50% !important;
  border-radius: 5px !important;
  height: 22px !important;
  width: 22px !important;
  padding: 0 !important;
  text-align: center;
  background-color: #161616;
  z-index: 999;
  transform: translate(-50%, -50%);
  color: #fff;
}

.comment-txt {
  white-space: pre-line;
  margin: 0;
}

/* ------------rsc-section-------- */

.rsc-tab {
  position: relative;
}

.rsc-title {
  white-space: inherit;
}

.rsc-title label {
  font-size: 18px;
}

.pack-data-container {
  float: left;
  width: 100%;
}

.pack_data_content {
  width: 100%;
  float: left;
  border: 1px solid #ccc;
  padding: 3px 10px;
  background: #f5f5f5;
}

.copy-pack-icon {
  padding: 2px;
  border: 1px solid #161616;
  border-radius: 3px;
  cursor: pointer;
}

.text-field-copy-icon {
  padding: 2px;
  /* margin-right: 5px; */
}

.cancel-pack-data {
  border: 1px solid #161616;
  border-radius: 5px;
  cursor: pointer;
}

.copy-pack-icon:before {
  font-size: 18px;
  color: #161616;
}

.copy-notify {
  color: #161616;
}

.table-data-copy {
  width: 4%;
  float: right;
  border: 1px solid #ccc;
  padding: 3px;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pack-table-content {
  width: 96%;
  float: left;
}

.comment-resize {
  resize: none;
}

.textarea-comment-box {
  max-width: 100%;
  max-height: 100%;
  min-height: 70px;
}

.euComplianceForm {
  width: 100%;
}

.euComplianceForm label {
  margin-bottom: 0.5rem !important;
}

.euComplianceForm .head-text {
  padding: 5px;
  font-weight: 600;
}

td .pack_data_content {
  border: none !important;
}

.rsc-comments-heading {
  background-color: #f5f6fa;
}

.select-retailer .modal-body {
  max-height: unset;
  overflow: unset;
}

/***service-message****/

.alert-service-message {
  position: relative;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  font-size: 14px;
  margin: 3px 10px;
  text-align: center;
  border-color: #ffeeba;
  display: flex;
  align-items: center;
  justify-content: center;
}

/***certified-content-health****/
.square {
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
}

.square-red {
  background-color: #ff471a;
}

.square-orange {
  background-color: #f6961e;
}

.square-yellow {
  background-color: #ecdb17;
}

.square-green {
  background-color: #aee228;
}

.square-dark-green {
  background-color: #6ad78b;
  left: 270px;
}

.rscBarData {
  background-color: #fb9208;
  display: block;
  margin: 3px 10px;
  width: 0.8rem;
  height: 0.8rem;
}

.rscBar {
  background-color: #12812c !important;
}

.dashboard-status-list {
  display: flex;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-start !important;
}

/* ---------------------dashboard-product-count-badge--------------------------- */

.dashboard-product-count-container .tab-dash-chart div:first-child {
  border-top: none !important;
}

.product-count-badge span {
  border-radius: 0;
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 6px;
  border: none !important;
}

.product-count-badge .approved {
  background: #008A28;
}

.product-count-badge .in_progress {
  background: #875c00;
}

.product-count-badge .error {
  background: #AA213F;
}

.product-count-badge .awaiting_approval {
  background: #b5cc18;
}

.product-count-badge .delisted {
  background: #6e6e6f;
}

.product-count-badge .delist_requested {
  background: #008080;
}

.product-count-badge .rejected {
  background: #161616;
}

.product-count-badge .awaiting_core_data_approval {
  background: #004085;
}

.product-count-badge .outstanding_rejection {
  background: #fb9208;
}

.product-count-badge .awaiting_review {
  background: #00aaed;
}

.product-count-badge .awaiting_pre_approval {
  background: #ff00ee;
}

.product-count-badge .draft {
  background: #875c00;
}

.product-count-badge .queued_for_publishing {
  background: #fb9208;
}

.product-count-badge .published {
  background: #00b050;
}

.product-count-badge .publishing_error {
  background: #ba2121;
}

.product-count-badge .awaiting_withdrawal {
  background: #ffc000;
}

.product-count-badge .approval_processing {
  background: #79DCFF;
}

.product-count-badge .pending_live {
  background: #92D050;
}

.product-count-badge .published_with_cic_error {
  background: #FF471A;
}

/*-------Fix-asset-download-icon---------*/

.fix-download-btn {
  display: flex;
  margin: 0 auto;
}

/*--------- Category Path CSS------*/
/* .category-path {
  height: 50px;
  background-color: #F2F2F2;
}
.category-txt{
  padding: .4375rem 0.875rem;
} */

/*--------- End Category Path CSS------*/
.css-1rhbuit-multiValue {
  border-radius: 25px !important;
  background-color: #BDD1FC !important;
}

.logo-brand-main {
  border-right: none !important;
  background-color: transparent !important;
}

.sidebar-xs .sidebar-main .nav-sidebar>.nav-item>.nav-link img:hover,
.nav-item:hover>.nav-link img,
.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item a.active img {
  /* filter: invert(0.5) sepia(1) saturate(5) hue-rotate(90deg); */
  filter: brightness(0) saturate(100%) invert(56%) sepia(92%) saturate(7495%) hue-rotate(222deg) brightness(111%) contrast(102%);
}

.sidebar-secondary .nav-sidebar>.nav-item>.nav-link img:hover,
.sidebar-secondary .nav-item:hover>.nav-link img,
.tab_header .nav-item:hover img {
  filter: none !important;
}

.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item a,
.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item.logo-brand-main:hover a {
  border-right: solid 2px transparent;
}

/* .nav-group-sub .nav-item a.active,
.nav-group-sub .nav-item a {
  border-right: solid 2px transparent !important;
} */

/* .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar > .nav-item:hover a, */
/* .nav-group-sub .nav-item:hover, */
.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item:hover,
.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item a.active {
  border-right: solid 2px #2D6DF6;
  color: #FFF;
  background-color: transparent;
}

/* .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item:hover,
.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item .active {
  background-color: #161616;
} */

/* .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-link:hover, */
.sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub a.active {
  color: #2D6DF6;
  border-right: solid 2px #2D6DF6;
}

.list-icons-item {
  transition: all 1s ease;
}

.list-icons-item img {
  border: solid 1px #000;
}

.react-checkbox-tree label:hover {
  background: transparent !important;
  color: #161616;
}

.react-checkbox-tree label {
  color: #666666;
}

.sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item-submenu>.nav-group-sub {
  border-radius: 0;
}

.css-1q1falm-control {
  box-shadow: unset !important;
}

.ReactModalPortal .ril__toolbarLeftSide {
  padding-left: 45% !important;
}

.cmp-remaing {
  margin-bottom: 5px;
}

.cmp-remaing span {
  font-size: 14px;
}

.cmp-remaing span>span {
  font-weight: 600;
}

.product-info {
  padding: 8px 0 5px 0;
}

.css-1uj02my-control,
.css-7juk9b-control,
.select__control {
  border-radius: 0 !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.icon-forward3,
.icon-last,
.icon-backward2,
.icon-first {
  border: solid 1px #2D6DF6;
  border-radius: 0 !important;
  padding: 0 5px;
  color: #161616 !important;
}

.icon-forward3:hover,
.icon-last:hover,
.icon-backward2:hover,
.icon-first:hover {
  background: #2D6DF6;
  border: solid 1px #2D6DF6;
}
.icon-first:hover::before,
.icon-forward3:hover::before,
.icon-backward2:hover::before,
.icon-last:hover::before{
 color: #fff;
}
.close img {
  padding: 3px;
  height: 25px;
}

.modal-header .close {
  padding: 0 !important;
  margin: 3px 0 !important;
  border: solid 1px #2d6df6;
}

.close {
  height: auto;
  width: auto;
}

.close-icon{
  /* content: "";
  background: #2D6DF6;
  position: absolute;
  z-index: -1;
  padding: 0.85em 0.75em;
  display: block;
  transition: all 0.7s;
  height: 100%;
  left: -65px;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.9s;
  transform-origin: top left;
  width: 0; */
  background: linear-gradient(45deg,#2d6df6 50%,#fff 0);
  background-position: 100% 100%;
  background-size: 400% 100%;
  color: #000;
  transition: all .3s ease-out;
  
}

.close-icon:hover{  
  background-color: transparent;
  background-position: 0 100%;
  border-color: #2d6df6;
  color: #fff;
}

.close-icon {
  position: relative;
  z-index: 1;
  overflow: hidden;
}


.benelux-filter {
  margin-left: 8px;
  min-width: 35px;
  background-color: #fff;

}

.add-icon-top-right {
  position: absolute !important;
  top: 8px;
  right: 8px;
  padding: 5px !important;
}

.add-download-icon {
  padding: 5px !important;
}

.icon-shield-btn {
  height: 35px;
  width: 35px;
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-shield-btn img {
  margin-top: 5px;
}

.case {
  padding-bottom: 10px;
  margin-right: 10px;
}

.table-icon {
  /* position: relative;
  z-index: 1;
  overflow: hidden;
  display: block;
  border: solid 1px #161616;
  line-height: 20px;
 
 
  width: 100%;
  text-align: center;
  padding: 0;
  background-color: #ffffff;*/
  cursor: pointer; 
  background: linear-gradient(45deg,#2d6df6 50%,#fff 0);
  background-position: 100% 100%;
  background-size: 400% 100%;
  border:solid 1px #2d6df6;
  color: #000;
  transition: all .3s ease-out;
  margin-left: 5px;
  height: 35px;
  max-width: 35px;
  min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.table-icon:hover{
  background-color: transparent;
  background-position: 0 100%;
  border-color: #2d6df6;
  color: #fff;
}
.close img,
.table-icon img{
  transition: 0.4s;
}
.close-icon:hover img,
.filter-icon-btn:hover img,
.table-icon:hover img{
  filter: brightness(0) invert(1);
   border: solid 0px #2d6df6;
 
    }
.skeleton-table-icon {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: block;
  line-height: 20px;
  margin-left: 5px;
  height: 35px;
  max-width: 35px;
  min-width: 35px;
  width: 100%;
  text-align: center;
  padding: 0;
  background-color: #ffffff;
}


.table-icon::after {
  /* content: "";
  /* padding: 0.85em 0.75em; 
  background: #2D6DF6;
  position: absolute;
  z-index: -1;
  display: block;
  transition: all 0.7s;
  height: 100%;
  left: -195%;
  top: 0;
  transform: skew(50deg);
  transition-duration: 0.9s;
  transform-origin: top left;
  width: 0; */
}

.table-icon:focus-visible {
  background-color: #2D6DF6;
}

.table-icon:hover::after {
  height: 100%;
  width: 290%;
}

.custom-style-table tbody tr td .table-icon {
  padding: 7px;
}

.preview-fix-header .close-icon {
  overflow: visible !important;
}

.padding2 {
  padding: 0px 0px 0px 4px !important;
  font-size: unset !important;
}

.padding5 {
  padding: 5px !important;
}

.preview-basic-data tr,
.preview-compare-basic-data tr {
  border: 0;
  box-shadow: 1px 0px 0px 0px #d9d9d9;
}

.assets-uploader input[type="file"] {
  opacity: 1;
  margin-top: 4px;
}

.select__indicator-separator {
  background: none !important;
}

.multiline-input {
  padding: 0 8px;
  font-size: 14px;
  width: 100%;
}

.ssa-tabs a {
  margin-right: 2em !important;
}

.version_assets_preview>img {
  float: left !important;
}

.nutrition-card-section .global-card input {
  border: none;
}

.nutrition-card-section .global-card input:focus {
  border: 1px solid #ffffff;
}

.rsc-pack-icons span img,
.rsc-pack-icons span em {
  margin-top: 4px;
}

.table-form-ui tr {
  position: relative;
}

.close-right-btn {
  position: absolute;
  top: 2px;
  right: 0;
}

.table-form-ui,
.table-form-ui th,
.table-form-ui td {
  border: none !important;
  padding: 0;
}

/*-----------Custom Radio Button---------*/
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  color: #fff;
  min-width: 22px;
  width: 22px;
  height: 22px;
  border: 1px solid #000 !important;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-top: 2px;
}

input[type="radio"]::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #2d6df6;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.select__option label {
  width: 88%;
  color: #666666;
}

.remove-icon img {
  height: 36px !important;
  padding: 8px !important;
}

.remove-icon::after {
  left: -206% !important;
}

.remove-icon:hover::after {
  height: 100% !important;
  width: 130px !important;
}

.mpart-tab .nav-link a {
  color: #808080;
  font-family: "Aktiv-Grotesk-bold";
  font-size: 14px;
}

.mpart-tab .nav-link.active a {
  color: #000;
}

.assets-btn {
  padding: 5px 32px !important;
}

.removable-input textarea {
  min-height: 95px !important;
}

.dropdown-toggle::after {
  font-size: 14px !important;
}

.white-space-wrap {
  white-space: unset !important;
}

/*--------- Accordion CSS------------*/
.nbb-accordion {
  margin: 20px;
  border-top: 1px solid #b3b3b3;
}

.nbb-accordion-header {
  padding: 0px 10px 0px 0px;
}

.nbb-accordion-header .card-title {
  border-top: 1px solid #000;
  padding: 10px 0px;
  font-weight: bold;
  margin-top: -1px;
  width: 100%;
}

.nbb-accordion-header .revision-dropdown {
  /* margin-top: -24px; */
}

[data-action]:after {
  font-size: 22px !important;
  font-weight: normal;
}

.nbb-accordion .base-type-field-header {
  margin-bottom: 15px;
  border: none;
}

.nbb-accordion .product-identity-panel {
  padding: 0px 27px 20px 11px;
}

.dropdown-item:hover {
  color: #000 !important;
  background-color:#E7EEFF !important;
}

.dropdown-item:focus {
  background-color: #E7EEFF;
}

/* benelux list start*/

.image-section {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;


}

.image-section .list-thumbnail-img {
  height: 85px;
  width: 85px;
  border: 0;

}


.benelux-list .data-section {
  margin-bottom: 5px;
}

.benelux-list .last-details-section {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  /* min-width: 285px; */
  /* text-align: center; */

}

.benelux-list-edit span {
  padding: 6px;
  background: #f2f1f1;
  text-align: center;
}

/* .benelux-list .data-collection {
  padding: 8px 10px;
} */

.status-benelux {
  position: relative;
  /* padding: 0 0 4px 0; */
  margin-bottom: 4px;

}

.status-benelux ol {
  /* margin-left: 9px; */
  text-align: left;
  position: unset;
  justify-content: left;
  margin: 0;
  padding: 0px 0 5px;
}

.benelux-completeness {
  margin-left: 9px;
}

/* .benelux-list-edit {
  margin: 0px auto 20px;

} */

.benelux-add {
  padding: 3px 32px !important;
  font-size: 13px !important;
  margin: 0 3px !important;
  font-weight: 600;
}

.productlist-pagination {
  margin: 20px 10px;
}

/* benelux list end*/
/* benelux pages start */
.benelux-btn-section {
  padding: 20px 0 30px;
  border-top: solid 1px #d9d9d9;
}

.benelux-table-ui td {
  padding: 1px 0 !important;
}

.last-btn {
  border: solid 1px #dedddd;
  padding: 8px 30px;
  color: #b3b3b3;
  background-color: transparent;
  font-size: 12px;
  position: relative;
}

.benelux-group-btn {
  color: #161616;
  font-size: 12px;
  text-transform: capitalize;
  border: solid 1px #b3b3b3;
  padding: 8px 30px;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
}

.true-btn {
  background-color: #2D6DF6 !important;
  color: #ffffff;
}

.false-btn {
  background-color: #000 !important;
  color: #ffffff;
}

.grey-btn {
  color: #606060 !important;
  cursor: not-allowed;
}

.none-btn {
  background-color: #707070;
  color: #161616;
}

.search-benelux {
  position: relative;
}

.data-pool-radio-btn {
  display: -webkit-inline-box;
  margin-left: 0.5rem;
}

.channal-publication {
  align-items: start !important;
  color: #161616;
  font-size: 13px;
  padding-left: 10px;
  position: absolute;
  top: 2px;
}

.hybrid-list .channal-publication{
  position: unset !important;
}

.mainbox:hover .sorting img {
  filter: invert(1) sepia(0) saturate(0) hue-rotate(60deg);
}

.mainbox:hover .sorting,
.mainbox:hover .filter-icon-btn,
.mainbox:hover .filter-icon-btn .fa {
  background-color: #2D6DF6;
  color: #FFF;
  border: solid 1px #2D6DF6;
}

.search-benelux i {
  position: absolute;
  top: 9px;
  right: 10px;
  color: #666666;
  cursor: pointer;
}

.benelux-filter .fa {
  padding-top: 6px;
}



#multistepsform #progressbar {
  margin-bottom: 10px;
  overflow: hidden;
  counter-reset: step;
}

#multistepsform #progressbar li {
  list-style-type: none;
  color: #000;
  text-transform: capitalize;
  font-size: 14px;
  width: 33.33%;
  float: left;
  position: relative;
}

#multistepsform #progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 35px;
  display: block;
  font-size: 14px;
  color: #000;
  background: #eaeaeb;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  border: solid 3px #eaeaeb;
}

#multistepsform #progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #eaeaeb;
  position: absolute;
  left: -50%;
  top: 20px;
  z-index: -1;
}

#multistepsform #progressbar li:first-child:after {
  content: none;
}

#multistepsform #progressbar li.active {
  color: #2D6DF6;
}

#multistepsform #progressbar li.active:before {
  border: solid 3px #CDDCFD;
  color: #262626;
  background: #CDDCFD;
  font-weight: 600;
}

#multistepsform #progressbar li.active:after {
  background: #2D6DF6;
}

#multistepsform #progressbar li.complete,
#multistepsform #progressbar li.complete:before {
  color: #2D6DF6;
}

#multistepsform #progressbar li.complete:before {
  background-color: #fff;
  border: solid 3px #2D6DF6;
}

.advance-search-main {
  position: relative;
}

.download-clipboard-tooltip {
  display: block;
  height: auto;
  min-width: 100px;
  opacity: 1;
  text-align: center;
  position: absolute;
  white-space: pre-line;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 6px;
  background-color: #161616;
  font-size: 13px;
  color: #fff;
  border: 1px solid #161616;
  font-weight: normal !important;
  line-height: 15px;
  transition: 0.3s ease-in-out;
  left: 182px !important;
  top: 0 !important;
}

.copyToClipboardWrapper {
  position: relative;
}

.advance-search {
  width: 100%;
  height: auto;
  background-color: #fff;
  position: absolute;
  float: left;
  left: 0;
  bottom: 55px;
  z-index: 15;
  color: #000;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.advance-search-body {
  padding: 10px 20px;
}

.advance-search-body .form-group,
.advance-search-bottom-section .form-group {
  margin-bottom: 5px !important;
  padding: 0 5px;
}

.advance-search-body .form-row .form-group label,
.advance-search-bottom-section .form-row .form-group label,
.advance-head .modal-title {
  font-weight: 600 !important;
}

.advance-search.arrow-bottom:after {
  content: " ";
  position: absolute;
  right: 74px;
  bottom: -8px;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: none;
}

.advance-search .advance-head {
  padding: 12px 25px;
  border-bottom: solid 1px #d9d9d9;
}

.download-clipboard-tooltip:after {
  left: 50%;
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid 1px #707070;
  transform: rotate(-136deg);
  border-right: 0;
  border-bottom: 0;
  background: #161616;
  bottom: -6px;
}

.radio-datapool {
  margin-right: 0.5rem
}

.advance-search-bottom-section {
  border-top: solid 1px #d9d9d9;
  padding: 10px 20px;
}

.advance_search {
  font-size: 14px;
  color: #0056FF;
  white-space: nowrap;
}

.advance_search_section {
  /* min-width: 660px; */
  display: block !important;
  padding-left: 10px;
}

.product-speedomtr {
  align-items: baseline !important;
  display: flex;
  justify-content: center;
}

.benelux-table-icon {
  min-width: 100px;
}

/* .card-last-section {
  margin-top: 5px;
} */

/* table head */
.table-head-data {
  border-top: solid 1px #d9d9d9;
  border-left: solid 1px #d9d9d9;
  border-right: solid 1px #d9d9d9;
  padding: 8px;
}

.table-head-data .reset-btn {
  padding: 0px 30px !important;
  height: 35px;
  margin-top: 5px !important;
}

.table-head-data ul {
  padding: 10px 0;
  margin: 0;
  list-style-type: none;
}

.table-head-data ul>li {
  display: inline-block;
  margin: 5px;
  color: #666666;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.table-head-data ul>li>.close-imges {
  height: 15px;
  width: 15px;
  background: #707070;
  border-radius: 50%;
  padding: 3px;
  margin-left: 5px;
}

.table-head-data ul>li>.close-main {
  height: 8px;
  width: 15px;
}

.btn-alignment-reset {
  display: flex;
  align-items: center;
  justify-content: center;
}

.benelux-filter:hover::after {
  width: 92px !important;
}

.benelux-filter::after {
  left: -63px;
}

.details-product-form>.benelux-filter {
  position: absolute;
  top: 10px;
  right: 20px;
}

.form-identification {
  border: solid 1px #d9d9d9;
  background: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
}

/* hierarchy modal start */
.hierarchy_modal .modal-dialog {
  max-width: 1200px;
  width: 1200px;
  margin: 0 auto;
}

.right-side-section h3 {
  color: #9f9f9f;
  font-size: 18px;
  text-transform: uppercase;
}

.right-side-section ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.right-side-section ul li img {
  height: 17px;
  width: 17px;
  margin-right: 8px;
}

.right-side-section ul li {
  border: solid 1px #eeeeee;
  padding: 5px;
  color: #161616;
  font-size: 13px;
}

.hierarchy-main-section {
  max-width: 880px;
  white-space: nowrap;
  min-height: 500px;
  height: 500px;
  cursor: grabbing;
}

.p-hierarchy-section{
  max-width: 100% !important;
  height:300px;
  min-height: 100px;
  cursor:grabbing;
}

.skeleton-approval-hierarchy ul li span {
  color: #9f9f9f;
  font-size: 11px;
  padding: 5px;
}

/* .hierarchy-main-section span, */
.approval-hierarchy ul li span {
  border: solid 1px #000;
  color: #9f9f9f;
  font-size: 11px;
  padding: 5px;
}

.hierarchy-main-section span img {
  height: 15px;
  width: 15px;
}

.hierarchy-main-section .active,
.approval-hierarchy .active {
  background-color: #000;
}

.hierarchy-main-section .sec-section {
  margin-left: 15px;
}

.section-bottom {
  padding-top: 90px;
  display: flex;
}

.hierarchy-main-section ul,
.skeleton-approval-hierarchy ul {
  margin: 0;
  /* padding: 100px 0 0; */
  list-style: none;
}

.hierarchy-main-section ul,
.approval-hierarchy ul {
  margin: 0;
  /* padding: 100px 0 0; */
  list-style: none;
}

.hierarchy-main-section ul li,
.skeleton-approval-hierarchy ul li {
  /* display: inline-block; */
  margin: 5px;
}

.hierarchy-main-section ul li,
.approval-hierarchy ul li {
  /* display: inline-block; */
  margin: 5px;
}

.hierarchy-main-section .bottom-list li:first-child {
  margin-left: 0;
}

.first-section,
.sec-section {
  position: relative;
}

.dotted-border {
  border-left: 2px #000 dotted;
  position: absolute;
  height: 97px;
  left: 26px;
  top: 30px;
}

.dotted-border span {
  background: #000;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 40px;
  left: -7px;
}

.sec-box-section {
  transform: rotateZ(306deg);
  border-left: 2px #000 dotted;
  position: absolute;
  height: 150px;
  left: 92px;
  top: 0px;
}

.thr-box-section {
  transform: rotateZ(287deg);
  border-left: 2px #000 dotted;
  position: absolute;
  height: 305px;
  left: 176px;
  top: -79px;
}

.thr-box-section span {
  top: 50px;
}

.fou-box-section span {
  top: 70px;
}

.fiv-box-section span {
  top: 100px;
}

.fou-box-section {
  top: -141px;
  transform: rotateZ(283deg);
  border-left: 2px #000 dotted;
  position: absolute;
  height: 433px;
  left: 266px;
}

.fiv-box-section {
  top: -216px;
  transform: rotateZ(279deg);
  border-left: 2px #000 dotted;
  position: absolute;
  height: 580px;
  left: 357px;
}

.bottom-list,
.bottom-list1,
.bottom-list2 {
  position: relative;
}

.bottom-list>.dotted-border {
  top: 137px;
}

.last-list {
  position: relative;
}

.last-list li::before {
  content: "";
  border-left: 2px #000 dotted;
  position: absolute;
  height: 97px;
  left: 35px;
  top: 3px;
}

.last-list li:nth-child(2):before {
  left: 175px;
}

.last-list i {
  height: 10px;
  width: 10px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 33px;
  left: 31px;
}

.last-list li:nth-child(2) i {
  left: 171px;
}

.base-icon {
  height: 10px !important;
  margin-left: 2px;
  vertical-align: baseline;
}

.published-body-section {
  padding: 20px 0;
}

.additional-trade {
  background-color: #f2f2f2;
  margin-bottom: 20px;
  border: solid 1px #d9d9d9 !important;
  border-radius: 1px;
  padding: 15px;
  position: relative;
}

.composite-detail,
.composite-detail-main {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}

.disable-field label,
.disable-field ::placeholder,
.disable-field .css-1wa3eu0-placeholder,
.composite-detail p,
.composite-detail label,
.composite-detail .css-1wa3eu0-placeholder,
.composite-detail ::placeholder {
  color: #b3b3b3 !important;
}

.no-wrap {
  white-space: nowrap;
}

.connected-border {
  border-left: 2px dotted #000;
  height: 102px;
  position: absolute;
  left: 28px;
}

.connected-border2 {
  left: 180px;
}

.connected-border::after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #000;
  border-radius: 50%;
  left: -5px;
  top: 20px;
}

.connected-border-transform {
  transform: rotateZ(309deg);
  height: 165px;
  left: 94px;
  top: 6px;
}

.text-black {
  color: #161616;
  font-size: 14px;
  opacity: 1;
}

.approval-hierarchy {
  border-top: solid 1px #d9d9d9;
  padding: 20px 0;
}

.hierarchy-span {
  padding: 8px;
  font-size: 14px;
}

.benelux-header-img {
  line-height: 0 !important;
}

.benelux-header-img img {
  vertical-align: baseline;
}

.benelux-header-img::after {
  left: -86px;
}

.benelux-header-img:hover::after {
  width: 130px;
}

.assets-body-section {
  border: solid 1px #d9d9d9;
  padding: 5px;
}

.assets-table {
  width: 100%;
  margin-bottom: 10px;
}

.assets-table thead th,
.assets-table tbody tr td {
  padding: 8px 20px;
  border-left: 0;
  border-right: 0;
}

.small-img-assets {
  margin: 0 10px 0 15px;
}

/* body scrool table start */
.button-drp .show {
  left: -90px !important;
  background-color: #fff;
  margin: 0;
  border: 0;
  padding: 0;
  min-width: 210px;
}

.button-drp .dropdown-menu:after {
  content: none;
}

.button-drp .dropdown-item {
  color: #fff !important;
  margin: 1px 0;
}

/* .dropdown-toggle {
  padding: 12px 70px 12px 28px !important;
} */
.button-drp {
  position: relative;
  /* overflow: hidden; */
}

.drop-toggle {
  border-left: solid 1px #d9d9d9;
  position: absolute;
  z-index: 1;
  right: -8px;
  height: 41px;
  width: 41px;
  overflow: hidden;
  margin-right: 8px;
}

.drop-toggle::after {
  font-family: icomoon;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  vertical-align: middle;
  line-height: 40px;
  content: "\e9c9";
  position: relative;
  color: #ffffff;
  transition: all 0.5s linear;
}

.drop-toggle-click::after {
  transform: rotate(180deg);
  transition: all 0.5s linear;
}

/* sticky table */

.custom-style-table .header .tr .th {
  color: #161616 !important;
  font-weight: bold;
  border: none;
  padding: 8px;
  line-height: normal;
  vertical-align: middle;
}

.table-striped .body .tr .td {
  border: 0;
}

.table-striped .body .tr:nth-of-type(even) {
  border: solid 1px #d9d9d9;
}

.table-striped .body .tr:nth-of-type(even) .td {
  background-color: #f2f2f2;
}

.gyWHtn .table .td .table-icon {
  padding: 2px 5px;
}

.gyWHtn .table .th,
.gyWHtn .table .td {
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
}

.gyWHtn .table .td .form-group {
  margin: 0;
}

.gyWHtn .table .td .form-group .custom-checkbox {
  margin: 5px;
}

.benelux-head_description {
  font-size: 25px;
  color: #000;
  font-weight: normal;
  line-height: normal;
}

.p_date_section {
  line-height: normal;
  margin-bottom: 5px;
  display: flex;
  /* white-space: nowrap; */
}

/* sticky table  end*/
.rct-node:hover .rct-icon-expand-close,
.rct-node:hover .rct-icon-uncheck,
.rct-node:hover .rct-title {
  color: #2D6DF6;
  opacity: 1;
}
.rct-text .rct-checkbox:hover .rct-icon-uncheck{
border:  solid 1px #2D6DF6;
}
.rct-text .rct-checkbox .rct-icon-check{
  background-color: #2D6DF6;
  border:  solid 1px #2D6DF6;
}

/* delist page start */

.delist-body-section {
  margin-bottom: 30px;
}

.delist-body-table {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.data-section {
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.first-data-section {
  background: #f2f2f2;
  border: none;
}

.delist-checkbox-section {
  padding: 25px 0 0px 45px;
}

.info-data-section b {
  padding: 5px 25px 5px 0;
  position: relative;
}

.info-data-section b::after,
.info-data-section ul li::after {
  content: "|";
  position: absolute;
  right: 12px;
}

.info-data-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-data-section ul li {
  display: inline-block;
  padding: 0px 25px 0px 0;
  position: relative;
  font-size: 14px;
}

.info-data-section {
  padding: 15px 10px;
}

/* .info-data-section ul li strong {
  color: #666;
} */

.info-data-section ul li:last-child:after {
  content: "";
}

.data-collection {
  /* padding: 8px 0; */
  display: flex;
}

.last-details-section {
  padding: 10px;
}

.col-details-section {
  border-left: 1px solid rgba(0, 0, 0, 0.125);

}

.discountinue>.discountinue-date {
  width: 25%;
  min-height: 42px !important;
  margin-right: 10px;

}

.discountinue .discountinue-date {
  min-height: 41px !important;
}

.discountinue label {
  margin: 0;
  padding: 10px;
}

.benelux-list-popup {
  padding: 15px 0;
}

.benelux-export h2 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.hierarchies-text {
  position: relative;
  top: -3px;
  padding-left: 5px;
  color: #000;
}

.benelux-popup-btn {
  padding-top: 20px;
}

/* delist page End */
/* nutritional-information-start */
.nutrient-input {
  margin-bottom: 3px;
}

.nutrient-input span {
  padding: 6px;
}

.nutrient-table tbody tr td:nth-child(1),
.nutrient-table thead tr th {
  font-weight: 600;
}

.nutrient-table td,
.nutrient-table {
  padding: 10px 8px;
  vertical-align: top;
}

.nutrient-table .calories-btn {
  background-color: #fff;
  border: solid 1px #000;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.last-input {
  max-width: 90%;
  width: 100%;
}

.nutrient-source {
  width: 200px;
}

.nutrient-section {
  margin-bottom: 40px;
}

/** Approval View White Space Change **/

.custom-approval-view .nbb-accordion-header .card-title {
  padding-bottom: 5px;
  margin-bottom: 0;
}

.custom-approval-view .nbb-accordion {
  margin-bottom: 0;
  margin-top: 0;
}

.custom-approval-view .card-body .approval-view-accordion {
  padding-bottom: 0;
}

.custom-approval-view .card-body .approval-view-accordion .base-type-field-header {
  margin-bottom: 0;
}

.custom-approval-view .card-body .approval-view-accordion .form-group {
  margin-bottom: 10px;
}

.data-pool-radio-btn {
  display: -webkit-inline-box;
}

.clipboard-tooltip-Message {
  display: block;
  height: auto;
  min-width: 100px;
  opacity: 1;
  text-align: center;
  position: absolute;
  white-space: pre-line;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 6px;
  background-color: #161616;
  font-size: 13px;
  color: #fff;
  border: 1px solid #161616;
  font-weight: normal !important;
  line-height: 15px;
  transition: 0.3s ease-in-out;
  left: 50px !important;
  top: 0 !important;
}

.copyToClipboardWrapper {
  position: relative;
  padding-bottom: 30px;
}

.clipboard-tooltip-Message:after {
  left: 50%;
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid 1px #707070;
  transform: rotate(-136deg);
  border-right: 0;
  border-bottom: 0;
  background: #161616;
  bottom: -6px;
}

.radio-datapool {
  margin-right: 0.5rem;
}

.dataPool-model-btn {
  margin-top: -20px;
}

.activity-log-download {
  font-size: 14px;
  margin-bottom: 25px;
}

.width95 {
  width: 95% !important;
}

.checkbox-compo {
  top: 4px;
  left: 4px;
}

.components-header {
  max-width: 1100px;
  overflow: auto;
  overflow-y: hidden;
}

.dropdown-menu-form {
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  width: 99.5%;
  border: solid 0.5px #CCC;
}

.attribute-dropdown-menu-form {
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  width: 99.5%;
  border: solid 0.5px #CCC;
}

.form-check-label {
  color: #666666;
}

.dropdown-menu-form li {
  padding: 12px;
  border-bottom: solid 0.5px #CCC;
}

.attribute-dropdown-menu-form li {
  padding: 5px 12px 5px 12px;
  border-bottom: solid 0.5px #CCC;
}

.attribute-label {
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 0px;
}

.remove-arrow .dropdown-toggle::after {
  content: '' !important;
}

.remove-arrow .form-check-label .custom-checkbox {
  top: 3px;
  margin: 0 12px 0 0;
}

.benelux-sort {
  background-color: transparent;
  border: 1px solid #B3B3B3;
  border-left: 0;
  height: auto !important;
  z-index: 1;
  width: 50px;

}

.benelux-sort:hover {
  border-color: #161616;
}

.benelux-sort img {
  transition: 0.5s all;
}

.sorting-benelux img {
  transform: rotate(180deg);
}

.toggle-filter-section {
  top: 0 !important;
  bottom: unset !important;
}

.toggle-filter-section::after {
  display: none !important;
}

.disabled-checkbox {
  border: 1px solid #b3b3b3 !important;
  cursor: default;
}

.disabled-checkbox::before {
  color: #b3b3b3 !important;
}

.tag_cross_icon {
  height: 11px;
  padding: 1px;
  margin-left: 2px;
}

.inner_tag_item {
  margin: 3px;
}

/* ---- Hierarchy Node Css -----*/
.hierarchy-main-section .react-flow__node {
  border: solid 1px #000;
  color: #9f9f9f;
  font-size: 11px;
  max-width: 225px;
  min-width: 210px;
}

.hierarchy-main-section .react-flow__node span img {
  height: 18px;
  width: 15px;
}

.validation-TestSection {
  display: flex;
  padding-left: 28px !important;
  align-items: flex-start;
  flex-flow: wrap;
}

.validation-TestSection img {
  margin: 1px 5px 0 0;
}

.react-flow__node {
  text-align: left !important;
  padding: 0px !important;
  background-color: #fff !important;
  /* width: 150px ; */
  border-radius: 0px !important;
  pointer-events: initial !important;
  cursor: pointer !important;
}

.react-flow__node:hover {
  z-index: 9999 !important;
}

/* .h-node span:first-child {
  border-right: 1px solid #000;
} */

.channel-border {
  border-right: 1px solid #000;
  text-align: center;
  padding: 5px 0 !important;
  min-width: 40px;
}

.channel-border-line {
  text-align: center;
  padding: 5px 0 !important;
  min-width: 40px;
}

.lastImg {
  top: 2px;
}

.channel-border img {
  height: 20px !important;
  width: 19px !important;
}


.h-node span {
  display: inline-block;
  padding: 5px;
}

.hierarchy_wrapper {
  position: relative;
  display: inline-block;
}

.hierarchy_wrapper .tooltiptext {
  visibility: hidden;
  width: 250px;
  height: auto;
  background: #fff;
  color: #000;
  padding: 10px;
  position: absolute;
  z-index: 999999999;
  top: 150%;
  left: 0%;
  box-shadow: 0px 3px 6px #1616160d;
  border: 1px solid #DDDDDD;
  overflow-wrap: break-word;
  white-space: normal;
}

.hierarchy_wrapper .tooltiptext::after {
  content: " ";
  position: absolute;
  left: 6%;
  top: -7px;
  width: 12px;
  height: 12px;
  border: solid 1px #dad5d5;
  transform: rotate(45deg);
  border-right: 0;
  border-bottom: 0;
  background: #fff;

}

.h-node:hover .tooltiptext {
  visibility: visible;
}

.ptype-txt {
  display: inline-block;
  padding-left: 5px;
}

.react-flow__edge-path {
  stroke-dasharray: 1, 2;
  stroke: #000 !important;
  position: relative;
}

/****** Product List Checkbox Start *****/
.width50 {
  max-width: 50px;
}

.width65 {
  max-width: 65px;
  min-width: 65px;
}

.product-list-checkbox {
  max-width: 180px;
}

.dropSelect {
  color: #666 !important;
}

/****** Product List Checkbox End *****/

.invalid-lower {
  border: 1px solid #F00;
}

.modal-footer .btn:first-of-type {
  margin-left: 0rem;
}

.modal-body .badge {
  margin: 7px 5px 0px 0px !important;
}

.select__multi-value svg:hover {
  fill: red;
}

.css-14jk2my-container {
  width: 100%;
}

.hide-dropdown-option {
  display: none;
}

.nbb-description-wrapper {
  width: "fit-content";
  word-break: break-all;
}

.nutritional-table-product {
  background-color: #ffffff;
  position: relative;
  font-size: 13px !important;

}

.nutritional-table-product td,
.nutritional-table-product td label,
.nutritional-table-product tr th,
.nutritional-table-product tr th label,
.nutritional-table-product .select__single-value,
.nutritional-table-product .css-1wa3eu0-placeholder,
.nutritional-table-product .select__menu {
  font-size: 12px !important;
}

.nutritional-table-product tr td:nth-child(5),
.nutritional-table-product tr td:nth-child(3) {
  max-width: 350px;
}


/* .nutritional-table-product tr td:nth-child(2) {
  max-width: 500px;
} */

.css-qtnvvb-control {
  border-radius: 0 !important;
}
.removable-input:hover .filter-icon-btn,
.css-d8wand-control:hover,
.css-qtnvvb-control:hover,
.form-control:hover {
  border-color: #000;
  border-bottom: solid 1px #2D6DF6 !important;
}

.nutritional-table-product .select__indicator {
  padding: 0 !important;
}

.nutritional-table-product>thead>tr>th,
.nutritional-table-product>tbody>tr>td {
  border-right: solid 1px #d9d9d9;
}

/* ------------------------------------------tagged field css------------------------------------- */
.nbb-approval-taggable-field .BOLD tagged[id='1'] {
  font-weight: bold !important;
}

.nbb-approval-taggable-field .ITALIC tagged[id='1'] {
  font-style: italic !important;
}

.nbb-approval-taggable-field .UNDERLINED tagged[id='1'] {
  text-decoration: underline !important;
}

.nbb-approval-taggable-field .UPPER_CASE tagged[id='1'] {
  text-transform: uppercase !important;
}


.product-listNBB .form-row:nth-child(2).form-row .basic_select {
  border-bottom: solid 1px #161616;

}

.product-listNBB .table-form-ui {
  margin: 0 10px;

}

.product-listNBB .first-cars-section .hide-trigger .percent-icon {
  position: absolute;
  right: 15px;
  top: 11px !important;
}

.product-listNBB .custom-cards-width {
  display: flex;
  flex-wrap: wrap;
}

.product-listNBB .global-card input {
  border-bottom: solid 1px #161616;

}

.product-listNBB .global-card table tr:last-child td input {
  border-bottom: none !important;
}

.product-listNBB .card-body .form-group:first-of-type div input {
  padding: 0 10px;
}

.product-listNBB .global-card .card-body .form-group:first-of-type input {
  border-top: solid 1px #161616;
  border-bottom: none !important;
}

.product-listNBB .global-card table td {
  border: none !important;
}

.allergen label {
  margin-bottom: 0;
}

.product-listNBB .basic-select .select__single-value {
  color: #161616 !important;
  font-weight: 500;
}

.nutritional-table-product td {
  vertical-align: top;
}

.multipart {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.statusborder {
  border: 1px solid #ccc;
}

.react-datepicker__time-container {
  right: -86px !important;
}

.rc-tree-select-dropdown {
  z-index: 95 !important;
}

.disabled-row {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .125) !important;
}

.disabled-row .custom-checkbox {
  cursor: default;
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3);
}

.disabled-row .custom-checkbox.checked:before {
  color: #d4d4d4;
}

.custom-tooltip-left-top {
  left: unset !important;
  right: -200px !important;
  min-width: unset !important;
  max-width: unset !important;
  top: 30px;

}

.custom-tooltip-left-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, 0.7);
  transform: translateX(-50%);
  top: -9px;
  right: 193px;


}

.custom-tooltip-left-top:after {
  border-bottom: 9px solid #161616 !important;
}

.nutritional-table-product th:first-child {
  width: 150px;
}

/* .nutritional-table-product th:nth-child(2) {
  width: 150px;
} */


.cmp-attr-style {
  color: #006800;
  padding-right: 5px;
}

.left-margin {
  margin-left: 47%;
}

.gray-out-css .rc-tree-select-selection-selected-value {
  color: #ccc !important;
}

.score-section {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px;
}

.retailerBg {
  background: rgba(0, 0, 0, .09);
  padding: 5px 0px 0px 0px;

}

.retailerBgDateInput {
  background: rgba(0, 0, 0, .09);
  padding: 5px;

}

.all-attributes-disabled {
  color: #B3B3B3;
}

.lastcol tr td:first-child {
  max-width: 400px;
  padding-right: 30px;
}

.publishCheck {
  margin-left: 5px;
}

.deprecated {
  color: #ba2121 !important;
  font-style: italic;
}

.nav-link-asterisk {
  margin-top: 10px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.css-qtnvvb-control:hover,
.css-qtnvvb-control:focus-visible,
.nav-link:focus-visible,
textarea:focus-visible,
:focus-visible {
  border-bottom: solid 1px #2D6DF6 !important;
  outline: none !important
}

.custom-checkbox:focus-visible {
  border: solid 1px #2D6DF6 !important;
  outline: none !important
}

.drop-toggle:focus-visible,
button:focus-visible {
  background-color: #2D6DF6 !important;

}

.approval-modal-fontsize {
  font-size: 14px;
}

.center-image {
  padding-top: 9vh;
}

.input-code-retailerBg {
  background-color: rgba(0, 0, 0, .09);
  padding: 5px 0px 5px 0px;
}

.booleanBG {
  margin-top: -5px;
}

.btnadvanced-search {
  padding: 6px 15px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}

.gutterSm>.form-group {
  margin: 0;
  padding: 0 5px;
  max-width: 200px
}

.minw-200 {
  min-width: 200px;
}

.inline-display {
  display: inline-block;
}

.fixedSection {
  position: fixed;
  bottom: 0px;
  background-color: #F4F4F4;
  width: 100%;
  max-width: 270px;


}



.fixedSection li:first-child {
  border-top: 2px solid #c2c2c2;
}

.firstSection {
  overflow-y: auto;
  overflow-x: hidden;


}

.productlist-pagination .active {
  font-weight: 600;
}

.overflow {
  overflow: unset !important;
}


.select__menu {
  border-radius: 0 !important;
}

.nutrition-card-section .Medium>.card-body .form-group:first-of-type div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nutrition-card-section .Medium>.card-body .form-row:last-of-type.form-group,
.nutrition-card-section .global-card .form-row:last-of-type,
.nutrition-card-section .global-card .form-row:last-of-type .form-group {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: unset !important;
}

.nutrition-card-section .Medium>.card-body .form-row:first-of-type,
.nutrition-card-section .High>.card-body .form-row:first-of-type,
.nutrition-card-section .Low>.card-body .form-row:first-of-type {
  overflow: hidden;
  max-width: 178px;
  margin: 0 auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nutrition-card-section .Medium>.card-body .form-row,
.nutrition-card-section .High>.card-body .form-row,
.nutrition-card-section .Low>.card-body .form-row {
  overflow: hidden;
  max-width: 178px;
  margin: 0 auto;
}

.saveBtn {
  padding: 9px 45px 9px 0;
}

.header-disable {
  color: #606060 !important;
}

.access-denied {
  text-align: left;
  display: inline-block;
}

.nbb-logo-image {
  margin: 25px 5px;
}

.NutritionFopTable thead tr th {
  padding: 15px 10px;
  border-right: 1px solid #d0d8e8 !important;
}



.NutritionFopTable tbody tr td {
  padding: 15px 10px;
  border-top: 1px solid #d0d8e8;
  border-left: 0;
  border-bottom: 0;
}

.NutritionFopTable tbody tr td table tbody tr td {
  border: none !important;
  padding: 0 !important;
  align-items: center !important;
}


.NutritionFopTable tbody tr td table tbody tr td .fop_selectBox {
  margin-left: 10px !important;
  min-height: 18px !important;
}

.NutritionFopTable thead tr th:nth-child(1),
.NutritionFopTable tbody tr td:nth-child(1),
.NutritionFopTable thead tr th:nth-child(3),
.NutritionFopTable tbody tr td:nth-child(3),
.NutritionFopTable thead tr th:nth-child(5),
.NutritionFopTable tbody tr td:nth-child(5) {
  border-right: 0 !important;
}

.NutritionFopTable tbody tr td input,
.NutritionFopTable tbody tr td .select__control {
  min-height: 20px !important;
}

.NutritionFopTable tbody tr td .FopInputProdut,
.NutritionFopTable tbody tr td .fopNumberInput {
  padding: 5px !important;
}

.NutritionFopTable tbody tr td button {
  height: 25px !important;
  max-width: 25px !important;
  min-width: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NutritionFopTable tbody tr td button img {
  width: 15px !important;
  height: 15px !important;
}

/* .fopPrecentInput {
  width: 120px;
} */








.NutritionFopTable>.fop_selectBox {
  min-width: 190px !important;
}

.NutritionFopTable thead tr .minWidth190 {
  min-width: 190px;
}

.NutritionFopTable thead tr .minWidth160 {
  min-width: 160px;
}

.P-absolute {
  position: absolute;
}

#bottomSpace {
  margin-bottom: 10px;
}

.NutritionFopTable thead tr .width90 {
  width: 115px;
}

.FopInputProdut {
  max-width: 70px;
  min-width: 70px;
}

.FopInputProdut:disabled {
  width: 70px;
}

.select_Box>.select_wrapper>.basic-select {
  min-width: 180px;
  max-width: 180px;
}

.select_wrapper {
  display: flex;
  align-items: center;

}

.leftmrgn {
  margin-left: 10px;
}

.select_Box>.select--is-disabled {
  min-width: 231px;

}

.fopRating-low td {
  border-top: solid 2px #61ba13 !important;
}

.fopRating-low td:first-child {
  border-left: solid 4px #61ba13 !important;
}

.fopRating-low td:last-child {
  border-right: solid 2px #61ba13 !important;
}

.low>.select__control--is-focused {
  background-color: #61ba13;
}

.NutritionFopTable tbody .fopRating-low td .low .select__single-value,
.NutritionFopTable tbody .fopRating-low td .low .css-6q0nyr-Svg,
.NutritionFopTable tbody .fopRating-medium td .medium .select__single-value,
.NutritionFopTable tbody .fopRating-medium td .medium .css-6q0nyr-Svg,
.NutritionFopTable tbody .fopRating-high td .high .select__single-value,
.NutritionFopTable tbody .fopRating-high td .high .css-6q0nyr-Svg {
  color: #000000 !important;
  fill: #000000 !important;
}

.NutritionFopTable tbody .fopRating-low td .select--is-disabled .select__single-value--is-disabled,
.NutritionFopTable tbody .fopRating-medium td .select--is-disabled .select__single-value--is-disabled,
.NutritionFopTable tbody .fopRating-high td .select--is-disabled .select__single-value--is-disabled,
.NutritionFopTable tbody .fopRating-low td .select--is-disabled .select__control--is-disabled svg,
.NutritionFopTable tbody .fopRating-medium td .select--is-disabled .select__control--is-disabled svg,
.NutritionFopTable tbody .fopRating-high td .select--is-disabled .select__control--is-disabled svg {
  color: #606060 !important;
  fill: #606060 !important;
}

.NutritionFopTable tbody .fopRating-low td .low .select__control--is-focused,
.NutritionFopTable tbody .fopRating-low td .low .css-sbae5h-control {
  background-color: #61ba13;
}

.fopRating-medium td {
  border-top: solid 2px #f89300 !important;
}

.fopRating-medium td:first-child {
  border-left: solid 4px #f89300 !important;
}

.fopRating-medium td:last-child {
  border-right: solid 2px #f89300 !important;
}

.NutritionFopTable tbody .fopRating-medium td .medium .select__control--is-focused,
.NutritionFopTable tbody .fopRating-medium td .medium .css-sbae5h-control {
  background-color: #f89300;
}


.fopRating-high td {
  border-top: solid 2px #e3001b !important;
}

.fopRating-high td:first-child {
  border-left: solid 4px #e3001b !important;
}

.fopRating-high td:last-child {
  border-right: solid 2px #e3001b !important;
}

.NutritionFopTable tbody .fopRating-high td .high>.select__control--is-focused,
.NutritionFopTable tbody .fopRating-high td .high .css-sbae5h-control {
  background-color: #e3001b;
}


.fopRating-low .table-form-ui td:first-child,
.fopRating-medium .table-form-ui td:first-child,
.fopRating-high .table-form-ui td:first-child {
  border-left: none !important;
}

#bottomSpace>.select_wrapper>.bottom09 {
  bottom: -4px !important;
}

.bottom09 {
  bottom: -15px;
}

.disabled-icon {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: block;
  border: solid 1px #161616;
  line-height: 20px;
  margin-left: 5px;
  height: 35px;
  max-width: 35px;
  min-width: 35px;
  width: 100%;
  text-align: center;
  padding: 0;
  background-color: #f2f2f2 !important;
}

.nbb-approval-view .low-card{
  border-left: solid 4px #61ba13 !important;
  border-top: solid 2px #61ba13 !important;
  border-right: solid 2px #61ba13 !important;
}
.nbb-approval-view .medium-card{
  border-left: solid 4px #f89300 !important;
  border-top: solid 2px #f89300 !important;
  border-right: solid 2px #f89300 !important;
}
.nbb-approval-view .high-card{
  border-left: solid 4px #e3001b !important;
  border-top: solid 2px #e3001b !important;
  border-right: solid 2px #e3001b !important;
}

.GLN-dropdown li {
  background: #fff;
}

.GLN-dropdown li:hover {
  font-weight: bold;
}

.GLN-search-icon {
  background: #fff;
}

/* .GLN-dropdown{
  cursor: not-allowed !important;
} */

.disable-dropdown {
  /* pointer-events: none; */
  opacity: 0.6;
}

.GLN-invalid-color {
  color: rgb(240, 100, 0);
}
.error-items-container ul{
  padding: 0;
}
/*  order progress viewer icon  */
.sidebarImage{
  width: 20px !important;
  height: 20px !important;
   margin-left:0 !important;
}

.cin-retailer-item, .widget-tooltip{
  white-space: normal;
  font-weight: normal;
}
.cnn-layoutflow{
  position: absolute;
  width: 100%;
  height:100%;
}
.cnn-layoutflow .react-flow{
  overflow-y: scroll;
}

.select__option:hover{
  background-color: #E7EEFF !important;
}
.ril-prev-button{
  left: 80px !important;
}
.ril-next-button{
  right: 80px !important;
}

.notification{
  border: solid 1px #999;
}
.notification-success{
background-color: #fff !important;
border-left:solid 6px #51a351!important;
}
.notification-success:before{
  color:#51a351;
}
.notification-success .message,
.notification-success h4{
  color: #262626 !important;
}
.notification-error{
  background-color: #fff !important;
  border-left:solid 8px #AA213F !important;
  }
  .notification-error:before{
    color:#AA213F;
  }
  .notification-error .message,
  .notification-error h4{
    color: #262626 !important;
  }

  .notification-warning{
    background-color: #fff !important;
    border-left:solid 8px #FFB500 !important;
    }
    .notification-warning:before{
      color:#FFB500;
    }
    .notification-warning .message,
    .notification-warning h4{
      color: #262626 !important;
    }

    .notification-info{
      background-color: #fff !important;
      border-left:solid 8px #3265D2 !important;
      }
      .notification-info:before{
        color:#3265D2;
      }
      .notification-info .message,
      .notification-info h4{
        color: #262626 !important;
      }
        .react-datepicker__day--keyboard-selected, 
      .react-datepicker__month-text--keyboard-selected, 
      .react-datepicker__quarter-text--keyboard-selected,
       .react-datepicker__year-text--keyboard-selected{
        background-color: #2d6df6 !important;
      }
      .badge-danger {
        color: #fff;
        background-color: #2d6df6 !important;
      }
      .userimg{
        padding-top: 0 !important;
      }
      .userimg img{
        height: 15px;
        position: relative;
        top: -4px;
      }
      .alert{
        color: #262626 !important;
       
      }
      .alert-warning{      
        background-color: #FFF4D9 !important;
      
      }
      .alert-warning::before{
        content: url(/assets/images/icons-svg/alertInfo.svg);
        position: relative;
        left: 0;
        margin-right: 6px;
        vertical-align: middle;
      }
      .alert-success {      
        background-color: #D9EDDF !important;
   
      }
      .alert-success::before{
        content: url(/assets/images/icons-svg/success.svg);
        position: relative;
        left: 0;
        margin-right: 6px;
        vertical-align: middle;
      } 
      .alert-danger::before{
        content: url(/assets/images/icons-svg/metro-blocked-red.svg);
        position: relative;
        left: 0;
        margin-right: 6px;
        vertical-align: middle;
      }
  .alert-danger {
  background-color: #F2DEE2 !important;
  }
  .bg-success{
  background-color: #2d6df6 !important;
  }
  .bulk-popup ul{
  padding: 0;
  }
  .nutrition-container .nutritional-table>thead>tr>th {
    outline-offset: -1px;
  
  }
  
  .alert-info::before{
    content: url(/assets/images/icons-svg/alertInfodel.svg);
    position: relative;
    left: 0;
    margin-right: 6px;
    vertical-align: middle;
  }
 
  .alert:has(> .progress).alert-info::before,
  .alert:has(> .progress).alert-warning::before,
  .alert:has(> .progress).alert-danger::before,
  .alert:has(> .progress).alert-success::before{
    display: none;
  }

  .chp-processing-section{
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-weight: 600;
    color: #2A65E4;
  }
  .coming-soon{
    color: #F00;
    font-size: 11px;
    padding: 5px;
    position: absolute;
  }
  .dot-one {
    animation: dot-one 2s infinite linear
  }
  
  .dot-two {
    animation: dot-two 2s infinite linear
  }
  
  .dot-three {
    animation: dot-three 2s infinite linear
  }
  
  @keyframes dot-one {
    0% {
      opacity: 0;
    }
  
    15% {
      opacity: 0;
    }
  
    25% {
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes dot-two {
    0% {
      opacity: 0;
    }
  
    25% {
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes dot-three {
    0% {
      opacity: 0;
    }
  
    50% {
      opacity: 0;
    }
  
    75% {
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }

  /** Custom CSS for the new Userlane */
  .dGpciM {
    left: 0 !important;
  }

  /* Action Required Color Change */
  .action-req-select-dropdown {
    background-color: #e8f4ea;
  }

  .version-modal-dialog {
    max-width: 1150px;
    width: 1150px;
    margin: 0 auto;
  }

  .preview-popup .modal.right .version-modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .preview-popup .modal.right.fade .version-modal-dialog {
    right: 0;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  .preview-show-diff-switch {
    padding-left: 715px;
    list-style: none;
  }

  .preview-switch-txt {
    margin-left: 10px;
    padding: 10px;
    color: #161616;
  }
  .box-overflow{
    clip-path: inset(0 0 0 -50px);
  }

  .show-only-difference {
    .globe-card {
      display: none;
    }

    .nbb-approval-view{
      display: none;
    }

    .nbb-approval-view:has(.hybrid-highlighted-versions) {
      display: block
    }

    .globe-card:has(.hybrid-highlighted-versions) {
      display: block
    }
  }

  .hybrid-highlighted-versions {
    background-color: #bdffbb !important;
    padding: 10px 4px;
  }
  .hybrid-highlighted-versions p{
    margin: 0;
  }
  .hybrid-product-version-nutrient-modal {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .hybrid-product-version-nutrient .modal-header {
    padding: 5px 20px;
    border: 1px solid #e8e8e8;
  }
  
  .hybrid-product-version-nutrient .modal-content {
    border-radius: 0px;
    box-shadow: 0px 3px 6px #16161629;
    max-height: auto;
  }
  
  .hybrid-product-version-nutrient .modal-title,
  .hybrid-product-version-nutrient .close {
    font-size: 22px;
    color: #161616;
    text-align: left;
    font-weight: normal;
  }

  .hybrid-product-version-nutrient .modal-dialog {
    max-width: 1370px;
    width: 1370px;
    margin: 0 auto;
  }

  .hierarchy-version-icon {
    margin-right: 15px;
    margin-top: 10px;
  }
  
  .preview-popup .modal.right.fade.in .version-modal-dialog {
    right: 0;
  }

  .version-modal-dialog {
    width: 100%;
  }
  .Login-spx-logo  img {
    height: 150px;
}

.selected-product-section {
  background: #f3f9ff;
  border: none;
}

.custom-checkbox.disabled {
  width: 16px;
  height: 16px;
  border: 1px solid #9c9c9c;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.navbar-row {
  position: sticky;
  top: 44px;
  width: 100%;
  z-index: 99;
  background-color: #fafafa;
  height: 35px;
}
.copyToclipBtn{
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 10px;
}
.prev-prod {
  margin-left: 12px;
  color: #2d6df6;
  cursor: pointer;
}
.prev-prod:after {
  content: "\e9c4";
  font-family: "icomoon";
  display: inline-block;
  font-size: 2rem;
  vertical-align: middle;
  line-height: 1;
  position: absolute;
  top: 50%;
  font-weight: lighter !important;
  color: #2d6df6;
  transform: translateY(-50%);
  transform-origin: top;
  -webkit-transform-origin: top;
  left: 0.75rem;
  transition: transform 0.25s ease-in-out;
  -webkit-font-smoothing: antialiased;
}
.next-prod {
  margin-right: 12px;
  color: #2d6df6;
  cursor: pointer;
}
.next-prod:after {
  content: "\e9c7";
  font-family: "icomoon";
  display: inline-block;
  font-size: 2rem;
  vertical-align: middle;
  line-height: 1;
  position: absolute;
  top: 50%;
  font-weight: lighter !important;
  color: #2d6df6;
  transform: translateY(-50%);
  transform-origin: top;
  -webkit-transform-origin: top;
  right: 0.75rem;
  transition: transform 0.25s ease-in-out;
  -webkit-font-smoothing: antialiased;
}