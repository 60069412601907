/* ----------------------------------------------------------SPV2--------------------------------------------------------------- */

    .imageTopupSec .nbb-accordion{
      margin: 5px  0!important;
    }
    .asset-layout{
     display: flex;
    }
    .asset-layout label{
     min-width: 150px;
     display: flex;
     align-items: center;
     font-size: 14px;
     font-weight: 600;
    }
    .downloadAsset{
     display: flex;
     justify-content: space-between;
     border: 1px solid rgba(0,0,0,.125);
     padding: 5px 10px;
     align-items: center;
    }
    .downloadAsset a{
     font-size: 15px;
    }
    
    .headertext ul{
     list-style: none;
     text-align: right;
     margin: 0;
    }
    .headertext ul li {
     display: inline-block;
     padding: 10px 0;
     font-size: 13px;
     font-weight: bold;
    }
    .headertext ul li:nth-child(2) a{
     border-left: solid 1px #ccc;
    }
    .headertext ul li a{
    color: #666666;
    font-size: 12px;
    padding: 0 10px;
    }
    
    .headertext{
     border-bottom: 1px solid rgba(0,0,0,.125);
     margin: 0 10px;
    } 
    .imagewrapper{
      border: 1px solid rgba(0,0,0,.125);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 10px;
      overflow: hidden;
    }
    
    .subimagewrapper img{
     max-height: 110px;;
    }
    .imagewrapper span,
    .subimagewrapper span{
     min-width: 100%;
       text-align: center;
       position: absolute;
       bottom: 0;
       background: rgba(0, 0, 0, 0.7);
       color: #fff;
       font-size: 13px;
    }
    .imagewrapper img{
      height: 100%;
      width: 100%;
    }
    .subimagewrapper{
     border: 1px solid rgba(0,0,0,.125);
     height: 115px;
     width: 115px;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
     margin:0 5px 10px;
   
    }
    
    .tagright {
     color: #fff;
     font-weight: bold;
     font-size: 13px;
    }
    .topright:before {
     content: "";
     width: 0;
     height: 0;
     border-top: 45px solid #D9D9D9;
     border-left: 45px solid transparent;
     position: absolute;
     top: 0;
     right: 0;
    }
    .topright img{
     position: absolute;
     top: 6px;
     right: -26px;
       width: 80px;
     text-align: center;
     height: 15px;
    }
    .subimagewrapper .thumb-cross-icon{
      position: absolute;
      top: -8px;
      right: -8px;
      border: 1px solid rgba(0,0,0,.125);
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
  
    }
    .subimagewrapper .thumb-cross-icon img{
      height: 12px !important;
    }
    .subBoxCont{
     display: flex;
     flex-direction: row;
     flex-wrap:wrap;
    margin: 0px 0 0 12px;
      
    }
    .boxScrolable{
      max-height: 285px;
      overflow-y: scroll;
    }

    .main-card {
      min-height: 525px;
    }
    .dummyBox{
     border: 1px dashed rgba(0,0,0,.125);
    }
    
    .previous_layout{
     display: flex;
      border: 1px solid rgba(0,0,0,.125);
     padding:10px;
     align-items: center;
    }
    .viewImg{
     padding: 0px 20px 0 15px
    }
    .headingsection{
     letter-spacing: 0px;
     color: #000000;
     opacity: 1;
     font-size: 20px;
     padding:10px 0px 0px 15px;
    }
    .leftboxsec{
     height: 100px;
     width: 100px;
    }
    .leftboxsec img {
     max-height: 85px;
     overflow: hidden;
    }
    .checkboxsec span{
     min-width:unset;
     right: 78px;
     bottom: 78px;
       font-size: unset;
       top: unset !important;
       background-color: unset !important;
    }
    .checkboxsecSpv .checked,
    .checkboxsec .checked{
     background-color: #2D6DF6 !important; 
     border: solid 1px #2D6DF6 !important; 
    }
    .assetLIbd{
     margin-bottom: 0px;
    }
    .assetLIb{
     padding:15px !important;
     white-space: unset;
    }
    .removeare{
   display: flex;
   align-items: center;
    }
    
    .uploadeImg{
     max-width: 1500px;
     width: 1077px;
     margin: 0 auto;
    }
    .imguploadesection{
     /* border: solid 1px #707070; */
     border: 2px dashed #bbb;
     text-align: center;
     min-height: 200px;
     position: relative;
     border-radius: 5px;
    }
    .upload_video_tab{
      border: solid 1px #707070 !important; 
    }
    .imguploadesection p{
    color: #000;
    font-size: 22px;
    margin: 20px;
    }
    .imguploadesection img{
     height: 75px;
     margin: 40px;
    }
    .uploadeImg .nav-tabs li{
     padding-right: 6%;
    }
    .uploadeImg .nav-tabs li a{
     padding: 10px 0;
     color: #666666;
     font-size: 20px !important;
     display: block;
    }
    .uploadeImg .nav-tabs .active a{
     color: #000000;
     border-top: solid 1px #000000;
    }
    .imguploadesection form{
     text-align: left;
     padding: 20px;
    }
    
    .imguploadesection form .form-vimo{
    margin-bottom: 20px;
    }
    .uploadeImg .modal-body{
     margin: 25px;
    }
    .input-file-trigger{
     color: #FFF !important;
    }
    .tabFixed{
      position: fixed;
      top: 43px;
      background: #FFF;
      width: 91%;
      z-index: 9;
    }
    .tabFixeddiv{
    margin-top: 40px;
    }
    .uplodePopup{
     border: 1px solid rgba(0,0,0,.125);
     max-width: 100px;
     height: 100px;
     margin: 10px;
     text-align: center;
     overflow: hidden;
     padding: 10px;
    }
    .uplodePopup img{
     height: 45px;
    
    }
    .uplodePopup a{
     font-size: 12px;
     display: block;
    }
    .msgsec{
     border: solid 1px #B8DAFF;
     background-color: #CCE5FF;
     padding: 15px;
     margin-bottom: 25px;
    }
    .msgsec p{
     font-size: 13px;
     color: #004085;
     margin: 0;
    } 
    
    .img-hover-zoom .libraray-image {
      transition: transform .3s ease;
    }
    
    .img-hover-zoom .libraray-image:hover {
      transform: scale(1.1);
    }
    .text-success {
      color: #65b165!important;
    }
   
    .topupbtn{
      padding: 6px 35px !important;
      z-index: 0;
      display: flex;
      align-items: center;
    }
    .topupbtn img{
      margin: 0 10px;
      transition: 0.4s;
    }
    .topupbtn:hover img{
      filter: brightness(0) invert(1);
   
    }
    .removeallbtn{
      width: 100%;
      margin: 0px 0 20px 5px;
    
    }
    .removeallbtn img {
      height: 15px;
      margin: 0 5px 0 0;
    }
    .previous-accordion-container{
      display: contents;
    }
    .previous-accordion-content{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 6px;
    }
    
    .previousLayout-image{
      max-height: 150px;
    }
    
    .video-thumb-play-icon {
        display: flex;
        position: absolute;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .video-thumb-play-icon::after{
      content: url('/assets/images/icons-svg/play-button-white.svg');
      position: absolute;
      background: #383434;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      /* padding: 12px 15px; */
      padding:8px 11px;
      opacity: 0.9;
      cursor: pointer;
    }
    
    .subimagewrapper:hover .product_status_tooltip {
      display: block !important;
      transition: 0.3s ease-in-out;
    }
    
    .draggable-input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width:unset !important;
      margin-top:unset !important;
    }

    .video_frame_container iframe{
      position: absolute; 
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border:none;
    }
    .enabled-layout-count{
      display: flex;
      align-items: center;
      font-size:14px;
      font-weight: 600;
    }
    .enabled-layout-action p{
      font-size: 13px;
      margin-top:5px;
    }
    .layout-disabled-info p{
      font-size: 14px !important;
    }
    .select-switch-input{
      min-width: 45px !important;
    }

    .connect-plus-btn-sec{
      display: flex;
      justify-content: flex-end;
    }
    .prev_des{
padding: 20px 3px;
    }
    .prev_des span{
      font-weight: normal !important;
      font-size: 13px !important;
      color: #161616 !important;
    }
    .prev_des strong{
      margin-right: 5px;
      color: #292929;
    }
    .mw-0{
      min-width: 0px !important;
    }
    .checkboxsecSpv{
      margin-left: 50px;
    }

    .layout-card-backdrop{
      height:100%;
      width:100%;
      position: absolute;
      background-color: #333;
      z-index: 1;
      opacity: 0.3;
      border-radius: 2px;
    }
    .imagealayout{
      padding: 20px 10px;
    }
   
.ImgTopUpcount {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: #fff;
    color: #000 !important;
    font-size: 10px !important;
    text-align: center;
    border: dotted 1px #2d6df6;
    line-height: normal;
    }

.file_input_container
{
  position: relative;
}    

.mp4VideoUpload{
  margin:0px 20px 20px;
}
.subimagewrapper .videoThumb{
  min-width: 80px;
  padding: 7px;
}
.subBoxCont .video-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20px;
  padding-left: 5px;
}