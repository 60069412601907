/* ------------------------------------------------------------------------------
 *
 *  # Main content layout
 *
 *  Styles for main structure of content area
 *
 * ---------------------------------------------------------------------------- */
html {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

body {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  background-color: #ffffff;
  
}

.page-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  /* overflow: auto;  comment for sticky header*/
}

.content {
  padding: 1.25rem 1.25rem;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.content::after {
  display: block;
  clear: both;
  content: "";
}

/* ------------------------------------------------------------------------------
 *
 *  # Page header
 *
 *  Page header components and color options
 *
 * ---------------------------------------------------------------------------- */

.page-title small {
  display: inline-block;
  margin-left: 0.625rem;
}

.page-title small:before {
  content: "/";
  margin-right: 0.875rem;
}

.page-title small.d-block {
  margin-left: 0;
}

.page-title small.d-block:before {
  content: none;
}

.page-title small.d-block,
.page-title .breadcrumb {
  margin-left: 1.875rem;
}


.page-title h1,
.page-title h2,
.page-title h3,
.page-title h4,
.page-title h5,
.page-title h6 {
  margin: 0;
  font-family: "Aktiv-Grotesk-bold";
  text-align: left;
  line-height: 1;
  font-size: 32px;
  display: flex;
  align-items: center;
}

/* .page-title img {
  display: block !important;
} */
.canvas_container {
  width: 280px;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.page-header-content[class*="border-bottom-"]+.breadcrumb-line {
  border-top: 0;
}

.page-header-light {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.page-header-light.has-cover {
  background: url(/assets/images/backgrounds/seamless.png);
}

.page-header-dark {
  background-color: #273246;
  color: #fff;
  margin-bottom: 1.25rem;
}

.page-header-dark>.breadcrumb>li>a,
.page-header-dark>.breadcrumb>li+li:before,
.page-header-dark .page-header-content .breadcrumb>li>a,
.page-header-dark .page-header-content .breadcrumb>li+li:before {
  color: rgba(255, 255, 255, 0.9);
}

.page-header-dark>.breadcrumb>li>a:hover,
.page-header-dark>.breadcrumb>li>a:focus,
.page-header-dark .page-header-content .breadcrumb>li>a:hover,
.page-header-dark .page-header-content .breadcrumb>li>a:focus {
  color: #fff;
  opacity: 1;
}

.page-header-dark>.breadcrumb>.active,
.page-header-dark .page-header-content .breadcrumb>.active {
  color: rgba(255, 255, 255, 0.5);
}

.page-header-dark.has-cover {
  background: url(/assets/images/login_cover.jpg);
  background-size: cover;
}

/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Sidebar components, main navigation and sidebar itself
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  /* width: 16.875rem; */
  z-index: 1040;
}

.sidebar-secondary {
  width: 16.875rem;
}

.sidebar:not(.sidebar-component) {
  position: fixed;
  top: 0;
  bottom: 0;
  box-sizing: content-box;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sidebar:not(.sidebar-component) {
    transition: none;
  }
}

.sidebar-main,
.sidebar-main .sidebar-content {
  /* left: -18.5625rem; */
  right: -45.5625rem;
}

.sidebar-secondary,
.sidebar-secondary .sidebar-content {
  left: -18.5625rem;

}

.sidebar-right,
.sidebar-right .sidebar-content {
  right: -18.5625rem;
}

.sidebar.sidebar-fullscreen {
  width: 100%;
}

.sidebar:not(.sidebar-component) .sidebar-content {
  position: fixed;
  top: 3.12503rem;
  bottom: 0;
  width: inherit;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sidebar:not(.sidebar-component) .sidebar-content {
    transition: none;
  }
}

.navbar-lg:first-child~.page-content .sidebar:not(.sidebar-component) .sidebar-content {
  top: 3.37503rem;
}

.navbar-sm:first-child~.page-content .sidebar:not(.sidebar-component) .sidebar-content {
  top: 2.87503rem;
}

.sidebar-mobile-main .sidebar-main {
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35);
}

.sidebar-mobile-main .sidebar-main,
.sidebar-mobile-main .sidebar-main .sidebar-content {
  left: 0;
}

.sidebar-mobile-secondary .sidebar-secondary {
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35);
}

.sidebar-mobile-secondary .sidebar-secondary,
.sidebar-mobile-secondary .sidebar-secondary .sidebar-content {
  left: 0;
}

.sidebar-mobile-right .sidebar-right {
  display: block;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.35);
}

.sidebar-mobile-right .sidebar-right,
.sidebar-mobile-right .sidebar-right .sidebar-content {
  right: 0;
}

.sidebar-mobile-component .sidebar-component {
  display: block;
}

.nav-sidebar {
  -ms-flex-direction: column;
  flex-direction: column;
 
}
.card-sidebar-mobile .nav-sidebar {
  padding-top: 50px;
}

.nav-sidebar .nav-item:not(.nav-item-header):last-child {
  padding-bottom: 0rem;
}

.nav-sidebar .nav-item:not(.nav-item-divider) {
  margin-bottom: 0;
}

/* .create-products-tabs .nav-sidebar .nav-item:not(.nav-item-divider) {
    margin-bottom: 10px;
} */

.nav-sidebar .nav-item:not(.nav-item-divider):last-child {
  width: 100%;
}

.create-products-tabs li.product-tab:nth-last-child(2) {
  width: 100%;
}

.create-products-tabs li.product-tab:nth-last-child(2) a,
.create-products-tabs .nav-sidebar .nav-item:not(.nav-item-divider):last-child a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-sidebar>.nav-item>.nav-link {
  font-weight: 500;
}

.nav-sidebar .nav-link {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0.75rem 1.25rem;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  line-height: normal;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-link {
    transition: none;
  }
}

.nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0.12502rem;
  margin-bottom: 0.12502rem;
  top: 0;
  min-width: 26px;
}

.nav-sidebar .nav-link .badge {
  transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-link .badge {
    transition: none;
  }
}

.nav-sidebar .nav-link.disabled,
.nav-sidebar .nav-link.disabled:hover,
.nav-sidebar .nav-link.disabled:focus {
  background-color: transparent;
  opacity: 0.5;
}

.nav-sidebar .nav-item-header {
  padding: 0.75rem 1.25rem;
  margin-top: 0.5rem;
}

.nav-sidebar .nav-item-header>i {
  display: none;
}

.nav-sidebar .nav-item-divider {
  margin: 0.5rem 0;
  height: 1px;
}

.nav-item-submenu>.nav-link {
  padding-right: 2.75rem;
}

.nav-item-submenu>.nav-link:after {
  content: "\e9c7";
  font-family: "icomoon";
  display: inline-block;
  font-size: 2rem;
  vertical-align: middle;
  line-height: 1;
  position: absolute;
  top: 50%;
  font-weight: lighter !important;
  color: #000;
  transform: translateY(-50%);
  transform-origin: top;
  -webkit-transform-origin: top;
  right: 0.75rem;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opt-lang-css:after {
  right: 1.75rem !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-item-submenu>.nav-link:after {
    transition: none;
  }
}

.nav-item-submenu.nav-item-open>.nav-link:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: top;
  -webkit-transform-origin: top;
  right: -6px;
}

.right-arrow.nav-item-open::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: top;
  -webkit-transform-origin: top;
  right: -6px;
}
.right-arrow::after {
  content: "\e9c7";
  font-family: "icomoon";
  display: inline-block;
  font-size: 2rem;
  vertical-align: middle;
  line-height: 1;
  position: absolute;
  top: 50%;
  font-weight: lighter !important;
  color: #000;
  transform: translateY(-50%);
  transform-origin: top;
  -webkit-transform-origin: top;
  right: 0.75rem;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -webkit-font-smoothing: antialiased;
}

@media screen and (prefers-reduced-motion: reduce) {
  .right-arrow {
    transition: none;
  }
}



.nav-group-sub {
  display: none;
}

.nav-group-sub .nav-link {
  padding: 1.0rem 1.25rem 1.0rem 4rem;
  font-size: 15px;
}

.nav-group-sub .nav-group-sub .nav-link {
  padding-left: 4.75rem;
}

.nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 6rem;
}

.nav-item-expanded>.nav-group-sub {
  display: block;
}

.nav-scrollspy .nav-link.active+.nav,
.nav-scrollspy .nav-item-open .nav-link:not(.active)+.nav {
  display: block;
}

.nav-scrollspy .nav-link.active:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: top;
  -webkit-transform-origin: top;
}

.nav-sidebar-icons-reverse .nav-link {
  padding-right: 3.5rem;
}

.nav-sidebar-icons-reverse .nav-link i {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  margin-right: 0;
}

.nav-sidebar-icons-reverse .nav-item-submenu .nav-link {
  padding-right: 4.5rem;
}

.nav-sidebar-icons-reverse .nav-item-submenu .nav-link:after {
  right: 3.25rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-link {
  padding-left: 2.5rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-link i {
  top: 0.625rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 3.75rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 5rem;
}

.nav-sidebar-bordered>.nav-item+.nav-item:not(.nav-item-divider) {
  margin-bottom: 0;
}

.sidebar .card:first-child .nav-sidebar-bordered {
  padding-top: 0;
}

.sidebar .card:first-child .nav-sidebar-bordered>.nav-item:first-child,
.sidebar .card:first-child .nav-sidebar-bordered>.nav-item-header:first-child {
  border-top: 0;
}

.sidebar .card:last-child .nav-sidebar-bordered {
  padding-bottom: 0;
}

.sidebar .card:last-child .nav-sidebar-bordered>.nav-item:last-child,
.sidebar .card:last-child .nav-sidebar-bordered>.nav-item-header:last-child {
  border-bottom: 0;
}

.sidebar-dark .nav-sidebar-bordered>.nav-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-sidebar-bordered>.nav-item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-sidebar-bordered>.nav-item-header {
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0;
}

.sidebar-light .nav-sidebar-bordered>.nav-item {
  border-top: 1px solid #eee;
}

.sidebar-light .nav-sidebar-bordered>.nav-item:last-child {
  border-bottom: 1px solid #eee;
}

.sidebar-light .nav-sidebar-bordered>.nav-item-header {
  background-color: #fafafa;
  border-top: 1px solid #eee;
  margin: 0;
}

.sidebar-mobile-toggler {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  /* border-top: 1px solid transparent;*/
  border-bottom: 2px solid #514e4e;
}

.sidebar-mobile-toggler a {
  padding: 10px 15px;
  transition: all ease-in-out 0.15s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sidebar-mobile-toggler a {
    transition: none;
  }
}

.navbar-lg:first-child~.page-content .sidebar-mobile-toggler a {
  padding: 1rem 1.25rem;
}

.navbar-sm:first-child~.page-content .sidebar-mobile-toggler a {
  padding: 0.75rem 1.25rem;
}

.sidebar-mobile-expand i:last-child:not(:first-child) {
  display: none;
}

.sidebar-fullscreen .sidebar-mobile-expand i:first-child {
  display: none;
}

.sidebar-fullscreen .sidebar-mobile-expand i:last-child {
  display: inline-block;
}

.sidebar-dark {
  background: #000;
  color: #fff;
}

.sidebar-dark .sidebar-mobile-toggler {
  color: rgba(255, 255, 255, 0.9);
  /* border-bottom-color: rgba(255, 255, 255, 0.1); */
}

.sidebar-dark .sidebar-mobile-toggler:not([class*="bg-"]) {
  /* background-color: #1e272c; */
  /* border-bottom-color: rgba(255, 255, 255, 0.1); */
}

.sidebar-dark .sidebar-mobile-toggler a {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-dark .sidebar-mobile-toggler a:hover {
  color: #fff;
  background-color: transparent;
}

.sidebar-dark .card-header {
  border-color: rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-sidebar .nav-link,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-link {
  color: #ffffff;
}
.card-sidebar-mobile .nav-sidebar .nav-item-submenu .nav-group-sub .nav-item:hover a,
.card-sidebar-mobile .nav-sidebar .nav-item-submenu .nav-group-sub .nav-item:hover{ 
  background-color:#2d6df6;
  color: #fff !important;
}

.sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-link:not(.disabled):hover {
  color: #262626;
  background-color:transparent;
}

.sidebar-dark .nav-sidebar .nav-item>.nav-link.active,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item>.nav-link.active {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.sidebar-dark .nav-sidebar .nav-item-open>.nav-link:not(.disabled),
.sidebar-dark .nav-sidebar>.nav-item-expanded:not(.nav-item-open)>.nav-link,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-open>.nav-link:not(.disabled),
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar>.nav-item-expanded:not(.nav-item-open)>.nav-link {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.sidebar-dark .nav-sidebar .nav-item-header,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-header {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .nav-sidebar .nav-item-divider,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-divider {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-sidebar>.nav-item-submenu>.nav-group-sub,
.sidebar-light .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar>.nav-item-submenu>.nav-group-sub {
  background-color: rgba(0, 0, 0, 0.15);
}

.sidebar-dark[class*="bg-"] .nav-sidebar>.nav-item-open>.nav-link:not(.disabled),
.sidebar-dark[class*="bg-"] .nav-sidebar>.nav-item>.nav-link.active,
.sidebar .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar>.nav-item-open>.nav-link:not(.disabled),
.sidebar .card[class*="bg-"]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar>.nav-item>.nav-link.active {
  background-color: rgba(0, 0, 0, 0.3);
}

.sidebar-light {
  background-color: #fff;
  color: #333;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  background-clip: content-box;
}

.sidebar-light.sidebar-right {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.sidebar-light .nav-sidebar .nav-link:not(.disabled):hover {
  color: #333;
  background-color: #cbcbde;
}

.sidebar-light .nav-sidebar .nav-item>.nav-link.active {
  background-color: #cbcbde;
  color: #333;
}

.sidebar-light .nav-sidebar .nav-item-open>.nav-link:not(.disabled),
.sidebar-light .nav-sidebar>.nav-item-expanded:not(.nav-item-open)>.nav-link {
  background-color: #cbcbde;
  color: #333;
}

.sidebar-light .nav-sidebar>.nav-item-open>.nav-link:not(.disabled),
.sidebar-light .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #cbcbde;
  color: #333;
}

.sidebar-light .nav-sidebar .nav-item-header {
  color: rgba(51, 51, 51, 0.5);
}

.sidebar-light .nav-sidebar .nav-item-divider {
  background-color: rgba(0, 0, 0, 0.125);
}

.sidebar-light .nav-sidebar>.nav-item-submenu>.nav-group-sub {
  background-color: #ffffff;
}

.sidebar-light .sidebar-mobile-toggler {
  color: rgba(51, 51, 51, 0.8);
  border-bottom-color: rgba(0, 0, 0, 0.125);
}

.sidebar-light .sidebar-mobile-toggler:not([class*="bg-"]) {
  background-color: whitesmoke;
}

.sidebar-light .sidebar-mobile-toggler a {
  color: rgba(51, 51, 51, 0.8);
}

.sidebar-light .sidebar-mobile-toggler a:hover {
  color: #333;
  background-color: transparent;
}

.sidebar-light .sidebar-mobile-toggler[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
  color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.sidebar-light .sidebar-mobile-toggler[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) a {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-light .sidebar-mobile-toggler[class*="bg-"]:not(.bg-white):not(.bg-light):not(.bg-transparent) a:hover {
  color: #fff;
  background-color: transparent;
}

.sidebar-component {
  display: none;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.sidebar-component.sidebar-dark {
  border-color: rgba(255, 255, 255, 0.1);
}

.sidebar-component.sidebar-light {
  border-color: rgba(0, 0, 0, 0.125);
}

@media (min-width: 768px) {
  .sidebar-xs .sidebar-main {
    width: 3.5rem;
  }

  .sidebar-xs .sidebar-main .sidebar-content::-webkit-scrollbar {
    width: 0 !important;
  }

  .sidebar-xs .sidebar-main .card:not(.card-sidebar-mobile),
  .sidebar-xs .sidebar-main .card-title {
    display: none;
  }

  .sidebar-xs .sidebar-main .card-header h6+.header-elements {
    padding-top: 0.22117rem;
    padding-bottom: 0.22117rem;
  }

  .sidebar-xs .sidebar-main .card-header h5+.header-elements {
    padding-top: 0.31733rem;
    padding-bottom: 0.31733rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item {
    position: relative;
    margin: 0 0 11px 0;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item>.nav-link {
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item>.nav-link>i {
    position: static;
    margin-left: 0;
    margin-right: 0;
    display: block;
    padding-bottom: 1px;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item>.nav-link>span {
    display: none;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-submenu:hover>.nav-group-sub,
  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-submenu:focus>.nav-group-sub {
    display: block !important;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-submenu>.nav-group-sub {
    position: absolute;
    top: 1px;
    right: -11.875rem;
    width: 11.875rem;
    display: none;
    z-index: 1000;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-submenu>.nav-link:after {
    content: none;
  }

  .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-link {
    padding-left: 1.25rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-group-sub .nav-link {
    padding-left: 2.25rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
    padding-left: 3.5rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-header {
    padding: 0;
    text-align: center;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-header>i {
    display: block;
    top: 0;
    padding: 0.75rem 1.25rem;
    margin-top: 0.12502rem;
    margin-bottom: 0.12502rem;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-header>div {
    display: none;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item-open>.nav-group-sub {
    display: none !important;
  }

  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item:hover>.nav-link.disabled+.nav-group-sub,
  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item:hover>.nav-link.disabled>span,
  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item:focus>.nav-link.disabled+.nav-group-sub,
  .sidebar-xs .sidebar-main .nav-sidebar>.nav-item:focus>.nav-link.disabled>span {
    display: none !important;
  }

  .sidebar-xs .sidebar-main .sidebar-user .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-xs .sidebar-main .sidebar-user .media {
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sidebar-xs .sidebar-main .sidebar-user .media>div:not(:first-child) {
    display: none !important;
  }

  .sidebar-xs .sidebar-main .sidebar-user .media>div:first-child {
    margin: 0 !important;
  }

  .sidebar-xs .sidebar-main .nav-item-submenu-reversed .nav-group-sub {
    top: auto !important;
    bottom: 0;
  }

  .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item:hover,
  .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item.active {
    background-color: #000000;
  }

  .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item:not(.nav-item-open):hover>.nav-link:not(.active):not(.disabled) {
    /* color: #fff;
    background-color: rgba(0, 0, 0, 0.15); */
  }

  .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item-submenu>.nav-group-sub {
    background-color: #060a45;
  }

  .sidebar-xs .sidebar-main.sidebar-light .nav-sidebar>.nav-item:not(.nav-item-open):hover>.nav-link:not(.active):not(.disabled) {
    color: #333;
    background-color: #f5f5f5;
  }

  .sidebar-xs .sidebar-main.sidebar-light .nav-sidebar>.nav-item-submenu>.nav-group-sub {
    background-color: #fcfcfc;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  /* .sidebar-xs .sidebar-main.sidebar-fixed {
    z-index: 1029;
  } */

  .sidebar-xs .navbar-lg:first-child~.page-content .sidebar-fixed.sidebar-main .nav-sidebar>.nav-item-submenu:hover>.nav-group-sub,
  .sidebar-xs .navbar-lg:first-child~.page-content .sidebar-fixed.sidebar-main .nav-sidebar>.nav-item-submenu:focus>.nav-group-sub {
    top: 3.37503rem;
  }

  .sidebar-xs .navbar-sm:first-child~.page-content .sidebar-fixed.sidebar-main .nav-sidebar>.nav-item-submenu:hover>.nav-group-sub,
  .sidebar-xs .navbar-sm:first-child~.page-content .sidebar-fixed.sidebar-main .nav-sidebar>.nav-item-submenu:focus>.nav-group-sub {
    top: 2.87503rem;
  }
}

@media (min-width: 576px) {
  .sidebar-expand-sm.sidebar-main {
    z-index: 99;
    box-shadow: none;
  }

  .sidebar-expand-sm.sidebar-main .sidebar-content {
    left: 0;
  }

  .sidebar-expand-sm.sidebar-secondary {
    z-index: 98;
    box-shadow: none;
  }

  .sidebar-expand-sm.sidebar-secondary .sidebar-content {
    left: 0;
  }

  .sidebar-expand-sm.sidebar-right {
    z-index: 97;
    box-shadow: none;
  }

  .sidebar-expand-sm.sidebar-right .sidebar-content {
    right: 0;
  }

  .sidebar-expand-sm.sidebar-component {
    z-index: 96;
  }

  .sidebar-expand-sm:not(.sidebar-component) {
    position: static;
    transition: none;
  }
}

@media screen and (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-sm:not(.sidebar-component) {
    transition: none;
  }
}

@media (min-width: 576px) {
  .sidebar-expand-sm:not(.sidebar-component):not(.sidebar-fixed) .sidebar-content {
    position: static;
    overflow: visible;
    width: auto;
  }

  .sidebar-expand-sm.sidebar-dark:not(.sidebar-component)+.sidebar-dark:not(.sidebar-component) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-expand-sm .sidebar-mobile-toggler {
    display: none;
  }

  .sidebar-expand-sm.sidebar-fullscreen {
    width: 16.875rem;
  }

  .sidebar-main-hidden .sidebar-expand-sm.sidebar-main,
  .sidebar-component-hidden .sidebar-expand-sm.sidebar-component,
  .sidebar-secondary-hidden .sidebar-expand-sm.sidebar-secondary,
  .sidebar-mobile-right .sidebar-expand-sm.sidebar-right {
    display: none;
  }

  .sidebar-expand-sm.sidebar-right {
    display: none;
  }

  .sidebar-right-visible .sidebar-expand-sm.sidebar-right {
    display: block;
  }

  .sidebar-expand-sm.sidebar-component {
    display: block;
    width: 16.875rem;
  }

  .sidebar-expand-sm.sidebar-component-left {
    margin-right: 1.25rem;
  }

  .sidebar-expand-sm.sidebar-component-right {
    margin-left: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .sidebar-expand-sm:not(.sidebar-component) {
    border: 0;
  }

  .revision-dropdown {
    width: 60% !important;
  }
}

@media (min-width: 768px) {
  .sidebar-expand-md.sidebar-main {
    z-index: 23;
    box-shadow: none;
  }

  .sidebar-expand-md.sidebar-main .sidebar-content {
    left: 0;
  }

  .sidebar-expand-md.sidebar-secondary {
    z-index: 1;
    box-shadow: none;
  }

  .sidebar-expand-md.sidebar-secondary .sidebar-content {
    left: 0;
  }

  .sidebar-expand-md.sidebar-right {
    z-index: 97;
    box-shadow: none;
  }

  .sidebar-expand-md.sidebar-right .sidebar-content {
    right: 0;
  }

  .sidebar-expand-md.sidebar-component {
    z-index: 96;
  }

  .sidebar-expand-md:not(.sidebar-component) {
    position: relative;
    transition: 1s;
  }
}

@media screen and (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-md:not(.sidebar-component) {
    transition: 1s;
  }
}

@media (min-width: 768px) {
  .sidebar-expand-md:not(.sidebar-component):not(.sidebar-fixed) .sidebar-content {
    position: fixed;
    left: auto;
    background: #fff;
  }

  .sidebar-expand-md.sidebar-dark:not(.sidebar-component)+.sidebar-dark:not(.sidebar-component) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-expand-md .sidebar-mobile-toggler {
    display: none;
  }

  .sidebar-expand-md.sidebar-fullscreen {
    width: 16.875rem;
  }

  .sidebar-main-hidden .sidebar-expand-md.sidebar-main,
  .sidebar-component-hidden .sidebar-expand-md.sidebar-component,
  .sidebar-secondary-hidden .sidebar-expand-md.sidebar-secondary,
  .sidebar-mobile-right .sidebar-expand-md.sidebar-right {
    display: none;
  }

  .sidebar-expand-md.sidebar-right {
    display: none;
  }

  .sidebar-right-visible .sidebar-expand-md.sidebar-right {
    display: block;
  }

  .sidebar-expand-md.sidebar-component {
    display: block;
    width: 16.875rem;
  }

  .sidebar-expand-md.sidebar-component-left {
    margin-right: 1.25rem;
  }

  .sidebar-expand-md.sidebar-component-right {
    margin-left: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .sidebar-expand-md:not(.sidebar-component) {
    border: 0;
  }
}

@media (min-width: 992px) {
  .sidebar-expand-lg.sidebar-main {
    z-index: 99;
    box-shadow: none;
  }

  .sidebar-expand-lg.sidebar-main .sidebar-content {
    left: 0;
  }

  .sidebar-expand-lg.sidebar-secondary {
    z-index: 98;
    box-shadow: none;
  }

  .sidebar-expand-lg.sidebar-secondary .sidebar-content {
    left: 0;
  }

  .sidebar-expand-lg.sidebar-right {
    z-index: 97;
    box-shadow: none;
  }

  .sidebar-expand-lg.sidebar-right .sidebar-content {
    right: 0;
  }

  .sidebar-expand-lg.sidebar-component {
    z-index: 96;
  }

  .sidebar-expand-lg:not(.sidebar-component) {
    position: static;
    transition: none;
  }
}

@media screen and (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-lg:not(.sidebar-component) {
    transition: none;
  }
}

@media (min-width: 992px) {
  .sidebar-expand-lg:not(.sidebar-component):not(.sidebar-fixed) .sidebar-content {
    position: static;
    overflow: visible;
    width: auto;
  }

  .sidebar-expand-lg.sidebar-dark:not(.sidebar-component)+.sidebar-dark:not(.sidebar-component) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-expand-lg .sidebar-mobile-toggler {
    display: none;
  }

  .sidebar-expand-lg.sidebar-fullscreen {
    width: 16.875rem;
  }

  .sidebar-main-hidden .sidebar-expand-lg.sidebar-main,
  .sidebar-component-hidden .sidebar-expand-lg.sidebar-component,
  .sidebar-secondary-hidden .sidebar-expand-lg.sidebar-secondary,
  .sidebar-mobile-right .sidebar-expand-lg.sidebar-right {
    display: none;
  }

  .sidebar-expand-lg.sidebar-right {
    display: none;
  }

  .sidebar-right-visible .sidebar-expand-lg.sidebar-right {
    display: block;
  }

  .sidebar-expand-lg.sidebar-component {
    display: block;
    width: 16.875rem;
  }

  .sidebar-expand-lg.sidebar-component-left {
    margin-right: 1.25rem;
  }

  .sidebar-expand-lg.sidebar-component-right {
    margin-left: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .sidebar-expand-lg:not(.sidebar-component) {
    border: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-expand-xl.sidebar-main {
    z-index: 99;
    box-shadow: none;
  }

  .sidebar-expand-xl.sidebar-main .sidebar-content {
    left: 0;
  }

  .sidebar-expand-xl.sidebar-secondary {
    z-index: 98;
    box-shadow: none;
  }

  .sidebar-expand-xl.sidebar-secondary .sidebar-content {
    left: 0;
  }

  .sidebar-expand-xl.sidebar-right {
    z-index: 97;
    box-shadow: none;
  }

  .sidebar-expand-xl.sidebar-right .sidebar-content {
    right: 0;
  }

  .sidebar-expand-xl.sidebar-component {
    z-index: 96;
  }

  .sidebar-expand-xl:not(.sidebar-component) {
    position: static;
    transition: none;
  }
}

@media screen and (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-xl:not(.sidebar-component) {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar-expand-xl:not(.sidebar-component):not(.sidebar-fixed) .sidebar-content {
    position: static;
    overflow: visible;
    width: auto;
  }

  .sidebar-expand-xl.sidebar-dark:not(.sidebar-component)+.sidebar-dark:not(.sidebar-component) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-expand-xl .sidebar-mobile-toggler {
    display: none;
  }

  .sidebar-expand-xl.sidebar-fullscreen {
    width: 16.875rem;
  }

  .sidebar-main-hidden .sidebar-expand-xl.sidebar-main,
  .sidebar-component-hidden .sidebar-expand-xl.sidebar-component,
  .sidebar-secondary-hidden .sidebar-expand-xl.sidebar-secondary,
  .sidebar-mobile-right .sidebar-expand-xl.sidebar-right {
    display: none;
  }

  .sidebar-expand-xl.sidebar-right {
    display: none;
  }

  .sidebar-right-visible .sidebar-expand-xl.sidebar-right {
    display: block;
  }

  .sidebar-expand-xl.sidebar-component {
    display: block;
    width: 16.875rem;
  }

  .sidebar-expand-xl.sidebar-component-left {
    margin-right: 1.25rem;
  }

  .sidebar-expand-xl.sidebar-component-right {
    margin-left: 1.25rem;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-expand-xl:not(.sidebar-component) {
    border: 0;
  }

  .revision-dropdown {
    width: 40%;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (orientation : landscape) {

  .sidebar-main {
    width: 100%;

  }

  .sidebar-main,
  .sidebar-content {
    right: 0 !important;
  }

  .navbar-brand {
    margin-left: 5% !important;
  }

  .nav-sidebar .nav-item .logo-brand img {
    height: 30px !important;
    width: 66px !important;

  }

  .nav-sidebar .nav-item .logo-brand {
    padding: 10px 0 !important;
  }

  .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar>.nav-item a span {
    margin: 8px;
  }

  .language-menu .nav-item-submenu>.nav-link:after {
    right: 0.75rem;
  }

  .nav-item-open {
    background-color: #000000;
    z-index: 9;
  }

  .sidebar-dark .sidebar-mobile-toggler a {
    margin-left: auto;
    z-index: 9;
    border-left: solid 1px #707070;
    border-bottom: solid 1px #00f000;
    min-height: 50px;
  }
}

.sidebar-expand.sidebar-main {
  z-index: 99;
  box-shadow: none;
}

.sidebar-expand.sidebar-main .sidebar-content {
  left: 0;
}

.sidebar-expand.sidebar-secondary {
  z-index: 98;
  box-shadow: none;
}

.sidebar-expand.sidebar-secondary .sidebar-content {
  left: 0;
}

.sidebar-expand.sidebar-right {
  z-index: 97;
  box-shadow: none;
}

.sidebar-expand.sidebar-right .sidebar-content {
  right: 0;
}

.sidebar-expand.sidebar-component {
  z-index: 96;
}

.sidebar-expand:not(.sidebar-component) {
  position: static;
  transition: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .sidebar-expand:not(.sidebar-component) {
    transition: none;
  }
}

.sidebar-expand:not(.sidebar-component):not(.sidebar-fixed) .sidebar-content {
  position: static;
  overflow: visible;
  width: auto;
}

.sidebar-expand.sidebar-dark:not(.sidebar-component)+.sidebar-dark:not(.sidebar-component) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-expand .sidebar-mobile-toggler {
  display: none;
}

.sidebar-expand.sidebar-fullscreen {
  width: 16.875rem;
}

.sidebar-main-hidden .sidebar-expand.sidebar-main,
.sidebar-component-hidden .sidebar-expand.sidebar-component,
.sidebar-secondary-hidden .sidebar-expand.sidebar-secondary,
.sidebar-mobile-right .sidebar-expand.sidebar-right {
  display: none;
}

.sidebar-expand.sidebar-right {
  display: none;
}

.sidebar-right-visible .sidebar-expand.sidebar-right {
  display: block;
}

.sidebar-expand.sidebar-component {
  display: block;
  width: 16.875rem;
}

.sidebar-expand.sidebar-component-left {
  margin-right: 1.25rem;
}

.sidebar-expand.sidebar-component-right {
  margin-left: 1.25rem;
}

.sidebar-expand:not(.sidebar-component) {
  border: 0;
}

.sidebar:not(.bg-transparent) .card {
  border-width: 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
}

.sidebar:not(.bg-transparent) .card .card {
  border-width: 1px;
}

.sidebar:not(.bg-transparent) .card:not([class*="bg-"]):not(.fixed-top) {
  background-color: transparent;
}

.sidebar .card-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.sidebar .row:not(.no-gutters) {
  margin-left: -0.0025rem;
  margin-right: -0.3125rem;
}

.sidebar .row:not(.no-gutters) [class*="col"] {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.sidebar .form-group:last-child {
  margin-bottom: 0;
}

.sidebar .nav-tabs .nav-item:first-child .nav-link {
  border-left: 0;
}

.sidebar .nav-tabs .nav-item:last-child .nav-link {
  border-right: 0;
}

.sidebar .nav-tabs .nav-link {
  border-top: 0;
  border-bottom-width: 0;
}

.sidebar .nav-tabs .nav-link.active {
  border-bottom-color: transparent;
}

.sidebar-dark .nav-tabs {
  background-color: #1e272c;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-dark .nav-tabs .nav-link:hover,
.sidebar-dark .nav-tabs .nav-link:focus {
  color: #fff;
}

.sidebar-dark .nav-tabs .nav-link.active {
  color: #fff;
}

.sidebar-dark .nav-tabs:not(.nav-tabs-bottom) .nav-link.active {
  background-color: #263238;
  border-color: rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-tabs .nav-item.show .nav-link:not(.active) {
  color: #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-right: transparent !important;
  border-left: transparent !important;
}

.sidebar-light .nav-tabs {
  background-color: whitesmoke;
  border-bottom-color: rgba(0, 0, 0, 0.125);
}

.sidebar-light .nav-tabs .nav-link:hover,
.sidebar-light .nav-tabs .nav-link:focus {
  color: #333;
}

.sidebar-light .nav-tabs .nav-link.active {
  color: #333;
}

.sidebar-light .nav-tabs:not(.nav-tabs-bottom) .nav-link.active {
  background-color: #fff;
}

.row-tile div[class*="col"] .btn {
  border-radius: 0;
}

.row-tile div[class*="col"]:first-child .btn:first-child {
  border-top-left-radius: 0.1875rem;
}

.row-tile div[class*="col"]:first-child .btn:last-child {
  border-bottom-left-radius: 0.1875rem;
}

.row-tile div[class*="col"]:last-child .btn:first-child {
  border-top-right-radius: 0.1875rem;
}

.row-tile div[class*="col"]:last-child .btn:last-child {
  border-bottom-right-radius: 0.1875rem;
}

.row-tile div[class*="col"] .btn+.btn {
  border-top: 0;
}

.row-tile div[class*="col"]+div[class*="col"] .btn {
  border-left: 0;
}

/* ------------------------------------------------------------------------------
 *
 *  # Boxed layout
 *
 *  Styles for main structure of content area in boxed layout
 *
 * ---------------------------------------------------------------------------- */
.layout-boxed-bg {
  background: url(/assets/images/backgrounds/boxed_bg.png) repeat;
}

.layout-boxed {
  box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.15), 4px 2px 4px rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {

  .layout-boxed,
  .layout-boxed>.navbar {
    width: 992px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {

  .layout-boxed,
  .layout-boxed>.navbar {
    width: 1200px;
  }
}

.layout-boxed .page-content {
  background-color: #f5f5f5;
}

@media (min-width: 992px) {
  .content-boxed:not(.navbar-collapse) {
    margin-left: 6%;
    margin-right: 6%;
  }

  .navbar>.content-boxed>*:first-child {
    margin-left: 6%;
  }

  .navbar>.content-boxed>*:last-child {
    margin-right: 6%;
  }

  .breadcrumb-line>.content-boxed>*:first-child {
    margin-left: 1.25rem;
  }

  .breadcrumb-line>.content-boxed>*:last-child {
    margin-right: 1.25rem;
  }
}

/* HELPING COMPONENTS */

/* ------------------------------------------------------------------------------
*
*  # Header elements
*
*  Display default and custom components in page header, card header and breadcrumbs
*
* ---------------------------------------------------------------------------- */
.card-header .header-elements {
  padding-top: 0.9375rem;
}

/* .page-header-content .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1.25rem 1.25rem;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  background-color: #efefef;
} */

.page-header-light .header-elements {
  background-color: #fafafa;
  border-color: #ddd;
  border-bottom: 0;
}

.page-header-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: 0;
}

.breadcrumb-line .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 0 1.25rem;
  border-top: 1px solid transparent;
}

.breadcrumb-line-light .header-elements {
  background-color: transparent;
  border-color: #ddd;
}

.breadcrumb-line-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.25);
}

.header-elements .form-group:last-child {
  margin-bottom: 0;
}

.header-elements-toggle,
.footer-elements-toggle {
  margin-left: auto;
  -ms-flex-item-align: center;
  align-self: center;
}

/* .header-elements-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
} */

.header-elements-inline .header-elements {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin-left: 0;
  margin-right: 0;
}

.header-elements-inline .card-header-tabs .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 576px) {
  .header-elements-sm-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-sm-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-sm-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .header-elements-md-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-md-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-end;
  }

  .header-elements-md-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .header-elements-lg-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-lg-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-lg-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1200px) {
  .header-elements-xl-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .header-elements-xl-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .header-elements-xl-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/* ------------------------------------------------------------------------------
 *
 *  # Helper classes
 *
 *  Custom helper classes used in the template.
 *
 * ---------------------------------------------------------------------------- */
.font-size-lg {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 0.8125rem;
}

.font-size-sm {
  font-size: 0.75rem;
}

.font-size-xs {
  font-size: 0.6875rem;
}

.line-height-lg {
  line-height: 1.4286;
}

.line-height-base {
  line-height: 1.5385;
}

.line-height-sm {
  line-height: 1.6667;
}

.line-height-xs {
  line-height: 1.82;
}

.line-height-1 {
  line-height: 1;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-0 {
  opacity: 0;
}

.top-0 {
  top: 0;
}

.top-auto {
  top: auto;
}

.bottom-0 {
  bottom: 0;
}

.bottom-auto {
  bottom: auto;
}

.left-0 {
  left: 0;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.right-auto {
  right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-disabled {
  cursor: default;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

.border-width-0 {
  border-width: 0;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-1 {
  border: 1px solid;
}

.border-top-1 {
  border-top: 1px solid;
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

.border-left-1 {
  border-left: 1px solid;
}

.border-right-1 {
  border-right: 1px solid;
}

.border-y-1 {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-x-1 {
  border-left: 1px solid;
  border-right: 1px solid;
}

.border-2 {
  border: 2px solid;
}

.border-top-2 {
  border-top: 2px solid;
}

.border-bottom-2 {
  border-bottom: 2px solid;
}

.border-left-2 {
  border-left: 2px solid;
}

.border-right-2 {
  border-right: 2px solid;
}

.border-y-2 {
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.border-x-2 {
  border-left: 2px solid;
  border-right: 2px solid;
}

.border-3 {
  border: 3px solid;
}

.border-top-3 {
  border-top: 3px solid;
}

.border-bottom-3 {
  border-bottom: 3px solid;
}

.border-left-3 {
  border-left: 3px solid;
}

.border-right-3 {
  border-right: 3px solid;
}

.border-y-3 {
  border-top: 3px solid;
  border-bottom: 3px solid;
}

.border-x-3 {
  border-left: 3px solid;
  border-right: 3px solid;
}

.border-dashed {
  border-style: dashed;
}

.border-top-dashed {
  border-top-style: dashed;
}

.border-bottom-dashed {
  border-bottom-style: dashed;
}

.border-left-dashed {
  border-left-style: dashed;
}

.border-right-dashed {
  border-right-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.rounded-round {
  border-radius: 100px !important;
}

.rounded-top-round {
  border-top-left-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

.rounded-bottom-round {
  border-bottom-right-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.rounded-left-round {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.rounded-right-round {
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-45 {
  width: 45% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.wmin-0 {
  min-width: 0 !important;
}

.wmin-120 {
  min-width: 120px !important;
}

.wmin-200 {
  min-width: 200px !important;
}

.wmin-250 {
  min-width: 250px !important;
}

.wmin-300 {
  min-width: 300px !important;
}

.wmin-350 {
  min-width: 350px !important;
}

.wmin-400 {
  min-width: 400px !important;
}

.wmin-450 {
  min-width: 450px !important;
}

.wmin-500 {
  min-width: 500px !important;
}

.wmin-550 {
  min-width: 550px !important;
}

.wmin-600 {
  min-width: 600px !important;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

.ml-10 {
  margin-left: 10px !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .wmin-sm-0 {
    min-width: 0 !important;
  }

  .wmin-sm-200 {
    min-width: 200px !important;
  }

  .wmin-sm-250 {
    min-width: 250px !important;
  }

  .wmin-sm-300 {
    min-width: 300px !important;
  }

  .wmin-sm-350 {
    min-width: 350px !important;
  }

  .wmin-sm-400 {
    min-width: 400px !important;
  }

  .wmin-sm-450 {
    min-width: 450px !important;
  }

  .wmin-sm-500 {
    min-width: 500px !important;
  }

  .wmin-sm-550 {
    min-width: 550px !important;
  }

  .wmin-sm-600 {
    min-width: 600px !important;
  }

  .flex-sm-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .wmin-md-0 {
    min-width: 0 !important;
  }

  .wmin-md-200 {
    min-width: 200px !important;
  }

  .wmin-md-250 {
    min-width: 250px !important;
  }

  .wmin-md-300 {
    min-width: 300px !important;
  }

  .wmin-md-350 {
    min-width: 350px !important;
  }

  .wmin-md-400 {
    min-width: 400px !important;
  }

  .wmin-md-450 {
    min-width: 450px !important;
  }

  .wmin-md-500 {
    min-width: 500px !important;
  }

  .wmin-md-550 {
    min-width: 550px !important;
  }

  .wmin-md-600 {
    min-width: 600px !important;
  }

  .flex-md-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .wmin-lg-0 {
    min-width: 0 !important;
  }

  .wmin-lg-200 {
    min-width: 200px !important;
  }

  .wmin-lg-250 {
    min-width: 250px !important;
  }

  .wmin-lg-300 {
    min-width: 300px !important;
  }

  .wmin-lg-350 {
    min-width: 350px !important;
  }

  .wmin-lg-400 {
    min-width: 400px !important;
  }

  .wmin-lg-450 {
    min-width: 450px !important;
  }

  .wmin-lg-500 {
    min-width: 500px !important;
  }

  .wmin-lg-550 {
    min-width: 550px !important;
  }

  .wmin-lg-600 {
    min-width: 600px !important;
  }

  .flex-lg-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .wmin-xl-0 {
    min-width: 0 !important;
  }

  .wmin-xl-200 {
    min-width: 200px !important;
  }

  .wmin-xl-250 {
    min-width: 250px !important;
  }

  .wmin-xl-300 {
    min-width: 300px !important;
  }

  .wmin-xl-350 {
    min-width: 350px !important;
  }

  .wmin-xl-400 {
    min-width: 400px !important;
  }

  .wmin-xl-450 {
    min-width: 450px !important;
  }

  .wmin-xl-500 {
    min-width: 500px !important;
  }

  .wmin-xl-550 {
    min-width: 550px !important;
  }

  .wmin-xl-600 {
    min-width: 600px !important;
  }

  .flex-xl-1 {
    -ms-flex: 1;
    flex: 1;
  }
}

.no-transitions *,
.no-transitions *:before,
.no-transitions *:after {
  transition: none !important;
}

@media screen and (prefers-reduced-motion: reduce) {

  .no-transitions *,
  .no-transitions *:before,
  .no-transitions *:after {
    transition: none;
  }
}

.caret-0::before,
.caret-0::after {
  content: none;
}

.shadow-0 {
  box-shadow: none !important;
}

.outline-0,
.outline-0:hover,
.outline-0:focus {
  outline: 0;
}

.text-shadow-dark {
  text-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
}

.text-shadow-light {
  text-shadow: 0 0 0.1875rem rgba(255, 255, 255, 0.5);
}

/* ------------------------------------------------------------------------------
 *
 *  # Footable
 *
 *  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
 *
 * ---------------------------------------------------------------------------- */

.footable>thead>tr>th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footable.breakpoint>tbody>tr.footable-detail-show>td {
  border-bottom: 0;
}

.footable.breakpoint>tbody>tr.footable-row-detail:hover {
  background-color: transparent;
}

.footable.breakpoint>tbody>tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}

.footable.breakpoint>tbody>tr>.footable-cell-detail {
  background-color: #fafafa;
  border-top: 0;
}

.footable.breakpoint>tbody>tr .footable-toggle:before {
  content: "\e9e4";
  display: inline-block;
  font-family: "icomoon";
  font-size: 1rem;
  vertical-align: middle;
  position: relative;
  top: -0.09375rem;
  line-height: 1;
  color: #43425d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footable.breakpoint>tbody>tr.footable-detail-show .footable-toggle:before {
  content: "\e9e7";
}

.footable-row-detail-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.footable-row-detail-row {
  display: table-row;
}

.footable-row-detail-group {
  display: block;
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.footable-row-detail-group:first-child {
  margin-top: 0.5rem;
}

.footable-row-detail-name {
  display: table-cell;
  font-weight: 500;
  padding-right: 2.5rem;
}

.footable-row-detail-value {
  display: table-cell;
  padding: 0.5rem 0;
}

/*******  to fix the top header while scrolling for actions like save, cancel *****/

/******* to fix the Completeness value field in filter (certified product) ****/

.css-tlfecz-indicatorContainer {
  padding: 2px !important;
  color: #666666 !important;
}

.gtin-btn-group {
  top: 2px;
}