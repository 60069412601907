@font-face {
	font-family: 'icomoon';
	src:url('./fonts/icomoon.eot?3p0rtw');
	src:url('./fonts/icomoon.eot?#iefix3p0rtw') format('embedded-opentype'),
		url('./fonts/icomoon.woff?3p0rtw') format('woff'),
		url('./fonts/icomoon.ttf?3p0rtw') format('truetype'),
		url('./fonts/icomoon.svg?3p0rtw#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* Use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	min-width: 1em;
	display: inline-block;
	text-align: center;
	font-size: 16px;
	vertical-align: middle;
	position: relative;
	top: -1px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-2x {
	font-size: 32px;
}
.icon-3x {
	font-size: 48px;
}

.icon-bordered {
	 padding: 5px;
 border: 2px solid;
 border-radius: 50%;
}

.icon-home:before{content:"\e900";}
.icon-home2:before{content:"\e901";}
.icon-home5:before{content:"\e904";}
.icon-home7:before{content:"\e906";}
.icon-home8:before{content:"\e907";}
.icon-home9:before{content:"\e908";}
.icon-office:before{content:"\e909";}
.icon-city:before{content:"\e90a";}
.icon-newspaper:before{content:"\e90b";}
.icon-magazine:before{content:"\e90c";}
.icon-design:before{content:"\e90d";}
.icon-pencil:before{content:"\e90e";}
.icon-pencil3:before{content:"\e910";}
.icon-pencil4:before{content:"\e911";}
.icon-pencil5:before{content:"\e912";}
.icon-pencil6:before{content:"\e913";}
.icon-pencil7:before{content:"\e914";}
.icon-eraser:before{content:"\e915";}
.icon-eraser2:before{content:"\e916";}
.icon-eraser3:before{content:"\e917";}
.icon-quill2:before{content:"\e919";}
.icon-quill4:before{content:"\e91b";}
.icon-pen:before{content:"\e91c";}
.icon-pen-plus:before{content:"\e91d";}
.icon-pen-minus:before{content:"\e91e";}
.icon-pen2:before{content:"\e91f";}
.icon-blog:before{content:"\e925";}
.icon-pen6:before{content:"\e927";}
.icon-brush:before{content:"\e928";}
.icon-spray:before{content:"\e929";}
.icon-color-sampler:before{content:"\e92c";}
.icon-toggle:before{content:"\e92d";}
.icon-bucket:before{content:"\e92e";}
.icon-gradient:before{content:"\e930";}
.icon-eyedropper:before{content:"\e931";}
.icon-eyedropper2:before{content:"\e932";}
.icon-eyedropper3:before{content:"\e933";}
.icon-droplet:before{content:"\e934";}
.icon-droplet2:before{content:"\e935";}
.icon-color-clear:before{content:"\e937";}
.icon-paint-format:before{content:"\e938";}
.icon-stamp:before{content:"\e939";}
.icon-image2:before{content:"\e93c";}
.icon-image-compare:before{content:"\e93d";}
.icon-images2:before{content:"\e93e";}
.icon-image3:before{content:"\e93f";}
.icon-images3:before{content:"\e940";}
.icon-image4:before{content:"\e941";}
.icon-image5:before{content:"\e942";}
.icon-camera:before{content:"\e944";}
.icon-shutter:before{content:"\e947";}
.icon-headphones:before{content:"\e948";}
.icon-headset:before{content:"\e949";}
.icon-music:before{content:"\e94a";}
.icon-album:before{content:"\e950";}
.icon-tape:before{content:"\e952";}
.icon-piano:before{content:"\e953";}
.icon-speakers:before{content:"\e956";}
.icon-play:before{content:"\e957";}
.icon-clapboard-play:before{content:"\e959";}
.icon-clapboard:before{content:"\e95a";}
.icon-media:before{content:"\e95b";}
.icon-presentation:before{content:"\e95c";}
.icon-movie:before{content:"\e95d";}
.icon-film:before{content:"\e95e";}
.icon-film2:before{content:"\e95f";}
.icon-film3:before{content:"\e960";}
.icon-film4:before{content:"\e961";}
.icon-video-camera:before{content:"\e962";}
.icon-video-camera2:before{content:"\e963";}
.icon-video-camera-slash:before{content:"\e964";}
.icon-video-camera3:before{content:"\e965";}
.icon-dice:before{content:"\e96a";}
.icon-chess-king:before{content:"\e972";}
.icon-chess-queen:before{content:"\e973";}
.icon-chess:before{content:"\e978";}
.icon-megaphone:before{content:"\e97a";}
.icon-new:before{content:"\e97b";}
.icon-connection:before{content:"\e97c";}
.icon-station:before{content:"\e981";}
.icon-satellite-dish2:before{content:"\e98a";}
.icon-feed:before{content:"\e9b3";}
.icon-mic2:before{content:"\e9ce";}
.icon-mic-off2:before{content:"\e9e0";}
.icon-book:before{content:"\e9e1";}
.icon-book2:before{content:"\e9e9";}
.icon-book-play:before{content:"\e9fd";}
.icon-book3:before{content:"\ea01";}
.icon-bookmark:before{content:"\ea02";}
.icon-books:before{content:"\ea03";}
.icon-archive:before{content:"\ea04";}
.icon-reading:before{content:"\ea05";}
.icon-library2:before{content:"\ea06";}
.icon-graduation2:before{content:"\ea07";}
.icon-file-text:before{content:"\ea08";}
.icon-profile:before{content:"\ea09";}
.icon-file-empty:before{content:"\ea0a";}
.icon-file-empty2:before{content:"\ea0b";}
.icon-files-empty:before{content:"\ea0c";}
.icon-files-empty2:before{content:"\ea0d";}
.icon-file-plus:before{content:"\ea0e";}
.icon-file-plus2:before{content:"\ea0f";}
.icon-file-minus:before{content:"\ea10";}
.icon-file-minus2:before{content:"\ea11";}
.icon-file-download:before{content:"\ea12";}
.icon-file-download2:before{content:"\ea13";}
.icon-file-upload:before{content:"\ea14";}
.icon-file-upload2:before{content:"\ea15";}
.icon-file-check:before{content:"\ea16";}
.icon-file-check2:before{content:"\ea17";}
.icon-file-eye:before{content:"\ea18";}
.icon-file-eye2:before{content:"\ea19";}
.icon-file-text2:before{content:"\ea1a";}
.icon-file-text3:before{content:"\ea1b";}
.icon-file-picture:before{content:"\ea1c";}
.icon-file-picture2:before{content:"\ea1d";}
.icon-file-music:before{content:"\ea1e";}
.icon-file-music2:before{content:"\ea1f";}
.icon-file-play:before{content:"\ea20";}
.icon-file-play2:before{content:"\ea21";}
.icon-file-video:before{content:"\ea22";}
.icon-file-video2:before{content:"\ea23";}
.icon-copy:before{content:"\ea24";}
.icon-copy2:before{content:"\ea25";}
.icon-file-zip:before{content:"\ea26";}
.icon-file-zip2:before{content:"\ea27";}
.icon-file-xml:before{content:"\ea28";}
.icon-file-xml2:before{content:"\ea29";}
.icon-file-css:before{content:"\ea2a";}
.icon-file-css2:before{content:"\ea2b";}
.icon-file-presentation:before{content:"\ea2c";}
.icon-file-presentation2:before{content:"\ea2d";}
.icon-file-stats:before{content:"\ea2e";}
.icon-file-stats2:before{content:"\ea2f";}
.icon-file-locked:before{content:"\ea30";}
.icon-file-locked2:before{content:"\ea31";}
.icon-file-spreadsheet:before{content:"\ea32";}
.icon-file-spreadsheet2:before{content:"\ea33";}
.icon-copy3:before{content:"\ea34";}
.icon-copy4:before{content:"\ea35";}
.icon-paste:before{content:"\ea36";}
.icon-paste2:before{content:"\ea37";}
.icon-paste3:before{content:"\ea38";}
.icon-paste4:before{content:"\ea39";}
.icon-stack:before{content:"\ea3a";}
.icon-stack2:before{content:"\ea3b";}
.icon-stack3:before{content:"\ea3c";}
.icon-folder:before{content:"\ea3d";}
.icon-folder-search:before{content:"\ea3e";}
.icon-folder-download:before{content:"\ea3f";}
.icon-folder-upload:before{content:"\ea40";}
.icon-folder-plus:before{content:"\ea41";}
.icon-folder-plus2:before{content:"\ea42";}
.icon-folder-minus:before{content:"\ea43";}
.icon-folder-minus2:before{content:"\ea44";}
.icon-folder-check:before{content:"\ea45";}
.icon-folder-heart:before{content:"\ea46";}
.icon-folder-remove:before{content:"\ea47";}
.icon-folder2:before{content:"\ea48";}
.icon-folder-open:before{content:"\ea49";}
.icon-folder3:before{content:"\ea4a";}
.icon-folder4:before{content:"\ea4b";}
.icon-folder-plus3:before{content:"\ea4c";}
.icon-folder-minus3:before{content:"\ea4d";}
.icon-folder-plus4:before{content:"\ea4e";}
.icon-folder-minus4:before{content:"\ea4f";}
.icon-folder-download2:before{content:"\ea50";}
.icon-folder-upload2:before{content:"\ea51";}
.icon-folder-download3:before{content:"\ea52";}
.icon-folder-upload3:before{content:"\ea53";}
.icon-folder5:before{content:"\ea54";}
.icon-folder-open2:before{content:"\ea55";}
.icon-folder6:before{content:"\ea56";}
.icon-folder-open3:before{content:"\ea57";}
.icon-certificate:before{content:"\ea58";}
.icon-cc:before{content:"\ea59";}
.icon-price-tag:before{content:"\ea5a";}
.icon-price-tag2:before{content:"\ea5b";}
.icon-price-tags:before{content:"\ea5c";}
.icon-price-tag3:before{content:"\ea5d";}
.icon-price-tags2:before{content:"\ea5e";}
.icon-barcode2:before{content:"\ea5f";}
.icon-qrcode:before{content:"\ea60";}
.icon-ticket:before{content:"\ea61";}
.icon-theater:before{content:"\ea62";}
.icon-store:before{content:"\ea63";}
.icon-store2:before{content:"\ea64";}
.icon-cart:before{content:"\ea65";}
.icon-cart2:before{content:"\ea66";}
.icon-cart4:before{content:"\ea67";}
.icon-cart5:before{content:"\ea68";}
.icon-cart-add:before{content:"\ea69";}
.icon-cart-add2:before{content:"\ea6a";}
.icon-cart-remove:before{content:"\ea6b";}
.icon-basket:before{content:"\ea6c";}
.icon-bag:before{content:"\ea6d";}
.icon-percent:before{content:"\ea6f";}
.icon-coins:before{content:"\ea70";}
.icon-coin-dollar:before{content:"\ea71";}
.icon-coin-euro:before{content:"\ea72";}
.icon-coin-pound:before{content:"\ea73";}
.icon-coin-yen:before{content:"\ea74";}
.icon-piggy-bank:before{content:"\ea75";}
.icon-wallet:before{content:"\ea76";}
.icon-cash:before{content:"\ea77";}
.icon-cash2:before{content:"\ea78";}
.icon-cash3:before{content:"\ea79";}
.icon-cash4:before{content:"\ea7a";}
.icon-credit-card:before{content:"\ea6e";}
.icon-credit-card2:before{content:"\ea7b";}
.icon-calculator4:before{content:"\ea7c";}
.icon-calculator2:before{content:"\ea7d";}
.icon-calculator3:before{content:"\ea7e";}
.icon-chip:before{content:"\ea7f";}
.icon-lifebuoy:before{content:"\ea80";}
.icon-phone:before{content:"\ea81";}
.icon-phone2:before{content:"\ea82";}
.icon-phone-slash:before{content:"\ea83";}
.icon-phone-wave:before{content:"\ea84";}
.icon-phone-plus:before{content:"\ea85";}
.icon-phone-minus:before{content:"\ea86";}
.icon-phone-plus2:before{content:"\ea87";}
.icon-phone-minus2:before{content:"\ea88";}
.icon-phone-incoming:before{content:"\ea89";}
.icon-phone-outgoing:before{content:"\ea8a";}
.icon-phone-hang-up:before{content:"\ea8e";}
.icon-address-book:before{content:"\ea90";}
.icon-address-book2:before{content:"\ea91";}
.icon-address-book3:before{content:"\ea92";}
.icon-notebook:before{content:"\ea93";}
.icon-envelop:before{content:"\ea94";}
.icon-envelop2:before{content:"\ea95";}
.icon-envelop3:before{content:"\ea96";}
.icon-envelop4:before{content:"\ea97";}
.icon-envelop5:before{content:"\ea98";}
.icon-mailbox:before{content:"\ea99";}
.icon-pushpin:before{content:"\ea9a";}
.icon-location3:before{content:"\ea9d";}
.icon-location4:before{content:"\ea9e";}
.icon-compass4:before{content:"\ea9f";}
.icon-map:before{content:"\eaa0";}
.icon-map4:before{content:"\eaa1";}
.icon-map5:before{content:"\eaa2";}
.icon-direction:before{content:"\eaa3";}
.icon-reset:before{content:"\eaa4";}
.icon-history:before{content:"\eaa5";}
.icon-watch:before{content:"\eaa6";}
.icon-watch2:before{content:"\eaa7";}
.icon-alarm:before{content:"\eaa8";}
.icon-alarm-add:before{content:"\eaa9";}
.icon-alarm-check:before{content:"\eaaa";}
.icon-alarm-cancel:before{content:"\eaab";}
.icon-bell2:before{content:"\eaac";}
.icon-bell3:before{content:"\eaad";}
.icon-bell-plus:before{content:"\eaae";}
.icon-bell-minus:before{content:"\eaaf";}
.icon-bell-check:before{content:"\eab0";}
.icon-bell-cross:before{content:"\eab1";}
.icon-calendar:before{content:"\eab2";}
.icon-calendar2:before{content:"\eab3";}
.icon-calendar3:before{content:"\eab4";}
.icon-calendar52:before{content:"\eab6";}
.icon-printer:before{content:"\eab7";}
.icon-printer2:before{content:"\eab8";}
.icon-printer4:before{content:"\eab9";}
.icon-shredder:before{content:"\eaba";}
.icon-mouse:before{content:"\eabb";}
.icon-mouse-left:before{content:"\eabc";}
.icon-mouse-right:before{content:"\eabd";}
.icon-keyboard:before{content:"\eabe";}
.icon-typewriter:before{content:"\eabf";}
.icon-display:before{content:"\eac0";}
.icon-display4:before{content:"\eac1";}
.icon-laptop:before{content:"\eac2";}
.icon-mobile:before{content:"\eac3";}
.icon-mobile2:before{content:"\eac4";}
.icon-tablet:before{content:"\eac5";}
.icon-mobile3:before{content:"\eac6";}
.icon-tv:before{content:"\eac7";}
.icon-radio:before{content:"\eac8";}
.icon-cabinet:before{content:"\eac9";}
.icon-drawer:before{content:"\eaca";}
.icon-drawer2:before{content:"\eacb";}
.icon-drawer-out:before{content:"\eacc";}
.icon-drawer-in:before{content:"\eacd";}
.icon-drawer3:before{content:"\eace";}
.icon-box:before{content:"\eacf";}
.icon-box-add:before{content:"\ead0";}
.icon-box-remove:before{content:"\ead1";}
.icon-download:before{content:"\ead2";}
.icon-upload:before{content:"\ead3";}
.icon-floppy-disk:before{content:"\ead4";}
.icon-floppy-disks:before{content:"\ead5";}
.icon-usb-stick:before{content:"\ead6";}
.icon-drive:before{content:"\ead7";}
.icon-server:before{content:"\ead8";}
.icon-database:before{content:"\ead9";}
.icon-database2:before{content:"\eada";}
.icon-database4:before{content:"\eadb";}
.icon-database-menu:before{content:"\eadc";}
.icon-database-add:before{content:"\eadd";}
.icon-database-remove:before{content:"\eade";}
.icon-database-insert:before{content:"\eadf";}
.icon-database-export:before{content:"\eae0";}
.icon-database-upload:before{content:"\eae1";}
.icon-database-refresh:before{content:"\eae2";}
.icon-database-diff:before{content:"\eae3";}
.icon-database-edit2:before{content:"\eae5";}
.icon-database-check:before{content:"\eae6";}
.icon-database-arrow:before{content:"\eae7";}
.icon-database-time2:before{content:"\eae9";}
.icon-undo:before{content:"\eaea";}
.icon-redo:before{content:"\eaeb";}
.icon-rotate-ccw:before{content:"\eaec";}
.icon-rotate-cw:before{content:"\eaed";}
.icon-rotate-ccw2:before{content:"\eaee";}
.icon-rotate-cw2:before{content:"\eaef";}
.icon-rotate-ccw3:before{content:"\eaf0";}
.icon-rotate-cw3:before{content:"\eaf1";}
.icon-flip-vertical2:before{content:"\eaf2";}
.icon-flip-horizontal2:before{content:"\eaf3";}
.icon-flip-vertical3:before{content:"\eaf4";}
.icon-flip-vertical4:before{content:"\eaf5";}
.icon-angle:before{content:"\eaf6";}
.icon-shear:before{content:"\eaf7";}
.icon-align-left:before{content:"\eafc";}
.icon-align-center-horizontal:before{content:"\eafd";}
.icon-align-right:before{content:"\eafe";}
.icon-align-top:before{content:"\eaff";}
.icon-align-center-vertical:before{content:"\eb00";}
.icon-align-bottom:before{content:"\eb01";}
.icon-undo2:before{content:"\eb02";}
.icon-redo2:before{content:"\eb03";}
.icon-forward:before{content:"\eb04";}
.icon-reply:before{content:"\eb05";}
.icon-reply-all:before{content:"\eb06";}
.icon-bubble:before{content:"\eb07";}
.icon-bubbles:before{content:"\eb08";}
.icon-bubbles2:before{content:"\eb09";}
.icon-bubble2:before{content:"\eb0a";}
.icon-bubbles3:before{content:"\eb0b";}
.icon-bubbles4:before{content:"\eb0c";}
.icon-bubble-notification:before{content:"\eb0d";}
.icon-bubbles5:before{content:"\eb0e";}
.icon-bubbles6:before{content:"\eb0f";}
.icon-bubble6:before{content:"\eb10";}
.icon-bubbles7:before{content:"\eb11";}
.icon-bubble7:before{content:"\eb12";}
.icon-bubbles8:before{content:"\eb13";}
.icon-bubble8:before{content:"\eb14";}
.icon-bubble-dots3:before{content:"\eb15";}
.icon-bubble-lines3:before{content:"\eb16";}
.icon-bubble9:before{content:"\eb17";}
.icon-bubble-dots4:before{content:"\eb18";}
.icon-bubble-lines4:before{content:"\eb19";}
.icon-bubbles9:before{content:"\eb1a";}
.icon-bubbles10:before{content:"\eb1b";}
.icon-user:before{content:"\eb33";}
.icon-users:before{content:"\eb34";}
.icon-user-plus:before{content:"\eb35";}
.icon-user-minus:before{content:"\eb36";}
.icon-user-cancel:before{content:"\eb37";}
.icon-user-block:before{content:"\eb38";}
.icon-user-lock:before{content:"\eb39";}
.icon-user-check:before{content:"\eb3a";}
.icon-users2:before{content:"\eb3b";}
.icon-users4:before{content:"\eb44";}
.icon-user-tie:before{content:"\eb45";}
.icon-collaboration:before{content:"\eb46";}
.icon-vcard:before{content:"\eb47";}
.icon-hat:before{content:"\ebb8";}
.icon-bowtie:before{content:"\ebb9";}
.icon-quotes-left:before{content:"\eb49";}
.icon-quotes-right:before{content:"\eb4a";}
.icon-quotes-left2:before{content:"\eb4b";}
.icon-quotes-right2:before{content:"\eb4c";}
.icon-hour-glass:before{content:"\eb4d";}
.icon-hour-glass2:before{content:"\eb4e";}
.icon-hour-glass3:before{content:"\eb4f";}
.icon-spinner:before{content:"\eb50";}
.icon-spinner2:before{content:"\eb51";}
.icon-spinner3:before{content:"\eb52";}
.icon-spinner4:before{content:"\eb53";}
.icon-spinner6:before{content:"\eb54";}
.icon-spinner9:before{content:"\eb55";}
.icon-spinner10:before{content:"\eb56";}
.icon-spinner11:before{content:"\eb57";}
.icon-microscope:before{content:"\eb58";}
.icon-enlarge:before{content:"\eb59";}
.icon-shrink:before{content:"\eb5a";}
.icon-enlarge3:before{content:"\eb5b";}
.icon-shrink3:before{content:"\eb5c";}
.icon-enlarge5:before{content:"\eb5d";}
.icon-shrink5:before{content:"\eb5e";}
.icon-enlarge6:before{content:"\eb5f";}
.icon-shrink6:before{content:"\eb60";}
.icon-enlarge7:before{content:"\eb61";}
.icon-shrink7:before{content:"\eb62";}
.icon-key:before{content:"\eb63";}
.icon-lock:before{content:"\eb65";}
.icon-lock2:before{content:"\eb66";}
.icon-lock4:before{content:"\eb67";}
.icon-unlocked:before{content:"\eb68";}
.icon-lock5:before{content:"\eb69";}
.icon-unlocked2:before{content:"\eb6a";}
.icon-safe:before{content:"\eb6b";}
.icon-wrench:before{content:"\eb6c";}
.icon-wrench2:before{content:"\eb6d";}
.icon-wrench3:before{content:"\eb6e";}
.icon-equalizer:before{content:"\eb6f";}
.icon-equalizer2:before{content:"\eb70";}
.icon-equalizer3:before{content:"\eb71";}
.icon-equalizer4:before{content:"\eb72";}
.icon-cog:before{content:"\eb73";}
.icon-cogs:before{content:"\eb74";}
.icon-cog2:before{content:"\eb75";}
.icon-cog3:before{content:"\eb76";}
.icon-cog4:before{content:"\eb77";}
.icon-cog52:before{content:"\eb78";}
.icon-cog6:before{content:"\eb79";}
.icon-cog7:before{content:"\eb7a";}
.icon-hammer:before{content:"\eb7c";}
.icon-hammer-wrench:before{content:"\eb7d";}
.icon-magic-wand:before{content:"\eb7e";}
.icon-magic-wand2:before{content:"\eb7f";}
.icon-pulse2:before{content:"\eb80";}
.icon-aid-kit:before{content:"\eb81";}
.icon-bug2:before{content:"\eb83";}
.icon-construction:before{content:"\eb85";}
.icon-traffic-cone:before{content:"\eb86";}
.icon-traffic-lights:before{content:"\eb87";}
.icon-pie-chart:before{content:"\eb88";}
.icon-pie-chart2:before{content:"\eb89";}
.icon-pie-chart3:before{content:"\eb8a";}
.icon-pie-chart4:before{content:"\eb8b";}
.icon-pie-chart5:before{content:"\eb8c";}
.icon-pie-chart6:before{content:"\eb8d";}
.icon-pie-chart7:before{content:"\eb8e";}
.icon-stats-dots:before{content:"\eb8f";}
.icon-stats-bars:before{content:"\eb90";}
.icon-pie-chart8:before{content:"\eb91";}
.icon-stats-bars2:before{content:"\eb92";}
.icon-stats-bars3:before{content:"\eb93";}
.icon-stats-bars4:before{content:"\eb94";}
.icon-chart:before{content:"\eb97";}
.icon-stats-growth:before{content:"\eb98";}
.icon-stats-decline:before{content:"\eb99";}
.icon-stats-growth2:before{content:"\eb9a";}
.icon-stats-decline2:before{content:"\eb9b";}
.icon-stairs-up:before{content:"\eb9c";}
.icon-stairs-down:before{content:"\eb9d";}
.icon-stairs:before{content:"\eb9e";}
.icon-ladder:before{content:"\eba0";}
.icon-rating:before{content:"\eba1";}
.icon-rating2:before{content:"\eba2";}
.icon-rating3:before{content:"\eba3";}
.icon-podium:before{content:"\eba5";}
.icon-stars:before{content:"\eba6";}
.icon-medal-star:before{content:"\eba7";}
.icon-medal:before{content:"\eba8";}
.icon-medal2:before{content:"\eba9";}
.icon-medal-first:before{content:"\ebaa";}
.icon-medal-second:before{content:"\ebab";}
.icon-medal-third:before{content:"\ebac";}
.icon-crown:before{content:"\ebad";}
.icon-trophy2:before{content:"\ebaf";}
.icon-trophy3:before{content:"\ebb0";}
.icon-diamond:before{content:"\ebb1";}
.icon-trophy4:before{content:"\ebb2";}
.icon-gift:before{content:"\ebb3";}
.icon-pipe:before{content:"\ebb6";}
.icon-mustache:before{content:"\ebb7";}
.icon-cup2:before{content:"\ebc6";}
.icon-coffee:before{content:"\ebc8";}
.icon-paw:before{content:"\ebd5";}
.icon-footprint:before{content:"\ebd6";}
.icon-rocket:before{content:"\ebda";}
.icon-meter2:before{content:"\ebdc";}
.icon-meter-slow:before{content:"\ebdd";}
.icon-meter-fast:before{content:"\ebdf";}
.icon-hammer2:before{content:"\ebe1";}
.icon-balance:before{content:"\ebe2";}
.icon-fire:before{content:"\ebe5";}
.icon-fire2:before{content:"\ebe6";}
.icon-lab:before{content:"\ebe7";}
.icon-atom:before{content:"\ebe8";}
.icon-atom2:before{content:"\ebe9";}
.icon-bin:before{content:"\ebfa";}
.icon-bin2:before{content:"\ebfb";}
.icon-briefcase:before{content:"\ebff";}
.icon-briefcase3:before{content:"\ec01";}
.icon-airplane2:before{content:"\ec03";}
.icon-airplane3:before{content:"\ec04";}
.icon-airplane4:before{content:"\ec05";}
.icon-paperplane:before{content:"\ec06";}
.icon-car:before{content:"\ec07";}
.icon-steering-wheel:before{content:"\ec08";}
.icon-car2:before{content:"\ec09";}
.icon-gas:before{content:"\ec0a";}
.icon-bus:before{content:"\ec0b";}
.icon-truck:before{content:"\ec0c";}
.icon-bike:before{content:"\ec0d";}
.icon-road:before{content:"\ec0e";}
.icon-train:before{content:"\ec0f";}
.icon-train2:before{content:"\ec10";}
.icon-ship:before{content:"\ec11";}
.icon-boat:before{content:"\ec12";}
.icon-chopper:before{content:"\ec13";}
.icon-cube:before{content:"\ec15";}
.icon-cube2:before{content:"\ec16";}
.icon-cube3:before{content:"\ec17";}
.icon-cube4:before{content:"\ec18";}
.icon-pyramid:before{content:"\ec19";}
.icon-pyramid2:before{content:"\ec1a";}
.icon-package:before{content:"\ec1b";}
.icon-puzzle:before{content:"\ec1c";}
.icon-puzzle2:before{content:"\ec1d";}
.icon-puzzle3:before{content:"\ec1e";}
.icon-puzzle4:before{content:"\ec1f";}
.icon-glasses-3d2:before{content:"\ec21";}
.icon-brain:before{content:"\ec24";}
.icon-accessibility:before{content:"\ec25";}
.icon-accessibility2:before{content:"\ec26";}
.icon-strategy:before{content:"\ec27";}
.icon-target:before{content:"\ec28";}
.icon-target2:before{content:"\ec29";}
.icon-shield-check:before{content:"\ec2f";}
.icon-shield-notice:before{content:"\ec30";}
.icon-shield2:before{content:"\ec31";}
.icon-racing:before{content:"\ec40";}
.icon-finish:before{content:"\ec41";}
.icon-power2:before{content:"\ec46";}
.icon-power3:before{content:"\ec47";}
.icon-switch:before{content:"\ec48";}
.icon-switch22:before{content:"\ec49";}
.icon-power-cord:before{content:"\ec4a";}
.icon-clipboard:before{content:"\ec4d";}
.icon-clipboard2:before{content:"\ec4e";}
.icon-clipboard3:before{content:"\ec4f";}
.icon-clipboard4:before{content:"\ec50";}
.icon-clipboard5:before{content:"\ec51";}
.icon-clipboard6:before{content:"\ec52";}
.icon-playlist:before{content:"\ec53";}
.icon-playlist-add:before{content:"\ec54";}
.icon-list-numbered:before{content:"\ec55";}
.icon-list:before{content:"\ec56";}
.icon-list2:before{content:"\ec57";}
.icon-more:before{content:"\ec58";}
.icon-more2:before{content:"\ec59";}
.icon-grid:before{content:"\ec5a";}
.icon-grid2:before{content:"\ec5b";}
.icon-grid3:before{content:"\ec5c";}
.icon-grid4:before{content:"\ec5d";}
.icon-grid52:before{content:"\ec5e";}
.icon-grid6:before{content:"\ec5f";}
.icon-grid7:before{content:"\ec60";}
.icon-tree5:before{content:"\ec61";}
.icon-tree6:before{content:"\ec62";}
.icon-tree7:before{content:"\ec63";}
.icon-lan:before{content:"\ec64";}
.icon-lan2:before{content:"\ec65";}
.icon-lan3:before{content:"\ec66";}
.icon-menu:before{content:"\ec67";}
.icon-circle-small:before{content:"\ec68";}
.icon-menu2:before{content:"\ec69";}
.icon-menu3:before{content:"\ec6a";}
.icon-menu4:before{content:"\ec6b";}
.icon-menu5:before{content:"\ec6c";}
.icon-menu62:before{content:"\ec6d";}
.icon-menu7:before{content:"\ec6e";}
.icon-menu8:before{content:"\ec6f";}
.icon-menu9:before{content:"\ec70";}
.icon-menu10:before{content:"\ec71";}
.icon-cloud:before{content:"\ec72";}
.icon-cloud-download:before{content:"\ec73";}
.icon-cloud-upload:before{content:"\ec74";}
.icon-cloud-check:before{content:"\ec75";}
.icon-cloud2:before{content:"\ec76";}
.icon-cloud-download2:before{content:"\ec77";}
.icon-cloud-upload2:before{content:"\ec78";}
.icon-cloud-check2:before{content:"\ec79";}
.icon-import:before{content:"\ec7e";}
.icon-download4:before{content:"\ec80";}
.icon-upload4:before{content:"\ec81";}
.icon-download7:before{content:"\ec86";}
.icon-upload7:before{content:"\ec87";}
.icon-download10:before{content:"\ec8c";}
.icon-upload10:before{content:"\ec8d";}
.icon-sphere:before{content:"\ec8e";}
.icon-sphere3:before{content:"\ec90";}
.icon-earth:before{content:"\ec93";}
.icon-link:before{content:"\ec96";}
.icon-unlink:before{content:"\ec97";}
.icon-link2:before{content:"\ec98";}
.icon-unlink2:before{content:"\ec99";}
.icon-anchor:before{content:"\eca0";}
.icon-flag3:before{content:"\eca3";}
.icon-flag4:before{content:"\eca4";}
.icon-flag7:before{content:"\eca7";}
.icon-flag8:before{content:"\eca8";}
.icon-attachment:before{content:"\eca9";}
.icon-attachment2:before{content:"\ecaa";}
.icon-eye:before{content:"\ecab";}
.icon-eye-plus:before{content:"\ecac";}
.icon-eye-minus:before{content:"\ecad";}
.icon-eye-blocked:before{content:"\ecae";}
.icon-eye2:before{content:"\ecaf";}
.icon-eye-blocked2:before{content:"\ecb0";}
.icon-eye4:before{content:"\ecb3";}
.icon-bookmark2:before{content:"\ecb4";}
.icon-bookmark3:before{content:"\ecb5";}
.icon-bookmarks:before{content:"\ecb6";}
.icon-bookmark4:before{content:"\ecb7";}
.icon-spotlight2:before{content:"\ecb8";}
.icon-starburst:before{content:"\ecb9";}
.icon-snowflake:before{content:"\ecba";}
.icon-weather-windy:before{content:"\ecd0";}
.icon-fan:before{content:"\ecd1";}
.icon-umbrella:before{content:"\ecd2";}
.icon-sun3:before{content:"\ecd3";}
.icon-contrast:before{content:"\ecd4";}
.icon-bed2:before{content:"\ecda";}
.icon-furniture:before{content:"\ecdb";}
.icon-chair:before{content:"\ecdc";}
.icon-star-empty3:before{content:"\ece0";}
.icon-star-half:before{content:"\ece1";}
.icon-star-full2:before{content:"\ece2";}
.icon-heart5:before{content:"\ece9";}
.icon-heart6:before{content:"\ecea";}
.icon-heart-broken2:before{content:"\eceb";}
.icon-thumbs-up2:before{content:"\ecf2";}
.icon-thumbs-down2:before{content:"\ecf4";}
.icon-thumbs-up3:before{content:"\ecf5";}
.icon-thumbs-down3:before{content:"\ecf6";}
.icon-height:before{content:"\ecf7";}
.icon-man:before{content:"\ecf8";}
.icon-woman:before{content:"\ecf9";}
.icon-man-woman:before{content:"\ecfa";}
.icon-yin-yang:before{content:"\ecfe";}
.icon-cursor:before{content:"\ed23";}
.icon-cursor2:before{content:"\ed24";}
.icon-lasso2:before{content:"\ed26";}
.icon-select2:before{content:"\ed28";}
.icon-point-up:before{content:"\ed29";}
.icon-point-right:before{content:"\ed2a";}
.icon-point-down:before{content:"\ed2b";}
.icon-point-left:before{content:"\ed2c";}
.icon-pointer:before{content:"\ed2d";}
.icon-reminder:before{content:"\ed2e";}
.icon-drag-left-right:before{content:"\ed2f";}
.icon-drag-left:before{content:"\ed30";}
.icon-drag-right:before{content:"\ed31";}
.icon-touch:before{content:"\ed32";}
.icon-multitouch:before{content:"\ed33";}
.icon-touch-zoom:before{content:"\ed34";}
.icon-touch-pinch:before{content:"\ed35";}
.icon-hand:before{content:"\ed36";}
.icon-grab:before{content:"\ed37";}
.icon-stack-empty:before{content:"\ed38";}
.icon-stack-plus:before{content:"\ed39";}
.icon-stack-minus:before{content:"\ed3a";}
.icon-stack-star:before{content:"\ed3b";}
.icon-stack-picture:before{content:"\ed3c";}
.icon-stack-down:before{content:"\ed3d";}
.icon-stack-up:before{content:"\ed3e";}
.icon-stack-cancel:before{content:"\ed3f";}
.icon-stack-check:before{content:"\ed40";}
.icon-stack-text:before{content:"\ed41";}
.icon-stack4:before{content:"\ed47";}
.icon-stack-music:before{content:"\ed48";}
.icon-stack-play:before{content:"\ed49";}
.icon-move:before{content:"\ed4a";}
.icon-dots:before{content:"\ed4b";}
.icon-warning:before{content:"\ed4c";}
.icon-warning22:before{content:"\ed4d";}
.icon-notification2:before{content:"\ed4f";}
.icon-question3:before{content:"\ed52";}
.icon-question4:before{content:"\ed53";}
.icon-plus3:before{content:"\ed5a";}
.icon-minus3:before{content:"\ed5b";}
.icon-plus-circle2:before{content:"\ed5e";}
.icon-minus-circle2:before{content:"\ed5f";}
.icon-cancel-circle2:before{content:"\ed63";}
.icon-blocked:before{content:"\ed64";}
.icon-cancel-square:before{content:"\ed65";}
.icon-cancel-square2:before{content:"\ed66";}
.icon-spam:before{content:"\ed68";}
.icon-cross2:before{content:"\ed6a";}
.icon-cross3:before{content:"\ed6b";}
.icon-checkmark:before{content:"\ed6c";}
.icon-checkmark3:before{content:"\ed6e";}
.icon-checkmark2:before{content:"\e372";}
.icon-checkmark4:before{content:"\ed6f";}
.icon-spell-check:before{content:"\ed71";}
.icon-spell-check2:before{content:"\ed72";}
.icon-enter:before{content:"\ed73";}
.icon-exit:before{content:"\ed74";}
.icon-enter2:before{content:"\ed75";}
.icon-exit2:before{content:"\ed76";}
.icon-enter3:before{content:"\ed77";}
.icon-exit3:before{content:"\ed78";}
.icon-wall:before{content:"\ed79";}
.icon-fence:before{content:"\ed7a";}
.icon-play3:before{content:"\ed7b";}
.icon-pause:before{content:"\ed7c";}
.icon-stop:before{content:"\ed7d";}
.icon-previous:before{content:"\ed7e";}
.icon-next:before{content:"\ed7f";}
.icon-backward:before{content:"\ed80";}
.icon-forward2:before{content:"\ed81";}
.icon-play4:before{content:"\ed82";}
.icon-pause2:before{content:"\ed83";}
.icon-stop2:before{content:"\ed84";}
.icon-backward2:before{content:"\ed85";}
.icon-forward3:before{content:"\ed86";}
.icon-first:before{content:"\ed87";}
.icon-last:before{content:"\ed88";}
.icon-previous2:before{content:"\ed89";}
.icon-next2:before{content:"\ed8a";}
.icon-eject:before{content:"\ed8b";}
.icon-volume-high:before{content:"\ed8c";}
.icon-volume-medium:before{content:"\ed8d";}
.icon-volume-low:before{content:"\ed8e";}
.icon-volume-mute:before{content:"\ed8f";}
.icon-speaker-left:before{content:"\ed90";}
.icon-speaker-right:before{content:"\ed91";}
.icon-volume-mute2:before{content:"\ed92";}
.icon-volume-increase:before{content:"\ed93";}
.icon-volume-decrease:before{content:"\ed94";}
.icon-volume-mute5:before{content:"\eda4";}
.icon-loop:before{content:"\eda5";}
.icon-loop3:before{content:"\eda7";}
.icon-infinite-square:before{content:"\eda8";}
.icon-infinite:before{content:"\eda9";}
.icon-loop4:before{content:"\edab";}
.icon-shuffle:before{content:"\edac";}
.icon-wave:before{content:"\edae";}
.icon-wave2:before{content:"\edaf";}
.icon-split:before{content:"\edb0";}
.icon-merge:before{content:"\edb1";}
.icon-arrow-up5:before{content:"\edc4";}
.icon-arrow-right5:before{content:"\edc5";}
.icon-arrow-down5:before{content:"\edc6";}
.icon-arrow-left5:before{content:"\edc7";}
.icon-arrow-up-left2:before{content:"\edd0";}
.icon-arrow-up7:before{content:"\edd1";}
.icon-arrow-up-right2:before{content:"\edd2";}
.icon-arrow-right7:before{content:"\edd3";}
.icon-arrow-down-right2:before{content:"\edd4";}
.icon-arrow-down7:before{content:"\edd5";}
.icon-arrow-down-left2:before{content:"\edd6";}
.icon-arrow-left7:before{content:"\edd7";}
.icon-arrow-up-left3:before{content:"\edd8";}
.icon-arrow-up8:before{content:"\edd9";}
.icon-arrow-up-right3:before{content:"\edda";}
.icon-arrow-right8:before{content:"\eddb";}
.icon-arrow-down-right3:before{content:"\eddc";}
.icon-arrow-down8:before{content:"\eddd";}
.icon-arrow-down-left3:before{content:"\edde";}
.icon-arrow-left8:before{content:"\eddf";}
.icon-circle-up2:before{content:"\ede4";}
.icon-circle-right2:before{content:"\ede5";}
.icon-circle-down2:before{content:"\ede6";}
.icon-circle-left2:before{content:"\ede7";}
.icon-arrow-resize7:before{content:"\edfe";}
.icon-arrow-resize8:before{content:"\edff";}
.icon-square-up-left:before{content:"\ee00";}
.icon-square-up:before{content:"\ee01";}
.icon-square-up-right:before{content:"\ee02";}
.icon-square-right:before{content:"\ee03";}
.icon-square-down-right:before{content:"\ee04";}
.icon-square-down:before{content:"\ee05";}
.icon-square-down-left:before{content:"\ee06";}
.icon-square-left:before{content:"\ee07";}
.icon-arrow-up15:before{content:"\ee30";}
.icon-arrow-right15:before{content:"\ee31";}
.icon-arrow-down15:before{content:"\ee32";}
.icon-arrow-left15:before{content:"\ee33";}
.icon-arrow-up16:before{content:"\ee34";}
.icon-arrow-right16:before{content:"\ee35";}
.icon-arrow-down16:before{content:"\ee36";}
.icon-arrow-left16:before{content:"\ee37";}
.icon-menu-open:before{content:"\ee38";}
.icon-menu-open2:before{content:"\ee39";}
.icon-menu-close:before{content:"\ee3a";}
.icon-menu-close2:before{content:"\ee3b";}
.icon-enter5:before{content:"\ee3d";}
.icon-esc:before{content:"\ee3e";}
.icon-enter6:before{content:"\ee3f";}
.icon-backspace:before{content:"\ee40";}
.icon-backspace2:before{content:"\ee41";}
.icon-tab:before{content:"\ee42";}
.icon-transmission:before{content:"\ee43";}
.icon-sort:before{content:"\ee45";}
.icon-move-up2:before{content:"\ee47";}
.icon-move-down2:before{content:"\ee48";}
.icon-sort-alpha-asc:before{content:"\ee49";}
.icon-sort-alpha-desc:before{content:"\ee4a";}
.icon-sort-numeric-asc:before{content:"\ee4b";}
.icon-sort-numberic-desc:before{content:"\ee4c";}
.icon-sort-amount-asc:before{content:"\ee4d";}
.icon-sort-amount-desc:before{content:"\ee4e";}
.icon-sort-time-asc:before{content:"\ee4f";}
.icon-sort-time-desc:before{content:"\ee50";}
.icon-battery-6:before{content:"\ee51";}
.icon-battery-0:before{content:"\ee57";}
.icon-battery-charging:before{content:"\ee58";}
.icon-command:before{content:"\ee5f";}
.icon-shift:before{content:"\ee60";}
.icon-ctrl:before{content:"\ee61";}
.icon-opt:before{content:"\ee62";}
.icon-checkbox-checked:before{content:"\ee63";}
.icon-checkbox-unchecked:before{content:"\ee64";}
.icon-checkbox-partial:before{content:"\ee65";}
.icon-square:before{content:"\ee66";}
.icon-triangle:before{content:"\ee67";}
.icon-triangle2:before{content:"\ee68";}
.icon-diamond3:before{content:"\ee69";}
.icon-diamond4:before{content:"\ee6a";}
.icon-checkbox-checked2:before{content:"\ee6b";}
.icon-checkbox-unchecked2:before{content:"\ee6c";}
.icon-checkbox-partial2:before{content:"\ee6d";}
.icon-radio-checked:before{content:"\ee6e";}
.icon-radio-checked2:before{content:"\ee6f";}
.icon-radio-unchecked:before{content:"\ee70";}
.icon-checkmark-circle:before{content:"\ee73";}
.icon-circle:before{content:"\ee74";}
.icon-circle2:before{content:"\ee75";}
.icon-circles:before{content:"\ee76";}
.icon-circles2:before{content:"\ee77";}
.icon-crop:before{content:"\ee78";}
.icon-crop2:before{content:"\ee79";}
.icon-make-group:before{content:"\ee7a";}
.icon-ungroup:before{content:"\ee7b";}
.icon-vector:before{content:"\ee7c";}
.icon-vector2:before{content:"\ee7d";}
.icon-rulers:before{content:"\ee7e";}
.icon-pencil-ruler:before{content:"\ee80";}
.icon-scissors:before{content:"\ee81";}
.icon-filter3:before{content:"\ee88";}
.icon-filter4:before{content:"\ee89";}
.icon-font:before{content:"\ee8a";}
.icon-ampersand2:before{content:"\ee8b";}
.icon-ligature:before{content:"\ee8c";}
.icon-font-size:before{content:"\ee8e";}
.icon-typography:before{content:"\ee8f";}
.icon-text-height:before{content:"\ee90";}
.icon-text-width:before{content:"\ee91";}
.icon-height2:before{content:"\ee92";}
.icon-width:before{content:"\ee93";}
.icon-strikethrough2:before{content:"\ee98";}
.icon-font-size2:before{content:"\ee99";}
.icon-bold2:before{content:"\ee9a";}
.icon-underline2:before{content:"\ee9b";}
.icon-italic2:before{content:"\ee9c";}
.icon-strikethrough3:before{content:"\ee9d";}
.icon-omega:before{content:"\ee9e";}
.icon-sigma:before{content:"\ee9f";}
.icon-nbsp:before{content:"\eea0";}
.icon-page-break:before{content:"\eea1";}
.icon-page-break2:before{content:"\eea2";}
.icon-superscript:before{content:"\eea3";}
.icon-subscript:before{content:"\eea4";}
.icon-superscript2:before{content:"\eea5";}
.icon-subscript2:before{content:"\eea6";}
.icon-text-color:before{content:"\eea7";}
.icon-highlight:before{content:"\eea8";}
.icon-pagebreak:before{content:"\eea9";}
.icon-clear-formatting:before{content:"\eeaa";}
.icon-table:before{content:"\eeab";}
.icon-table2:before{content:"\eeac";}
.icon-insert-template:before{content:"\eead";}
.icon-pilcrow:before{content:"\eeae";}
.icon-ltr:before{content:"\eeaf";}
.icon-rtl:before{content:"\eeb0";}
.icon-ltr2:before{content:"\eeb1";}
.icon-rtl2:before{content:"\eeb2";}
.icon-section:before{content:"\eeb3";}
.icon-paragraph-left2:before{content:"\eeb8";}
.icon-paragraph-center2:before{content:"\eeb9";}
.icon-paragraph-right2:before{content:"\eeba";}
.icon-paragraph-justify2:before{content:"\eebb";}
.icon-indent-increase:before{content:"\eebc";}
.icon-indent-decrease:before{content:"\eebd";}
.icon-paragraph-left3:before{content:"\eebe";}
.icon-paragraph-center3:before{content:"\eebf";}
.icon-paragraph-right3:before{content:"\eec0";}
.icon-paragraph-justify3:before{content:"\eec1";}
.icon-indent-increase2:before{content:"\eec2";}
.icon-indent-decrease2:before{content:"\eec3";}
.icon-share:before{content:"\eec4";}
.icon-share2:before{content:"\eec5";}
.icon-new-tab:before{content:"\eec6";}
.icon-new-tab2:before{content:"\eec7";}
.icon-popout:before{content:"\eec8";}
.icon-embed:before{content:"\eec9";}
.icon-embed2:before{content:"\eeca";}
.icon-markup:before{content:"\eecb";}
.icon-regexp:before{content:"\eecc";}
.icon-regexp2:before{content:"\eecd";}
.icon-code:before{content:"\eece";}
.icon-circle-css:before{content:"\eecf";}
.icon-circle-code:before{content:"\eed0";}
.icon-terminal:before{content:"\eed1";}
.icon-unicode:before{content:"\eed2";}
.icon-seven-segment-0:before{content:"\eed3";}
.icon-seven-segment-1:before{content:"\eed4";}
.icon-seven-segment-2:before{content:"\eed5";}
.icon-seven-segment-3:before{content:"\eed6";}
.icon-seven-segment-4:before{content:"\eed7";}
.icon-seven-segment-5:before{content:"\eed8";}
.icon-seven-segment-6:before{content:"\eed9";}
.icon-seven-segment-7:before{content:"\eeda";}
.icon-seven-segment-8:before{content:"\eedb";}
.icon-seven-segment-9:before{content:"\eedc";}
.icon-share3:before{content:"\eedd";}
.icon-share4:before{content:"\eede";}
.icon-google:before{content:"\eee3";}
.icon-google-plus:before{content:"\eee4";}
.icon-google-plus2:before{content:"\eee5";}
.icon-google-drive:before{content:"\eee7";}
.icon-facebook:before{content:"\eee8";}
.icon-facebook2:before{content:"\eee9";}
.icon-instagram:before{content:"\eeec";}
.icon-twitter:before{content:"\eeed";}
.icon-twitter2:before{content:"\eeee";}
.icon-feed2:before{content:"\eef0";}
.icon-feed3:before{content:"\eef1";}
.icon-youtube:before{content:"\eef3";}
.icon-youtube2:before{content:"\eef4";}
.icon-youtube3:before{content:"\eef5";}
.icon-vimeo:before{content:"\eef8";}
.icon-vimeo2:before{content:"\eef9";}
.icon-lanyrd:before{content:"\eefb";}
.icon-flickr:before{content:"\eefc";}
.icon-flickr2:before{content:"\eefd";}
.icon-flickr3:before{content:"\eefe";}
.icon-picassa:before{content:"\ef00";}
.icon-picassa2:before{content:"\ef01";}
.icon-dribbble:before{content:"\ef02";}
.icon-dribbble2:before{content:"\ef03";}
.icon-dribbble3:before{content:"\ef04";}
.icon-forrst:before{content:"\ef05";}
.icon-forrst2:before{content:"\ef06";}
.icon-deviantart:before{content:"\ef07";}
.icon-deviantart2:before{content:"\ef08";}
.icon-steam:before{content:"\ef09";}
.icon-steam2:before{content:"\ef0a";}
.icon-dropbox:before{content:"\ef0b";}
.icon-onedrive:before{content:"\ef0c";}
.icon-github:before{content:"\ef0d";}
.icon-github4:before{content:"\ef10";}
.icon-github5:before{content:"\ef11";}
.icon-wordpress:before{content:"\ef12";}
.icon-wordpress2:before{content:"\ef13";}
.icon-joomla:before{content:"\ef14";}
.icon-blogger:before{content:"\ef15";}
.icon-blogger2:before{content:"\ef16";}
.icon-tumblr:before{content:"\ef17";}
.icon-tumblr2:before{content:"\ef18";}
.icon-yahoo:before{content:"\ef19";}
.icon-tux:before{content:"\ef1a";}
.icon-apple2:before{content:"\ef1b";}
.icon-finder:before{content:"\ef1c";}
.icon-android:before{content:"\ef1d";}
.icon-windows:before{content:"\ef1e";}
.icon-windows8:before{content:"\ef1f";}
.icon-soundcloud:before{content:"\ef20";}
.icon-soundcloud2:before{content:"\ef21";}
.icon-skype:before{content:"\ef22";}
.icon-reddit:before{content:"\ef23";}
.icon-linkedin:before{content:"\ef24";}
.icon-linkedin2:before{content:"\ef25";}
.icon-lastfm:before{content:"\ef26";}
.icon-lastfm2:before{content:"\ef27";}
.icon-delicious:before{content:"\ef28";}
.icon-stumbleupon:before{content:"\ef29";}
.icon-stumbleupon2:before{content:"\ef2a";}
.icon-stackoverflow:before{content:"\ef2b";}
.icon-pinterest2:before{content:"\ef2d";}
.icon-xing:before{content:"\ef2e";}
.icon-flattr:before{content:"\ef30";}
.icon-foursquare:before{content:"\ef31";}
.icon-paypal:before{content:"\ef32";}
.icon-paypal2:before{content:"\ef33";}
.icon-yelp:before{content:"\ef35";}
.icon-file-pdf:before{content:"\ef36";}
.icon-file-openoffice:before{content:"\ef37";}
.icon-file-word:before{content:"\ef38";}
.icon-file-excel:before{content:"\ef39";}
.icon-libreoffice:before{content:"\ef3a";}
.icon-html5:before{content:"\ef3b";}
.icon-html52:before{content:"\ef3c";}
.icon-css3:before{content:"\ef3d";}
.icon-git:before{content:"\ef3e";}
.icon-svg:before{content:"\ef3f";}
.icon-codepen:before{content:"\ef40";}
.icon-chrome:before{content:"\ef41";}
.icon-firefox:before{content:"\ef42";}
.icon-IE:before{content:"\ef43";}
.icon-opera:before{content:"\ef44";}
.icon-safari:before{content:"\ef45";}
.icon-check2:before{content:"\e601";}
.icon-home4:before{content:"\e603";}
.icon-people:before{content:"\e81b";}
.icon-checkmark-circle2:before{content:"\e853";}
.icon-arrow-up-left32:before{content:"\e8ae";}
.icon-arrow-up52:before{content:"\e8af";}
.icon-arrow-up-right32:before{content:"\e8b0";}
.icon-arrow-right6:before{content:"\e8b1";}
.icon-arrow-down-right32:before{content:"\e8b2";}
.icon-arrow-down52:before{content:"\e8b3";}
.icon-arrow-down-left32:before{content:"\e8b4";}
.icon-arrow-left52:before{content:"\e8b5";}
.icon-calendar5:before{content:"\e985";}
.icon-move-alt1:before{content:"\e986";}
.icon-reload-alt:before{content:"\e987";}
.icon-move-vertical:before{content:"\e988";}
.icon-move-horizontal:before{content:"\e989";}
.icon-hash:before{content:"\e98b";}
.icon-bars-alt:before{content:"\e98c";}
.icon-eye8:before{content:"\e98d";}
.icon-search4:before{content:"\e98e";}
.icon-zoomin3:before{content:"\e98f";}
.icon-zoomout3:before{content:"\e990";}
.icon-add:before{content:"\e991";}
.icon-subtract:before{content:"\e992";}
.icon-exclamation:before{content:"\e993";}
.icon-question6:before{content:"\e994";}
.icon-close2:before{content:"\e995";}
.icon-task:before{content:"\e996";}
.icon-inbox:before{content:"\e997";}
.icon-inbox-alt:before{content:"\e998";}
.icon-envelope:before{content:"\e999";}
.icon-compose:before{content:"\e99a";}
.icon-newspaper2:before{content:"\e99b";}
.icon-calendar22:before{content:"\e99c";}
.icon-hyperlink:before{content:"\e99d";}
.icon-trash:before{content:"\e99e";}
.icon-trash-alt:before{content:"\e99f";}
.icon-grid5:before{content:"\e9a0";}
.icon-grid-alt:before{content:"\e9a1";}
.icon-menu6:before{content:"\e9a2";}
.icon-list3:before{content:"\e9a3";}
.icon-gallery:before{content:"\e9a4";}
.icon-calculator:before{content:"\e9a5";}
.icon-windows2:before{content:"\e9a6";}
.icon-browser:before{content:"\e9a7";}
.icon-portfolio:before{content:"\e9a8";}
.icon-comments:before{content:"\e9a9";}
.icon-screen3:before{content:"\e9aa";}
.icon-iphone:before{content:"\e9ab";}
.icon-ipad:before{content:"\e9ac";}
.icon-googleplus5:before{content:"\e9ad";}
.icon-pin:before{content:"\e9ae";}
.icon-pin-alt:before{content:"\e9af";}
.icon-cog5:before{content:"\e9b0";}
.icon-graduation:before{content:"\e9b1";}
.icon-air:before{content:"\e9b2";}
.icon-droplets:before{content:"\e7ee";}
.icon-statistics:before{content:"\e9b4";}
.icon-pie5:before{content:"\e7ef";}
.icon-cross:before{content:"\e9b6";}
.icon-minus2:before{content:"\e9b7";}
.icon-plus2:before{content:"\e9b8";}
.icon-info3:before{content:"\e9b9";}
.icon-info22:before{content:"\e9ba";}
.icon-question7:before{content:"\e9bb";}
.icon-help:before{content:"\e9bc";}
.icon-warning2:before{content:"\e9bd";}
.icon-add-to-list:before{content:"\e9bf";}
.icon-arrow-left12:before{content:"\e9c0";}
.icon-arrow-down12:before{content:"\e9c1";}
.icon-arrow-up12:before{content:"\e9c2";}
.icon-arrow-right13:before{content:"\e9c3";}
.icon-arrow-left22:before{content:"\e9c4";}
.icon-arrow-down22:before{content:"\e9c5";}
.icon-arrow-up22:before{content:"\e9c6";}
.icon-arrow-right22:before{content:"\e9c7";}
.icon-arrow-left32:before{content:"\e9c8";}
.icon-arrow-down32:before{content:"\e9c9";}
.icon-arrow-up32:before{content:"\e9ca";}
.icon-arrow-right32:before{content:"\e9cb";}
.icon-switch2:before{content:"\e647";}
.icon-checkmark5:before{content:"\e600";}
.icon-ampersand:before{content:"\e9cc";}
.icon-alert:before{content:"\e9cf";}
.icon-alignment-align:before{content:"\e9d0";}
.icon-alignment-aligned-to:before{content:"\e9d1";}
.icon-alignment-unalign:before{content:"\e9d2";}
.icon-arrow-down132:before{content:"\e9d3";}
.icon-arrow-up13:before{content:"\e9da";}
.icon-arrow-left13:before{content:"\e9d4";}
.icon-arrow-right14:before{content:"\e9d5";}
.icon-arrow-small-down:before{content:"\e9d6";}
.icon-arrow-small-left:before{content:"\e9d7";}
.icon-arrow-small-right:before{content:"\e9d8";}
.icon-arrow-small-up:before{content:"\e9d9";}
.icon-check:before{content:"\e9db";}
.icon-chevron-down:before{content:"\e9dc";}
.icon-chevron-left:before{content:"\e9dd";}
.icon-chevron-right:before{content:"\e9de";}
.icon-chevron-up:before{content:"\e9df";}
.icon-clippy:before{content:"\f035";}
.icon-comment:before{content:"\f02b";}
.icon-comment-discussion:before{content:"\f04f";}
.icon-dash:before{content:"\e9e2";}
.icon-diff:before{content:"\e9e3";}
.icon-diff-added:before{content:"\e9e4";}
.icon-diff-ignored:before{content:"\e9e5";}
.icon-diff-modified:before{content:"\e9e6";}
.icon-diff-removed:before{content:"\e9e7";}
.icon-diff-renamed:before{content:"\e9e8";}
.icon-file-media:before{content:"\f012";}
.icon-fold:before{content:"\e9ea";}
.icon-gear:before{content:"\e9eb";}
.icon-git-branch:before{content:"\e9ec";}
.icon-git-commit:before{content:"\e9ed";}
.icon-git-compare:before{content:"\e9ee";}
.icon-git-merge:before{content:"\e9ef";}
.icon-git-pull-request:before{content:"\e9f0";}
.icon-graph:before{content:"\f043";}
.icon-law:before{content:"\e9f1";}
.icon-list-ordered:before{content:"\e9f2";}
.icon-list-unordered:before{content:"\e9f3";}
.icon-mail5:before{content:"\e9f4";}
.icon-mail-read:before{content:"\e9f5";}
.icon-mention:before{content:"\e9f6";}
.icon-mirror:before{content:"\f024";}
.icon-move-down:before{content:"\f0a8";}
.icon-move-left:before{content:"\f074";}
.icon-move-right:before{content:"\f0a9";}
.icon-move-up:before{content:"\f0a7";}
.icon-person:before{content:"\f018";}
.icon-plus22:before{content:"\e9f7";}
.icon-primitive-dot:before{content:"\f052";}
.icon-primitive-square:before{content:"\f053";}
.icon-repo-forked:before{content:"\e9f8";}
.icon-screen-full:before{content:"\e9f9";}
.icon-screen-normal:before{content:"\e9fa";}
.icon-sync:before{content:"\e9fb";}
.icon-three-bars:before{content:"\e9fc";}
.icon-unfold:before{content:"\e9fe";}
.icon-versions:before{content:"\e9ff";}
.icon-x:before{content:"\ea00";}
