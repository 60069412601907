#nutrition-content table.border td {
  border: 1px solid #ddd !important;
}

.td-width-min {
  min-width: 65px;
}

.nutrition-table-td td {
  min-width: 68px;
}

.nutrition-table-td td:nth-child(1) {
  max-width: 300px;
}


#nutrition-content .modal-dialog {
  max-width: 96% !important;
  width: inherit !important;
}

.product-error {
  color: #480c12;
  font-size: 14px;
}

.select__control {
  border-radius: 0 !important;
}

.select__indicator,
.select__value-container {
  padding: 0 8px !important;
}

.basic-multi-select .select__value-container {
  font-size: 12px;
}

.loaderOneCss {
  opacity: 0.7;
}

.table-control {
  float: right;
  /* padding: 0 5px; */
  cursor: pointer;
}

.table-control:hover img {
  filter: invert(85%) sepia(100%) saturate(0%) hue-rotate(26deg) brightness(104%) contrast(103%);
}

.table-control .add-row,
.table-control-blue .add-row {
  color: green;
  font-size: 18px;
}

.table-control .delete-row,
.table-control-blue .delete-row {
  color: red;
  font-size: 18px;
}

.table-control.inside-icon {
  background: #f44336;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}

.table-control.inside-icon .delete-row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1rem;
}

.asterisk {
  font-size: 14px;
  font-weight: 600;
}

.table-control-blue {
  padding: 0 5px;
  cursor: pointer;
}

.rc-tree-select-selection--single {
  height: 36px !important;
}

.rc-tree-select-selection {
  border-radius: 0 !important;
}

.select__single-value,
.select__multi-value__label,
.rc-tree-select-selection {
  color: #000000;
}

.select__multi-value {
  padding: 0px 6px;
  background-color: #BDD1FC !important;
}

.select__multi-value:hover {
  background-color: #d5d5df;
}

.select__multi-value__remove {
  cursor: pointer;
}

.select__multi-value:hover .select__multi-value__label,
.select__multi-value:hover .select__multi-value__remove,
.select__multi-value:hover .select__multi-value__remove svg {
  color: #fff !important;
  background-color: transparent !important;
}

/* .select__multi-value__remove:hover svg, .select__multi-value__remove svg:hover{
  background-color: #000 !important;
} */

.react-tree-dropdown {
  font-size: 14px !important;
}

.rc-tree-select-selection {
  border: 1px solid #B3B3B3 !important;
}

.rc-tree-select-tree li span.rc-tree-select-tree-switcher {
  background-position: center !important;
  line-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
}

.rc-tree-select-tree li span.rc-tree-select-tree-switcher_close {
  background-image: url("/assets/images/icons-svg/main-accordion-plus.svg") !important;
}

.rc-tree-select-tree li span.rc-tree-select-tree-switcher_open {
  background-image: url("/assets/images/icons-svg/main-accordion-minus.svg") !important;
}

.rc-tree-select-selection--single .rc-tree-select-selection__clear:after {
  font-size: 24px;
}

.group-title {
  padding: 10px 0;
  border-bottom: 1px solid lightgray;
}

/*----------------------------------product status button css--------------------------------------*/

.st-brand .select__indicators {
  display: none;
}

.flag-img {
  max-height: 18px;
  margin-right: 5px;
}

.deprecated {
  color: #ba2121 !important;
  font-style: italic;
}

.progressbar {
  max-height: 250px;
  overflow: auto;
}

.mtb-10 {
  margin: 10px 0;
}

.nutrition-card-section table>tbody>tr>td:first-child {
  width: 50%;
}

.nutrition-card-section .form-group>i,
.nutrition-card-section .form-group .hide-trigger>i,
.nutrition-card-section .form-group .product_view>i {
  position: absolute;
  display: block;
  top: 25%;
  pointer-events: none;
  width: 25px;
  right: 16px;
  text-align: center;
  font-style: normal;
}

.nutrition-card-section .nutri-block-border>table input {
  padding-left: 5px;
}

.nutrition-card-section label {
  width: auto !important;
}

.percent-color {
  color: rgba(61, 59, 59, 0.6);
}

.nutrition_header_title {
  display: inline-block;
  width: 90%;
  font-weight: bold;
}

.nutrition-header>tr>td {
  border: 1px solid #d5d5df;
}

.table-cell {
  height: 40px;
  padding: 0 0 0 10px;
  cursor: pointer;
}

.lock-icon-detail-page {
  font-size: 30px;
  cursor: not-allowed;
  margin-top: 47px;
}

.btn-lock-icon {
  background: rgba(235, 165, 98, 1);
  margin-top: 56px;
  cursor: default;
  color: #fff;
}

.em-lock-btn {
  color: #fff;
}

.add_table_row {
  background-size: auto;
  height: 21px;
  width: 30px;
  background: url(/assets/images/row.svg) no-repeat;
}

.add_table_column {
  background-size: auto;
  height: 21px;
  width: 30px;
  background: url(/assets/images/col.svg) no-repeat;
}

.ulCls {
  padding-inline-start: 0;
}

.listItems {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.copy-checkbox {
  margin: 0;
  display: inline-block !important;
  margin-right: 10px !important;
  opacity: 1 !important;
}

.nutrition-header>tr:nth-of-type(even) {
  background-color: #fff !important;
}

.nutrition-header>tr:nth-of-type(odd) {
  background-color: #f5f5f7 !important;
}

.nutrition-header>tr:first-child {
  background-color: #e9edf9 !important;
}

.nutrition-header>tr:first-child>td {
  border: 1px solid #d5d5df;
  color: #a3a6b4 !important;
}

.nutrition-remove-row {
  width: 100%;
}

.padding-10 {
  padding: 10px;
}

.download-zip-asset-heading {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}

.popup-download .custom-checkbox {
  width: 13px;
  height: 13px;
  border: 1px solid #b4b4bb;
  border-radius: 0;
  font-size: 11px;
  margin-left: 10px;
}

.nutrition {
  table-layout: fixed;
  width: 100%;
}

.nowrap-col {
  word-wrap: normal !important;
  word-break: normal !important;
  hyphens: unset !important;
  white-space: nowrap !important;
}

.table th.table-actions {
  border-right: 1px solid #ededed !important;
}

.upload-card-close {
  font-size: 18px;
}

#progress-bar-collapse-icon {
  font-weight: bold !important;
  font-size: 28px;
}

.default-scroll {
  overflow-y: auto !important;
}

.modal-body {
  height: auto;
  overflow-y: auto;
}

.bulk-popup .modal-body {
  min-height: 200px;
}

.bulk-popup #modal-root {
  position: absolute !important;
}

.bulk-popup .spinner-box {
  transform: translate(-50%, -50%);
}

.bulk-download {
  padding-left: 10px !important;
}

.sidebar-close-tab .closeafter4 em {
  font-size: 16px;
  width: 16px;
  position: relative;
  top: -2px;
}

.sidebar-close-tab {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.product_view {
  color: #272848;
  font-size: 14px;
  padding: 8px;
  width: 100%;
  background-color: #f2f2f2 !important;
  border: 1px solid #d1d9df;
  line-height: normal;
}

.data-height {
  height: 100%;
}

.product_view_input {
  border: 1px solid #caced5;
}

textarea {
  resize: vertical;
  width: 100%;
}

.pd {
  padding: 10px 0 10px 0 !important;
}

.structure-panel {
  padding: 8px 0;
}

.neutral-icon {
  text-align: right;
  padding-right: 0 !important;
  color: #000000;
}

.neutral-icon span {
  color: #fff;
  position: absolute;
  right: 0;
  top: 1px;
  min-width: 16px;
  width: 16px;
  left: 0;
  margin-left: 0 !important;
  font-family: "Aktiv-Grotesk";
}

.rc-tree-select-tree-title {
  cursor: pointer;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.4rem !important;
}

.rc-tree-select-disabled,
.rc-tree-select-disabled .rc-tree-select-selection--single {
  cursor: default !important;
  background: hsl(0, 0%, 95%);
  overflow: hidden;
}

.comment-card-body p {
  white-space: pre-wrap;
  word-break: break-word;
}

.p-bar-bottom {
  border-bottom: solid 2px #f1f1f3;
  width: fit-content;
}

.rc-tree-select-disabled .rc-tree-select-selection__clear {
  display: none;
}

.select__multi-value--is-disabled {
  padding: 0 6px;
}

.select__multi-value--is-disabled>.select__multi-value__remove {
  display: none;
}

.qualifier_select {
  min-width: 100px;
  background: #fff;
  border: 1px solid #caced5;
}

.qualifier_select :disabled {
  background: #f0f0f7 !important;
}

.structure_table_td_input {
  min-width: 65px !important;
}

.customized-datepicker .react-datepicker__close-icon::after {
  background-color: transparent;
  color: #ddd;
  font-size: 20px;
  content: "\ed6b";
  font-family: icomoon, serif !important;
}

.react-datepicker__close-icon::after {
  background-color: #2D6DF6 !important;
}

.customized-datepicker .daterange-single.cal-icon {
  display: block;
  position: relative;
  font-size: 1rem;
  background: #fff url("/assets/images/icons-svg/calendar-gray.svg") right 0rem center no-repeat;
  cursor: pointer;
  background-size: 25px;
}

.formelement-datepicker {
  display: block;
  position: relative;
  font-size: 1rem;
  background: #fff url("/assets/images/icons-svg/calendar-gray.svg") right 0rem center no-repeat;
  cursor: pointer;
  background-size: 25px;
}

.filter-container .form-control__control,
.logType .form-control__control {
  border-radius: 0;
}

.break-nospace {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.zIndexVisible .select__menu {
  z-index: 25 !important;
}

.quantityZindex .select__menu {
  z-index: 21 !important;
}

.canvas_container {
  width: 200px;
  position: relative;
}

.canvas_container p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#compare-version-modal .modal-dialog {
  width: 1010px !important;
  max-width: 1010px !important;
}

#nutrition-content {
  z-index: 99999;
}

.multipart-checkbox {
  display: inline-block !important;
  opacity: 1 !important;
}

.nav-item-close>ul.multipart {
  display: none;
}

.nav-item-open>ul.multipart {
  display: block;
}

.tabContext>.react-contextmenu-wrapper {
  display: inherit;
}

.tabContext .react-contextmenu-item {
  padding: 3px 10px;
  cursor: pointer;
}

.tabContext .react-contextmenu-item:hover {
  background: -webkit-linear-gradient(top, #84a3f7, #2866f2);
  background: linear-gradient(to bottom, #84a3f7 0%, #2866f2 100%);
  color: #ffffff;
}

.tabContext>nav {
  display: block;
  position: absolute;
  top: 50px;
  left: 50px;
  margin: 0;
  padding: 3px 0 4px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  font-family: Lucida Grande;
  font-size: 14px;
  line-height: 15px;
}

.upload-button-product-xlsx {
  opacity: 1 !important;
  background: none !important;
}

.max-wid-pop {
  max-width: 50%;
  overflow: auto;
}

#nutrition-content .modal-body {
  max-height: 500px;
  overflow: auto;
}

.loading-center {
  margin: 0 auto !important;
  text-align: center;
  font-weight: bold;
}

.tdpad {
  padding: 10px 0px;
}

.basic-multi-select {
  color: #000 !important;
}

.block-error {
  font-size: 11px;
  text-align: left;
  color: #f06400;
}

/* .percent-icon {
  top: 12% !important;
} */

.ml-15 {
  margin-left: 15px;
}

@media only screen and (max-width: 1460px) {
  .block-error {
    font-size: 9px;
    text-align: left;
  }
}

#workflowModal ul {
  padding-inline-start: 15px;
}

#workflowModal .message {
  font-size: 14px;
  color: #272848;
}

#workflowModal .text_container {
  max-height: 250px;
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  overflow: auto;
}

#footer_desc {
  line-height: 15px;
}

#fade-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  top: 0;
  bottom: 0;
}

.fadeZindex {
  z-index: 10 !important;
}

.modal-height-auto {
  min-height: auto !important;
  top: 14px;
}

.label-suffix {
  margin-left: 0.3125rem !important;
  text-transform: none !important;
}

.service-width {
  width: 65%;
}

.txt-right {
  text-align: right;
}

.ReactModal__Overlay {
  z-index: 1031 !important;
}

.footer-ctrl {
  float: right;
  padding: 0 !important;
}

.modal-body p {
  color: #272848;
}

.nutri-table-control .breadcrumb-elements-item,
.uncertified-product-form .breadcrumb-elements-item {
  padding: 0 !important;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.padding-0 {
  padding: 0;
}

.div-editable-border {
  margin-top: 2px;
  /* border: 1px solid #fff !important; */
}

@media only screen and (min-device-width: 767px) {
  .canvas_container canvas {
    height: 100% !important;
    width: 100% !important;
    cursor: pointer;
    position: relative;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1165px) {
  .canvas_container {
    width: 240px !important;
    height: 240px !important;
  }
}

.new-line {
  white-space: pre-line;
}

.is_multipart_message_container p {
  padding-left: 13px;
  padding-right: 5px;
  font-weight: bold;
}

.product-status-button {
  position: relative;
}

.error_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledCursor {
  cursor: default;
  color: #000;
}

.disabledCursor:hover {
  color: #000;
}

.revert_icon_img {
  height: 16px;
  cursor: pointer;
}

.rejected-attribute-modal {
  overflow-y: auto;
  max-height: 470px;
}

.comment-flag {
  height: 13px;
  margin-right: 20px;
}

.rejected_attributes_fields_link {
  cursor: pointer;
}

.attibute_not_found-heading {
  color: #808080;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* product not found page css */

.not-found-content {
  position: absolute;
  top: 40%;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.not-found-btn {
  margin-left: 30px;
  padding: 10px;
  border-radius: 50px;
  background: #00aaed;
  margin-top: 20px;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
}

.not-found-btn:hover {
  color: #fff;
}

.multi-part-tab {
  cursor: default !important;
}

.blinking {
  animation: blinker 2s linear infinite;
  position: absolute;
  top: -32px;
  left: 0px;
}

.blinking svg {
  height: 80px;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.nutrition-delete-icon-pad {
  padding: 0px 8px 0px 0px !important;
}

/*--- version preview ---*/

.preview-fix-header {
  position: sticky;
  overflow: visible;
  z-index: 100;
  top: 0px;
}

.preview-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  cursor: pointer;
}

.dashboard-service {
  width: 100%;
  text-align: center;
  margin: 0 10px;
}

.dashboard-service .btn {
  padding: 7px 80px;
}

.dashboard-product .tab_header {
  background-color: transparent !important;
  margin: 0px;
  transition: 1s;
}

.dashboard-product li,
.dashboard-product>.tab_header.nav>a {
  margin-right: 2em !important;
}

.dashboard-product .nav-link {
  font-family: "Aktiv-Grotesk-bold";
  color: #737373;
  padding: 12px 0;

}

.dashboard-product .nav-link.active {
  color: #000;
  border-color: #ddd #ddd #fff;
  border-top: solid 2px #2D6DF6;
  margin: -1px;
}

.service-tab {
  border: solid 1px rgba(221, 221, 221, 0);
  color: #000000;
  background-color: #ffffff;
  box-shadow: none !important;
}

.active-service-tab {
  color: #fff;
  background-color: #000000;
  border: solid 1px #ddd;
}

.single-service .btn {
  border-radius: 20px !important;
}

.tab-btn-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.tab-btn-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pad-left-zero {
  padding-left: 0;
}

.pad-right-zero {
  padding-right: 0;
}

.dashboard-filter {
  padding: 10px 0px;
}

.revision-span {
  background-color: #777;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-weight: 400;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.speedometer>.pointer {
  cursor: initial;
}

.image-gallery-image,
.image-gallery-thumbnail-image {
  cursor: pointer;
}

.head_description {
  font-size: 18px;
}

.p_date_section span {
  font-size: 12px;
  margin-right: 3px;
  /* margin-right: 8px; */
}

/* ---------------------slider-style---------------------- */

.image-gallery-slide-wrapper .left {
  height: 325px !important;
}

.slider-nav-container {
  display: flex;
  height: 35px;
  width: 35px;
  background-color: #000;
  border-radius: 0;
  justify-content: center;
  align-items: center;
}

/* ------------------------------------------tagged field css------------------------------------- */
.certified-product .Bold tagged,
.nbb-taggable-field .bold tagged[id='1'] {
  font-weight: bold !important;
}


.certified-product .Italic tagged,
.nbb-taggable-field .italic tagged[id='1'] {
  font-style: italic !important;
}


.certified-product .Underlined tagged,
.nbb-taggable-field .underlined tagged[id='1'] {
  text-decoration: underline !important;
}


.certified-product .Upper tagged,
.nbb-taggable-field .upper_case tagged[id='1'] {
  text-transform: uppercase !important;
}

.uncertified-product tagged,
.nbb-taggable-field tagged[id='1'] {
  font-weight: bold !important;
}

/* ----------------------------brand-title-css during open,maximize,close----------------- */


.nbb-logo .sidebar-minimized {
  padding-left: 45px;
  transition: 0.5s;
}

.data-pool-error-box p {
  white-space: pre-wrap;
  word-break: break-word;
}

.data-pool-wrapper {
  position: absolute;
  right: 62px;
  transform: translateY(-50%);
  top: 40%;
  z-index: 1;
}

.data-pool-error-box {
  border: solid 1px #707070;
  position: relative;
  padding: 10px;
  background-color: #fff;
  min-width: 240px;
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  height: auto;
}

.data-pool-error-box:after {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid 1px #707070;
  transform: rotate(132deg);
  border-right: 0;
  border-bottom: 0;
  background: #fff;
  bottom: -6px;
  right: -6px;
  top: 50%;
}

#modal-root {
  z-index: 9999 !important;
}

.create-nbb-view-input {
  color: #757575;
  font-size: 14px;
  padding-left: 8px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #B3B3B3;
  line-height: normal;
}

.search-attribute-conainer {
  position: relative;
}

.search-attribute-dropdown {
  position: absolute;
  z-index: 10000;
  background: #fff;
  width: 530px;
  right: 0px;
}

.nbb_width {
  width: 80% !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.not-allowed:hover,
.not-allowed::after {
  background: none !important;
  border-color: #000 !important;
  box-shadow: none !important
}

.error-msg {
  font-size: 13px !important;
  color: rgba(226, 19, 61) !important;
  text-align: left;
}

.warning-msg {
  font-size: 13px !important;
  color: rgb(240, 124, 0) !important;
  text-align: left;
}

.announcement-msg {
  font-size: 13px !important;
  color: rgb(4, 4, 4) !important;
  text-align: left;
}

.warn-msg {
  font-size: 13px !important;
  color: rgb(240, 100, 0) !important;
  text-align: left;
}

.error-border-cls {
  border: 1px solid #F00;
}

/* Chrome, Safari, Edge, Opera */
.hide-trigger input::-webkit-outer-spin-button,
.hide-trigger input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-trigger input[type=number] {
  -moz-appearance: textfield;
}

.gdsn-cls h2 {
  display: inline-block;
}


/* Chrome, Safari, Edge, Opera */
.nbb-form-container input::-webkit-outer-spin-button,
.nbb-form-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.nbb-form-container input[type=number] {
  -moz-appearance: textfield;
}

.nutri-block-border {
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
}

.input-error {
  border: 1px solid #F00 !important;
}

.validation-seperator {
  font-size: 16px !important;
  color: #000 !important;
}

.nutrition-border {
  border: 1px solid #f06400 !important;
}

.lower-level-item-icon {
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 1% center;
  text-indent: 17px;
}

.cic_log {
  border: solid 1px #d9d9d9;
  padding: 10px;
  margin-bottom: 2px;
  font-size: 13px;
}

.cic_log b {
  color: #000000;
}

/** --- NBB action required css ------**/
.action-required-panel .card-title {
  font-size: 16px !important;
}

.action-dropdown {
  width: 36%;
  margin-top: -18px;
}

.action-dropdown-width {
  width: 90%
}

.action-item-divider {
  width: 100%;
  border: 1px solid #D9D9D9;
}

.b-right {
  border-right: 1px solid #b3b3b3;
}

.action-sec-height {
  min-height: 100%;
}

.action-accordion-panel {
  background-color: #EEF0F4;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

.data-sec-bg {
  background: #F3F3F3;
}

.data-txt-align {
  text-align: right;
}

.action-component-sec {
  border: 1px solid #242629;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

h4 .rev-font {

  font-size: 16px !important;
  font-weight: normal !important;
}

.stackedImage {
  background: #fafafa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 300px 25px rgba(250, 250, 250, 1) inset;
  width: 70px;
  height: 70px;
  margin: -0.5% 6% auto;
  left: -225px;
  padding: 3px;
  position: sticky;
}

.stackedImage:before {
  content: "";
  background: #fafafa !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), inset 0 0 300px rgba(250, 250, 250, 1);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -2;
  transition: .5s;
}

.stackedImage:before {
  right: 5px;
  top: 2px;
  /* transform: rotate(-0.4deg); */
}

.nbbStackedImage {
  background: #fafafa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 300px 25px rgba(250, 250, 250, 1) inset;
  width: 85px;
  height: 85px;
  /* margin: -0.5% 6% auto; */
  left: -225px;
  padding: 3px;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stackedImage img,
.nbbStackedImage img {
  margin-right: 10px;
  mix-blend-mode: multiply;
}

.nbbStackedImage:before {
  content: "";
  /* background: #fafafa; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), inset 0 0 300px rgba(250, 250, 250, 1);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -2;
  transition: .5s;
}

.nbbStackedImage:before {
  right: 5px;
  top: 5px;
  /* transform: rotate(-0.4deg); */
}

/**------End Acition Required css ------**/

#compare-version-modal .version-modal-dialog {
  width: 1200px !important;
  max-width: 1200px !important;
}

.list-table-action-btn {
  height: 30px !important;
  width: auto !important;
  max-width: 100% !important;
  min-width: auto !important;
  padding: 0 15px 0px 3px !important;
  color: #0056ff;
  border: solid 1px #0056ff;
  border-radius: 2px;
  font-size: 12px;
}
.list-table-action-btn img  {
  filter: invert(65%) sepia(80%) saturate(5991%) hue-rotate(222deg) brightness(101%) contrast(109%);
}
.removed-before-icon{
  color:#004085;
  background-color:#CCE5FF;
  border-color:#B8DAFF
}

.no_product_select_msg{
  margin-left: 1.7rem !important;
}



.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}
.custom-option.custom-select__option--is-focused {
  background: none;
}
.custom-option.custom-select__option--is-selected {
  background: #2684FF;
}

.css-1hb7zxy-IndicatorsContainer{
  align-items: flex-start !important;
  padding-top:8px;
}

.sxp-notify-footer {
  display: flex;
  justify-content: space-between;
}
.sxp-notify-footer > .form-check-label {
  display: flex;
  align-items: center;
}
.sxp-multi-notify{
  box-shadow: 5px -5px rgba(191, 191, 191, .3), 12px -10px #ededed !important
}

.assets-block .form-group:first-child{
  margin-left: 0 !important;
}